import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useAppointmentTypes } from "../hooks";
import { sortAppointmentNamesBy } from "../utils/languageUtils";
import AppointmentCalendar from "../components/AppointmentSchedulerComponent/AppointmentCalendar";
import "./AppointmentSchedulerPage.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import * as AppointmentActions from "../redux/actions/appointmentAction";
import { toast } from "react-toastify";
import previous from "../components/images/previous.png";
import next from "../components/images/next.png";
import warning from "../components/images/warning_red_16_16.png";
import CoachSchedulerCalendar from "../components/CoachSchedulerComponent/CoachSchedulerCalendar";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { CSVLink } from "react-csv";
import {sortByDates} from "../utils/languageUtils"

function AppointmentSchedulerPage(props) {
  const { appointmentSchedules } = props;
  const calRef = useRef(null);
  const calMultiRef = useRef();
  const [appointmentFilter, setAppointmentFilter] = useState({
    appointment_type: [3],
    filterDate: moment()
      .startOf("day")
      .utc()
      .toISOString(),
    //moment returns current timezone date, startOf will set time to 0
  });

  const [showCalendar, setShowCalendar] = useState(false);
  const isFromAppointment = true;

  //multiple select appointment types

  const appointmentTypes = useAppointmentTypes();
  const [checkedItems, setCheckedItems] = useState({});
  const [activeItems, setActiveItems] = useState([]);
  const [goLoader, setGoLoader] = useState(true);
  const [pushScheduleNotice, setpushScheduleNotice] = useState(false);
  const [updatedTimeZone, setUpdatedTimeZone] = useState();
  // const [isFromNavigation, setIsFromNavigation] = useState(false);

  const handleChange = (event) => {
    // mutate the current Map
    checkedItems.set(event.target.name, event.target.checked);
    // update the state by creating a new Map
    setCheckedItems(new Map(checkedItems));
    console.log("checkedItems: ", checkedItems);
  };

  useEffect(() => {
    console.log("checkedItems: ", checkedItems);
  }, [checkedItems]);

  async function onSubmitHandler(selectedDate = null, action = null) {
   setpushScheduleNotice(false);
  //  setSchedulerList([]);
   setGoLoader(true);
    let modals= document.querySelectorAll(".modal .close-icon");
    for (let modal of modals) {
      modal.click();
    }
    if (action == "custom") {
      // setIsFromNavigation(true);
      if (calRef.current != null) {
        let calendarApi = calRef.current.getApi();
        calendarApi.gotoDate(selectedDate);
      }
      setAppointmentFilter({ ...appointmentFilter, filterDate: selectedDate });
      props.actions.getAppointmentSchedules({ ...appointmentFilter, filterDate: selectedDate });
      setShowCalendar(true);
    //  setGoLoader(true);
      return;
    }
    await props.actions.pushAppointmentStatus({
      appointments_push: "true"
    }).then((response) => {
      if (response.locked == 0) {
        props.actions.getAppointmentSchedules(appointmentFilter);
        setShowCalendar(true);
        let currentDate = new Date().toString();
        let CurrentTimeZone = currentDate.split("(")[1];
        setUpdatedTimeZone(CurrentTimeZone);
      } else {
        setpushScheduleNotice(true);
        setShowCalendar(false);
        setGoLoader(false);
        return;
      }
    });
  }

  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setOptions([
      { label: "All", value: "*" },
      ...sortAppointmentNamesBy(appointmentTypes, "title").map(({ id, title }) => ({
        value: id,
        label: title,
      })),
    ]);
    if (appointmentTypes.length != 0) {
      props.actions.getAppointmentSchedules({
        ...appointmentFilter,
        appointment_type: appointmentTypes.map((type) => type.id),
      });
      setShowCalendar(true);
      let currentDate = new Date().toString();
      let CurrentTimeZone = currentDate.split("(")[1];
      setUpdatedTimeZone(CurrentTimeZone);
    }
  }, [appointmentTypes]);

  useEffect(() => {
    let appointmentType = selectedOptions.filter((item) => item.value !== "*");
    setAppointmentFilter({ ...appointmentFilter, appointment_type: appointmentType.map((el) => el.value) });
  }, [selectedOptions]);

  useEffect(() => {
    setSelectedOptions(options);
  }, [options]);

  const loaderCallBack = (data) => {
    setGoLoader(data);
  }

  function onChange(selectedItems, event) {
    //let multiAPI = calMultiRef.current.getApi();
    // let tempActiveItems = []
    // selectedItems.map((item) =>
    // {
    //     tempActiveItems.push(item.value);
    // })
    // setAppointmentFilter({...appointmentFilter, appointment_type: tempActiveItems})
    //setAppointmentFilter({ ...appointmentFilter, appointmentType: selectedDate});
    // setIsFromNavigation(false);
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (event.action === "deselect-option" && event.option.value === "*") {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(selectedItems.filter((o) => o.value !== "*"));
    } else if (selectedItems.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(selectedItems);
    }
    setGoLoader(false);
  }
  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `All`;
    } else if (value.length === 0) {
      return `Select`;
    } else {
      return `${value.length} selected`;
    }
  }

  //calender
  const [config, setConfig] = useState({
    display: true,
    slotDuration: "00:30:00",
  });

  const [schedulerList, setSchedulerList] = useState();
  useEffect(() => {
    if (appointmentSchedules != []) {
      setSchedulerList(appointmentSchedules);
    }
  }, [appointmentSchedules]);

  //Push scheduler
  const allTypes = appointmentTypes.map(({ id }) => `${id}`).join(",");

  const [pushFilter, setPushFilter] = useState({
    appointment_types: "3",
    //      start_time: moment().startOf("day").utc().format("yyyy-MM-DD"),
    appointments_push: true,
  });

  const [bulkSession, setBulkSession] = useState(false);

  function generateCSV(){
    let populateSessions = []
    let list = Array.isArray(schedulerList?.result) ? schedulerList?.result : []
    populateSessions.push(['Session Date', 'Session Time', 'Tutor Name', 'Language'])
    sortByDates(list, "startTime").forEach((session) => {
      // if(session.coaches?.length > 1)
      // {
        session.coaches?.forEach((coach) => {
        populateSessions.push([moment(session.startTime).format("MM/DD/YYYY"), moment(session.startTime).format("hh:mmA"), coach, "Appointment"])
        })
      // }
      // else
      // {
      //   populateSessions.push([session.startTime, session.startTime, session.coaches[0], session.language])
      // }
    })
    return populateSessions;
  }

  function handlePushScheduleClicked() {
    props.actions
      .pushAppointmentSchedules(
        { ...pushFilter, appointment_types: allTypes, start_time: appointmentFilter.filterDate },
        appointmentFilter
      )
      .then((response) => {
        toast.success(response);
        if(response != "No session to push") {
          setpushScheduleNotice(true);
          setShowCalendar(false);
        } 
      })
      .catch((err) => {
        console.log(err);
        toast.success("Failed to Pushed Appointment Schedules, Try again.");
      });
  }

  return (
    <div className="right-content apppointment-scheduler">
      <div className="appointment-schedule">
        <div className="title">
          <h2>All Appointments</h2>
        </div>
        <div className="week-info">
          <div>
            <div className="today-date-section">
              <span className="today-date">Today&apos;s Date:</span>
              <span className="today-date-in-dashboard">{moment(new Date()).format("dddd MMMM DD,YYYY")}</span>
            </div>
            <div className="coach-session-info-div">
              <div>
                <span className="bold-font">Confirmed: </span>
                <span>{schedulerList?.counts?.scheduled} </span>,
              </div>
              <div>
                <span className="bold-font">Unconfirmed: </span>
                <span>{schedulerList?.counts?.unConfirmedAppointment}</span>,
              </div>
              <div>
                <span className="bold-font">Cancelled: </span>
                <span>{schedulerList?.counts?.cancelled}</span>,
              </div>
              {/* <div>
                                <span className="bold-font">Substituted: </span>
                                <span>{appointmentSchedules.counts.scheduled}</span>
                            </div>*/}
            </div>
          </div>
        </div>
        {/* <div className="substitution-alert-div">
                    <div className="substitution-alert">
                        <table className="sub-alert-table">
                            <tbody>
                                <tr>
                                    <td className="substitution-needed">SUBSTITUTION NEEDED!</td>
                                    <td className="sub-data-div">
                                        <table className="sub-data">
                                            <tbody>
                                                <tr>
                                                    <td className="width3">
                                                        <img className="warning_icon" src={warning} />
                                                    </td>
                                                    <td className="width25 substitution-needed"></td>
                                                    <td className="width25">World Wide English</td>
                                                    <td className="width15">Wed,&nbsp;Mar 03,&nbsp;2021</td>
                                                    <td className="width10">04:30PM</td>
                                                    <td className="width10">L-1,&nbsp;U-1</td>
                                                </tr>
                                                <tr>
                                                    <td className="width3"></td>
                                                    <td className="width25 substitution-needed"></td>
                                                    <td className="width25">World Wide English </td>
                                                    <td className="width15">Thu,&nbsp;Mar&nbsp;04,&nbsp;2021</td>
                                                    <td className="width10">01:00AM</td>
                                                    <td className="width10">L-1,&nbsp;U-1</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="see-all-substitutions">
                                        <font className="substitution-font">
                                            <a href="/substitutions"> see all sub requests</a>
                                        </font>
                                    </td>
                                    <td>
                                        <div className="substitution-close-button">X</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> */}
        <div className="scheduler-form-data"> 
          <input
            className="today-button"
            type="button"
            value="Today"
            onClick={(e) => {
              onSubmitHandler(
                moment()
                  .endOf("day")
                  .toISOString(),
                "custom"
              );
              // setGoLoader(false);
            }}
          />
          <div className="calendar-data">
            <button className="arrow">
              <img
                alt="Previous Week"
                onClick={(e) => {
                  onSubmitHandler(
                    moment(appointmentFilter.filterDate)
                      .subtract(7, "days")
                      .startOf("week")
                      .endOf("day")
                      .toISOString(),
                    "custom"
                  );
                  // setGoLoader(false);
                }}
                className="previous-week"
                src={previous}
                title="Previous Week"
              />
            </button>
            <DatePicker
              selected={new Date(appointmentFilter.filterDate)}
              onChange={(date, e) => {
                e.preventDefault;
                setAppointmentFilter({
                  ...appointmentFilter,
                  filterDate: date,
                });
                // setPushFilter({
                //     ...pushFilter,
                //     start_time:moment(date).format("yyyy-MM-DD")
                // })
                e.target.value = date;
                props.actions.getAppointmentSchedules({ ...appointmentFilter, filterDate: date });
                setShowCalendar(true);
                setGoLoader(true);
              }}
              value={
                moment(appointmentFilter.filterDate)
                  .startOf("week")
                  .format("MMMM DD, YYYY") +
                "    -    " +
                moment(appointmentFilter.filterDate)
                  .endOf("week")
                  .format("MMMM DD, YYYY")
              }
              className="date-picker"
            />
            <button href="#" className="arrow">
              <img
                alt="Next Week"
                className="next-week"
                src={next}
                title="Next Week"
                onClick={(e) => {
                  onSubmitHandler(
                    moment(appointmentFilter.filterDate)
                      .add(7, "days")
                      .startOf("week")
                      .endOf("day")
                      .toISOString(),
                    "custom"
                  );
                  // setGoLoader(false);
                }}
              />
            </button>
          </div>
          <div className="appointmentType appointment-multiselect">
            {appointmentTypes.length > 0 && (
              <ReactMultiSelectCheckboxes
                options={options}
                value={selectedOptions}
                onChange={onChange}
                ref={calMultiRef}
                setState={setSelectedOptions}
                getDropdownButtonLabel={getDropdownButtonLabel}
              />
            )}

            {/* {appointmentTypes.length > 0 &&
                            appointmentTypes.map(({ id, title }) => (
                                <div key={id}>
                                    <label>
                                        <input type="checkbox" name={id} checked={checkedItems[title]} onChange={handleChange} />
                                        {title}
                                    </label>
                                    <br />
                                </div>
                            ))
                        } */}
          </div>
          <input className="go-button" type="button" value="GO" onClick={onSubmitHandler} />
        </div>
      </div>
      {pushScheduleNotice && (
          <div className="push-notice">
            <p>
              Currently, sessions are being pushed to eSchool for this language.
              <span style={{ display: "none" }}>Out of 68 sessions, 0 have been pushed.</span>
            </p>
            <p style={{ display: "none" }}>
              Korean sessions Pushed : 0 | Korean sessions in eSchool queue : 68 | Total sessions in eSchool queue : 68
            </p>
          </div>
        )}
      <div className="week-calendar">
        {/* <AppointmentCalendar /> */}
        <CoachSchedulerCalendar
          config={config}
          schedulerList={schedulerList?.result}
          coachFilter={appointmentFilter}
          showCalendar={showCalendar}
          isFromAppointment={true}
          calRef={calRef}
          double={goLoader}
          // isFromNavigation={isFromNavigation}
          onDoubleChange={(data) => loaderCallBack(data)}
          updatedTimeZone={updatedTimeZone}
          bulkSession={bulkSession}
          setBulkSession={setBulkSession}
          appointmentFilter={appointmentFilter}
        />
      </div>

      {showCalendar && (
            <div className="download-csv">
              <CSVLink
                data={generateCSV()}
                filename={`Appointments_${moment().format('MMDDYYYYThh:mmA(Z)')}.csv`} className="" target="_blank" style={{"float": "right", "color":"#222",
                  "padding": "5px 17px","border": "1px solid #555","marginTop": "10px","marginRight": "18px"}}
              >
                Download CSV
              </CSVLink>
            </div>
      )}

      <div className="push-schedule">
        <input type="submit" value="PUSH SCHEDULE" name="commit" onClick={handlePushScheduleClicked}></input>
      </div>
    </div>
  );
}

AppointmentSchedulerPage.propTypes = {
  actions: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  appointmentSchedules: state.appointmentSchedules,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      getAppointmentSchedules: bindActionCreators(AppointmentActions.getAppointmentSchedules, dispatch),
      pushAppointmentSchedules: bindActionCreators(AppointmentActions.pushAppointmentSchedules, dispatch),
      pushAppointmentStatus: bindActionCreators(AppointmentActions.pushAppointmentStatus,dispatch)
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentSchedulerPage);
