import { handleResponse, handleError , getRoute} from "./apiUtils";


const baseUrl = getRoute().AUTH_API_URL + "/api/login";
const switchUserUrl = getRoute().AUTH_API_URL + "/api/login-superuser"

export function login(data, userTimeZone = null) {    
  
  return fetch(baseUrl, {
        method: "POST", 
        headers: {'Accept': 'application/json','Content-Type': 'application/json','Access-Control-Allow-Origin': '*','Access-Control-Allow-Headers': '*',  'Access-Control-Allow-Methods':'PUT, GET, POST, DELETE, OPTIONS', 'Time-Zone': userTimeZone},
        body: JSON.stringify(data),
    })
    .then(handleResponse)
    .catch(handleError);
}

export function switchUser(data, userTimeZone = null) {    
  
  return fetch(switchUserUrl, {
        method: "POST", 
        headers: {'Accept': 'application/json','Content-Type': 'application/json','Access-Control-Allow-Origin': '*','Access-Control-Allow-Headers': '*',  'Access-Control-Allow-Methods':'PUT, GET, POST, DELETE, OPTIONS', 'Time-Zone': userTimeZone,
        "x-access-token": localStorage.getItem("token")},
        body: JSON.stringify(data),
    })
    .then(handleResponse)
    .catch(handleError);
}


