import { GET_PRODUCT_REPORT } from "../constants";
import { getProductReport } from "../../apis/ProductReportApi";

export function getProductRights(params) {
  return function(dispatch) {
    return getProductReport(params)
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_REPORT,
          productRights: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}