/* eslint-disable react/no-unknown-property */
import React ,{ useState, useEffect, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import propTypes from 'prop-types'
import decode from "jwt-decode"
import { useStateWithCallbackLazy } from "use-state-with-callback";

import * as alertActions from '../../redux/actions/coachAlertAction'
import StudioTeamOndutyModal from "./StudioTeamOndutyModal.jsx"
import logo from '../images/rs-logo.png'
import timerImg from '../images/icon_load.png'
import Modal from "./Modal.jsx"
import warning from "../images/warning_red.png"
import './Header.css'
import * as coachSchedulerApi from "../../apis/CoachSchedulerApi";
import moment from "moment";
import { getLanguageByIdentifier } from "../../utils/languageUtils";
import { useVillage } from "../../hooks";
import { switchUser } from "../../redux/actions/userAction";
import { getStartPageURL } from "../../utils/languageUtils";



function Header({...props}) {

    const history = useHistory();
    const [toggle, setToggle] = useState(false);
    const [onHoldToggle, setOnHoldToggle ] = useState(false);
    const user = decode(localStorage.getItem('token'));
    //const [props.recentSession, setprops.recentSession] = useState([]);
    const villages = useVillage();
    const [settings, setSettings] = useState({
      displayDetails: false,
      xAxis: "0px",
      yAxis: "0px",
      xDistance: "0px",
      yDistance: "0px",
    });
    const [modalOpen, setModalOpen] = useState({ open: false, email: "", timeZone: "", guid: "", xAxis: "0px", yAxis: "0px" });
    const [cancelModalOpen, setCancelModalOpen] = useStateWithCallbackLazy({ toggle: false });
    const [userType, setUserType] = useState("");

    const sessionType = (type) => {
      switch (type) {
        case "ConfirmedSession":
          return "SESSION DETAILS";
        case "Appointment":
          return "APPOINTMENT DETAILS";
      }
    };
    const [timer, setTimer] = useState(0);
    // const [intervalObject, setIntervalObject] = useState(null);
     useEffect(() => {
      //setInterval(() => { setTimer(JSON.stringify(moment().format('mm'))) }, 1000);
      if(props.recentSession.length != 0)
      {
 //     moment(moment(props.recentSession[0].session_start_time) - moment()).format('mm'))
      let shutTimer;

      shutTimer = setInterval(() => {
        setTimer(moment(moment(props.recentSession[0].session_start_time) - moment()).format('mm'))
        // if(timer <= 0){
        //   clearInterval(shutTimer);
        // }
        console.log("Timer Executes" + timer)
      }, 2000)
      //setIntervalObject(shutTimer);
      }
      // else if(props.recentSession.length == 0 && intervalObject)
      // {
      //   console.log("Inside Interval Object");
      //   clearInterval(intervalObject)
      // }
     }, [props.recentSession])

     function extractIdentifier(language) {
      let identifier = language.split("-");
      return identifier.length == 2
        ? identifier[0].toLowerCase()
        : identifier.length == 3
        ? "fbsolo"
        : "totale";
    }

    const launchOnline = function(launch_string) {
      const config =
        "menubar=0,resizable=1,scrollbars=0,toolbar=0,location=0,directories=0,status=0,top=0,left=0";
      let child_window;
      child_window = window.open(launch_string, "launch_window", config);
    };

    function switchBack(username, e){
      e.preventDefault();
      switchUser({user_name:username})
      .then((res)=>
      {
        localStorage.setItem("previous_user", "")
        localStorage.setItem("token", res.authKey)
      })
      .then(() => {
          const switched = true;
          window.location.href="/"+getStartPageURL("HOME")+"?switched="+`${switched}`+"&prevUser="+`${user.full_name}`;
      }).catch(e =>{
         // setErrors("Invalid Username/Password");
           console.log("Error in Switching User" + e);
          // let { error_message } = JSON.parse(e.message);
          // setErrors(error_message);
      });
    }


    const LOADING = "LOADING";
    const LOADED = "LOADED";
    function loadingReducer(state = false, action) {
      if (action.type === LOADING) return true;
      if (action.type === LOADED) return false;
      return state;
    }

    const [loading, loadingDispatch] = useReducer(loadingReducer, false);

    const cancelLoading=() => {
      loadingDispatch({ type: LOADED });
    }

    function coachAlert(e){
      e.preventDefault();
      setToggle(!toggle);
      setUserType(user.type);
    }

    useEffect(()=> {
      switch (window.location.hostname) {
        case "csp-ui-qa3.dev.rosettastone.com":
          window.location="https://coachportal-qa3.dev.rosettastone.com/";
          break;
        case ("csp-ui.rosettastone.com"):
          window.location="https://coachportal.rosettastone.com/";
          break;
      }
      const { alerts, actions } = props;

      console.log("=========> inside header useeffect");
      actions.getAlerts().catch(err => console.log(err));

      console.log(alerts);
    },[])

    function onTimerClick(e){
      e.preventDefault();
      // if (props.isFromAppointment == true) {
      //   setAppointmentDisplay({
      //     ...appointmentDisplay,
      //     ["displayAppointmentDetails"]: true,
      //     // appointment_date: e.event.start,
      //     // isFromEvent: true,
      //   });
      //   loadingDispatch({ type: LOADING });

      // } else if (props.isFromAppointment == false) {
        setSettings({
          ...settings,
          ["displayDetails"]: true
          // ["xAxis"]: xDistance - 380 - 94,
          // ["yAxis"]: yDistance - 105,
          // ["xDistance"]: xDistance,
          // ["yDistance"]: yDistance,
        });
    //    loadingDispatch({ type: LOADING });
      //}
    }


    function signOut(e){
      e.preventDefault();
      localStorage.removeItem("token");
      localStorage.removeItem("previous_user");
      history.push({
        pathname: "/",
        state: { loggedOut: true }
      })

      window.location.reload();
    }
    console.log(user);
    function liveSupportChat(e) {
      e.preventDefault();
      const config =
    "menubar=0,resizable=1,scrollbars=0,toolbar=0,location=0,directories=0,status=0,top=0,left=0,height=705,width=464";
      console.log(location.href);
      let supportURL = '';
      if(location.href.indexOf('qa3') != -1 || location.href.indexOf('dev') != -1 || location.href.indexOf('localhost') != -1) {
        supportURL = "http://rosettastone.fullsb.cs169.force.com/liveeventprechat";
      } else {
        supportURL = "http://rosettastone.force.com/liveeventprechat";
      }
      const supportURLWithParam = supportURL+"?language=en-US&email="+encodeURIComponent(user.rs_email)+"&firstName="+user.full_name+"&lastName=";
      if(confirm("This will open a new window so that you may talk with a Support representative. Are you sure you want to continue?")) {
        window.open(supportURLWithParam, "support_window", config);
      } else {
        console.log('cancel');
      }
    }
    function getURL(url){
      if(url === "csp-ui-dev.dev.rosettastone.com"){
        return "dev"
      } else if(url === "csp-ui-qa3.dev.rosettastone.com"){
        return "staging"
      } else if(url ==="csp-ui.rosettastone.com"){
        return "production beta"
      } else if(url ==="coachportal.rosettastone.com"){
        return "production"
      } else{
        return 0
      }
    }

    //Next Sesison Changes

    useEffect(()=>{
      if(modalOpen["open"]){
        let session_modal = document.getElementById("header-coach-schedule-session-details");
        session_modal.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
    },[modalOpen]);

    //Next Session

    return (
      <div className="header">
        <Modal key={toggle}
          coachAlert={coachAlert} toggle={toggle} setToggle={setToggle}
          alert={props.alerts[props.alerts.length - 1]}
          addAlert={props.actions.addAlert}
          getAlert={props.actions.getAlerts}
          removeAlert={props.actions.removeAlert}
          userType={user.type}/>
        <StudioTeamOndutyModal toggle={onHoldToggle} changeToggle={setOnHoldToggle} userType={user.type}/>
      <div className="left_strip">
        <div>
          <a href="">
          <img alt="rs-logo" src={logo} className="logo" title="Rosetta Stone"/>
          </a>
        </div>
        <h1>Customer Success Portal
        </h1>
        {getURL(window.location.hostname) !== 0  && (
          <div className="inst_container">
            <div className="instance">{getURL(window.location.hostname)}</div>
          </div>
        )}
      </div>

      {/* {(moment(moment(dummySession[0].session_start_time) - moment()).format('mm')) <= 10} */}
      {props.recentSession.length != 0 && user.type == "Coach" && timer < 11 && (
      <div>
        <div style={{marginTop: "14px", marginLeft: "14px", color: "white", float: "left"}}>

          {/* {"In " + (moment(moment(props.recentSession[0].session_start_time) - moment()).format('mm')) + " minutes"} */}
          {"In " + timer + " minutes "}
           <img src={timerImg} alt="timer" onClick={(e) => onTimerClick(e)}/>
          {/* {"In " + timer + " minutes"} */}
        </div>

          {/* {props.recentSession[0] != undefined && props.recentSession[0] != {} && ( */}
          <div
            className="modal coach-schedule-session-details-modal"
            id="header-coach-schedule-session-details"
            style={{
              display: settings.displayDetails ? "block" : "none",
              left: settings.xAxis + "px",
              top:
                props.recentSession[0].type === "ConfirmedSession"
                  ? settings.yAxis - 164 + "px"
                  : settings.yAxis - 108,
            }}
          >
            <div className="modal-dialog">
              {loading ? (
                <div>LOADING....</div>
              ) : (
                <div className="modal-content">
                  <div className="modal_header">
                    <h4 className="modal-title">
                      {"NEXT SESSION / APPOINTMENT DETAILS"}
                    </h4>
                    <a
                      className="close-icon"
                      title="Close"
                      aria-label="Close"
                      role="button"
                      data-dismiss="modal"
                      onClick={(e) => {
                        e.preventDefault();
                        cancelLoading();
                        setSettings((settings) => ({
                          ...settings,
                          ["displayDetails"]: false,
                        }));
                      }}
                    >
                      <span>×</span>
                    </a>
                  </div>
                  <div className="modal-body">
                    <div className="row1">
                      <div className="left-section">
                        <div>
                          <span>
                            {moment(
                              moment(props.recentSession[0].session_start_time).format()
                            ).format("ddd MM/DD/YY hh:mm A")}
                          </span>

                          {/* <input className="cancel-button" type="button" value="CANCEL" /> */}
                        </div>
                        {props.recentSession[0].type != "Appointment" &&
                          props.recentSession[0].rstv_guid == null && (
                            <div>
                              <div>
                                {getLanguageByIdentifier(
                                  props.recentSession[0].language_identifier
                                )}
                              </div>
                              {props.recentSession[0].teacher_confirmed == "" &&
                                props.recentSession[0].sub_requested == 0 && (
                                  <div className="red">Unconfirmed</div>
                                )}
                              {villages.length > 0 &&
                                villages.map(({ id, name }) =>
                                  props.recentSession[0].external_village_id == id ? (
                                    <div key={id}>{name}</div>
                                  ) : null
                                )}
                            </div>
                          )}
                      </div>

                    </div>

                    {props.recentSession[0].type == "ConfirmedSession" && (
                      <div>
                        <div className="row2">
                          <div className="left-section">
                            {props.recentSession[0].learners_signed_up != null &&
                              props.recentSession[0].rstv_guid == null && (
                                <div>
                                  {props.recentSession[0].learners_signed_up == 0 &&
                                    extractIdentifier(
                                      props.recentSession[0].language_identifier
                                    ) != "cp" && (
                                      <div>No Learners have signed up.</div>
                                    )}
                                  {props.recentSession[0].learners_signed_up > 0 &&
                                    props.recentSession[0].learner_details.attendance !=
                                      [] && (
                                      // <div>No of Learners have signed up: {dummySession[0].learners_signed_up}</div>
                                      <div>

                                        {props.recentSession[0].topic &&
                                          <div>
                                            { props.recentSession[0].topic.title &&
                                              <>
                                                <span style={{float: "left"}}>Topic:</span>

                                                <div style={{float: "left"}}>

                                                  {props.recentSession[0].topic.title}
                                                </div>
                                                <br />
                                              </>
                                            }
                                          </div>
                                        }

                                        Learners:
                                        {props.recentSession[0].learners_signed_up == 1 ? (
                                          <div>
                                            <a href="#" target="_blank">
                                              {
                                                props.recentSession[0].learner_details
                                                  .attendance.first_name
                                              }{" "}
                                              {
                                                props.recentSession[0].learner_details
                                                  .attendance.last_name
                                              }
                                            </a>
                                            <br />
                                          </div>
                                        ) : (
                                          <div>
                                            {" "}
                                            {props.recentSession[0].learner_details.attendance.map(
                                              (learner) => (
                                                <div key={learner.student_guid}>
                                                  <a href="#" target="_blank">
                                                    {learner.first_name}{" "}
                                                    {learner.last_name}
                                                  </a>
                                                  <br />
                                                </div>
                                              )
                                            )}{" "}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                </div>
                              )}
                            {props.recentSession[0].rstv_guid == null &&
                              extractIdentifier(
                                props.recentSession[0].language_identifier
                              ) != "cp" && (
                                <div>
                                  {" "}
                                  Avg attendance/session :{" "}
                                  {props.recentSession[0].average_attendance != null
                                    ? props.recentSession[0].average_attendance
                                    : 0.0}
                                </div>
                              )}
                            {props.recentSession[0].rstv_guid != null && (
                              <div>
                                <div>
                                  Language:{" "}
                                  {getLanguageByIdentifier(
                                    props.recentSession[0].language_identifier
                                  )}
                                </div>
                                <div>Coach: {props.recentSession[0].coach_name}</div>
                                <div>Topic: {props.recentSession[0].rstv_topic_title}</div>
                                <div>
                                  Moderator: {props.recentSession[0].moderator_name}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row3">
                          <div className="left-section">
                            {props.recentSession[0].rstv_guid == null && (
                              <div>
                                Eschool Session Id :
                                {props.recentSession[0].eschool_session_id != null
                                  ? props.recentSession[0].eschool_session_id
                                  : 0}
                              </div>
                            )}
                          </div>
                          {props.recentSession[0].cancelled == "false" &&
                            extractIdentifier(
                              props.recentSession[0].language_identifier
                            ) != "cp" && (
                              <div className="right-section">
                                {user.type == "Coach" &&
                                  (extractIdentifier(
                                    props.recentSession[0].language_identifier
                                  ) == "totale" ? (
                                    moment() >
                                    moment(
                                      props.recentSession[0].session_start_time
                                    ).subtract(10, "minutes") ? (
                                      <>
                                        <div className="session-feedback-button">
                                          <a
                                            onClick={(e) => {
                                              e.preventDefault();
                                              let arr = props.recentSession[0].launch_session_url
                                                ? props.recentSession[0].launch_session_url.split(
                                                    "'"
                                                  )[1]
                                                : "";
                                              //   .split(",");
                                              launchOnline(arr); //[0], arr[1]);
                                            }}
                                            rel="noreferrer"
                                            target="_blank"
                                          >
                                            Launch Session
                                          </a>
                                        </div>
                                        <div className="session-feedback-button feedback-disabled">
                                          Session Feedback
                                        </div>
                                      </>
                                    ) : (
                                      <div className="session-feedback-button feedback-disabled">
                                        Session Feedback
                                      </div>
                                    )
                                  ) : (
                                    <div className="session-feedback-button">
                                      <a
                                        onClick={(e) => {
                                          e.preventDefault();
                                          let arr = props.recentSession[0].launch_session_url
                                            ? props.recentSession[0].launch_session_url.split(
                                                "'"
                                              )[1]
                                            : "";
                                          //   .split(",");
                                          launchOnline(arr); //[0], arr[1]);
                                        }}
                                        rel="noreferrer"
                                        target="_blank"
                                      >
                                        Launch Session
                                      </a>
                                    </div>
                                  ))

                                }

                              </div>
                            )}
                        </div>
                      </div>
                    )}
                    {props.recentSession[0].type == "Appointment" && (
                      <div>
                        <div className="row2">
                          <div className="left-section">
                            <div>
                              Appointment: {props.recentSession[0]?.appointment_title}
                            </div>
                            <br />
                            <div>
                              <strong>Appointment Details:</strong>
                              <br />
                              {props.recentSession[0]?.details}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        {/* )} */}
        </div>)}
      <div className="right_strip">
        <ul className="links">
          <li className="link">
            { user.type !== "Coach" ?
              <>
                <a href="" onClick={coachAlert} >Coach Alert </a>
                { props.alerts.length > 0 && <img src={warning} width="15" height="15" title="Coach alert"/> }
              </>
               :
              <>
                { props.alerts.length > 0 &&
                <>
                  <a href="" onClick={coachAlert} >Coach Alert </a>
                  <img src={warning} width="15" height="15" title="Coach alert"/>
                </>
                }
              </>
            }
          </li>
          {
            (user.type === "CoachManager" || user.type === "Coach") &&
          <>
          <li className="link">
            <a onClick={(e)=>{ e.preventDefault(); setOnHoldToggle(true);
            setUserType(user.type)}}>Studio Team On Duty</a>
          </li>
          <li className="link">
            <a href="" className="live_help" url="" onClick={liveSupportChat}>Live Support Chat</a>
          </li>
          </>
          }

        </ul>
        <ul className="authenticate">
          <li>Welcome, {user.full_name}</li>
          <li><a href="/signOut" onClick={signOut} style={{"color": "#ccc","textDecoration": "none","borderBottom": 0}}>Sign Out</a></li>
          {localStorage.getItem("previous_user") != null && localStorage.getItem("previous_user") != "" && user.type == "Coach" &&
            <>
              <br />
              <li style={{"color": "#ccc","textDecoration": "none","borderBottom": 0, "cursor": "pointer"}}>
                <a onClick={(e) => switchBack(localStorage.getItem("previous_user"), e)}>Sign in as {localStorage.getItem("previous_user")}</a></li>
            </>
          }
        </ul>
      </div>
  </div>
    )
}


Header.propTypes = {
  alerts: propTypes.array.isRequired,
  actions: propTypes.object.isRequired
}

function mapStateToProps(state){
    return { alerts: state.alerts,
    recentSession: state.coachSheduler.futureSession
   }
}

function mapDispatchToProps(dispatch){
  return {
    actions: {
      addAlert: bindActionCreators(alertActions.add,dispatch),
      getAlerts: bindActionCreators(alertActions.getAlerts,dispatch),
      removeAlert: bindActionCreators(alertActions.removeAlert,dispatch)
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Header);
