import React from "react";
import "./LearnerDetailsModal.css";

function LearnerDetailsModal({ modalOpen, setModalOpen, ...props }) {
  console.log("5", modalOpen);
  return (
    // <div className="container">
    //   <button type="button" data-toggle="modal" data-target="#coach-schedule-learner-modal">
    //     LEARNER DETAILS
    //   </button>
    <div
      className="modal coach-schedule-session-details-modal"
      style={{ display: modalOpen.open ? "block" : "none", left: modalOpen.xAxis + "px", top: modalOpen.yAxis + "px" }}
      id="coach-schedule-learner-modal"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal_header">
            <h4 className="modal-title">LEARNER DETAILS</h4>
            <a
              className="close-icon"
              title="Close"
              aria-label="Close"
              role="button"
              data-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                setModalOpen((data) => ({ ...data, open: false }));
              }}
            >
              <span>×</span>
            </a>
          </div>
          <div className="modal-body">
            <div className="left-section">
              <a className="view-profile" onClick={() => window.open(`/view_learner?licenseGuid=${modalOpen.guid}`, "_blank")}>
                View Profile
              </a>
            </div>
            <div className="right-section">
              <div>
                <label>Email:</label>
                <span>{modalOpen.email}</span>
              </div>
              <div>
                <label>Time Zone:</label>
                <span>{modalOpen.timeZone}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default LearnerDetailsModal;
