import {
  GET_COACH_SCHEDULER_SUCCESS,
  ADD_SESSION_DETAILS_SUCCESS,
  GET_SESSION_DETAILS_SUCCESS,
  UPD_COACH_SCHEDULER_SUCCESS,
  REQUEST_SUBSTITUTE_COACH,
  GET_AVAILABLE_COACHES,
  GET_TIME_OFF_LIST_SUCCESS,
  CREATE_TIME_OFF_LIST_SUCCESS,
  GET_FUTURE_SESSION
} from "../constants";
import * as coachSchedulerApi from "../../apis/CoachSchedulerApi";
import moment from "moment";

export function addCoachSessions(session, params = {}) {
  const startTime = moment(session.session_start_time, "YYYY-MM-DD HH:mm:ss").toISOString();
  const endTime = moment(session.session_end_time, "YYYY-MM-DD HH:mm:ss").toISOString();
  let { language_type, ...data } = session;
  data = {
    ...data,
    external_village_id: data.external_village_id === "no-village" ? "" : data.external_village_id,
    session_start_time: moment(startTime)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss"),
    session_end_time: moment(endTime)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss"),
  };

  if (data.is_recurring) {
    const recurstart = startTime;
    const recurend = endTime;
    const recurring_start_date = data.session_start_time;
    //const day_index = moment(recurring_start_date).isoWeekday() - 1;
    const day_index = moment.utc(recurring_start_date).day();

    data = { ...data, recurring_start_date, recurstart, recurend, day_index };
  }

  console.log("data========", data);
  return function(dispatch) {
    return coachSchedulerApi
      .addCoachSession(data)
      .then((response) => {
        // [{"rstv_guid":"3257acdc-cea4-4188-a095-eb41376803a2","coach_session_id":682976,"slotType":"session"}]
        // const sessionDetail =
        //   data.type == "session"
        //     ? language_type === "rstv"
        //       ? {
        //           ...data,
        //           startTime,
        //           endTime,
        //           language_type,
        //           slotType: "cs_solid_session_slot",
        //           rstv_guid: response.data[0].rstv_guid,
        //           coach_session_id: response.data[0].coach_session_id,
        //         }
        //       : {
        //           ...data,
        //           startTime,
        //           endTime,
        //           language_type,
        //           slotType: "cs_solid_session_slot",
        //           id: response.data[0].eschool_sessions.eschool_session.external_session_id,
        //         }
        //     : {
        //         ...data,
        //         startTime,
        //         endTime,
        //         language_type,
        //         slotType: "cs_appointment_session_slot",
        //         id: response.data[0].insertId,
        //       };
        // dispatch({
        //   type: ADD_SESSION_DETAILS_SUCCESS,
        //   sessionDetail,
        // });
        return coachSchedulerApi.getCoachScheduler(params).then((response) => {
          dispatch({
            type: GET_COACH_SCHEDULER_SUCCESS,
            coachSheduler: response.data,
          });
        });
      })
      .catch((err) => {
        // console.log(err);
        // console.log(err.message);
        throw err;
      });
  };
}

export function getCoachScheduler(params) {
  return function(dispatch) {
    return coachSchedulerApi
      .getCoachScheduler(params)
      .then((response) => {
        console.log("response", response.data);
        dispatch({
          type: GET_COACH_SCHEDULER_SUCCESS,
          coachSheduler: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getSessionDetails(params) {
  console.log(params);
  return function(dispatch) {
    return coachSchedulerApi
      .getSessionDetails(params)
      .then((response) => {
        console.log("response", response.data[0]);
        dispatch({
          type: GET_SESSION_DETAILS_SUCCESS,
          sessionDetails: response.data[0],
        });
        return response.data[0];
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getClosestSessions() {
  return function(dispatch) {
    return coachSchedulerApi
      .getClosestSessions()
      .then((response) => {
        console.log("response closest session", response.data);
        dispatch({
          type: GET_FUTURE_SESSION,
          futureSession: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function requestSubstituteCoach(requestSubParams, params) {
  console.log(requestSubParams);
  return function(dispatch) {
    return coachSchedulerApi
      .requestSubstituteCoach(requestSubParams)
      .then((response) => {
        console.log("response", response.data);
        return coachSchedulerApi.getCoachScheduler(params).then((response) => {
          dispatch({
            type: GET_COACH_SCHEDULER_SUCCESS,
            coachSheduler: response.data,
          });
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function assignSubstituteCoach(assignSubstituteParams, params) {
  console.log(assignSubstituteParams);
  return function(dispatch) {
    return coachSchedulerApi
      .assignSubstituteCoach(assignSubstituteParams)
      .then((response) => {
        if(response.data == "This session was reassigned recently!")
        {
          alert(response.data);
          return false;
        }
        return coachSchedulerApi.getCoachScheduler(params).then((response) => {
          dispatch({
            type: GET_COACH_SCHEDULER_SUCCESS,
            coachSheduler: response.data,
          });
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function updateSessionDetail(session, params) {
  console.log("---------->" + JSON.stringify(session));
  const startTime = moment(session.session_start_time).format("YYYY-MM-DD HH:mm:ss");
  const endTime = moment(session.session_end_time).format("YYYY-MM-DD HH:mm:ss");

  let { language_type, ...data } = session;

  if (data.is_recurring) {
    const recurstart = startTime;
    const recurend = endTime;
    const recurring_start_date = data.session_start_time;
    //const day_index = moment(recurring_start_date).isoWeekday() - 1;
    const day_index = moment.utc(recurring_start_date).day();

    data = { ...data, recurring_start_date, recurstart, recurend, day_index };
  }

  return function(dispatch) {
    return coachSchedulerApi
      .updateSessionDetail(data)
      .then(() => {
        // console.log(response);
        // dispatch({
        //   type: UPD_COACH_SCHEDULER_SUCCESS,
        //   sessionDetail: { ...data, startTime, endTime },
        //   language_type,
        // });
        return coachSchedulerApi.getCoachScheduler(params).then((response) => {
          dispatch({
            type: GET_COACH_SCHEDULER_SUCCESS,
            coachSheduler: response.data,
          });
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function checkViolationPolicy(params) {
  console.log(params);
  return function(dispatch) {
    return coachSchedulerApi
      .checkViolationPolicy(params)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function checkRecurrings(params) {
  // console.log(params);
  // return function(dispatch) {
  return coachSchedulerApi
    .checkRecurrings(params)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
  // };
}

export function cancelCoachSession(cancelCoachSessionParams, params) {
  console.log(cancelCoachSessionParams);
  return function(dispatch) {
    return coachSchedulerApi
      .cancelCoachSession(cancelCoachSessionParams)
      .then(() => {
        return coachSchedulerApi.getCoachScheduler(params).then((response) => {
          dispatch({
            type: GET_COACH_SCHEDULER_SUCCESS,
            coachSheduler: response.data,
          });
        });
        //return response.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getAvailableCoaches(params) {
  console.log(params);
  return function(dispatch) {
    return coachSchedulerApi.getAvailableCoaches(params).then((response) => {
      dispatch({
        type: GET_AVAILABLE_COACHES,
        availableCoaches: response.data,
      });
    });
  };
}

//   export function createCoach(data) {
//     console.log("---------->" + JSON.stringify(data));
//     return function(dispatch) {
//       return coachProfileApi
//         .createCoachProfile(data)
//         .then((response) => {
//           console.log(response)
//           dispatch({
//             type: UPD_COACH_PROFILE_SUCCESS,
//             coachProfile: response.data,
//           });
//         })
//         .catch((err) => {
//           throw err;
//         });
//     };
//   }

export function getTimeOff(params) {
  return function(dispatch) {
    return coachSchedulerApi
      .getTimeOffApi(params)
      .then((response) => {
        console.log("response", response.data);
        dispatch({
          type: GET_TIME_OFF_LIST_SUCCESS,
          getTimeOffList: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function createTimeOff(params, usertId) {
  return function(dispatch) {
    return coachSchedulerApi
      .createTimeOffApi(params)
      .then((response) => {
        console.log("response", response);
        dispatch(getTimeOff(usertId));
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function cancelTimeOff(params, usertId) {
  return function(dispatch) {
    return coachSchedulerApi
      .cancelTimeOffApi(params)
      .then((response) => {
        console.log("response", response);
        dispatch(getTimeOff(usertId));
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function addBulkCoachSessions(session, params = {}) {
  console.log("Session params sent " + JSON.stringify(session));
  // const startTime = moment(session.session_start_time, "YYYY-MM-DD HH:mm:ss").toISOString();
  // const endTime = moment(session.session_end_time, "YYYY-MM-DD HH:mm:ss").toISOString();
  let { language_type, ...data } = session;
  data = {
    ...data,
    external_village_id: data.external_village_id === "no-village" ? "" : data.external_village_id,
    // session_start_time: moment(startTime)
    //   .utc()
    //   .format("YYYY-MM-DD HH:mm:ss"),
    // session_end_time: moment(endTime)
    //   .utc()
    //   .format("YYYY-MM-DD HH:mm:ss"),
  };

  // if (data.is_recurring) {
  //   const recurstart = startTime;
  //   const recurend = endTime;
  //   const recurring_start_date = data.session_start_time;
  //   //const day_index = moment(recurring_start_date).isoWeekday() - 1;
  //   const day_index = moment.utc(recurring_start_date).day();

  //   data = { ...data, recurring_start_date, recurstart, recurend, day_index };
  // }

  console.log("data========", data);
  return function(dispatch) {
    return coachSchedulerApi
      .addBulkCoachSessions(data)
      .then((bulkResponse) => {
        return coachSchedulerApi.getCoachScheduler(params).then((response) => {
          dispatch({
            type: GET_COACH_SCHEDULER_SUCCESS,
            coachSheduler: response.data,
          });

          if(bulkResponse.data?.failed_sessions?.length == 0) 
          {
            return true;
          }
          else
          {
            return false;
          }
        });

        
      })
      .catch((err) => {
        // console.log(err);
        // console.log(err.message);
        throw err;
      });
  };
}
