import { AccountAPI, EschoolAPI } from "./apiUtils";

export const getSessions = async (filter) =>
  await EschoolAPI.get("/eschoolsessions", { params: { ...filter } });

export const getRecipients = async (param) => {
  console.log({ ...param });
  return await EschoolAPI.post(
    "/recipientlist",
    { ...param },
    {
      // data: {...param},
      // params: {...param},
      // headers: {"Content-Type":"application/json"}
    }
  );
};

export const sendEmail = async (param) => {
  console.log(param);
  return await EschoolAPI.post("/send-email", param);
};

//  http://localhost:3000/api/languages
export const getLanguages = async () => await AccountAPI.get("/languages");

// GET -> http://localhost:3000/api/villages
// GET by id -> http://localhost:3000/api/villages?id=50
export const getVillages = async (id = null) =>
  await AccountAPI.get("/villages", id && { params: { id } });

// GET -> http://localhost:3000/api/village-lists
// GET by id -> http://localhost:3000/api/villages?id=50
export const getVillagesList = async () =>
await AccountAPI.get("/village-lists");

export const updateVillagesList = async (param) => {
  let newParam = {};
  if(param.id=="0") { newParam['status'] = param['status'];newParam['village_id'] = param['village_id'];} 
  else { newParam['status'] = param['status'];newParam['id'] = param['id'];}
  return await AccountAPI.post("/village-preference", newParam);
};
