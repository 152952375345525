import React, {
  useState,
  useEffect,
  useMemo,
  useReducer,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";
import propTypes from "prop-types";
import { bindActionCreators } from "redux";
import moment from "moment";
import clsx from "clsx";
import _ from "underscore";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";

import * as coachShedulerAction from "../../redux/actions/CoachShedulerAction";
import { getMaxLevelUnit } from "../../apis/CoachSchedulerApi"
import {
  getLanguageByIdentifier,
  sortAppointmentNamesBy,
  sortLanguagesBy,
} from "../../utils/languageUtils";
import { useVillage, useAppointmentTypes, useModerator } from "../../hooks";
import * as RstvAction from "../../redux/actions/RSTVAction";
import CreateSlotObject from "../../business_layer/model/CreateSlotObject";
import { getQualifiedLanguages } from "../../apis/CoachProfileApi";
import { getCoachRstvTopic } from "../../apis/CoachSchedulerApi";
import { topicTypes } from "../../utils/sessionUtils"
import "react-datepicker/dist/react-datepicker.css";
import "./CreateSessionModal.css";
import classes from "./CreateSessionModal.module.css";


function languageReducer(state = "", action) {
  console.log("Languageee=======>" + action.language);

  for (let language of ["totale", "solo", "fb", "yp", "rstv", "cp"]) {
    if (action.language.toLowerCase().includes(language.toLowerCase()))
      return language;
  }

  return "totale";
}

function extractIdentifier(language) {
  let identifier = language.split("-");
  return identifier.length == 2
    ? identifier[0].toLowerCase()
    : identifier.length == 3
    ? "fbsolo"
    : "totale";
}

function getRecurring(data) {
  const {
    is_recurring,
    recurring_end_date,
    recurring_frequency,
    ...rest
  } = data;
  if (is_recurring == false) return rest;
  else {
    const endDate = moment(recurring_end_date, "YYYY-MM-DD").isValid()
      ? { recurring_end_date }
      : {};
    return {
      ...data,
      ...endDate,
      recurring_frequency,
      recurring_start_date: data.session_start_time,
    };
  }
}

function sessionPayload(action, appointmentTypes = []) {
  const recurring = getRecurring(action.payload);

  if (action.payload.type === "session") {
    const {
      teacher_confirmed,
      appointment_type_id,
      details,
      ...restSession
    } = recurring;
    if (["totale", "solo", "fb", "yp", "cp"].includes(action.type)) {
      const { moderator_id, ...remain } = restSession;
      return remain;
    }
    if (action.type === "rstv") {
      const { number_of_seats, lesson, level, unit, ...remain } = restSession;
      return remain;
    }
  }

  if (action.payload.type === "appointment") {
    const {
      teacher_confirmed,
      number_of_seats,
      lesson,
      level,
      unit,
      language_identifier,
      moderator_id,
      topic_id,
      appointment_type_id,
      ...restAppointments
    } = recurring;
    let label;
    if(appointment_type_id === "" || appointment_type_id === "all"){
      label = "appointment";
    } else{
      label = appointmentTypes.find(
        (type) => type.id == appointment_type_id
      ).title;
    }
    return { ...restAppointments, appointment_type_id, label };
  }
}

function CreateSessionModal({ ...props }) {
  const createObject = new CreateSlotObject();
  const [session, setSession] = useState(createObject);
  const [languages, setLanguages] = useState([]);
  const [intimation, setIntimation] = useState(null);
  const appointmentTypes = useAppointmentTypes();
  const { removeModerators, setModeratorParams } = useModerator();
  const villages = useVillage();
  const { pathname } = useLocation();
  const [store, dispatch] = useReducer(languageReducer, "totale");
  const { settings, setSettings, SessionDetails, maxUnitInfo } = props;
  const [double, setDouble] = useState(false);
  const [checkRecurring, setCheckRecurring] = useState({
    disable_recurring: false,
  });
  const [rstvTopics, setRstvTopics] = useState([]);
  const [filteredLanguage, setFilteredLanguage] = useState([]);

  useEffect(() => {
    if (
      typeof settings?.dateStr != "undefined" &&
      session.language_identifier != "all" &&
      store == "rstv"
    )
      setModeratorParams({
        language: session.language_identifier,
        session_start_time: moment(settings.dateStr)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
      });
  }, [session.language_identifier, settings.dateStr]);

  const getNotes = useMemo(() => {
    const notes = [];
    // if (
    //   (settings.fromDetails != true
    //     ? checkRecurring.disable_recurring
    //     : false) ||
    //   (SessionDetails.cancelled == "true" &&
    //     settings.fromDetails == true &&
    //     settings.sessionDisableRecurring == true)
    // )
    if (
      (settings.fromDetails != true
        ? checkRecurring.disable_recurring
        : false) ||
      (SessionDetails.cancelled == "true" &&
        settings.fromDetails == true &&
        checkRecurring.disable_recurring == true)
    )
      notes.push(
        "This coach is already scheduled to have a recurring Session/Appointment starting on " +
          "the following week. You can only schedule a one-off Session/Appointment dialog at this time"
      );
    return notes.length <= 0 ? false : notes;
  }, [checkRecurring, settings.dateStr, settings.fromDetails, SessionDetails]);

  const getInputDisableConfig = useMemo(() => {
    const disable = {
      village: false,
      seat: false,
      unit: false,
      lesson: false,
      topic: false,
      moderator: false,
    };
    return {
      cp: disable,
      yp: disable,
      www: disable,
      fb: disable,
      solo: disable,
      totale: {
        ...disable,
        village: true,
        seat: true,
        unit: true,
        lesson: true,
      },
      //   fb: { ...disable, seat: false },
      rstv: { ...disable, topic: true, moderator: true },
    };
  });

  // Change value of topic restriction each time user changes languages
  useEffect(() => {
    let topic_restrictions = "all_topics_plus_cp"
    // Coach is not qualified to CP this language
    if (!props.coachFilter.CPLanguages?.includes(session.language_identifier) || session.type == "appointment") {
      topic_restrictions = "none"
    }     
    setSession({ ...session, topic_restrictions });
  }, [session.language_identifier, session.type])

  useEffect(() => {
    props.coachProfile.length > 0 &&
      getQualifiedLanguages(props.coachProfile[0][0].id).then((response) => {
        return setLanguages(response.data);
      });

    //props.rstvResponse.length == 0 && props.actions.getRstvfunction();
    console.log("Date format check before utc conversion" + settings.dateStr)
      console.log("Date format check" + moment(settings.dateStr).utc()
      .format("yyyy-MM-DD HH:mm:ss"))
    if (
      props.coachProfile.length > 0 &&
      typeof settings?.dateStr != "undefined" &&
      settings?.dateStr != ""
    ) {
      coachShedulerAction
        .checkRecurrings({
          coach_id: props.coachProfile[0][0].id,
          // session_start_time: moment
          //   .utc(settings.dateStr)
          //   .format("yyyy-MM-DD HH:mm:ss"),
          session_start_time: moment(settings.dateStr).utc()
          .format("yyyy-MM-DD HH:mm:ss"),
          day_index: moment(props.settings.dateStr).utc().day()
        })
        .then((result) => {
          setCheckRecurring(result);
        });
    }
  }, [props.coachProfile, settings.dateStr]);

  //CSP-2584
  function checkRecurringModerator(moderatorId){
    if (
      moderatorId &&
      typeof settings?.dateStr != "undefined" &&
      settings?.dateStr != "" 
    ) {
      coachShedulerAction
      .checkRecurrings({
        coach_id: moderatorId,
        session_start_time: moment(settings.dateStr).utc()
        .format("yyyy-MM-DD HH:mm:ss"),
        day_index: moment(props.settings.dateStr).utc().day()
      })
      .then((result) => {
      if (checkRecurring?.disable_recurring || result.disable_recurring)
      {
        setCheckRecurring({...result, disable_recurring: true});
      }
      else
      {
        setCheckRecurring({...result, disable_recurring: false});
      }
      });
    }
  }

  useEffect(()=>{
    console.log("Entered the filteredLang", languages)
    let filterlang = [];
    if(languages.length > 0 && moment(props.settings.dateStr).format("mm") != "30"){
      languages.map(language =>{
        if(getLanguageByIdentifier(language.identifier) !="--none--") {
          filterlang.push({language_code: language.identifier, language_name: getLanguageByIdentifier(language.identifier)})
        }
      })
    }
    if(languages.length > 0 && moment(props.settings.dateStr).format("mm") == "30"){
      languages.map(language =>{
        if(getLanguageByIdentifier(language.identifier) !="--none--" && extractIdentifier(language.identifier) != "fb") {
          filterlang.push({language_code: language.identifier, language_name: getLanguageByIdentifier(language.identifier)})
        }
      })
    }
    setFilteredLanguage(filterlang);
  },[languages])

  function onSessionChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let additional = {};
    if (target.name === "language_identifier") {
      dispatch({ language: value });
      if (!["cp", "yp", "fb", "rstv"].includes(value.toLowerCase())) {
        const field = languages.find((lang) => lang.identifier == value);

        getMaxLevelUnit({coach_id: (props.coachProfile.length > 0 ? props.coachProfile[0][0].id : ""), language_id: value}).then((response) => {
            //let int = `Max Level:5 Unit:${field?.max_unit || 0} Lesson:4`;
            let int = `Max Level:${response.data.maxLevel || 0} Unit:${response.data.maxUnit || 0} Lesson:4`;
            setIntimation(int);
        }).catch((err) => {
          console.log("Error occured during MaxUnitLevel Fetch :" + err);
          setIntimation(null)
        })

        additional.number_of_seats = 5;
      } 
      else setIntimation(null);
          
      if (session.type != "appointment")
      {
        {value.toLowerCase().includes("rstv") &&
          getCoachRstvTopic(value).then((response) => {
            setRstvTopics(response.data)
          })
        }
      }

      if (value.toLowerCase().includes("cp")) additional.number_of_seats = 0;
      if (value.toLowerCase().includes("yp")) additional.number_of_seats = 4;
      if (value.toLowerCase().includes("fb")) additional.number_of_seats = 6;
      if (value.toLowerCase().includes("solo")) additional.number_of_seats = 1;
    }
     //CSP-2584
     if (target.name === "moderator_id") {
      checkRecurringModerator(value)
    }
    //
    setSession({ ...session, [target.name]: value, ...additional });
  }

  const promt = (msg) => {
    setDouble(false);
    alert(msg);
    return false;
  };

  function validate(payload) {
    console.log("Session Type=======>" + session.type);
    if (session.type != "appointment") {
      if (store == "rstv") {
        for (const required of [
          "language_identifier",
          "moderator_id",
          "topic_id",
        ]) {
          if (
            payload[required] == "all" ||
            payload[required] == "" ||
            payload[required] == null
          )
            return promt(`Please select a ${required.split("_")[0]}`);
        }
        if (payload.coach_id == payload.moderator_id)
          return promt("Coach and Moderator should be different");
      } else {
        for (const required of ["language_identifier"]) {
          if (payload[required] == "all" || payload[required] == null)
            return promt(`Please select a ${required.split("_")[0]}`);
        }
      }
    }
    else if(session.type == "appointment"){
      if(payload.label == "appointment"){
        return promt(`Please select an appointment type to continue.`);
      }
    }
    return true;
  }

  function search(nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
        if (myArray[i].identifier === nameKey) {
            return myArray[i];
        }
    }
  }

  function onSessionSubmit() {
    if (checkRecurring.local_session) {
      alert("Coach already has a unpushed session in this slot");
      return;
    }
    setDouble(true);
    const payload = sessionPayload(
      { type: store, payload: session },
      appointmentTypes
    );
    if (!validate(payload)) return;

    const start_time =
      store == "fb"
        ? moment(props.settings.dateStr).format("mm") == "30"
          ? moment(props.settings.dateStr).subtract(30, "minutes")
          : props.settings.dateStr
        : props.settings.dateStr;
    //     console.log("check unit====================" + checkMaxUnitTreshold(payload.language_identifier, payload.unit));
    // if(checkMaxUnitTreshold(payload.language_identifier, payload.unit))
    // {
    //   alert("Please select a lesser unit")
    // }

    // To check unit treshold of the coach for the selected langauage

    // if (maxUnitInfo.length != 0) {
    //   maxUnitInfo.map((unitInfo) => {
    //     if (payload.language_identifier == unitInfo.identifier) {
    //       if (payload.unit >= unitInfo.max_unit) {
    //         alert("Please select a lesser unit");
    //         setDouble(false);

    //         return;
    //       }
    //     }
    //   });
    // } else {
      if(store == "totale" && payload.unit != '' && payload.type == "session")
      {
        if (maxUnitInfo.length != 0) {
          const unitInfo = search(payload.language_identifier, maxUnitInfo)
              if (payload.unit > unitInfo.max_unit) {
                alert("Coach is not qualified to teach selected unit");
                setDouble(false);
                return;
              }
        } 
      }

      props.actions
        .addSessions(
          {
            ...payload,
            language_type: store,
            coach_id:
              props.coachProfile.length > 0 ? props.coachProfile[0][0].id : "",
            //   session_start_time: moment(props.settings.dateStr).format("YYYY-MM-DD HH:mm:ss"),
            session_start_time: moment(start_time).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            //  session_end_time: moment(props.settings.dateStr)
            session_end_time: moment(start_time)
              .add(store == "fb" ? 60 : 30, "minutes")
              .format("YYYY-MM-DD HH:mm:ss"),
            teacher_confirmed: true,
          },
          props.coachFilter
        )
        .then(() => {
          setDouble(false);
          setSession(createObject);
          //setSession({...createObject, language_identifier: "all"});
          dispatch({ language: "totale" }) //Throw totale form by default
          setSettings((settings) => ({
            ...settings,
            ["displayCreate"]: false,
          }));
          if(document.getElementById("filter-language")){
            document.getElementById("filter-language").value=""; 
          }
          toast.success("Session Created Successfully");
        }).catch((err) => {
          setDouble(false);
          // console.log(err);
          // if (err?.message?.error_message) toast.error(err.message.error_message);
          // else if (err?.message?.error) toast.error(err.message.error);
          // else if (err?.message) toast.error(err.message);
          // else if (err) toast.error(err);
          // else toast.error("Failed to create...");
          toast.error("Failed to create...");
          setSession(createObject);
          //setSession({...createObject, language_identifier: "all"});
          dispatch({ language: "totale" }) //Throw totale form by default
          //
          setSettings((settings) => ({
            ...settings,
            ["displayCreate"]: false,
          }));
        });
  }

  function formStyle(prop = "") {
    const sessionInput = { display: session.type == "session" ? prop : "none" };
    const sessionLanguageInput = {
      ...(session.language_identifier == "all"
        ? { display: "none" }
        : sessionInput),
    };
    const recurringInput = { display: session.is_recurring ? prop : "none" };
    const appointmentInput = {
      display: session.type == "appointment" ? prop : "none",
    };

    return {
      language: sessionInput,
      village: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      seats: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      unit: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      level: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      appointment_type: appointmentInput,
      appointment_details: appointmentInput,
      is_recurring: recurringInput,
    };
  }

  const fieldStyle = useCallback(() => {
    return getInputDisableConfig[store];
  }, [store]);

  return (
    <>
      <div
        className={classes.blockOverlay}
        style={{ display: double ? "block" : "none" }}
      ></div>
      <div
        className={classes.blockMsg}
        style={{ display: double ? "block" : "none" }}
      >
        <h1>Please wait...</h1>
      </div>
      <div
        className={clsx(classes.modal, classes.coach_schedule_create_session)}
        id="coach-schedule-create-session"
        style={{
          display: settings.displayCreate ? "block" : "none",
          // left: settings.xDistance > 610 ? settings.xAxis - 237 + "px" : settings.xAxis + 240 + "px",
          left: settings.xAxis + "px",
          top: settings.yAxis + "px",
        }}
      >
        <div className={classes.modal_dialog}>
          <div className={classes.modal_content}>
            <div className={classes.modal_header}>
              <h4 className={classes.modal_title}>
                CREATE NEW SESSION/APPOINTMENT at{" "}
                <span style={{ color: "#1090CF" }}>
                  {moment(props.settings.dateStr).format(
                    "ddd MM/DD/YYYY hh:mmA"
                  )}
                </span>
              </h4>
              <a
                className={classes.close_icon}
                title="Close"
                aria-label="Close"
                role="button"
                data-dismiss="modal"
                id="coach-modal-close"
                onClick={(e) => {
                  e.preventDefault();
                  setSession(createObject); 
                //setSession({...createObject, language_identifier: "all"});
                  dispatch({ language: "totale" }) //Throw totale form by default
                  setSettings((settings) => ({
                    ...settings,
                    ["displayCreate"]: false,
                  }));
                }}
              >
                <span>×</span>
              </a>
            </div>
            <div className={classes.modal_body}>
              <div className={classes.session_type}>
                <select
                  className={clsx(classes.dropdown, classes.session_dropdown)}
                  id="session-dropdown"
                  name="type"
                  value={session.type}
                  onChange={onSessionChange}
                >
                  <option value="session">Session</option>
                  <option value="appointment">Appointment</option>
                </select>
                <select
                  className={clsx(
                    classes.dropdown,
                    classes.appointment_dropdown
                  )}
                  name="appointment_type_id"
                  onChange={onSessionChange}
                  value={session.appointment_type_id}
                  style={formStyle().appointment_type}
                >
                  <option value="all">Select appointment type</option>
                  {appointmentTypes.length > 0 &&
                    sortAppointmentNamesBy(appointmentTypes, "title").map(
                      ({ id, title }) => (
                        <option key={id} value={id}>
                          {title}
                        </option>
                      )
                    )}
                </select>
                <select
                  className={clsx(
                    classes.dropdown,
                    classes.appointment_dropdown
                  )}
                  style={
                    store != "rstv"
                      ? { display: "none" }
                      : session.type === "appointment"
                      ? { display: "none" }
                      : { display: "block" }
                  }
                  name="topic_id"
                  value={session.topic_id}
                  onChange={onSessionChange}
                >
                  <option value="">-Topic-</option>
                  
                  {/* {props.rstvResponse.length > 0 &&
                    props.rstvResponse.map((rstv) => (
                      <option key={rstv.id} value={rstv.id}>
                      {rstv.title.length > 50 ? rstv.title.substring(0,50) : rstv.title}
                    </option>
                  ))} */}
                {rstvTopics.length > 0 &&
                  rstvTopics.map((rstv) => (
                      <option key={rstv.id} value={rstv.id}>
                        {rstv.title.length > 50 ? rstv.title.substring(0,50) : rstv.title}
                      </option>
                    ))}
                </select>
              </div>
              <div className={classes.session_section}>
                <div className={classes.row1}>
                  <div className={classes.left_section}>
                    <div style={formStyle().language}>
                      {/* {moment(props.settings.dateStr).format("mm") == "30"}
                      {" "} {extractIdentifier(lang) == "fb"} */}
                      <select
                        className={classes.dropdown}
                        id="language_id"
                        name="language_identifier"
                        value={session.language_identifier}
                        style={{ display: "inline-block" }}
                        onChange={onSessionChange}
                      >
                        <option value="all">Select a language</option>
                        {filteredLanguage.length > 0 && sortLanguagesBy(filteredLanguage,"language_name").map((lang) => {
                          return <option key={lang.language_code} value={lang.language_code}>{lang.language_name}</option>
                        })}
                        {/* {languages.length > 0 &&
                          moment(props.settings.dateStr).format("mm") != "30" &&
                          languages.map(
                            (language) =>
                              getLanguageByIdentifier(language.identifier) !=
                                "--none--" && (
                                <option
                                  key={language.identifier}
                                  value={language.identifier}
                                >
                                  {getLanguageByIdentifier(language.identifier)}
                                </option>
                              )
                          )}

                        {languages.length > 0 &&
                          moment(props.settings.dateStr).format("mm") == "30" &&
                          languages.map(
                            (language) =>
                              getLanguageByIdentifier(language.identifier) !=
                                "--none--" &&
                              extractIdentifier(language.identifier) !=
                                "fb" && (
                                <option
                                  key={language.identifier}
                                  value={language.identifier}
                                >
                                  {getLanguageByIdentifier(language.identifier)}
                                </option>
                              )
                          )} */}
                      </select>
                    </div>
                    {session.type != "appointment" && /-solo/i.test(session.language_identifier) &&
                      <div className={classes['topic-restriction']}>
                        <div>Allowed Topics </div>    
                        <select 
                          className={classes.dropdown}
                          name='topic_restrictions' 
                          onChange={onSessionChange} 
                          disabled={!props.coachFilter.CPLanguages?.includes(session.language_identifier)}
                        >
                          {topicTypes.map(({ name, value }) => (
                            <option 
                              key={value} 
                              value={value}                                
                              selected={props.coachFilter.CPLanguages?.includes(session.language_identifier) && value === session.topic_restrictions}
                              >
                                { name }
                              </option>
                          ))}
                        </select>                    
                      </div>
                    }
                    <div
                      className={classes.village_dropdown}
                      style={formStyle().village}
                    >
                      <select
                        className={clsx(classes.dropdown, classes.village)}
                        name="external_village_id"
                        value={session.external_village_id}
                        onChange={onSessionChange}
                        disabled={!fieldStyle().village}
                      >
                        <option value="">Select a Village</option>
                        <option value="no-village">No Village</option>
                        {villages.length > 0 &&
                          villages.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                      </select>
                      <div className={classes.bold_font}>(Optional)</div>
                    </div>
                    <div className={classes.moderator_div}>
                      <select
                        className={classes.dropdown}
                        id="moderator"
                        name="moderator_id"
                        value={session.moderator_id}
                        onChange={onSessionChange}
                        style={
                          store != "rstv"
                            ? { display: "none" }
                            : session.type === "appointment"
                            ? { display: "none" }
                            : { display: "block" }
                        }
                      >
                        <option value="">Select Moderator</option>
                        {removeModerators(
                          props.coachProfile.length > 0 &&
                            props.coachProfile[0][0]?.id
                        ).map(({ id, full_name }) => (
                          <option key={id} value={id}>
                            {full_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div
                    className={classes.right_section}
                    style={formStyle().seats}
                  >
                    <div className={classes.right_section}>
                      <div className={classes.number_of_seats_div}>
                        <span>Number of Seats : </span>
                        <span>
                          <select
                            name="number_of_seats"
                            value={session.number_of_seats}
                            onChange={onSessionChange}
                            disabled={!fieldStyle().seat}
                          >
                            <option value="">Select</option>
                            {_.range(1, 11).map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                        </span>
                      </div>
                      <div
                        className={classes.level_unit_div}
                        style={formStyle("block").unit}
                      >
                        <select
                          className={classes.level_unit_dropdown}
                          name="unit"
                          value={session.unit}
                          onChange={onSessionChange}
                          disabled={!fieldStyle().unit}
                        >
                          <option value="">-Unit-</option>
                          {_.range(1, 21).map((num) => (
                            <option key={num} value={num}>
                              {num}
                            </option>
                          ))}
                        </select>
                        <select
                          className={classes.level_unit_dropdown}
                          name="lesson"
                          value={session.lesson}
                          onChange={onSessionChange}
                          disabled={!fieldStyle().lesson}
                        >
                          <option value="">-Lesson-</option>
                          <option value="2">2</option>
                          <option value="4">4</option>
                        </select>
                        {store == "totale" &&
                          <div
                            className={clsx(
                              classes.bold_font,
                              classes.clear_fix,
                              classes.max_text
                            )}
                          >
                            {intimation || "Max Level:1 Unit:1 Lesson:4"}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classes.appointment_content}
                  style={formStyle().appointment_details}
                >
                  <div className={clsx(classes.title, classes.bold_font)}>
                    Appointment details:
                  </div>
                  <textarea
                    cols="34"
                    name="details"
                    maxLength="500"
                    className={classes.textarea}
                    value={session.details}
                    onChange={onSessionChange}
                    style={{ display: "block", clear: "both" }}
                  ></textarea>
                  <div className={classes.appointment_char}>
                    <span>Characters remaining:</span>
                    <span className={classes.char_count}>
                      {session.details.length
                        ? 500 - session.details.length
                        : "500"}
                    </span>
                  </div>
                </div>
                <div className={classes.row2}>
                  <div className={classes.left_section}>
                    <div className={classes.recurring_checkbox}>
                      <span>Recurring</span>
                      <span>
                        <input
                          name="is_recurring"
                          type="checkbox"
                          checked={session.is_recurring ? "checked" : ""}
                          onChange={onSessionChange}
                          // disabled={
                          //   pathname.includes("my_schedule") ||
                          //   (settings.fromDetails != true
                          //     ? checkRecurring.disable_recurring
                          //     : false) ||
                          //   (SessionDetails.cancelled == "true" &&
                          //     settings.fromDetails == true &&
                          //     settings.sessionDisableRecurring == true) || (session.language_identifier == "all" && session.type == "session")
                          disabled={
                            pathname.includes("my_schedule") ||
                            (settings.fromDetails != true
                              ? checkRecurring.disable_recurring
                              : false) ||
                            (SessionDetails.cancelled == "true" &&
                              settings.fromDetails == true &&
                              checkRecurring.disable_recurring == true) || (session.language_identifier == "all" && session.type == "session")
                          }
                        />
                      </span>
                    </div>
                    <div className={classes.recurring_config}>
                      <div style={formStyle().is_recurring}>
                        <span>Recurring Frequency</span>
                        <span>
                          <select
                            id="recurring_freq"
                            name="recurring_frequency"
                            value={session.recurring_frequency}
                            onChange={onSessionChange}
                            style={{ verticalAlign: "bottom" }}
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                          </select>
                        </span>
                      </div>
                      <div style={formStyle().is_recurring}>
                        <span style={{ float: "left" }}>End at</span>
                        <span className={classes.end_recurring_at}>
                          {/* <input
                            id="end_recurring_at"
                            name="recurring_end_date"
                            value={session.recurring_end_date}
                            min={moment(new Date())
                              .subtract("years", 10)
                              .format("YYYY-01-01")}
                            max={moment(new Date())
                              .add("years", 10)
                              .format("YYYY-01-01")}
                            type="date"
                            onChange={onSessionChange}
                          /> */}
                          <div className={classes.customDatePickerWidth}>
                            <DatePicker
                              selected={
                                moment(
                                  session.recurring_end_date,
                                  "yyyy-MM-DD"
                                ).isValid()
                                  ? moment(
                                      session.recurring_end_date,
                                      "yyyy-MM-DD"
                                    ).toDate() //.format("MM/DD/yyyy")
                                  : null
                              }
                              minDate={moment(props.settings.dateStr).toDate()}
                              id="end_recurring_at"
                              // className={{
                              //   border: "1px solid #444",
                              //   width: "160px !important",
                              //   height: "80% !important",
                              // }}
                              // popperClassName={{ border: "1px solid #444", width: "160px", height: "80%" }}
                              name="recurring_end_date"
                              onChange={(date) => {
                                setSession({
                                  ...session,
                                  recurring_end_date: moment(
                                    date,
                                    "MM/DD/yyyy"
                                  ).format("yyyy-MM-DD"), //date, //moment(date, "MM/DD/YYYY").format("yyyy-MM-DD "),
                                });
                              }}
                            />
                          </div>
                        </span>
                      </div>
                      <div
                        className={classes.teacher_confirmed_div}
                        style={{ float: "left" }}
                      >
                        <span>Confirmed</span>
                        <span>
                          <input
                            // checked={session.teacher_confirmed ? "checked" : ""}
                            checked="checked"
                            id="teacher_confirmed"
                            name="teacher_confirmed"
                            type="checkbox"
                            onChange={onSessionChange}
                            disabled={true}
                          />
                        </span>
                      </div>                                            
                    </div>
                  </div>
                  <div className={classes.right_section}>
                    <input
                      type="button"
                      disabled={double}
                      value="CREATE"
                      className={classes.create_button}
                      onClick={onSessionSubmit}
                    />
                  </div>
                </div>
              </div>
              <div className="appointment-section"></div>
            </div>
            {getNotes && (
              <div
                className={clsx(classes.footer, classes.bold_font)}
                style={{ maxWidth: "475px" }}
              >
                Note: {getNotes.join(". ")}.
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

CreateSessionModal.propTypes = {
  coachProfile: propTypes.array.isRequired,
  rstvResponse: propTypes.array.isRequired,
  actions: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  coachProfile: state.coachProfile,
  rstvResponse: state.rstvStore.rstvResponse,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      addSessions: bindActionCreators(
        coachShedulerAction.addCoachSessions,
        dispatch
      ),
      getRstvfunction: bindActionCreators(RstvAction.getRstv, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSessionModal);
