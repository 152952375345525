import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as masterSchedulerAction from "../../redux/actions/MasterSchedulerAction";
import moment from "moment";
import { toast } from "react-toastify";
import CreateShiftModal from "./CreateShiftModal";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import "./ShiftDetail.css";
import EditShiftModal from "./EditShiftModal";
import RequestSubstitute from "../CoachSchedulerComponent/RequestSubstitute";
import { getCoachMaxUnits } from "../../apis/CoachProfileApi";

function ShiftDetail(props) {
  // const { settings, setSettings, shiftDetails, loading, cancelLoading,  openEditModal, setOpenEditModal } = props;
  const { settings, setSettings, shiftDetails, loading, cancelLoading } = props; 

  const [shiftInfo, setShiftInfo] = useState([shiftDetails]);
  const [sessionDetail, setSessionDetail] = useState({});
  // const [idx, setIdx] = useState(0);
  const [openCreatemodal, setOpenCreatemodal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [cancelConfirmation, setCancelConfirmation] = useState({
    displayCancelConfirmation: false
  });
  const [cancelLocal, setCancelLocal] = useState({
    displayCancelConfirmation: false,
    isFromExtraSession: false,
  });

  const [userClicked, setUserClicked] = useState({
    index: 0,
  });

  const [cancelLoader, setcancelLoader] = useState(false);
  const [openCancelConfirm, setOpenCancelConfirm] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useStateWithCallbackLazy({ toggle: false });
  const [openCancelReason, setOpenCancelReason] = useState(false);
  const [reasonfilter, setReasonFilter] = useState("");
  const [maxUnitLesson, setMaxUnitLesson] = useState("");

  useEffect(() => {
    if (shiftDetails != []) {
      console.log("SESSION DETAILS" + shiftDetails);
      setShiftInfo([...shiftDetails]);
      // setSessionDetailParams({id: shiftDetails[0].id,region_id:"all"})
    }
  }, [shiftDetails]);

  useEffect(() => {
    if (openCreatemodal) {
      let create_modal = document.getElementById("master-schedule-create-session");
      create_modal.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }
  }, [openCreatemodal]);

  useEffect(() => {
    if (openEditModal) {
      let edit_modal = document.getElementById("master-schedule-edit-session");
      edit_modal.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }
  }, [openEditModal]);

  useEffect(() => {
    if (props.closeAllModals) {
      setOpenCreatemodal(false);
      setOpenEditModal(false);
      setCancelConfirmation({
        displayCancelConfirmation: false
      });
      setOpenCancelConfirm(false);
      setOpenCancelReason(false);
    }
  }, [props.closeAllModals]);

  function onFilterChange(e) {
    const { name, value } = e.target;
    setReasonFilter(value);
  }

  function showCancelReason(e){
    setUserClicked({ ...userClicked, index: e });
    setOpenCancelReason(true);
  }

  function showConfirmedCancel(e) {
    setUserClicked({ ...userClicked, ["index"]: e });
    setOpenCancelConfirm(true);
  }

  function showCancelConfirmation(e) {
    setUserClicked({ ...userClicked, ["index"]: e });
    //Extra session popup
    if (shiftInfo[e].type == "ExtraSession") {
      setCancelLocal((cancelLocal) => ({
        ...cancelLocal,
        ["displayCancelConfirmation"]: true,
        ["isFromExtraSession"]: true,
      }));
      return;
    }

    //show the request substituted popup
    if (shiftInfo[e].coach_id == null) {
      //TODO: Need to Work
      return;
    }

    // show the recurring popup
    if (shiftInfo[e].recurring_schedule_id != null) {
      setCancelConfirmation((cancelConfirmation) => ({
        ...cancelConfirmation,
        ["displayCancelConfirmation"]: true
      }));
      return;
    }

  }

  function showCancelLocalSessionConfirmation(e) {
    setUserClicked({ ...userClicked, ["index"]: e });

    // setCancelLocal((cancelLocal) => ({
    //   ...cancelLocal,
    //   ["displayCancelConfirmation"]: true,
    //   ["isFromExtraSession"]: false
    // }));

    let params = {};
    if (shiftInfo[e].type == "recurring_session") {
      params = {
        recurring_schedule_id: shiftInfo[e].id,
        language_identifier: props.masterFilter.languageId,
        type: shiftInfo[e].type
      }
    } else {
      params = {
        local_session_id: shiftInfo[e].id,
        language_identifier: props.masterFilter.languageId,
        type: shiftInfo[e].type
      }
    }

    props.actions
      .deleteLocalSession(params, props.shiftDetailParams, props.masterFilter)
      .then((response) => {
        setCancelLocal((cancelLocal) => ({
          ...cancelLocal,
          ["displayCancelConfirmation"]: false,
        }));
      })
      .catch((err) => {
        console.log(err);
        setCancelLocal((cancelLocal) => ({
          ...cancelLocal,
          ["displayCancelConfirmation"]: false,
        }));
        toast.error("Failed to Delete Local Session");
      });
  }

  function onLocalSessionCancel(e) {
    setcancelLoader(true);
    props.actions
      .deleteLocalSession(
        {
          local_session_id: shiftInfo[userClicked.index].id,
          language_identifier: shiftInfo[userClicked.index].language_identifier,
        },
        props.shiftDetailParams,
        props.masterFilter
      )
      .then((response) => {
        setcancelLoader(false);
        setCancelLocal((cancelLocal) => ({
          ...cancelLocal,
          ["displayCancelConfirmation"]: false,
        }));
      })
      .catch((err) => {
        setcancelLoader(false);
        console.log(err);
        setCancelLocal((cancelLocal) => ({
          ...cancelLocal,
          ["displayCancelConfirmation"]: false,
        }));
        toast.error("Failed to Delete Local Session");
      });
  }

  /**
   * This is called from <EditShiftModal/>. Because <ShiftDetails> modal and <EditShiftModal> can stay open
   * at the same time, if if a user updates a session in the EditShiftModal, it needs to callback and update
   * the values of shiftInfo here, otherwise if the user clicks "edit" again in the ShiftDetails modal,
   * it will pass outdated, unupdated. So when a user updates a session, find that session in `shiftInfo`
   * and update that object too
   *
   * @param {obj} a session object 
   */
  function setSessionDetails(obj) {
    let updatedInfo = shiftInfo.map(shift => shift.id === obj.id ? obj : shift )
    setShiftInfo([...updatedInfo])
  }

  function onSessionCancel(e) {
    setcancelLoader(true);
    let actionValue = "";
    if (e.target.value == "YES") {
      props.actions
        .deleteConfirmedSession(
          {
            eschool_session_id: shiftInfo[userClicked.index].eschool_session_id,
            coach_session_id: shiftInfo[userClicked.index].id,
            type:
              shiftInfo[userClicked.index].type == "ConfirmedSession"
                ? "LocalSession"
                : shiftInfo[userClicked.index].type,
            cancellation_reason: shiftInfo[userClicked.index].learner_count > 0 ? reasonfilter : "test",
            action_type: "cancel",
            recurring: false,
          },
          props.shiftDetailParams,
          props.masterFilter
        )
        .then(() => {
          setcancelLoader(false);
          setOpenCancelConfirm(false);
        })
        .catch((err) => {
          setcancelLoader(false);
          setOpenCancelConfirm(false);
          toast.error("Failed to Delete Confirmed Session");
        });
    } else {
      if (e.target.value == "CANCEL") {
        actionValue = "cancel";
      } else {
        actionValue = "cancel_with_recurrence";
      }
      props.actions
        .deleteConfirmedSession(
          {
            eschool_session_id: shiftInfo[userClicked.index].eschool_session_id,
            coach_session_id: shiftInfo[userClicked.index].id,
            type:
              shiftInfo[userClicked.index].type == "ConfirmedSession"
                ? "LocalSession"
                : shiftInfo[userClicked.index].type,
            cancellation_reason: shiftInfo[userClicked.index].learner_count > 0 ? reasonfilter : "test",
            action_type: actionValue,
            recurring: actionValue == "cancel_with_recurrence" ? true : false,
          },
          props.shiftDetailParams,
          props.masterFilter
        )
        .then(() => {
          setcancelLoader(false);
          setCancelConfirmation((cancelConfirmation) => ({
            ...cancelConfirmation,
            ["displayCancelConfirmation"]: false
          }));
        })
        .catch((err) => {
          setcancelLoader(false);
          console.log(err);
          setCancelConfirmation((cancelConfirmation) => ({
            ...cancelConfirmation,
            ["displayCancelConfirmation"]: false
          }));
          toast.error("Failed to Delete Confirmed Session");
        });
    }
  }

  function onExtraSessionCancel(e) {
    setcancelLoader(true);
    props.actions
      .deleteExtraSession(
        {
          eschool_session_id: shiftInfo[userClicked.index].eschool_session_id,
          is_extra_session: true,
          coach_session_id: shiftInfo[userClicked.index].id,
          reason: "test",
          substitution_id: shiftInfo[userClicked.index].substitution_id,
        },
        props.shiftDetailParams,
        props.masterFilter
      )
      .then(() => {
        setcancelLoader(false);
        setCancelLocal((cancelLocal) => ({
          ...cancelLocal,
          ["displayCancelConfirmation"]: false,
        }));
      })
      .catch((err) => {
        setcancelLoader(false);
        console.log(err);
        setCancelLocal((cancelLocal) => ({
          ...cancelLocal,
          ["displayCancelConfirmation"]: false,
        }));
        toast.error("Failed to Delete Confirmed Session");
      });
  }

  function onUncancelSession(e) {
    if (!confirm("Do you want to uncancel?")) {
      return;
    }
    props.actions
      .uncancelConfirmedSession(
        {
          eschool_session_id: shiftInfo[userClicked.index].eschool_session_id,
          coach_session_id: shiftInfo[userClicked.index].id,
          type: "ConfirmedSession",
          action_type: "un_cancel",
        },
        props.shiftDetailParams,
        props.masterFilter
      )
      .then(() => { })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to Uncancel Confirmed Session");
      });
  }

  let shiftInfoCalFirst = (shift) => {
    let filterVal = shift.filter((elem) => {
      if (elem.eschool_session_id || elem.rstv_guid) {
        return elem;
      }
    })
    return filterVal.length;
  }

  let shiftInfoCalSecond = (shift) => {
    let filterVal = shift.filter((elem) => {
      if ((elem.eschool_session_id == undefined && elem.rstv_guid == null) || elem.eschool_session_id == null && elem.rstv_guid == null) {
        return elem;
      }
    })
    return filterVal.length;
  }

  let shiftInfoValueFirst = shiftInfoCalFirst(shiftInfo);
  let shiftInfoValueSecond = shiftInfoCalSecond(shiftInfo);
  console.log("shift props==============",props)
  return (
    <>
      <div>
        {shiftInfo != undefined && shiftInfo != [] && (
          <div
            className="modal coach-schedule-session-details-modal"
            id="master-schedule-shift-details"
            style={{ display: settings.displaySession ? "block" : "none" }}
          >
            <div className="modal-dialog">
              {loading ? (
                <div>LOADING....</div>
              ) : (
                <div className="modal-content">
                  <div className="modal_header">
                    <h4 className="modal-title">
                      SHIFT DETAIL -- {moment(moment(settings.sessionDate).format()).format("ddd MM/DD/YY hh:mm A")}
                    </h4>
                    <a
                      className="close-icon"
                      title="Close"
                      aria-label="Close"
                      role="button"
                      data-dismiss="modal"
                      onClick={(e) => {
                        e.preventDefault();
                        cancelLoading();
                        setSettings((settings) => ({ ...settings, ["displaySession"]: false }));
                      }}
                    >
                      <span>×</span>
                    </a>
                  </div>
                  <div className="modal-body">
                    {/* <div className="red bold-font">
                                            This shift is outside of the tech support window.
                                        </div> */}
                    <table className="shift-details-table">
                      <thead>
                        <tr>
                          <th style={{ width: "215px" }}>Coach Name</th>
                          {props.masterFilter.languageId.includes("RSTV") &&
                            <th style={{ width: "116px" }}>Moderator</th>
                          }
                          {props.masterFilter.languageId.includes("RSTV") &&
                            <th style={{ width: "126px" }}>Topic</th>
                          }
                          {props.masterFilter.languageId.includes("RSTV") != true &&
                            <th style={{ width: "116px" }}>Lev/Unit/Village</th>
                          }
                          <th style={{ width: "126px" }}>Classroom Type</th>
                          <th style={{ width: "126px" }}>#Learners</th>
                          <th style={{ width: "116px" }}>Status</th>
                          <th style={{ width: "116px" }}>Recurrence Ends</th>
                          <th style={{ width: "143px" }}>Action</th>
                        </tr>
                      </thead>
                      {shiftInfoValueFirst > 0 && (
                        <tbody>
                          {shiftInfo.map((shiftDetail, index) => {
                            if (shiftDetail.eschool_session_id != null || shiftDetail.rstv_guid != null) {
                              return (
                                <tr
                                  key={index}
                                  className={
                                    shiftDetail.cancelled == 1 && shiftDetail.type == "LocalSession"
                                      ? "uncancelrow"
                                      : ""
                                  }
                                >
                                  <td style={{ width: "215px" }}>
                                    <a
                                      href={"/view-coach-profiles?coach_id=" + (shiftDetail.coach_id ? shiftDetail.coach_id : shiftDetail.sub_requested_coach_id)}
                                      className="underline"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {/* {shiftDetail.coach_full_name} */}
                                      {shiftDetail.coach_full_name ? shiftDetail.coach_full_name : shiftDetail.sub_requested_coach}
                                    </a>
                                    {/* {shiftDetail.teacher_confirmed == "" && <div className="red">Unconfirmed</div>} */}
                                    {shiftDetail.type == "ExtraSession" && <div className="red">Unconfirmed</div>}
                                  </td>
                                  {/* Moderator Name */}
                                  {props.masterFilter.languageId.includes("RSTV") &&
                                    <td style={{ width: "116px" }}>
                                      <a href={"/view-coach-profiles?coach_id=" + (shiftDetail.moderator_id ? shiftDetail.moderator_id : "")} 
                                      className="underline">
                                        {shiftDetail.moderator_full_name != undefined ? shiftDetail.moderator_full_name : "--"}
                                      </a>
                                    </td>
                                  }
                                  {/* Topic */}
                                  {props.masterFilter.languageId.includes("RSTV") &&
                                    <td style={{ width: "116px" }}>
                                      {shiftDetail.topic_title != undefined ? shiftDetail.topic_title : "--"}
                                    </td>
                                  }
                                  {/* Lev/Unit/Village */}
                                  {props.masterFilter.languageId.includes("RSTV") != true &&
                                    <td style={{ width: "116px" }}>
                                      <a href="#" className="underline">
                                        {(shiftDetail.max_unit_village != undefined && props.masterFilter.languageId.includes("FB-") != true) ? shiftDetail.max_unit_village : "--"}
                                      </a>
                                     
                                      {shiftDetail.village_name != "--" && <div>{shiftDetail.village_name}</div>}
                                    </td>
                                  }
                                  {/* Classroom Type */}
                                  <td style={{ width: "126px" }}>
                                    {shiftDetail.number_of_seats == 1
                                      ? "Solo"
                                      : shiftDetail.wildcard == "false" ||
                                        (shiftDetail.wildcard == "true" && shiftDetail.number_of_seats != 4)
                                        ? "Group"
                                        : shiftDetail.wildcard == "true" && shiftDetail.number_of_seats == 4
                                          ? "Open"
                                          : "--"}
                                  </td>
                                  {/* Learners Count */}
                                  <td style={{ width: "116px" }}>{shiftDetail.learner_count}</td>
                                  {/* Status */}
                                  {shiftDetail.cancelled == 1 ? (
                                    <td style={{ width: "116px" }}>Cancelled</td>
                                  ) : shiftDetail.coach_id == null ? (
                                    <td style={{ width: "126px" }}>Substitute Required</td>
                                  ) : shiftDetail.session_status == 1 ? (
                                    <td style={{ width: "116px" }}>Active</td>
                                  ) : (
                                    <td style={{ width: "116px" }}>InActive</td>
                                  )}
                                  {/* Recurrence Ends */}
                                  <td style={{ width: "116px" }}>
                                    {" "}
                                    {shiftDetail.cancelled == 1
                                      ? "--"
                                      : shiftDetail.recurring_end_date != null
                                        ? moment(moment.utc(shiftDetail.recurring_end_date).toDate()).format("MM/DD/yyyy")
                                        : "--"}{" "}
                                  </td>
                                  {/* Action */}
                                  {moment(settings.sessionDate).isBefore(new Date()) || shiftDetail.cancelled == 1 ? (
                                    shiftDetail.type == "LocalSession" ? (
                                      <td style={{ width: "143px" }}>
                                        <a
                                          href="#"
                                          data-toggle="modal"
                                          onClick={() => {
                                            onUncancelSession(index);
                                          }}
                                        >
                                          UnCancel
                                        </a>
                                      </td>
                                    ) : (
                                      <td style={{ width: "143px" }}>--</td>
                                    )
                                  ) : (
                                    <td style={{ width: "143px" }}>
                                      <a
                                        href="#"
                                        data-toggle="modal"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          (shiftDetail.learner_count > 0) ?
                                           showCancelReason(index) :
                                          (shiftDetail.recurring_schedule_id != null) ?
                                            showCancelConfirmation(index) :
                                            showConfirmedCancel(index)
                                        }}
                                      >
                                        Cancel
                                      </a>{" "}
                                      |{" "}
                                      <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#master-schedule-edit-details"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          // setSettings((settings) => ({ ...settings, ["displaySession"]: false }));
                                          // setSessionDetailParams({ id: shiftDetail.id, region_id: "all" });
                                          let requestData = {
                                            'coach_id' : shiftDetail.coach_id,
                                            'language_id': shiftDetail.language_identifier
                                          };
                                          if(shiftDetail.coach_id != null)
                                          {
                                          getCoachMaxUnits(requestData).then((response) => {
                                            setMaxUnitLesson(response.data);
                                          })
                                          }
                                          setSessionDetail(Object.assign({}, shiftDetail));
                                          setOpenEditModal(true);
                                        }}
                                      >
                                        Edit
                                      </a>
                                    </td>
                                  )}
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      )}
                    </table>
                    {shiftInfoValueFirst === 0 && <div>No Sessions present in eschool.</div>}
                    <div className="locals-header bold-font">Not yet pushed to eSchool</div>
                    {/* {shiftInfoValueSecond.length > 0 && ( */}
                    <table className="shift-details-table second-table">
                      <tbody>
                        {shiftInfo.map((shiftDetail, index) => {
                          if ((shiftDetail.eschool_session_id == null && shiftDetail.rstv_guid == null) || (shiftDetail.eschool_session_id == undefined && shiftDetail.rstv_guid == null)) {
                            return (
                              <tr key={index}>
                                {/* coach Name */}
                                <td style={{ width: "215px" }}>
                                  <a
                                    href={"/view-coach-profiles?coach_id=" + (shiftDetail.sub_requested_coach_id ? shiftDetail.sub_requested_coach_id : shiftDetail.coach_id)}
                                    className="underline"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {/* {shiftDetail.coach_full_name} */}
                                    {shiftDetail.sub_requested_coach ? shiftDetail.sub_requested_coach : shiftDetail.coach_full_name}
                                  </a>
                                   {/* {shiftDetail.teacher_confirmed == "" && <div className="red">Unconfirmed</div>} */}
                                   {shiftDetail.type == "ExtraSession" && <div className="red">Unconfirmed</div>}
                                </td>
                                {/* Moderator Name */}
                                {props.masterFilter.languageId.includes("RSTV") &&
                                  <td style={{ width: "116px" }}>
                                    <a href="#" className="underline">
                                      {(shiftDetail.moderator_full_name != undefined || shiftDetail.moderator_full_name != null) ? shiftDetail.moderator_full_name : "--"}
                                    </a>
                                  </td>
                                }
                                {/* Topic */}
                                {props.masterFilter.languageId.includes("RSTV") &&
                                  <td style={{ width: "116px" }}>
                                    {(shiftDetail.topic_title != undefined || shiftDetail.topic_title != null) ? shiftDetail.topic_title : "--"}
                                  </td>
                                }
                                {/* Village Name */}
                                {props.masterFilter.languageId.includes("RSTV") != true &&
                                  <td style={{ width: "116px" }}>
                                    {/* <a href="#" className="underline"> */}
                                      {(shiftDetail.max_unit_village != undefined && props.masterFilter.languageId.includes("FB-") != true) ? shiftDetail.max_unit_village : "--"}
                                    {/* </a> */}
                                    {shiftDetail.village_name != "--" && <div>{shiftDetail.village_name}</div>}
                                  </td>
                                }
                                {/* Classroom type */}
                                {shiftDetail.number_of_seats == null && <td style={{ width: "126px" }}>--</td>}
                                {shiftDetail.number_of_seats == 1 && <td style={{ width: "126px" }}>Solo</td>}
                                {(shiftDetail.wildcard == "false" ||
                                  (shiftDetail.number_of_seats != 4) && shiftDetail.number_of_seats != 1) && <td style={{ width: "126px" }}>Group</td>}
                                {shiftDetail.number_of_seats == 4 && <td style={{ width: "126px" }}>Open</td>}
                                {/* Learners */}
                                <td style={{ width: "116px" }}>
                                  {shiftDetail.learner_count != undefined ? shiftDetail.learner_count : "0"}
                                </td>
                                {/* Status */}
                                <td style={{ width: "116px" }}>
                                  {((shiftDetail.action == "create" && shiftDetail.type == "LocalSession" && shiftDetail.is_recurring == 1) || (shiftDetail.type == "recurring_session" && shiftDetail.is_recurring == 1))
                                    ? "Not Active - Recurring"
                                    : ((shiftDetail.action == "create" && shiftDetail.type == "LocalSession" && shiftDetail.is_recurring == 0) && "Not Active")}
                                </td>
                                <td style={{ width: "116px" }}>
                                  {" "}
                                  {shiftDetail.recurring_end_date != null
                                    ? moment(moment.utc(shiftDetail.recurring_end_date).toDate()).format("MM/DD/yyyy")
                                    : "--"}{" "}
                                </td>
                                {moment(settings.sessionDate).isBefore(new Date()) || shiftDetail.cancelled == 1 ? (
                                  <td style={{ width: "143px" }}>--</td>
                                ) : (
                                  <td style={{ width: "143px" }}>
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      onClick={() => {
                                        showCancelLocalSessionConfirmation(index);
                                      }}
                                    >
                                      Delete
                                      </a>{" "}
                                      |{" "}
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#master-schedule-edit-details"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setSettings((settings) => ({ ...settings, ["displaySession"]: false }));
                                        // setIdx(index);
                                        // setSessionDetailParams({ id: shiftDetail.id, region_id: "all" });
                                        let requestData = {
                                          'coach_id' : shiftDetail.coach_id,
                                          'language_id': shiftDetail.language_identifier
                                        };
                                        if(shiftDetail.coach_id != null)
                                        {
                                          getCoachMaxUnits(requestData).then((response) => {
                                            setMaxUnitLesson(response.data);
                                          })
                                        }
                                        setSessionDetail(Object.assign({}, {...shiftDetail, current_slot_date: moment.utc(settings.sessionDate).format("YYYY-MM-DD HH:mm:ss")}));
                                        setOpenEditModal(true);
                                      }}
                                    >
                                      Edit
                                      </a>
                                  </td>
                                )}
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </table>
                    {/* )} */}
                    <button
                      className="create-session-button"
                      disabled={moment(settings.sessionDate).isBefore(new Date())}
                      onClick={() => {
                        setSettings((settings) => ({ ...settings, ["displaySession"]: false }));
                        setOpenCreatemodal(true);
                      }}
                    >
                      Create New Session
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {cancelLoader && (
          <>
            <div className="blockOverlay" style={{ display: "block" }}></div>
            <div className="blockMsg" style={{ display: "block" }}>
              <h1>Please wait...</h1>
            </div>
          </>
        )}
        {cancelConfirmation.displayCancelConfirmation && (
          <div
            className="modal coach-schedule-session-details-modal"
            id="master-schedule-shift-details-cancel"
            style={{
              display: cancelConfirmation.displayCancelConfirmation ? "block" : "none",
            }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <a
                  className="close-icon"
                  title="Close"
                  aria-label="Close"
                  role="button"
                  data-dismiss="modal"
                  onClick={(e) => {
                    setCancelConfirmation((cancelConfirmation) => ({
                      ...cancelConfirmation,
                      ["displayCancelConfirmation"]: false,
                      ["isFromConfirmedSession"]: false,
                    }));
                  }}
                >
                  <span>×</span>
                </a>
                <div className="modal-body">
                  Do you want to cancel this session only, or cancel both this session and all future occurrences?
                  </div>
                <div className="footer">
                  <div className="cancel-footer">
                    <input
                      type="button"
                      className="cancel-button"
                      value="CANCEL WITH RECURRENCE"
                      onClick={onSessionCancel}
                    />
                    <input className="footer-button" type="button" value="CANCEL"
                      onClick={
                        onSessionCancel
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {cancelLocal.displayCancelConfirmation && (
          <div
            className="rcmodal"
            id="master-schedule-request-confirmation"
            style={{
              display: cancelLocal.displayCancelConfirmation ? "block" : "none",
            }}
          >
            <div className="rcmodal-dialog">
              <div className="rcmodal-content">
                <div className="rcmodal-body">
                  <div>
                    {cancelLocal.isFromExtraSession == true ? (
                      <div>Are you sure want to cancel this extra session?</div>
                    ) : (
                      <div>Deletes all local recurring sessions before and after the week.</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="rcfooter">
                <button
                  className="cancel"
                  onClick={(e) => {
                    setCancelLocal({ ...cancelLocal, ["displayCancelConfirmation"]: false });
                  }}
                >
                  Cancel
                </button>
                <button
                  className="ok-btn"
                  onClick={(e) => {
                    {
                      cancelLocal.isFromExtraSession == true ? onExtraSessionCancel(e) : onLocalSessionCancel(e);
                    }
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
        {openCancelConfirm &&
          <div
            style={{ display: openCancelConfirm ? "block" : "none" }}
            className="modal, coach-schedule-session-details-modal"
            id="coach-schedule-cancel-conformation-modal" 
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <a
                  className="close-icon"
                  title="Close"
                  aria-label="Close"
                  role="button"
                  data-dismiss="modal"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenCancelConfirm(false);
                  }}
                >
                  <span>×</span>
                </a>
                <div className="modal-body">
                  Do you want to cancel?
            </div>
                <div className="footer">
                  <div className="cancel-footer">
                    <input
                      type="button"
                      className="cancel-button"
                      value="YES"
                      onClick={(e) => onSessionCancel(e)}
                    />
                    <input
                      className="footer-button"
                      type="button"
                      value="NO"
                      onClick={(e) =>
                        setOpenCancelConfirm(false)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {openCancelReason &&
          <div
          style={{ display: openCancelReason ? "block" : "none" }}
          className="modal, coach-schedule-session-details-modal"
          id="master-schedule-cancel-reason"
          >
            <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal_header">
              <h4 className="modal-title">CANCEL SESSION</h4>
              <a
                className="close-icon"
                title="Close"
                aria-label="Close"
                role="button"
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  setReasonFilter(false);
                  setOpenCancelReason(false);
                }}
              >
                <span>×</span>
              </a>
            </div>
            <div className="modal-body">
              <div>
                Please select the reason that best explains why this session is being cancelled.
              </div>
              <div>
                <select name="reason" onChange={onFilterChange}>
                  <option value="Select">--REASON--</option>
                  <option value="Sickness">Sickness</option>
                  <option value="System Issue">System Issue</option>
                  <option value="Personal Emergency">Personal Emergency</option>
                  <option value="Internet outage">Internet outage</option>
                  <option value="Electricity outage">Electricity outage</option>
                  <option value="No-show">No-show</option>
                  <option value="Schedule glitch">Schedule glitch</option>
                  <option value="Personnel change">Personnel change</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="footer">
              <input
                type="button"
                value="Cancel Session"
                onClick={() => {
                  if(reasonfilter == "" || reasonfilter == "Select"){
                    alert("Please select a reason");
                    return false;
                  }
                  setOpenCancelReason(false);
                  //To fix non recurring learner added session
                  (shiftInfo[userClicked.index].recurring_schedule_id != null) ?
                                            showCancelConfirmation(userClicked.index) :
                                            showConfirmedCancel(userClicked.index)
                  //
             //     showCancelConfirmation(userClicked.index);
                }}
              />
            </div>
          </div>
        </div>
          </div>
        }
      </div>
      <CreateShiftModal
        openModal={openCreatemodal}
        setOpenModal={setOpenCreatemodal}
        shiftDetailParams={props.shiftDetailParams}
        shiftInfo={shiftInfo}
        settings={settings}
        masterFilter={props.masterFilter}
        setSettings={setSettings}
      />
      <EditShiftModal
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        shiftDetailParams={props.shiftDetailParams}
        sessionDetail={sessionDetail}
        // sessionDetails={}
        // sessionDetailParams={sessionDetailParams}
        settings={settings}
        masterFilter={props.masterFilter}
        setSettings={setSettings}
        closeAllModals={props.closeAllModals}
        maxUnitLesson={maxUnitLesson}
        setSessionDetails={setSessionDetails}
      />
      {/* <RequestSubstitute
        // settings={requestSubstituteDisplay}
        // setSettings={setRequestSubstituteDisplay}
        // sessionInfo={sessionInfo}
        // coachFilter={props.masterFilter}
        // onRequestSuccess={handleRequestSuccess}
      /> */}
      {/* <ConfirmationDialogModal
        modalOpen={cancelModalOpen}
        setModalOpen={setCancelModalOpen}
        onSessionCancel={onSessionCancel}
      /> */}
    </>
  );
}

ShiftDetail.propTypes = {
  actions: propTypes.object.isRequired,
};
const mapStateToProps = (state) => ({}); //({shiftdetails: state.shiftDetails});
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      deleteConfirmedSession: bindActionCreators(masterSchedulerAction.deleteConfirmedSession, dispatch),
      deleteLocalSession: bindActionCreators(masterSchedulerAction.deleteLocalSession, dispatch),
      deleteExtraSession: bindActionCreators(masterSchedulerAction.deleteExtraSession, dispatch),
      uncancelConfirmedSession: bindActionCreators(masterSchedulerAction.uncancelConfirmedSession, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ShiftDetail);
