import { GET_SCORE_UPDATE } from "../constants";
import initialState from "./initialState";

export default function ScoreUpdateReducer(state = initialState.score, action) {
  switch(action.type){
    case "GET_SCORE_UPDATE":
      return action.score;
    default: 
      return state;
  }
}