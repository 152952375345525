import {
    GET_UNASSIGNED_COACH_SUCCESS,
    GET_COACH_MANAGER_SUCCESS,
    GET_COACH_MANAGER_ASSIGNED_SUCCESS
} from "../constants";

import initialState from "./initialState";

export default function assignCoachesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_UNASSIGNED_COACH_SUCCESS:
      return {...state, unAssignedCoachResponse:action.payload};
    case GET_COACH_MANAGER_SUCCESS:
      return {...state, coachManagerResponse:action.payload};
    case GET_COACH_MANAGER_ASSIGNED_SUCCESS:
      return {...state, assignedCoachResponse:action.payload};
    
    default:
      return state;
  }
}
