import {
    GET_TRACK_USERS_SUCCESS
  } from "../constants";
  
  import initialState from "./initialState";
  
  export default function trackUsersReducer(state = initialState.trackUsers, action) {
    switch (action.type) {
      case GET_TRACK_USERS_SUCCESS:
        return action.trackUsers;
      default:
        return state;
    }
  }
  