import initialState from "./initialState";
import {
  GET_COACH_SCHEDULER_SUCCESS,
  // ADD_COACH_SCHEDULER_SUCCESS,
  ADD_SESSION_DETAILS_SUCCESS,
  UPD_COACH_SCHEDULER_SUCCESS,
  GET_TIME_OFF_LIST_SUCCESS,
  CREATE_TIME_OFF_LIST_SUCCESS,
  GET_FUTURE_SESSION
} from "../constants";
import { getLanguageByIdentifier } from "../../utils/languageUtils";

export default function CoachShedulerReducer(state = initialState, action) {
  // if (action.type == ADD_COACH_SCHEDULER_SUCCESS) return { ...state, ...action.newSession };
  if (action.type == GET_TIME_OFF_LIST_SUCCESS)  return {...state, getTimeOffListResponse : action.getTimeOffList}
  if (action.type == CREATE_TIME_OFF_LIST_SUCCESS)  return action.createTimeOffList;
  if (action.type == GET_COACH_SCHEDULER_SUCCESS) return {...state, coachSheduler:action.coachSheduler}
  if (action.type == ADD_SESSION_DETAILS_SUCCESS) {
    const {
      endTime,
      startTime,
      slotType,
      language_identifier,
      type,
      level,
      unit,
      lesson,
      language_type,
      is_confirmed,
    } = action.sessionDetail;

    const newSession = {
      endTime,
      startTime,
      lang: language_identifier,
      slotType,
      label:
        type == "appointment"
          ? action.sessionDetail.label
          : language_type === "rstv"
          ? `${getLanguageByIdentifier(language_identifier)}\n`
          : `${language_identifier} Max L${lesson}-U${unit}-LE${level}\n0 learners\n ${
              !is_confirmed ? "Unconfirmed".fontcolor("red") : ""
            }`,
    };
    const schedules = state.coachSheduler.schedules;
    return { ...state.coachSheduler, schedules: [...schedules, { ...newSession }] };
  }

  if (action.type == UPD_COACH_SCHEDULER_SUCCESS) {
    const {
      endTime,
      startTime,
      slotType,
      language_identifier,
      type,
      level,
      unit,
      lesson,
      is_confirmed,
    } = action.sessionDetail;

    const updSchedule = {
      endTime,
      startTime,
      lang: language_identifier,
      slotType,
      label:
        type == "appointment"
          ? action.sessionDetail.label
          : action.language_type == "rstv"
          ? `${getLanguageByIdentifier(language_identifier)}\n`
          : `${language_identifier} Max L${lesson}-U${unit}-LE${level}\n0 learners\n ${
              !is_confirmed ? "Unconfirmed".fontcolor("red") : ""
            }`,
    };

    // console.log(state);

    return {
      ...state.coachSheduler,
      schedules: [
        ...state.coachSheduler.schedules.map((schedule) => (schedule.id === action.sessionDetail.id ? updSchedule : schedule)),
      ],
    };
  }

  if (action.type == GET_FUTURE_SESSION) return {...state, futureSession:action.futureSession}

  return state;
}
