import { GET_APPLICATION_STATUS } from "../constants";
import initialState from "./initialState";

export default function ApplicationStatusReducer(state = initialState.status, action) {
  switch(action.type){
    case "GET_APPLICATION_STATUS":
      return action.status;
    default: 
      return state;
  }
}