import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CoachSchedulerCalendar from "../components/CoachSchedulerComponent/CoachSchedulerCalendar";
import { getCoachNames, getQualifiedCoaches, getQualifiedLanguages } from "../apis/CoachProfileApi";
import { sortCoachNamesBy } from "../utils/languageUtils";
import { getLanguageByIdentifier, sortLanguagesBy } from "../utils/languageUtils";
// import * as notificationsAction from "../redux/actions/notificationsAction";
import * as languageActions from "../redux/actions/languageAction";
import * as coachProfileAction from "../redux/actions/coachProfileAction";
import * as coachShedulerActions from "../redux/actions/CoachShedulerAction";
import * as notificationsAction from "../redux/actions/notificationsAction";
import bigSpinner from "../components/images/big_spinner.gif";
import previous from "../components/images/previous.png";
import next from "../components/images/next.png";
import warning from "../components/images/warning_red_16_16.png";
import refresh from "../components/images/refresh.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";


import "bootstrap-daterangepicker/daterangepicker.css";
import "./CoachSchedulerPage.css";
import { showAlert } from "../utils/sessionUtils";
import moment from "moment";

const title = (coachName) => `Customer Success Portal: Coach Schedule - ${coachName}`;
function CoachSchedulerPage(props) {
  const {
    languages,
    actions: { getLanguages },
    coachSheduler,
  } = props;

  const calRef = useRef(null);

  function Loading() {
    return <img src={bigSpinner} />;
  }
  const [coachs, setCoachs] = useState([]);
  const [config, setConfig] = useState({
    display: false,
    slotDuration: "00:30:00", //"00:30:00",
  });

  const [filter, setFilter] = useState({
    language_category: "totale",
    language: "",
    coach_id: "",
  });

  const [coachFilter, setCoachFilter] = useState({
    language_category: "totale",
    coach_id: "",
    language: "",
    CPLanguages: [],
    filterDate: moment()
      .startOf("day")
      .utc()
      .toISOString(),
    //moment returns current timezone date, startOf will set time to 0
  });

  // Get week extremes in user timezone.
  //  getWeekExtremes(filterDate, userTimezone) {
  //   let curr = new Date(filterDate);
  //   let filterDateInUserzone = moment.tz([curr.getFullYear(), curr.getMonth(), curr.getDate()], userTimezone);

  //   let firstDay = filterDateInUserzone.startOf('week');
  //   let firstDayUTC = moment.utc(firstDay).toDate();

  //   let lastDay = filterDateInUserzone.endOf('week');
  //   let lastDayUTC = moment.utc(lastDay).toDate();

  //   return [firstDayUTC, lastDayUTC];
  //   }
  const [loaded, setLoaded] = useState(false);
  const [modifiedSubstitutions, setModifiedSubstitutions] = useState([]);
  let modified = [];
  const [showSubstitute, setShowSubstitute] = useState(false);
  const [schedulerList, setSchedulerList] = useState();
  const [showCalendar, setShowCalendar] = useState(false);
  const [typeBasedLang, setTypeBasedLang] = useState([]);
  const [double, setDouble] = useState(false);
  const [maxUnitInfo, setMaxUnitInfo] = useState(false);
  const [filterLanguages, setFilterLanguages] = useState([]);
  // const [isFromNavigation, setIsFromNavigation] = useState(false);
  const [updatedTimeZone, setUpdatedTimeZone] = useState();
  const [bulkSession, setBulkSession] = useState(false);
  const [coachName, setCoachName] = useState("");
  const [filterLang, setFilterLang] = useState("");
  useEffect(() => {
    if (coachSheduler != {}) {
      if(coachSheduler.schedules?.length == 0)
      {
        // alert("No sessions found")
         setDouble(false);
      //   if (calRef.current != null) {
      //     let calendarApi = calRef.current.getApi();
      //     var events = calendarApi.getEventSources(); 
      //     var len = events.length;
      // //    console.log("=============> events" + JSON.stringify(calendarApi.getEventSources()));
      //     for (var i = 0; i < len; i++) { 
      //       events[i].remove(); 
      //     } 
      //   }
      //   return;
      }
      setSchedulerList(coachSheduler.schedules);
      setMaxUnitInfo(coachSheduler.maxUnitInfo);
    }
    JSON.stringify(coachSheduler) != "{}" &&  setCoachName(coachSheduler.coachInfo[0].full_name)
  }, [coachSheduler]);

  useEffect(() => {
    getLanguages();
    //getCoachNames("Coach").then((res) => setCoachs(res.data));
  }, []);

  useEffect(() => {
    props.coachProfile.length > 0 &&
    getQualifiedLanguages(props.coachProfile[0][0].id).then((response) => {
      return setFilterLanguages(response.data);
    });
    //getCoachNames("Coach").then((res) => setCoachs(res.data));
  }, [props.coachProfile]);


  useEffect(() => {
    if (languages.length != 0) {
      setTypeBasedLang(filterTypeBasedLang("totale"));
    }
  }, [languages]);

  useEffect(() => {
    if (props.additionalSubstitutions.length == 0 && !loaded) {
      props.actions.getSubstitutions({ time_range: "upcoming", alert:"true" });
      setLoaded(true);
    }
    if(props.additionalSubstitutions.length == 0){
      setShowSubstitute(false)
    } else {
     
        props.additionalSubstitutions.map((substitution) => {
          if (showAlert(substitution.data[0].session_start_time))
          {
            modified.push({ lang: substitution.lang[0], session_start_time: substitution.data[0].session_start_time, level_unit_lesson:substitution.data[0].level_unit_lesson, row_id:substitution.data[0].id, sub_needed_for: substitution.data[0].sub_needed_for});
          }
          
        });

    console.log("modified value" + modified);
    if(modified.length != 0) { setShowSubstitute(true)}
    setModifiedSubstitutions(modified);
  }
  },[props.additionalSubstitutions]);

  const loaderCallBack = (data) => {
    setDouble(data);
  }

  function onFilterChange(e) {
    // setIsFromNavigation(false);
    const { name, value } = e.target;
    setCoachFilter({ ...coachFilter, [name]: name == "coach_id" ? parseInt(value) : value });
    setFilter({ ...filter, [name]: name == "coach_id" ? parseInt(value) : value });
    let slotDuration = {};
    if (name == "language_category") slotDuration = { slotDuration: value == "totale" ? "00:30:00" : "00:30:00" };
    setConfig({ ...config, ...slotDuration, display: true });
    setDouble(false);
    //  if (name == "language") getQualifiedCoaches(e.target.value);
    if (name == "language") getQualifiedCoaches(e.target.value).then((res) => setCoachs(res.data));
  }

  useEffect(() => {    
    // The /coachddetails API returns the wrong languages, it returns the non `-SOLO` versions. Ideally it should return the right
    // languages with the right ID so we could check based on ID. But for now, we'll take the regular language name, ie,
    // FB-XYZ and append -Solo to it if it has `conv_pract = 1`. We then pass this array of qualified languages to `CreateSessionModal`,
    // since the user can change languages there as well, and we'll be able to check if the newly selected language name is in the array we pass.
    const CPLanguages = props.coachProfile[1]?.filter(e => e.conv_pract).map(e => e.identifier = e.identifier + '-Solo')    
    setCoachFilter({ ...coachFilter, CPLanguages })
  }, [props.coachProfile])

  useEffect(()=>{    
    if(coachFilter.coach_id){
      onSubmitHandler();
    }
  },[coachFilter.coach_id]);

  function onTypeFilterChange(e) {
    // setIsFromNavigation(false);
    const { name, value } = e.target;
    setCoachFilter({ ...coachFilter, [name]: value });
    setFilter({ ...filter, [name]: value });
    setTypeBasedLang(filterTypeBasedLang(e.target.value));
    console.log("target value =============>", e.target.value)
    //console.log("target value =============>", filterTypeBasedLang(e.target.value))
    let slotDuration = {};
    if (name == "language_category") slotDuration = { slotDuration: value == "totale" ? "00:30:00" : "00:30:00" };
    setConfig({ ...config, ...slotDuration, display: true });
    setDouble(false);
  }

  function filterTypeBasedLang(langType) {
    return languages.filter((lang) => {
      return extractIdentifier(lang.identifier) == langType;
    });
  }

  function languageFilterChange(e) {
    // setIsFromNavigation(false);
    if(e.target.value == ""){
      setSchedulerList(coachSheduler.schedules);
      setFilterLang(e.target.value);
      setDouble(false);
    }
    else if (JSON.stringify(coachSheduler) != "{}") {
      let filteredSessions = coachSheduler.schedules.filter((li) => li.lang == e.target.value);
      if (filteredSessions.length == 0) {
        alert("No sessions found for the selected language");
        document.getElementById("filter-language").value = filterLang;
        return;
      }
      setSchedulerList(filteredSessions);
      setFilterLang(e.target.value);
      setDouble(false);
    }
  }
  console.log(coachFilter);

  function onSubmitHandler(selectedDate = null, action = null) {
    if (coachFilter.language == "") {
      alert("Please Select a Language.");
      return;
    }
    if (coachFilter.coach_id == "" || isNaN(coachFilter.coach_id) || document.getElementById("coach_id").value == "") {
      alert("Please Select a Coach");
      return;
    }
    // setSchedulerList([]);
    let modals= document.querySelectorAll(".modal .close-icon, .rcmodal .cancel, #coach-modal-close, #recurring-close, #cancel-modal-close");
    for (let modal of modals) {
      modal.click();
    }
    if (action == "custom") {
      // setIsFromNavigation(true);
      if (calRef.current != null) {
        let calendarApi = calRef.current.getApi();
        calendarApi.gotoDate(selectedDate);
        
      }

      // setCoachFilter({ ...coachFilter, filterDate: selectedDate });
      props.actions.getCoachScheduler({ ...coachFilter, filterDate: selectedDate });
      setShowCalendar(true);
      setDouble(true);
  
      return;
    }
    setShowCalendar(true);
    let currentDate = new Date().toString();
    let CurrentTimeZone = currentDate.split("(")[1];
    setUpdatedTimeZone(CurrentTimeZone);
    setDouble(true);
    props.actions.getCoach({ id: coachFilter.coach_id }).then((response) => {
      props.actions.getCoachScheduler(coachFilter);
    }).catch((err) => {
      alert("Coach is not loaded, Please try again! " + JSON.stringify(coachFilter) + " Error " + err);
    })
    //props.actions.getCoachScheduler(coachFilter);
    
  }
  function extractIdentifier(language) {
    let identifier = language.split("-");
    return identifier.length == 2 ? identifier[0].toLowerCase() : (identifier.length == 3 ? "fbsolo" : (identifier[0] !== "ALL" ? "totale": "" ));
  }
  console.log("languages========>",languages)

  function createBulkSession(){
    setBulkSession(true);
  }

  return (
    <>
      <Helmet>
        <title>{title("")}</title>
      </Helmet>
      <div className="right-content">
        {/* style={{ ...(config.display && { height: "1080px" }) }}> */}
        <div className="coach-schedule">
          <div className="title">
            <h2>
              Coach Schedule {JSON.stringify(coachSheduler) != "{}" && " - " + coachSheduler.coachInfo[0].full_name}
            </h2>
          </div>
          <div className="today-date-section">
            <span className="today-date">Today&apos;s Date:</span>
            <span className="today-date-in-dashboard"> {moment().format("dddd, MMMM DD,YYYY")}</span>
          </div>

          <div className="coach-session-info-div">
            {JSON.stringify(coachSheduler) != "{}" && (
              <>
                <div>
                  <span className="bold-font">Scheduled: </span>
                  <span>{coachSheduler.coachSessionCount.scheduled}</span>(
                  <span>{coachSheduler.coachSessionCount.scheduledHours}</span> hrs),
                </div>
                <div>
                  <span className="bold-font">Cancelled: </span>
                  <span>{coachSheduler.coachSessionCount.cancelled}</span>,
                </div>
                <div>
                  <span className="bold-font">Sub-requested: </span>
                  <span>{coachSheduler.coachSessionCount.subRequested}</span>
                </div>
              </>
            )}
          </div>
          <div className="substitution-alert-div"  style={{
              display: showSubstitute ? "block" : "none",
            }}>
            <div className="substitution-alert">
              <table className="sub-alert-table">
                <tbody>
                  <tr>
                    <td className="substitution-needed">SUBSTITUTION NEEDED!</td>
                    <td className="sub-data-div">
                      <table className="sub-data">
                        <tbody>
                        {modifiedSubstitutions.map((substitution, i) => (
                          <tr  key={i}>
                            <td className="width3">
                                <img className="warning_icon" src={warning} />
                            </td>
                            <td className="width25 substitution_needed">{substitution.sub_needed_for ? (substitution.sub_needed_for == "Coach" ? "Coach Required!" : "Moderator Required!") : ""}</td>
                            <td className="width10 substitution-needed"></td>
                            <td className="width25">{substitution.lang}</td>
                            <td className="width25">{moment(substitution.session_start_time).format("ddd, MMMM DD,YYYY hh:mm A")}</td>
                            <td className="width15">{substitution.level_unit_lesson}</td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </td>
                    <td className="see-all-substitutions">
                      <font className="substitution-font">
                        <a href="/substitutions"> see all sub requests</a>
                      </font>
                    </td>
                    <td>
                      <div className="substitution-close-button" onClick={() => {setShowSubstitute(false)}}>X</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="scheduler-form-data">
            <input
              className="today-button"
              type="button"
              value="Today"
              onClick={(e) => {
                setCoachFilter({ ...coachFilter, filterDate: moment().endOf("day").toISOString() });
                if(filter.language && filter.coach_id){
                  onSubmitHandler(
                    moment()
                      .endOf("day")
                      .toISOString(),
                    "custom"
                  );
                }
              }}
            />
            <div className="calendar-data">
              <button className="arrow">
                <img
                  alt="Previous Week"
                  onClick={(e) => {
                    setCoachFilter({ ...coachFilter, filterDate: moment(coachFilter.filterDate).subtract(7, "days").endOf("day").toISOString() });
                    if(filter.language && filter.coach_id){
                      onSubmitHandler(
                        moment(coachFilter.filterDate)
                          .subtract(7, "days")
                          .endOf("day")
                          .toISOString(),
                        "custom"
                      );
                    }
                  }}
                  className="previous-week"
                  src={previous}
                  title="Previous Week"
                />
              </button>
              <DatePicker
                selected={new Date(coachFilter.filterDate)}
                onChange={(date, e) => {
                  e.preventDefault;
                  setCoachFilter({
                    ...coachFilter,
                    filterDate: date,
                  });
                  e.target.value = date;
                  if(filter.language && filter.coach_id){
                    props.actions.getCoachScheduler({ ...coachFilter, filterDate: date });
                    setShowCalendar(true);
                    setDouble(true);
                  }
                }}
                value={
                  moment(coachFilter.filterDate)
                    .startOf("week")
                    .format("MMMM DD, YYYY") +
                  "    -    " +
                  moment(coachFilter.filterDate)
                    .endOf("week")
                    .format("MMMM DD, YYYY")
                }
                className="date-picker"
              />
              <button href="#" className="arrow">
                <img
                  alt="Next Week"
                  onClick={(e) => {
                    setCoachFilter({ ...coachFilter, filterDate: moment(coachFilter.filterDate).add(7, "days").startOf("week").endOf("day").toISOString() });
                    if(filter.language && filter.coach_id){
                      onSubmitHandler(
                        moment(coachFilter.filterDate)
                          .add(7, "days")
                          .startOf("week")
                          .endOf("day")
                          .toISOString(),
                        "custom"
                      );
                    }
                  }}
                  className="next-week"
                  src={next}
                  title="Next Week"
                />
              </button>
            </div>
            <div className="scheduler-language-category">
              <select className="dropdown" name="language_category" onChange={onTypeFilterChange}>
                <option value="totale">Foundations</option>
                {/* <option value="WWW">World Wide English</option> */}
                <option value="fbsolo">Fluency Builder Solo</option>
                <option value="fb">Fluency Builder Group</option>
                {/* <option value="cp">Cross Product</option>
                <option value="yp">Your Plan</option> */}
                <option value="rstv">Live Streaming</option>
              </select>
            </div>
            <div className="scheduler-language">
              <select name="language" onChange={onFilterChange} className="dropdown longer-dropdown language">
                <option value="">Select a Language</option>
                {sortLanguagesBy(typeBasedLang, "language_name").map(
                  (language) =>
                    getLanguageByIdentifier(language.identifier) != " -- none --" && (
                      <option key={language.id} value={language.identifier} id={language.identifier}>
                        {getLanguageByIdentifier(language.identifier)}
                      </option>
                    )
                )}
              </select>
            </div>
            <div className="cs-coach">
              <select
                name="coach_id"
                className="coach dropdown"
                onChange={onFilterChange}
                disabled={filter.language == ""}
                id="coach_id"
              >
                <option value="">Select a Coach</option>
                {sortCoachNamesBy(coachs, "full_name").map((coach) => (
                  <option key={coach.id} value={coach.id}>
                    {coach.full_name}
                  </option>
                ))}
              </select>
            </div>
            {showCalendar && (<button className="go-button" type="button" value="GO" onClick={onSubmitHandler} >
              {/* <img src={refresh}/> */}
              <FontAwesomeIcon icon={faSyncAlt} />
              </button>)}
          </div>
          {showCalendar && (
          <>
            <div className="filter-language-div">
              <span className="bold-font">Filter Schedule by :</span>
              <select className="dropdown longer-dropdown" name="filter-language" onChange={languageFilterChange}  id="filter-language">
                <option value="">All Languages</option>
                {/* {languages.map((language) => { */}
                {filterLanguages.map((language) => {
                  return (
                    <option key={language.id} value={language.identifier}>
                      {getLanguageByIdentifier(language.identifier)}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="bulk-session-link">
              <button href="#" onClick={createBulkSession}>Create bulk sessions</button>
            </div>
          </>
          )}
        </div>
        <div className="week-calendar">
          {/* style={{ ...(!config.display && { display: "none" }) }}> */}
          {/* {schedulerList != [] && JSON.stringify(schedulerList)} */}
          {/* {schedulerList != [] && ( */}
            <CoachSchedulerCalendar
              config={config}
              schedulerList={schedulerList}
              coachFilter={coachFilter}
              showCalendar={showCalendar}
              isFromAppointment={false}
              calRef={calRef}
              double={double}
              maxUnitInfo={maxUnitInfo}
              onDoubleChange={(data) => loaderCallBack(data)}
              updatedTimeZone={updatedTimeZone}
              bulkSession={bulkSession}
              setBulkSession={setBulkSession}
              coachName={coachName}
              // isFromNavigation={isFromNavigation}
            />
          {/* )} */}
          {/* style={{ ...(!config.display && { display: "none" }) }} /> */}
        </div>
        <div style={{ overFlow: "auto" }} />
      </div>
    </>
  );
}

CoachSchedulerPage.propTypes = {
  languages: propTypes.array.isRequired,
  // notifications: propTypes.array.isRequired,
  actions: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  coachProfile: state.coachProfile,
  languages: state.languages,
  coachSheduler: state.coachSheduler.coachSheduler,
  additionalSubstitutions: state.additionalSubstitutions,
  // qualifiedCoaches: state.qualifiedCoaches
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      // getNotifications: bindActionCreators(notificationsAction.getNotifications, dispatch),
      getLanguages: bindActionCreators(languageActions.getLanguages, dispatch),
      getCoach: bindActionCreators(coachProfileAction.getCoach, dispatch),
      getCoachScheduler: bindActionCreators(coachShedulerActions.getCoachScheduler, dispatch),
      //   getQualifiedCoaches: bindActionCreators(coachProfileAction.getQualifiedCoaches, dispatch)
      getSubstitutions: bindActionCreators(notificationsAction.getAdditionalSubstitutions,dispatch)
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoachSchedulerPage);
