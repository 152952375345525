import { GET_INACTIVE_COACH_SUB } from "../constants";
import initialState from "./initialState";

export default function InactiveCoachSubReducer(state = initialState.InactiveCoachSub, action) {
  switch(action.type){
    case "GET_INACTIVE_COACH_SUB":
      return action.InactiveCoachSub;
    default: 
      return state;
  }
}