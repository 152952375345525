import {
    GET_RSTV_SUCCESS
} from "../constants";

import initialState from "./initialState";

export default function rstvReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RSTV_SUCCESS:
      return {...state, rstvResponse:action.rstvResponse };
     default:
      return state;
  }
}
