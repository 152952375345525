import { param } from "jquery";
import { AccountAPI, EschoolAPI } from "./apiUtils";

//http://localhost:3002/api/session-scheduler
export const getMasterScheduler = async (params) => await EschoolAPI.post("/session-scheduler", params);

//http://localhost:3002/api/fetch-session-slot-details
export const getShiftDetails = async (params) => await EschoolAPI.post("/fetch-session-slot-details", params);

//http://localhost:3002/api/master-schedular-confirmed-sessions
export const deleteConfirmedSession = async (params) =>
  EschoolAPI.delete("/master-schedular-confirmed-sessions", { params });

// Master Schedular – Create Local Session
// Method	: POST
// Path		: http://localhost:3002/api/master-schedular-sessions
export const addMasterScheduler = async (data) => await EschoolAPI.post("/master-schedular-sessions", data);

// Create Extra Session
// Method	: POST
// Path		: http://localhost:3002/api/extra-sessions
export const addMasterExtraSession = async (data) => await EschoolAPI.post("/extra-sessions", data);

//http://localhost:3000/api/fetch-available-coaches?sessionId=914409
export const fetchAvailableCoaches = async (sessionId) =>
  await EschoolAPI.get("/fetch-available-coaches", { params: { sessionId } });

//   Create Extra Session – Get Data Before Creation
// Method	: GET
// Path		: http://localhost:3002/api/extra-sessions-creation?start_time=1618655400000&language_identifier=ENG&region_id=all&external_village_id=all
// Service	: Eschool Service
export const getCreateExtraSession = async (params = null) =>
  await EschoolAPI.get("/extra-sessions-creation", params && { params });
// export const deleteConfirmedSession = async (params) =>
//   EschoolAPI.delete("/master-schedular-confirmed-sessions", params);

//http://localhost:3002/api/master-schedular-local-sessions
export const deleteLocalSession = async (params) =>
  await EschoolAPI.delete("/master-schedular-local-sessions", { params });

//http://localhost:3002/api/push-sessions-status
//export const pushSessionStatus = async (params) => await EschoolAPI.post("/push-sessions-status", {...params, ["appointments_push"]: appointments_push.toString()});
export const pushSessionStatus = async (params) =>
  await EschoolAPI.post("/push-sessions-status", {
    ...params,
    ["appointments_push"]: params.appointments_push.toString(),
  });

//http://localhost:3002/api/master-schedular-confirmed-sessions
export const uncancelConfirmedSession = async (params) =>
  await EschoolAPI.post("/master-schedular-confirmed-sessions", params);

//http://localhost:3000/api/mod-coach-available-create-new
export const getModerators = async (params) => await AccountAPI.post("/mod-coach-available-create-new", params);

//http://localhost:3002/api/extra-sessions?id=684578&region_id=all
export const getShiftSessionDetails = async (params = null) =>
  await EschoolAPI.get("/extra-sessions", params && { params });

//http://localhost:3000/api/fetch-available-coaches?sessionId=914409
export const getAvailableCoaches = async (params = null) =>
  await AccountAPI.get(`/fetch-available-coaches`, params && { params });

//http://localhost:3002/api/extra-sessions
export const deleteExtraSession = async (params) => await EschoolAPI.delete("/extra-sessions", { params });

// Edit extra session
export const updateExtraSession = async (data) => await EschoolAPI.patch("/extra-sessions", data);

// http://localhost:3002/api/master-schedular-confirmed-sessions
//Edit Confirmed session
export const updateConfirmedSession = async (data) =>
  await EschoolAPI.patch("/master-schedular-confirmed-sessions", data);

// Method : PATCH
// Path  : http://localhost:3002/api/master-schedular-local-sessions
export const updateLocalSession = async (data) => await EschoolAPI.patch("/master-schedular-local-sessions", data);


// Method : PATCH
// Path : http://localhost:3002/api/create-bulk-extra-session
export const addBulkExtrasessions = async (data) => await EschoolAPI.post("/create-bulk-extra-session", data);
