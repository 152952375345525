import {
    GET_NON_MANAGE_LANG_SUCCESS,
    GET_MANAGE_LANG_SUCCESS,
    DELETE_MANAGE_LANG_SUCCESS
} from "../constants";

import initialState from "./initialState";

export default function manageLanguageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NON_MANAGE_LANG_SUCCESS:
      return {...state, nonManageLanguageResponse:action.payload};
    case GET_MANAGE_LANG_SUCCESS:
      return {...state, manageLanguageResponse:action.payload, manageLanguageCoachId: getCoachId(action.payload)};
    // case DELETE_MANAGE_LANG_SUCCESS:
    //   return {...state, manageResponse:updateManageLanguage(state, action.param.id)};
     default:
      return state;
  }
}

function getCoachId(data) {
  if(data.length > 0) {
    return data[0].coach_id;
  }
  return '';
}
// function updateManageLanguage(state, id) {
//   const update = {...state};
//   console.log(update.manageLanguageResponse);
//   const test = update.manageLanguageResponse.filter((param) => param.id !== id);
//   console.log(test);
//   return test;
// }