import { useState, useEffect } from "react";
import * as appointmentApi from "../apis/appointmentsApi";
import * as masterSchedulerApi from "../apis/MasterSchedulerApi";

export default function useModerator(isMasterSchedular = false) {
  const [moderators, setModerators] = useState([]);
  const [params, setModeratorParams] = useState({});
  useEffect(() => {
    Object.keys(params).length != 0 &&
      // (isMasterSchedular ?
      masterSchedulerApi
        .getModerators(params)
        //  : appointmentApi.getModerators(params))
        .then((response) => {
          setModerators(response.data);
        })
        .catch((err) => {
          throw err;
        });
  }, [params]);

  function removeModerators(...moderatorId) {
    if (moderatorId.length == 0) return moderators;
    return moderators.filter((moderator) => !moderatorId.includes(moderator.id));
  }

  return { moderators, setModeratorParams, removeModerators };
}
