import { AccountAPI } from "./apiUtils";

export const getNotifications = async (filter = null) =>
  await AccountAPI.get("/homepage-notification", filter && { params: { ...filter } });

// http://localhost:3000/api/homepage-sub-notification
export const getSubstitutions = async (filter = null) =>
  await AccountAPI.get("/homepage-sub-notification", filter && { params: { ...filter } });

export const getAdditionalSubstitutions = async (filter = null) => {
  if(filter.alert)
  {
    return await AccountAPI.post("/substitutions?time_range=" + filter.time_range + "&alert=true");
  }
  else
  {
    return await AccountAPI.post("/substitutions?time_range=" + filter.time_range + "&home=true"); 
  }
}
  
