import { GET_VILLAGE_SUCCESS,GET_VILLAGE_LIST_SUCCESS,UPD_VILLAGE_LIST_SUCCESS,ADD_VILLAGE_LIST_SUCCESS } from "../constants";
import * as sessionsApi from "../../apis/SessionsApi";

export function getVillages() {
  return function(dispatch) {
    return sessionsApi
      .getVillages()
      .then((response) => {
        dispatch({
          type: GET_VILLAGE_SUCCESS,
          villages: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getVillagesList() {
  return function(dispatch) {
    return sessionsApi
      .getVillagesList()
      .then((response) => {
          response.data.all.forEach((row, id) => {
            response.data.disabled.forEach((disabledstatus,disid ) => {
              if(response.data.all[id]['id'] == response.data.disabled[disid]['village_id']) {
                response.data.all[id]['status'] = response.data.disabled[disid]['status'];
                response.data.all[id]['statusid'] = response.data.disabled[disid]['id'];
              }
            });
          });
        dispatch({
          type: GET_VILLAGE_LIST_SUCCESS,
          villagesList: response.data.all,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}


//

export function updateVillagesList(villagesList, id) {
  return function(dispatch) {
    return sessionsApi
      .updateVillagesList(villagesList, id)
      .then((response) => {
        if(response.data.insertId) {
          dispatch({
            type: ADD_VILLAGE_LIST_SUCCESS,
            updateVillagesList: {
              ...villagesList,
              ["id"]: villagesList.id,
              ["statusid"]:response.data.insertId,
              ["name"]: villagesList.villagename,
            },
          });
        } else {
          dispatch({
            type: UPD_VILLAGE_LIST_SUCCESS,
            updateVillagesList: {
              ...villagesList,
              ["id"]: villagesList.id,
              ["name"]: villagesList.villagename,
            },
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  };
}