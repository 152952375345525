/* eslint-disable no-unused-vars */
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useReducer,
  useCallback,
} from "react";
import propTypes from "prop-types";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "underscore";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { topicTypes } from "../../utils/sessionUtils"
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import * as coachShedulerAction from "../../redux/actions/CoachShedulerAction";

import * as masterShedulerAction from "../../redux/actions/MasterSchedulerAction";
import * as RstvAction from "../../redux/actions/RSTVAction";
import {
  useVillage,
  useModerator,
  useAvailableCoach,
  useConfig,
} from "../../hooks";
import CreateSlotObject from "../../business_layer/model/CreateSlotObject";
import { getCoachMaxUnits } from "../../apis/CoachProfileApi";

import "react-datepicker/dist/react-datepicker.css";
import "./CreateShiftModal.css";
import classes from "../CoachSchedulerComponent/CreateSessionModal.module.css";
import clsx from "clsx";
import { getCoachRstvTopic } from "../../apis/CoachSchedulerApi";

function languageReducer(state = "", action) {
  for (let language of ["totale", "solo", "fb", "yp", "rstv", "cp"]) {
    if (action.language.toLowerCase().includes(language.toLowerCase()))
      return language;
  }
  return "totale";
}

function getRecurring(data) {
  const {
    is_recurring,
    recurring_end_date,
    recurring_frequency,
    ...rest
  } = data;
  if (is_recurring == false || rest.type == "ExtraSession") return rest;
}

// function sessionPayload(action) {
//   //const recurring = getRecurring(action.payload);

//   if (
//     action.payload.type === "session" ||
//     action.payload.type == "ConfirmedSession" ||
//     action.payload.type == "ExtraSession" ||
//     action.payload.type == "LocalSession"
//   ) {
//     const {
//       teacher_confirmed,
//       appointment_type_id,
//       details,
//       ...restSession
//     } = recurring;
//     if (["totale", "solo", "fb", "yp", "cp"].includes(action.type)) {
//       const { moderator_id, ...remain } = restSession;
//       return remain;
//     }
//     if (action.type === "rstv") {
//       const { number_of_seats, lesson, level, unit, ...remain } = restSession;
//       return remain;
//     }
//   }
// }

function CreateExtraBulkSessionModal({ ...props }) {
  const createObject = new CreateSlotObject();
  const {
    settings,
    setSettings,
    SessionDetails,
    masterFilter,
    bulkSessions,
    setBulkSessions,
    setBulkSession,
    bulkSessionsObject
  } = props;

  const [session, setSession] = useState({
    ...createObject,
    language_identifier: masterFilter.languageId,
    is_recurring: true,
    type: ["rstv", "cp"].some((e) =>
      masterFilter.languageId.toLowerCase().includes(e)
    )
      ? "session"
      : "ExtraSession",
    max_unit: 20,
    wildcard: false,
  });
  const { moderators, setModeratorParams } = useModerator(true);
  const [checkRecurring, setCheckRecurring] = useState({
    disable_recurring: false,
  });
  //const { sessions, setSessionParams } = useConfig();
  const villages = useVillage();
  const [store, dispatch] = useReducer(languageReducer, "totale");
  const [double, setDouble] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { coachs, setCoachParams } = useAvailableCoach(true);
  const calMultiRef = useRef();
  const coachTime = useRef("");
  const [maxUnit, setMaxUnit] = useState({});
  const [notes, setNotes] = useState(null);
  const [rstvTopics, setRstvTopics] = useState([]);
  const [intimation, setIntimation] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());

  const coaches = useMemo(() => bulkSessions.topic_restrictions === "none" ? coachs : coachs.filter(c => c.conv_pract), [bulkSessions.topic_restrictions, coachs]);
  // useEffect(() => {
  //   if (session.type == "ExtraSession") {
  //     setOptions([
  //       { label: "All", value: "*" },
  //       ...coachs.map(({ id, full_name }) => ({ label: full_name, value: id })),
  //     ]);
  //     setSelectedOptions([
  //       { label: "All", value: "*" },
  //       ...coachs.map(({ id, full_name }) => ({ label: full_name, value: id })),
  //     ]);
  //   }
  // }, [sessions]);

  useEffect(()=>{
    dragElement(document.getElementById("masterBulkDraggable"));
  },[props.openModal])

  useEffect(() => {
    setOptions([
      { label: "All", value: "*" },
      ...coaches.map(({ id, full_name, conv_pract }) => ({ label: full_name, value: id, conv_pract })),
    ]);
    setSelectedOptions([
      { label: "All", value: "*" },
      ...coaches.map(({ id, full_name, conv_pract }) => ({ label: full_name, value: id, conv_pract })),
    ]);
  }, [coaches, props.openModal]);

  useEffect(() => {
    if (masterFilter.languageId != "" && props.openModal == true) {
      let additional = {};
      const value = masterFilter.languageId;
      dispatch({ language: value });
      if (!["cp", "yp", "fb", "rstv"].includes(value.toLowerCase()))
        additional.number_of_seats = 5;
      if (value.toLowerCase().includes("cp")) additional.number_of_seats = 0;
      if (value.toLowerCase().includes("yp")) additional.number_of_seats = 4;
      if (value.toLowerCase().includes("fb")) additional.number_of_seats = 6;
      if (value.toLowerCase().includes("solo")) additional.number_of_seats = 1;
      setBulkSessions({
        ...bulkSessions,
        language_identifier: masterFilter.languageId,
        type: "ExtraSession",
        // is_recurring: true,
        // wildcard: false,
        ...additional,
      });
      // {masterFilter.languageId.toLowerCase().includes("rstv") &&
      //     getCoachRstvTopic(masterFilter.languageId).then((response) => {
      //       setRstvTopics(response.data);
      //     });
      // }
    }
  }, [masterFilter.languageId, props.openModal]);

  useEffect(() => {
    if (masterFilter.languageId != "") {
      var topic_restrictions = 'none';
      if (/FB-.+-Solo/i.test(masterFilter.languageId)){
        topic_restrictions = 'all_topics_plus_cp';
      }
      setBulkSessions({
        ...bulkSessions,
        topic_restrictions: topic_restrictions
      });
    }
  }, [masterFilter.languageId]);

  useEffect(() => {
    console.log(
      session,
      coachs,
      props.shiftDetailParams.start_date,
      props.openModal
    );
    if (props.openModal == true) {
      // if (coachTime.current != currentDate) {
        setCoachParams({
          language: masterFilter.languageId,
          session_start_time:  moment(currentDate).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
        });
        // coachTime.current = currentDate;
      // }
      // store == "rstv" &&
      //   setModeratorParams({
      //     language: masterFilter.languageId,
      //     session_start_time: moment(props.shiftDetailParams.start_date).format(
      //       "YYYY-MM-DD HH:mm:ss"
      //     ),
      //   });
    }
//  }, [session, coachs, props.openModal]);
    }, [props.openModal]);
  //start from above
  // useEffect(() => {
  //   if (props.openModal == true && props.shiftDetailParams.start_date != "") {
  //     setSessionParams({
  //       start_time: moment(props.shiftDetailParams.start_date).unix() || "",
  //       language_identifier: masterFilter.languageId || "",
  //       region_id: "all",
  //       external_village_id: "all",
  //     });
  //   }
  // }, [masterFilter.languageId, props.shiftDetailParams.start_date, props.openModal]);

  const getInputDisableConfig = useMemo(() => {
    const disable = {
      village: false,
      seat: false,
      unit: false,
      lesson: false,
      topic: false,
      moderator: false,
    };
    return {
      cp: disable,
      yp: disable,
      www: disable,
      solo: disable,
      fb: disable,
      totale: {
        ...disable,
        village: true,
        seat: true,
        unit: true,
        lesson: true,
      },
      rstv: { ...disable, topic: true, moderator: true },
    };
  });

  // useEffect(() => {
  //   //props.rstvResponse.length == 0 && props.actions.getRstvfunction();
  // }, [props.coachProfile, settings.dateStr]);

  function onSessionChange(e) {
    const target = e.target;
    // if (e.target.name == "coach_id" && store == "totale") {
    //   let requestData = {
    //     coach_id: e.target.value,
    //     language_id: masterFilter.languageId,
    //   };
    //   getCoachMaxUnits(requestData)
    //     .then((response) => {
    //       // let maxUnitValue = search(masterFilter.languageId, response.data.maxUnit)
    //       // console.log("Inside session change===========>" + JSON.stringify(maxUnitValue));
    //       setMaxUnit(response.data?.maxUnit);
    //       let int = `Max Level:${response.data.maxLevel || 0} Unit:${response
    //         .data.maxUnit || 0} Lesson:4`;
    //       setIntimation(int);
    //     })
    //     .catch((err) => {
    //       console.log("Error occured during MaxUnitLevel Fetch :" + err);
    //       setIntimation(null);
    //     });
    // }
    // if (e.target.name == "coach_id") {
    //   if (e.target.value != "") {
    //     coachShedulerAction
    //       .checkRecurrings({
    //         coach_id: e.target.value,
    //         session_start_time: moment
    //           .utc(settings.sessionDate)
    //           .format("yyyy-MM-DD HH:mm:ss"),
    //         day_index: moment.utc(settings.sessionDate).day(),
    //       })
    //       .then((result) => {
    //         if (result.disable_recurring) {
    //           //  setSession({...session, ["is_recurring"]: false})
    //           setNotes(
    //             "This coach is already scheduled to have a recurring Session/Appointment starting on " +
    //               "the following week. You can only schedule a one-off Session/Appointment dialog at this time"
    //           );
    //         } else {
    //           // setSession({...session, ["is_recurring"]: true})
    //           setNotes(null);
    //         }
    //         setCheckRecurring(result);
    //       });
    //   }
    // }
    // const value = target.type === "checkbox" ? target.checked : target.value;
    setBulkSessions({ ...bulkSessions, [target.name]: target.value });
  }

  function modifiedSession(start_time, name, value) {
    bulkSessions.sessions.map((session) => {
      if (session.session_start_time == start_time) {
        session[name] = value;
      }
    });
    return bulkSessions.sessions;
  }

  function onIndividualFieldChange(e) {
    const target = e.target;
    const startTime = target.getAttribute("data-start-time");
    setBulkSessions({
      ...bulkSessions,
      sessions: modifiedSession(startTime, target.name, target.value),
    });
  }

  function onChange(selectedItems, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(selectedItems.filter((o) => o.value !== "*"));
    } else if (selectedItems.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(selectedItems);
    }
  }
  // eslint-disable-next-line no-unused-vars
  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `All`;
    } else if (value.length === 0) {
      return `Select`;
    } else {
      return `${value.length} selected`;
    }
  }

  const promt = (msg) => {
    setDouble(false);
    alert(msg);
    return false;
  };

  function validate(payload) {
    // if (store == "rstv") {
    //   for (const required of ["coach_id", "moderator_id", "topic_id"]) {
    //     if (payload[required] == "" || payload[required] == null)
    //       return promt(`Please select a ${required.split("_")[0]}`);
    //   }
    //   if (payload.coach_id == payload.moderator_id)
    //     return promt("Coach and Moderator should be different");
    // }

    // if (store == "totale") {
    //   if (session.type == "session") {
    //     for (const required of ["coach_id"]) {
    //       if (payload[required] == "" || payload[required] == null)
    //         return promt(`Please select a ${required.split("_")[0]}`);
    //     }
    //   } else {
    //     const Soptions = [...selectedOptions.map((opt) => opt.value)];
    //     if (Soptions.length == 0 || selectedOptions == []) {
    //       return promt(`Please select a Coach`);
    //     }
    //   }
    // }
    return true;
  }

  function onSessionSubmit() {
    if(bulkSessions.sessions?.length == 0){
      alert("Please select atleast one slot");
      setDouble(false);
      return;
    }
    setDouble(true);
    // const payload = sessionPayload({ type: store, payload: session });
    const payload = bulkSessions;
   if (!validate(payload)) return;

    const Soptions = ["*", ...selectedOptions.map((opt) => opt.value)];

    if (Soptions == "*" || selectedOptions == []) {
      return promt(`Please select a Coach`);
    }

    // if (store == "totale" && payload.unit != "" && payload.type == "session") {
    //   if (payload.unit > maxUnit) {
    //     alert("Coach is not qualified to teach selected unit");
    //     setDouble(false);
    //     return;
    //   }
    // }
    props.actions
      .addBulkExtraSessions(
        {
          ...payload,
          language_type: store,
          ...(["solo", "fb", "yp"].includes(store)
            ? { max_unit: 1, unit: payload.unit || null }
            : store == "cp"
            ? { max_unit: 20, unit: false }
            : { unit: payload.unit || null }), //max_unit
          excluded_coaches: options
            .filter((option) => !Soptions.includes(option.value))
            .map((opt) => opt.value),
          sessions: payload.sessions.map((session) => {
            const start_time =
              store == "fb"
                ? moment(session.session_start_time).format("mm") == "30"
                  ? moment(session.session_start_time).subtract(30, "minutes")
                  : session.session_start_time
                : session.session_start_time;

            session.session_start_time = moment(start_time)
              .utc()
              .format("YYYY-MM-DD HH:mm:ss");
            session.session_end_time = moment(start_time)
              .add(store == "fb" ? 60 : 30, "minutes")
              .utc()
              .format("YYYY-MM-DD HH:mm:ss");
            session.number_of_sessions = parseInt(session.number_of_sessions)
            return session;
          }),
        },
        masterFilter
      )
      .then((response) => {
        setDouble(false);
        setBulkSession(false);
        setBulkSessions(bulkSessionsObject);
        props.setOpenModal(false);
        if(!response) {
          toast.error("Failed to create...");
        }
        // setSession({
        //   ...createObject,
        //   coach_id: "",
        //   is_recurring: true,
        //   language_identifier: masterFilter.languageId,
        //   type: ["rstv", "cp"].some((e) =>
        //     masterFilter.languageId.toLowerCase().includes(e)
        //   )
        //     ? "session"
        //     : "ExtraSession",
        //   max_unit: 20,
        //   wildcard: false,
        //   recurring_frequency: 1,
        //   recurring_end_date: "",
        // });
        // setSettings((settings) => ({ ...settings, ["displaySession"]: false }));
        // coachTime.current = "";
        // props.setOpenModal(false);
      })
      .catch(() => {
        setBulkSession(false);
        setBulkSessions(bulkSessionsObject);
        setDouble(false);
        props.setOpenModal(false);
        toast.error("Failed to create...");
        // setSession(createObject);
        // setSettings((settings) => ({ ...settings, ["displayCreate"]: false }));
      });
  }

  function formStyle(prop = "") {
    const sessionType =
      session.type == "session" || session.type == "ExtraSession";
    const sessionInput = {
      display: sessionType ? (prop == "" ? "block" : prop) : "none",
    };
    const sessionLanguageInput = {
      ...(session.language_identifier == "all"
        ? { display: "none" }
        : sessionInput),
    };
    // const recurringInput = {
    //   display: session.is_recurring && session.type === "session" ? (prop == "" ? "block" : prop) : "none",
    // };

    const recurringInput = {
      display:
        session.is_recurring &&
        !checkRecurring.disable_recurring &&
        session.type === "session"
          ? prop == ""
            ? "block"
            : prop
          : "none",
    };

    return {
      language: sessionInput,
      village: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      seats: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      unit: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      level: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      is_recurring: recurringInput,
    };
  }

  const fieldStyle = useCallback(() => {
    return getInputDisableConfig[store];
  }, [store]);

  function dragElement(elmnt) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById(elmnt.id + "Header")) {
      document.getElementById(elmnt.id + "Header").onmousedown = dragMouseDown;
    } else {
      elmnt.onmousedown = dragMouseDown;
    }
    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    }
    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      var winW = document.documentElement.clientWidth || document.body.clientWidth;
      var winH = document.documentElement.clientHeight || document.body.clientHeight;
      var maxX = winW - elmnt.offsetWidth - 1;
      var maxY = winH - elmnt.offsetHeight - 1;
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      if((elmnt.offsetTop - pos2) <= maxY && (elmnt.offsetTop - pos2) >= 0){
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      }
      if((elmnt.offsetLeft - pos1) <= maxX && (elmnt.offsetLeft - pos1) >= 0){
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      }
    }
    function closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  return (
    <>
      <div
        className="blockOverlay"
        style={{ display: double ? "block" : "none" }}
      ></div>
      <div className="blockMsg" style={{ display: double ? "block" : "none" }}>
        <h1>Please wait...</h1>
      </div>
      <div id="masterBulkDraggable">
        <div
          className="modal coach-schedule-session-details-modal master-schedule-create-edit-modal"
          id="create-extra-session"
          style={{
            display: props.openModal ? "block" : "none",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal_header" id="masterBulkDraggableHeader">
                <h4 className="modal-title">CREATE EXTRA BULK SESSION</h4>
                <a
                  className="close-icon"
                  title="Close"
                  aria-label="Close"
                  role="button"
                  data-dismiss="modal"
                  onClick={(e) => {
                    e.preventDefault();
                    // setSession({
                    //   ...createObject,
                    //   language_identifier: masterFilter.languageId,
                    //   is_recurring: true,
                    //   type: "session",
                    //   max_unit: 20,
                    //   wildcard: false,
                    // });
                    // setSettings((settings) => ({ ...settings, ["displaySession"]: true }));
                    props.setOpenModal(false);
                    props.setBulkSessions({...bulkSessions, sessions: [] })
                    props.setSchedules(props.schedules.filter((slot) =>{ return slot.slotType != "on-select" }));
                    //   document.getElementById("session-coach-dropdown").value = "";
                  }}
                >
                  <span>×</span>
                </a>
              </div>
              <div className="modal-body">
                <div className="left-section">
                  {/* only show conv topics for fb solo. IDs are in the format FB-ITA-Solo */}
                  {/FB-.+-Solo/i.test(masterFilter.languageId) && 
                    <div className="topic-restriction">
                      <div>Allowed Topics</div>    
                      <select name='topic_restrictions' onChange={onSessionChange}>
                          {topicTypes.map(({ name, value }) => (
                            <option key={value} value={value} selected={session.topic_restrictions === value}>                        
                              { name }
                            </option>
                          ))}
                        </select>                    
                    </div>
                  }
                  <div className="coach-availabe">
                    <span>Included Coaches:</span>
                    <ReactMultiSelectCheckboxes
                      options={options}
                      value={selectedOptions}
                      onChange={onChange}
                      ref={calMultiRef}
                      setState={setSelectedOptions}
                      getDropdownButtonLabel={getDropdownButtonLabel}
                    />
                  </div>
                  <select
                    className="external-village-id"
                    name="external_village_id"
                    value={bulkSessions.external_village_id}
                    onChange={onSessionChange}
                    disabled={!fieldStyle().village}
                  >
                    <option value="">Select a Village</option>
                    {villages.length > 0 &&
                      villages.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                  </select>
                  <div
                    className="unit-lesson-section clear-fix"
                    style={(formStyle("block").unit, formStyle().seats)}
                  >
                    <div>
                      <span>Unit</span>
                      <select
                        className="level-unit-dropdown"
                        name="unit"
                        value={bulkSessions.unit}
                        onChange={onSessionChange}
                        disabled={!fieldStyle().unit}
                      >
                        <option value="">-Unit-</option>
                        {_.range(1, 21).map((num) => (
                          <option key={num} value={num}>
                            {num}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <span>Lesson</span>
                      <select
                        className="level-unit-dropdown"
                        name="lesson"
                        value={bulkSessions.lesson}
                        onChange={onSessionChange}
                        disabled={!fieldStyle().lesson}
                      >
                        <option value="">-Lesson-</option>
                        <option value="2">2</option>
                        <option value="4">4</option>
                      </select>
                    </div>
                  </div>
                  <div className="max-unit-lesson">{intimation}</div>

                  <div style={formStyle().seats}>
                    <div style={{ display: "flex" }}>
                      <span>No. of Seats : </span>
                      <span>
                        <select
                          name="number_of_seats"
                          className="number_of_seats"
                          value={bulkSessions.number_of_seats}
                          onChange={onSessionChange}
                          disabled={!fieldStyle().seat}
                        >
                          {_.range(1, 11).map((num) => (
                            <option key={num} value={num}>
                              {num}
                            </option>
                          ))}
                        </select>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="selected-sessions">
                  {bulkSessions.sessions.map((session, index) => {
                    return (
                      <div className="individual-session" key={index}>
                        <div className="bold-font bulk-session-time">
                          {moment(session.session_start_time).format("ddd MM/DD/YYYY hh:mmA")}
                          {/* {moment(new Date()).format("ddd MM/DD/YYYY hh:mmA")} */}
                        </div>
                        <div className="session-name clear-fix">
                          <span>Session Name</span>
                          <input
                            id="session-name-txt"
                            name="name"
                            data-start-time={session.session_start_time}
                            type="text"
                            value={session.session_name_txt}
                            onChange={onIndividualFieldChange}
                          />
                        </div>
                        {/* <div style={formStyle().seats}> */}
                        <div>
                          <div style={{ display: "flex" }}>
                            <span className="number_of_sessions">No. of Sessions : </span>
                            <span>
                              <select
                                name="number_of_sessions"
                                value={session.number_of_sessions}
                                onChange={onIndividualFieldChange}
                                data-start-time={session.session_start_time}
                              >
                                {_.range(1, 11).map((num) => (
                                  <option key={num} value={num}>
                                    {num}
                                  </option>
                                ))}
                              </select>
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="footer-section">
                  <input
                    type="button"
                    disabled={double}
                    value="CREATE"
                    className="create_button"
                    onClick={onSessionSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

CreateExtraBulkSessionModal.defaultProps = {
  masterFilter: { languageId: "all" },
};

CreateExtraBulkSessionModal.propTypes = {
  settings: propTypes.object,
  setSettings: propTypes.func,
  SessionDetails: propTypes.object,
  masterFilter: propTypes.object,
  shiftDetailParams: propTypes.object,
  openModal: propTypes.bool.isRequired,
  setOpenModal: propTypes.func,
  coachProfile: propTypes.array.isRequired,
  rstvResponse: propTypes.array.isRequired,
  actions: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  coachProfile: state.coachProfile,
  rstvResponse: state.rstvStore.rstvResponse,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      addBulkExtraSessions: bindActionCreators(masterShedulerAction.addBulkExtrasessions, dispatch),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateExtraBulkSessionModal);
