import { GET_LANGUAGE_SUCCESS } from "../constants";
import * as sessionsApi from "../../apis/SessionsApi";
import { getLanguageByIdentifier } from "../../utils/languageUtils";

export function getLanguages() {
  return function(dispatch) {
    return sessionsApi
      .getLanguages()
      .then((response) => {
        //let data = response.data
        response.data.forEach((row, id) => {
          response.data[id]["language_name"] = getLanguageByIdentifier(
            response.data[id]["identifier"]
          );
        });
        console.log(response);
        dispatch({
          type: GET_LANGUAGE_SUCCESS,
          languages: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}
