import { useState, useEffect } from "react";
import * as coachProfileApi from "../apis/CoachProfileApi";

export default function useRegion() {
  const [regions, setRegions] = useState([]);
  console.log("object region");
  useEffect(() => {
    coachProfileApi
      .getRegions()
      .then((response) => {
        setRegions(response.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  return regions;
}
