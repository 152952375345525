import { GET_VILLAGE_LIST_SUCCESS,UPD_VILLAGE_LIST_SUCCESS,ADD_VILLAGE_LIST_SUCCESS } from "../constants";

import initialState from "./initialState";

export default function villageAdminReducer(state = initialState.villagesList, action) {
  switch (action.type) {
    case ADD_VILLAGE_LIST_SUCCESS:
      return [...state, { ...action.updateVillagesList }];
    case GET_VILLAGE_LIST_SUCCESS:
      return action.villagesList;
    case UPD_VILLAGE_LIST_SUCCESS:
      return state.map((link) =>
        link.id === action.updateVillagesList.id ? action.updateVillagesList : link
      );
    default:
      return state;
  }
}
