import { AccountAPI } from "./apiUtils";


export const addLink = async message => 
    await AccountAPI.post("/links",{
        ...message,
        "created_by": 16,       
    });

export const getLinks = async (id=null) => await AccountAPI.get("/links", id && { params: { id }});

export const updateLink = async(link, id) =>    
    await AccountAPI.patch("/links",{...link,["id"]:id});


export const deleteLink = async id => await AccountAPI.delete("/links",{params:{id}});