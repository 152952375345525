import { 
      ADD_COACH_ALERT_SUCCESS
    , GET_COACH_ALERT_SUCCESS
    , DEL_COACH_ALERT_SUCCESS 
} from "../constants";
import * as coachAlertApi from '../../apis/CoachAlertApi';


export function add(alart) {
    return function (dispatch) {
        return coachAlertApi
            .addCoachAlert(alart)
            .then((response) => 
                dispatch({
                    type: ADD_COACH_ALERT_SUCCESS,
                    addAlert: { 
                        id: response.data.insertId,
                        description: alart
                }}))
            .catch(err => { throw err; })   
    }
}


export function getAlerts() {
    return function(dispatch) {
        return coachAlertApi.getCoachAlert()
            .then((response)=> {
                dispatch({
                    type: GET_COACH_ALERT_SUCCESS,
                    alerts: response.data
                })})
            .catch(err => { throw err; })
    } 
}

export function removeAlert(alert) {
    return function(dispatch) {
        return coachAlertApi.deleteCoachAlert(alert.id)            
            .then(() => {
                dispatch({
                    type: DEL_COACH_ALERT_SUCCESS,
                    alert
                })})
            .catch(err => { throw err; })
    } 
}