import React, { useState } from "react";

import "./Edit.css";
import close from "../images/closelabel.gif";

export default function Add({ ...props }) {
  function addLink(e) {
    e.preventDefault();
    //console.log({name,url});
    if (name == "") {
      alert("Please Enter a Name");
      return;
    } else if (url == "") {
      alert("Please Enter a URL");
      return;
    }
    const { add, setAddToggle, addToggle } = props;
    // console.log({name,url});
    add({ name, url });
    setAddToggle(!addToggle);
  }

  const { addToggle, setAddToggle } = props;
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");

  return (
    <div className="container">
      <div
        className="modal"
        style={{ display: addToggle ? "block" : "none" }}
        id="edit_link_modal"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal_header">
              <h4 className="modal-title">Editing Link</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => setAddToggle(!addToggle)}
              >
                <img src={close} title="close" className="close_image" />
              </button>
            </div>
            <div className="modal-body">
              <p className="subnote">
                Fields labeled with <i>*</i> are required.
              </p>
              <div className="formfield">
                <span className="required">*</span>
                <label htmlFor="alink_name">Name</label>
                <input
                  id="alink_name"
                  size="30"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="formfield">
                <span className="required">*</span>
                <label htmlFor="alink_url">URL</label>
                <input
                  id="alink_url"
                  size="30"
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="footer">
              <p>
                {" "}
                <input
                  id="alink_submit"
                  type="submit"
                  value="Save"
                  onClick={(e) => addLink(e)}
                />{" "}
              </p>
              {/* <a href="">Show</a> |
                <a href="">Links List</a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
