import {
  GET_LINKS_SUCCESS,
  ADD_LINKS_SUCCESS,
  UPD_LINKS_SUCCESS,
  DEL_LINKS_SUCCESS,
} from "../constants";

import initialState from "./initialState";

export default function linksReducer(state = initialState.links, action) {
  switch (action.type) {
    case ADD_LINKS_SUCCESS:
      return [...state, { ...action.addLink }];
    case GET_LINKS_SUCCESS:
      return action.links;
    case UPD_LINKS_SUCCESS:
      return state.map((link) =>
        link.id === action.updateLink.id ? action.updateLink : link
      );
    case DEL_LINKS_SUCCESS:
      return state.filter((link) => link.id !== action.link.id);
    default:
      return state;
  }
}
