import immer from "immer";
import { GET_STUDIO_ON_DUTY_SUCCESS, ADD_STUDIO_ON_DUTY_SUCCESS, MDY_GLOBAL_SETTINGS } from "../constants";
import initialState from "./initialState";

export default function studioOnDutyReducer(state = initialState.studioTeam, action) {
  if (action.type === GET_STUDIO_ON_DUTY_SUCCESS) return { onDuty: action.onDuty };

  if (action.type === ADD_STUDIO_ON_DUTY_SUCCESS) {
    return immer(state, function(currState) {
      currState["onDuty"][0] = action.addStudioTeam;
      return currState;
    });
  }

  if (action.type === MDY_GLOBAL_SETTINGS) {
    // console.log(action.mdySettings);
    return immer(state, (currState) => {
      currState["onDuty"][1] = [action.mdySettings];
      return currState;
    });
  }

  return state;
}
