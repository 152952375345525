import { AccountAPI, EschoolAPI } from "./apiUtils";

// http://localhost:3002/api/create-session
export const addCoachSession = async (data) => await EschoolAPI.post("/create-session", data);

// http://localhost:3002/api/create-session
export const addBulkCoachSessions = async (data) => await EschoolAPI.post("/create-bulk-session", data);

//http://localhost:3002/api/coach-scheduler
export const getCoachScheduler = async (params) => await EschoolAPI.post("/coach-scheduler", params);

//http://localhost:3002/api/slot-session-details/
export const getSessionDetails = async (params) => await EschoolAPI.post("/slot-session-details", params);

//http://localhost:3002/api/sub-request/
export const requestSubstituteCoach = async (params) => await EschoolAPI.post("/sub-request", params);

//http://localhost:3002/appointment-type
export const getAppointmentTypes = async () => await EschoolAPI.get(`/appointment-type`);

//http://localhost:3002/api/reassign-coach-substitution
export const assignSubstituteCoach = async (params) => await EschoolAPI.post("/reassign-coach-substitution", params);

//http://localhost:3002/api/edit-session
export const updateSessionDetail = async (data) => await EschoolAPI.patch("/edit-session", data);

//http://localhost:3000/api/check-violation-policy?start_time=2021-03-27 10:00:00&session_id=684058&coach_id=9547
export const checkViolationPolicy = async ({ start_time, session_id, coach_id }) =>
  await AccountAPI.get(
    `/check-violation-policy?start_time=${start_time}&session_id=${session_id}&coach_id=${coach_id}`
  );

//http://localhost:3002/api/cancel-session
export const cancelCoachSession = async (params) => await EschoolAPI.post("/cancel-session", params);

//http://localhost:3000/api/fetch-available-coaches?lang_identifier=TUR&start_time="2021-04-23 10:00:00"&session_id=668582&coach_id=9145
export const getAvailableCoaches = async ({ lang_identifier, start_time, session_id, coach_id }) =>
  await AccountAPI.get(
    `/fetch-available-coaches?lang_identifier=${lang_identifier}&start_time=${start_time}&session_id=${session_id}&coach_id=${coach_id}`
  );

export const getTimeOffApi = async (id) => await AccountAPI.get(`/list-timeoff?coach_id=${id}`);

export async function createTimeOffApi(params) {
  return await AccountAPI.post("/request-timeoff", params);
}

export const cancelTimeOffApi = async (data) => {
  return await AccountAPI.delete("/cancel-timeoff", { data });
};

//http://localhost:3002/api/check-recurrings
export const checkRecurrings = async (params) => await EschoolAPI.post("/check-recurrings", params);

export const getClosestSessions = async (id) => await EschoolAPI.get("/next-session");

export const getUpcomingSessions = async () => await EschoolAPI.get("/my-upcoming-sessions");

export const getMaxLevelUnit = async (params) => await EschoolAPI.get(`/max-unit-level-for-coach?coachId=${params.coach_id}&langCode=${params.language_id}`);

export const getCoachRstvTopic = async (language) => await EschoolAPI.get("/get-rstv-topics?language_identifier=" + language);
