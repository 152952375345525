import { AccountAPI } from "./apiUtils";


// const baseUrl = `${process.env.API_URL}/api/alerts`;// + "/api/login";
// const getByURI = uri => `${process.env.API_URL}/api/alerts/${uri}`;// + "/api/login";

const doublefy = num => (num > 9)? num: `0${num}`;
const getDate = date => `${date.getFullYear()}-
            ${doublefy(date.getMonth())}-${doublefy(date.getDay())} 
            ${doublefy(date.getHours())}:${doublefy(date.getMinutes())}:
            ${doublefy(date.getSeconds())}`;

export const addCoachAlert = async message => 
    await AccountAPI.post("/alerts",{
        "description": message,
        "updated_at": getDate(new Date()),
        "created_by": 16,
        "created_at": getDate(new Date()),
        "active": 1	
    });

export const getCoachAlert = async () => await AccountAPI.get("/alerts");

export const updateCoachAlert = async id => await AccountAPI.put("/alerts",{},{params:{id}});

export const deleteCoachAlert = async id => await AccountAPI.delete("/alerts",{params:{id}})