import React from "react";
import clsx from "clsx";
import propTypes from "prop-types";
import classes from "./CancelSessionModal.module.css";

function CancelSessionModal({ modalOpen, setModalOpen, ...props }) {
  return (
    <div
      style={{ display: modalOpen.toggle ? "block" : "none" }}
      className={clsx(classes.modal, classes.coach_schedule_session_details_modal)}
      id={classes.coach_schedule_cancel_conformation_modal} //"coach-schedule-cancel-conformation-modal"
    >
      <div className={classes.modal_dialog}>
        <div className={classes.modal_content}>
          <a
            className={classes.close_icon}
            title="Close"
            aria-label="Close"
            role="button"
            data-dismiss="modal"
            id="cancel-modal-close"
            onClick={(e) => {
              e.preventDefault();
              setModalOpen((data) => ({ ...data, toggle: false }));
            }}
          >
            <span>×</span>
          </a>
          <div className={classes.modal_body}>
            Do you want to cancel this session only, or cancel both this session and all future occurrences?
          </div>
          <div className="footer">
            <div className={classes.cancel_footer}>
              <input
                type="button"
                className={classes.cancel_button}
                value="CANCEL WITH RECURRENCE"
                onClick={(e) =>{
                  setModalOpen(
                    (data) => ({ ...data, value: e.target.value }),
                    () => props.onSessionCancel(e.target.value)
                  )
                  document.getElementById("filter-language").value="";
                }
                }
              />
              <input
                className="footer-button"
                type="button"
                value="CANCEL"
                onClick={(e) =>{
                  setModalOpen(
                    (data) => ({ ...data, value: e.target.value }),
                    () => props.onSessionCancel(e.target.value)
                  )
                  document.getElementById("filter-language").value="";
                }
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CancelSessionModal.defaultProps = {
  modalOpen: { toggle: false },
};
CancelSessionModal.propTypes = {
  modalOpen: propTypes.bool.isRequired,
  setModalOpen: propTypes.func,
};

export default CancelSessionModal;
