import { useState, useEffect } from "react";
import * as masterSchedulerApi from "../apis/MasterSchedulerApi";

export default function useConfig() {
  const [sessions, setSessions] = useState({ all_coaches: [[], []] });
  const [params, setParams] = useState({
    start_time: "1618655400000",
    language_identifier: "ENG",
    region_id: "all",
    external_village_id: "all",
  });

  useEffect(() => {
    Object.keys(params).length > 0 &&
      masterSchedulerApi
        .getCreateExtraSession(params)
        .then((response) => {
          console.log(response.data);
          setSessions(response.data);
        })
        .catch((err) => {
          throw err;
        });
  }, [params]);

  return { sessions, setSessionParams: setParams };
}
