import classes from "../CoachSchedulerComponent/CreateSessionModal.module.css";
import React, {
  useState,
  useEffect,
  useMemo,
  useReducer,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";
import propTypes from "prop-types";
import { bindActionCreators } from "redux";
import moment from "moment";
import clsx from "clsx";
import _ from "underscore";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import * as coachShedulerAction from "../../redux/actions/CoachShedulerAction";

  
import * as appointmentAction from "../../redux/actions/appointmentAction";
import {
  getLanguageByIdentifier,
  sortAppointmentNamesBy,
  sortLanguagesBy,
} from "../../utils/languageUtils";
import { useVillage, useAppointmentTypes, useModerator } from "../../hooks";
import * as RstvAction from "../../redux/actions/RSTVAction";
import CreateSlotObject from "../../business_layer/model/CreateSlotObject";
import { getQualifiedLanguages } from "../../apis/CoachProfileApi";
import { getCoachRstvTopic } from "../../apis/CoachSchedulerApi";
import { modCoachAvailabilityApi } from "../../apis/SubstitutionApi";
import "react-datepicker/dist/react-datepicker.css";
import "../CoachSchedulerComponent/CreateSessionModal.css";
import { sortCoachNamesBy } from "../../utils/languageUtils";



function AppointmentCreate({...props}) {
  const {settings, setSettings, isFromAppoinment} = props;
  const createObject = new CreateSlotObject();
  const [session, setSession] = useState(createObject);
  const appointmentTypes = useAppointmentTypes();
  const [checkRecurring, setCheckRecurring] = useState({
    disable_recurring: false,
  });
  const { pathname } = useLocation();
  const [double, setDouble] = useState(false);
  const [coaches, setCoaches] = useState([]);
  const [notes, setNotes] = useState(null);

  useEffect(() => {
    // getCoachNames("Coach").then((res) => {
    //   setCoaches(res.data);
    // });
    if(settings.displayAppointmentCreate)
    { 
      setDouble(true)
      setSession({...createObject, coach_id: ""});
      // modCoachAvailabilityApi({"session_start_time": moment(props.settings.appointment_date).utc().format("YYYY-MM-DD HH:mm:ss")}).then((res) => {
        modCoachAvailabilityApi({"type": "appointment"}).then((res) => {
        setDouble(false);
        setCoaches(res.data);
      });
    }
  }, [settings.displayAppointmentCreate]);

  function onSessionChange(e) {
    let additional = {};
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setSession({ ...session, [target.name]: value, ...additional });
    if(target.name == "coach_id")
    {
      if(target.value != "")
      {
        props.actions.checkCoachAvailable({
          session_start_time: settings.start_date,
          coach_id: target.value,
        }).then((response)=>{
          if(response.status){
            if(response.available){
              coachShedulerAction
              .checkRecurrings({
                coach_id: target.value,
                session_start_time: moment
                  .utc(props.settings.appointment_date)
                  .format("yyyy-MM-DD HH:mm:ss"),
                day_index: moment.utc(props.settings.appointment_date).day(),
              })
              .then((result) => {
                if(result.disable_recurring) //CSP-2584
                {
                //  setSession({...session, ["is_recurring"]: false})
                  setNotes(
                    "This coach is already scheduled to have a recurring Session/Appointment starting on " +
                      "the following week. You can only schedule a one-off Session/Appointment dialog at this time"
                  )
                  setCheckRecurring({...result, disable_recurring: true});
                }
                else
                {
                // setSession({...session, ["is_recurring"]: true})
                setNotes(null)
                setCheckRecurring({...result, disable_recurring: false});
                }
              });
            }
            else{
              alert("Coach is not available for the selected slot")
            }
          }
          else{
            alert(response.data);
          }
        });
      }
    }
  }

  function getRecurring(data) {
    const {
      is_recurring,
      recurring_end_date,
      recurring_frequency,
      ...rest
    } = data;
    if (is_recurring == false) return rest;
    else {
      const endDate = moment(recurring_end_date, "YYYY-MM-DD").isValid()
        ? { recurring_end_date }
        : {};
      return {
        ...data,
        ...endDate,
        recurring_frequency,
        recurring_start_date: data.session_start_time,
      };
    }
  }

  function validate(payload) {
      if(payload.label == "appointment"){
        return promt(`Please select an appointment type to continue.`);
      }
    return true;
  }

  const promt = (msg) => {
    setDouble(false);
    alert(msg);
    return false;
  };

  function sessionPayload(action, appointmentTypes = []) {
    const recurring = getRecurring(action.payload);
    const {
      teacher_confirmed,
      number_of_seats,
      lesson,
      level,
      unit,
      language_identifier,
      moderator_id,
      topic_id,
      appointment_type_id,
      ...restAppointments
    } = recurring;

    if (action.payload.type === "appointment") {
      let label;
      if(appointment_type_id === "" || appointment_type_id === "all"){
        label = "appointment";
      } else{
        label = appointmentTypes.find(
          (type) => type.id == appointment_type_id
        ).title;
      }
      return { ...restAppointments, appointment_type_id, label };
    }
  }

  

  function onSessionSubmit() {
    if(!session.coach_id)
    {
      alert("Please select a coach")
      return;
    }
    if(!session.appointment_type_id)
    {
      alert("Please select a appointment type")
      return;
    }
    setDouble(true)

    const appointments = []
    let getAppointmentParams={
      appointment_type_id: settings.appointment_type,
      start_date: settings.start_date,
      day_index: settings.day_index
    }
    appointments.push({...session, 
      session_start_time: moment(props.settings.appointment_date).utc().format("YYYY-MM-DD HH:mm:ss"), 
      session_end_time: moment(props.settings.appointment_date)
      .add(30, "minutes").utc()
      .format("YYYY-MM-DD HH:mm:ss"),
      type: "appointment",
      language_identifier: "",
      day_index: moment.utc(props.settings.appointment_date).day(),
      recurring_start_date: session.is_recurring ? moment(props.settings.appointment_date).utc().format("YYYY-MM-DD HH:mm:ss") : null,
      is_recurring: (checkRecurring.disable_recurring) ? false : session.is_recurring
    })
    props.actions.createAppointment(appointments, props.appointmentFilter, getAppointmentParams).then(() =>{
      toast.success("Appointment created successfully")
      setSettings({...settings, displayAppointmentCreate: false})
     
      setDouble(false)
    })
    .catch((err) => {
      toast.error("Failed to create appointment")
      setSettings({...settings, displayAppointmentCreate: false})
      setDouble(false)
    })
      // props.actions
      //   .addSessions(
      //     {
      //       ...payload,
      //       language_type: store,
      //       coach_id:
      //         props.coachProfile.length > 0 ? props.coachProfile[0][0].id : "",
      //       //   session_start_time: moment(props.settings.dateStr).format("YYYY-MM-DD HH:mm:ss"),
      //       session_start_time: moment(start_time).format(
      //         "YYYY-MM-DD HH:mm:ss"
      //       ),
      //       //  session_end_time: moment(props.settings.dateStr)
      //       session_end_time: moment(start_time)
      //         .add(store == "fb" ? 60 : 30, "minutes")
      //         .format("YYYY-MM-DD HH:mm:ss"),
      //       teacher_confirmed: true,
      //     },
      //     props.coachFilter
      //   )
      //   .then(() => {
      //     setDouble(false);
      //     setSession(createObject);
      //     dispatch({ language: "totale" }) //Throw totale form by default
      //     setSettings((settings) => ({
      //       ...settings,
      //       ["displayCreate"]: false,
      //     }));
      //   })
      //   .catch((err) => {
      //     setDouble(false);
      //     toast.error("Failed to create...");
      //     setSession(createObject);
      //     dispatch({ language: "totale" }) //Throw totale form by default
      //     setSettings((settings) => ({
      //       ...settings,
      //       ["displayAppointmentCreate"]: false,
      //     }));
      //   });
  }

  const getNotes = useMemo(() => {
    const notes = [];
    // if (
    //   (settings.fromDetails != true
    //     ? checkRecurring.disable_recurring
    //     : false) ||
    //   (SessionDetails.cancelled == "true" &&
    //     settings.fromDetails == true &&
    //     settings.sessionDisableRecurring == true)
    // )
    if (
      (settings.fromDetails != true
        ? checkRecurring.disable_recurring
        : false) ||
      ( settings.fromDetails == true &&
        checkRecurring.disable_recurring == true)
    )
      notes.push(
        "This coach is already scheduled to have a recurring Session/Appointment starting on " +
          "the following week. You can only schedule a one-off Session/Appointment dialog at this time"
      );
    return notes.length <= 0 ? false : notes;
  }, [checkRecurring, settings.dateStr, settings.fromDetails]);

  function formStyle(prop = "") {
    const sessionInput = { display: session.type == "session" ? prop : "none" };
    const sessionLanguageInput = {
      ...(session.language_identifier == "all"
        ? { display: "none" }
        : sessionInput),
    };
    const recurringInput = { display: session.is_recurring ? prop : "none" };
    const appointmentInput = {
      display: session.type == "appointment" ? prop : "none",
    };

    return {
      appointment_type: appointmentInput,
      appointment_details: appointmentInput,
      is_recurring: recurringInput,
    };
  }

  return (
    <>
      <div
        className={classes.blockOverlay}
        style={{ display: double ? "block" : "none" }}
      ></div>
      <div
        className={classes.blockMsg}
        style={{ display: double ? "block" : "none" }}
      >
        <h1>Please wait...</h1>
      </div>
      
      <div
        className={clsx(classes.modal, classes.coach_schedule_create_session)}
        id="coach-schedule-create-session"
        style={{
          display: settings.displayAppointmentCreate ? "block" : "none",
          //display: "block",
          // left: settings.xDistance > 610 ? settings.xAxis - 237 + "px" : settings.xAxis + 240 + "px",
          left: settings.xAxis + "px",
          top: settings.yAxis + "px",
        }}
      >
        <div className={classes.modal_dialog}>
          <div className={classes.modal_content}>
            <div className={classes.modal_header}>
              <h4 className={classes.modal_title}>
                CREATE NEW APPOINTMENT at{" "}
                <span style={{ color: "#1090CF" }}>
                  {moment(props.settings.appointment_date).format(
                    "ddd MM/DD/YYYY hh:mmA"
                  )}
                </span>
              </h4>
              <a
                className={classes.close_icon}
                title="Close"
                aria-label="Close"
                role="button"
                data-dismiss="modal"
                id="coach-modal-close"
                onClick={(e) => {
                  e.preventDefault();
                  setSession(createObject); 
                //setSession({...createObject, language_identifier: "all"});
                  //dispatch({ language: "totale" })
                  setSettings((settings) => ({
                    ...settings,
                    ["displayAppointmentCreate"]: false,
                  }));
                }}
              >
                <span>×</span>
              </a>
            </div>
            <div className={classes.modal_body}>
              
              <div className={classes.session_section}>
              <div className={classes.session_type}>
                <div>
                  <select
                    name="coach_id"
                    className={clsx(
                      classes.dropdown,
                    )}
                    onChange={onSessionChange}
                    value={session.coach_id}
                    // disabled={filter.language == ""}
                    style={{ width: "150px" }}
                  >
                    <option value="">Select a Coach</option>
                    {sortCoachNamesBy(coaches, "full_name").map((coach) => {
                    // if (sessionInfo.rstv_guid != null)
                    // {
                    //   return( 
                    //   <option key={coach.id} value={coachDetail(coach)} style={{backgroundColor: coach?.have_session ? "#ffa500" : "" }}>
                    //   {coach.full_name}
                    //   </option>
                    //   )
                    // }
                    // else
                    // {
                      return(
                      <option key={coach.id} value={coach.id}>
                      {coach.full_name}
                      </option>
                      )
                    // }
                    })}
                  </select>
                </div>
                <br />
                <select
                  className={clsx(
                    classes.dropdown,
                  )}
                  name="appointment_type_id"
                  onChange={onSessionChange}
                  value={session.appointment_type_id}
                  // style={formStyle().appointment_type}
                >
                  <option value="all">Select appointment type</option>
                  {appointmentTypes.length > 0 &&
                    sortAppointmentNamesBy(appointmentTypes, "title").map(
                      ({ id, title }) => (
                        <option key={id} value={id}>
                          {title}
                        </option>
                      )
                    )}
                </select>
              </div>
                <div
                  className={classes.appointment_content}
                  // style={formStyle().appointment_details}
                >
                  <div className={clsx(classes.title, classes.bold_font)}>
                    Appointment details:
                  </div>
                  <textarea
                    cols="34"
                    name="details"
                    maxLength="500"
                    className={classes.textarea}
                    value={session.details}
                    onChange={onSessionChange}
                    style={{ display: "block", clear: "both" }}
                  ></textarea>
                  <div className={classes.appointment_char}>
                    <span>Characters remaining:</span>
                    <span className={classes.char_count}>
                      {session.details.length
                        ? 500 - session.details.length
                        : "500"}
                    </span>
                  </div>
                </div>
                <div className={classes.row2}>
                  <div className={classes.left_section}>
                    <div className={classes.recurring_checkbox}>
                      <span>Recurring</span>
                      <span>
                        <input
                          name="is_recurring"
                          type="checkbox"
                          checked={session.is_recurring && !checkRecurring.disable_recurring ? "checked" : ""}
                          onChange={onSessionChange}
                          
                          // disabled={
                          //   pathname.includes("my_schedule") ||
                          //   (settings.fromDetails != true
                          //     ? checkRecurring.disable_recurring
                          //     : false) ||
                          //   (appointmentDetails.cancelled == "true" &&
                          //     settings.fromDetails == true &&
                          //     checkRecurring.disable_recurring == true) || (session.language_identifier == "all" && session.type == "session")
                          // }
                          disabled={
                            (checkRecurring.disable_recurring) ? true : false
                          }
                        />
                      </span>
                    </div>
                    <div className={classes.recurring_config}>
                      <div style={formStyle().is_recurring}>
                        <span>Recurring Frequency</span>
                        <span>
                          <select
                            id="recurring_freq"
                            name="recurring_frequency"
                            value={session.recurring_frequency}
                            onChange={onSessionChange}
                            style={{ verticalAlign: "bottom" }}
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                          </select>
                        </span>
                      </div>
                      <div style={formStyle().is_recurring}>
                        <span style={{ float: "left" }}>End at</span>
                        <span className={classes.end_recurring_at}>
                          {/* <input
                            id="end_recurring_at"
                            name="recurring_end_date"
                            value={session.recurring_end_date}
                            min={moment(new Date())
                              .subtract("years", 10)
                              .format("YYYY-01-01")}
                            max={moment(new Date())
                              .add("years", 10)
                              .format("YYYY-01-01")}
                            type="date"
                            onChange={onSessionChange}
                          /> */}
                          <div className={classes.customDatePickerWidth}>
                            <DatePicker
                              selected={
                                moment(
                                  session.recurring_end_date,
                                  "yyyy-MM-DD"
                                ).isValid()
                                  ? moment(
                                      session.recurring_end_date,
                                      "yyyy-MM-DD"
                                    ).toDate() //.format("MM/DD/yyyy")
                                  : null
                              }
                              minDate={moment(props.settings.dateStr).toDate()}
                              id="end_recurring_at"
                              // className={{
                              //   border: "1px solid #444",
                              //   width: "160px !important",
                              //   height: "80% !important",
                              // }}
                              // popperClassName={{ border: "1px solid #444", width: "160px", height: "80%" }}
                              name="recurring_end_date"
                              onChange={(date) => {
                                setSession({
                                  ...session,
                                  recurring_end_date: moment(
                                    date,
                                    "MM/DD/yyyy"
                                  ).format("yyyy-MM-DD"), //date, //moment(date, "MM/DD/YYYY").format("yyyy-MM-DD "),
                                });
                              }}
                            />
                          </div>
                        </span>
                      </div>
                      <div
                        className={classes.teacher_confirmed_div}
                        style={{ float: "left" }}
                      >
                        <span>Confirmed</span>
                        <span>
                          <input
                            // checked={session.teacher_confirmed ? "checked" : ""}
                            checked="checked"
                            id="teacher_confirmed"
                            name="teacher_confirmed"
                            type="checkbox"
                            onChange={onSessionChange}
                            disabled={true}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={classes.right_section}>
                    <input
                      type="button"
                      disabled={double}
                      value="CREATE"
                      className={classes.create_button}
                      onClick={onSessionSubmit}
                    />
                  </div>
                </div>
              </div>
              <div className="appointment-section"></div>
            </div>
            {getNotes && (
              <div
                className={clsx(classes.footer, classes.bold_font)}
                style={{ maxWidth: "475px" }}
              >
                Note: {getNotes.join(". ")}.
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );

}

AppointmentCreate.propTypes = {
  coachProfile: propTypes.array.isRequired,
  rstvResponse: propTypes.array.isRequired,
  actions: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  coachProfile: state.coachProfile,
  rstvResponse: state.rstvStore.rstvResponse,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      createAppointment: bindActionCreators(
        appointmentAction.createAppointment,
        dispatch
      ),
      getRstvfunction: bindActionCreators(RstvAction.getRstv, dispatch),
      checkCoachAvailable: bindActionCreators(
        appointmentAction.checkCoachAvailable,
        dispatch
      ),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentCreate);
