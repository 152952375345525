import { useState, useEffect } from "react";
import * as coachSchedulerApi from "../apis/CoachSchedulerApi";

export default function useRegion() {
  const [session, setSession] = useState({});
  const [sessionParams, setSessionParams] = useState({});

  useEffect(() => {
    Object.keys(sessionParams).length != 0 &&
      coachSchedulerApi
        .getSessionDetails(sessionParams)
        .then((response) => {
          console.log(session);
          setSession(response.data);
        })
        .catch((err) => {
          throw err;
        });
  }, [sessionParams]);

  return { session, setSessionParams };
}
