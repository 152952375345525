import {
  GET_COACH_PROFILE_SUCCESS,
  // GET_CONTACT_PREFERENCE_SUCCESS,
  UPD_COACH_PROFILE_SUCCESS,
  // ADD_LINKS_ALERT_SUCCESS ,
  // UPD_LINKS_ALERT_SUCCESS ,
  // DEL_LINKS_ALERT_SUCCESS
} from "../constants";

import initialState from "./initialState";

export default function coachProfileReducer(
  state = initialState.coachProfile,
  action
) {
  switch (action.type) {
    // case ADD_LINKS_ALERT_SUCCESS:
    //     return [...state, { ...action.addLink}];
    case GET_COACH_PROFILE_SUCCESS:
      return action.coachProfile;
    case UPD_COACH_PROFILE_SUCCESS:
      //return state.map(link => link.id === action.updateLink.id? action.updateLink : link);
      //return state.map(link => link.id === action.updateLink.id? action.updateLink : link);
      return action.coachProfile;
    // case DEL_LINKS_ALERT_SUCCESS:
    //     return state.filter(link => link.id !== action.link.id);
    default:
      return state;
  }
}
