import moment from "moment";
export function showAlert(start_time) {
    return moment() > moment(start_time).subtract(3, 'hours')
  }


export const topicTypes = [
  {
    name: 'Only Flubu Topics',
    value: 'none'
  },
  {
    name: 'Conversational Practice',
    value: 'cp_only'
  },
  {
    name: 'Both',
    value: 'all_topics_plus_cp'
  }
]