import {
    GET_REGIONS_SUCCESS
  } from "../constants";
  
  import initialState from "./initialState";
  
  export default function regionsReducer(state = initialState.regionsList, action) {
    switch (action.type) {
      case GET_REGIONS_SUCCESS:
        return action.regionsList;
      default:
        return state;
    }
  }
  