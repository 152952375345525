import { GET_GENERATE_REPORT_SUCCESS } from "../constants";

import initialState from "./initialState";

export default function coachTimeListReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GENERATE_REPORT_SUCCESS:
      //console.log(getGenerateReportResponse)
      console.log(action.payload)
        return {...state, getGenerateReportResponse:action.responseList, getGenerateReportRange: action.responseRange};
    default:
      return state;
  }
}
