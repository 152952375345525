import React, {useEffect}  from 'react'
import logo from '../images/rs-logo.png'
import './Header_login.css'


function Header_login() {

    useEffect(()=> {
      switch (window.location.hostname) {
        case "csp-ui-qa3.dev.rosettastone.com":
          window.location="https://coachportal-qa3.dev.rosettastone.com/";
          break;
        case ("csp-ui.rosettastone.com"):
          window.location="https://coachportal.rosettastone.com/";
          break;
      }
    },[])

    function getURL(url){
      if(url === "csp-ui-dev.dev.rosettastone.com"){
        return "dev"
      } else if(url === "csp-ui-qa3.dev.rosettastone.com"){
        return "staging"
      } else if(url ==="csp-ui.rosettastone.com"){
        return "production beta"
      } else if(url ==="coachportal.rosettastone.com"){
        return "production"
      } else{
        return 0
      }
    }
    return (
      <div className="header">
      <div className="left_strip">
        <div>
          <a href="">
          <img alt="rs-logo" src={logo} className="logo" title="Rosetta Stone"/>
            {/* <img alt="rs-logo" className="logo" src="rs-logo.png" title="Rosetta Stone"/> */}
          </a>
        </div>
        <h1>Customer Success Portal</h1>
        {getURL(window.location.hostname) !== 0  && (
          <div className="inst_container">
            <div className="instance">{getURL(window.location.hostname)}</div>
          </div>
        )}
      </div>
      </div> 
    )
}


export default Header_login;
