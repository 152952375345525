import { GET_ADMIN_DASHBOARD_SETTINGS, UPD_ADMIN_DASHBOARD_SETTINGS } from "../constants";
import initialState from "./initialState";

export default function adminDashboardReducer(state = initialState.admindashboardSettings, action) {
  if (action.type === GET_ADMIN_DASHBOARD_SETTINGS) return action.settings;

  if (action.type === UPD_ADMIN_DASHBOARD_SETTINGS) {
    // return state.global_settings.map(function(setting) {
    //   const newSetting = action.settings.find((sett) => sett.attribute_name == setting.attribute_name);
    //   return { ...setting, ["attribute_value"]: newSetting.attribute_value };
    // });
    return state;
  }

  return state;
}
