import {
  GET_SUBSTITUTION_LIST_SUCCESS,
  GET_SUBSTITUTION_FILTER_LIST_SUCCESS,
  GET_SUBSTITUTION_FETCH_COACH_LIST_SUCCESS,
  GET_SUBSTITUTION_REPORT_LIST_SUCCESS,
  GET_SUBSTITUTION_FULL_REPORT_LIST_SUCCESS,
  GET_WEEKLY_THRESHOLD,
  REASSIGN_SUBSTITUTE,
  CANCEL_SUBSTITUTE,
} from "../constants";

import * as SubstitutionApi from "../../apis/SubstitutionApi";
import { toast } from "react-toastify";

export function getSubstitutionList() {
  return function(dispatch) {
    return SubstitutionApi.getSubstitutionListApi()
      .then((response) => {
        console.log(response.data);
        dispatch({
          type: GET_SUBSTITUTION_LIST_SUCCESS,
          payload: response.data,
        });
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getSubstitutionFilterList(params) {
  return function(dispatch) {
    return SubstitutionApi.getSubstitutionFilterListApi(params)
      .then((response) => {
        console.log(response.data);
        dispatch({
          type: GET_SUBSTITUTION_FILTER_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getSubstitutionFilterApplyList(request) {
  return function(dispatch) {
    return SubstitutionApi.getSubstitutionFilterApi(request)
      .then((response) => {
        console.log(response.data);
        dispatch({
          type: GET_SUBSTITUTION_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function cancelSubstitution(request) {
  return function(dispatch) {
    return SubstitutionApi.cancelSubstitutionApi(request)
      .then((response) => {
        console.log(response);
        // return dispatch(getSubstitutionList());
         if(response.data.status == "Success")
        dispatch({
          type: CANCEL_SUBSTITUTE,
          request,
        })
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function reassignSubstitution(request, strict_edit = false) {
  return function(dispatch) {
    return SubstitutionApi.reassignSubstitutionApi(request)
      .then((response) => {
        console.log("Response =====>" + response.data);
        if(response.data == "This session was reassigned recently!")
        {
          alert(response.data);
        }
        else
        {
          toast.success(response.data)
        }
        if (strict_edit) return;
        //return dispatch(getSubstitutionList());
        if(response.data == "Coach has been reassigned successfully")
          dispatch({
            type: REASSIGN_SUBSTITUTE,
            request,
          })
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function fetchCoachSubstitution(request) {
  return function(dispatch) {
    return SubstitutionApi.fetchCoachSubstitutionApi(request)
      .then((response) => {
        console.log(response);
        dispatch({
          type: GET_SUBSTITUTION_FETCH_COACH_LIST_SUCCESS,
          //payload: response.data,
          substitutionCoachList: response.data
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function modCoachAvailable(request) {
  return function(dispatch) {
    return SubstitutionApi.modCoachAvailabilityApi(request)
      .then((response) => {
        console.log(response);
        dispatch({
          type: GET_SUBSTITUTION_FETCH_COACH_LIST_SUCCESS,
          //payload: response.data,
          substitutionCoachList: response.data
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getSubstitutionReportList(request) {
  return function(dispatch) {
    return SubstitutionApi.getSubstitutionReportListApi(request)
      .then((response) => {
        console.log(response.data);
        dispatch({
          type: GET_SUBSTITUTION_REPORT_LIST_SUCCESS,
          payload: response.data,
        });
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getSubstitutionFullReportList(request) {
  return function(dispatch) {
    return SubstitutionApi.getSubstitutionReportListApi(request)
      .then((response) => {
        console.log(response.data);
        dispatch({
          type: GET_SUBSTITUTION_FULL_REPORT_LIST_SUCCESS,
          payload: response.data,
        });
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getWeeklyThresholds(){
  return function (dispatch) {
    return SubstitutionApi.getWeeklyThresholds().then((response) => {
      dispatch({
        type: GET_WEEKLY_THRESHOLD,
        payload: response.data,
      });
      return response.data
    })
    .catch((err) => {
      throw err;
    });
  }
}