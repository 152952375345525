import React, { useState, useEffect } from "react";
import close from "../images/closelabel.gif";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import momenttimezone from "moment-timezone";
import { toast } from "react-toastify";
import AutoSubModal from "../CoachSchedulerComponent/AutoSubModal";
export default function CoachTimeOffPopup({ ...props }) {
  const { timeOffToggle, setTimeoffToggle} = props;
  const [editList, setEditList]  = useState('');
  const [createTimeOffToggle, setCreateTimeOffToggle] = useState(false);
  const {createTimeOffFunction} = props;
  const [saveOn, setSaveOn] = useState(false);
  const [autoSubDisplay, setAutoSubDisplay]  = useState(false);

  const [createTimeOff, setCreateTimeOff] = useState({
    startTime: new Date(),
    endTime: "",
    reason:""
  });
  // console.log(createTimeOff);
  let currentDate = new Date();
  currentDate.setMinutes(currentDate.getMinutes() + 30);
  var roundOFF = 1000 * 60 * 30;

  function toggleCreateTimeOff(e) {
    e.preventDefault();
    setStartDate(new Date(Math.ceil(new Date().getTime() / roundOFF) * roundOFF));
    setEndDate(new Date(Math.ceil(currentDate.getTime() / roundOFF) * roundOFF));
    setReason('');
    setEditCreateToggle(false);
    setCreateTimeOffToggle(!createTimeOffToggle);
  }
  function toggleEditTimeOff(e, list) {
    e.preventDefault();
    console.log(list)
    setEditList(list);
    setEditCreateToggle(true);
    setStartDate(new Date(list.start_date));
    setEndDate(new Date(list.end_date));
    setReason(list.comments);
    setCreateTimeOffToggle(!createTimeOffToggle);
  }
  function cancel(e) {
    e.preventDefault();
    setCreateTimeOffToggle(!createTimeOffToggle);
  }

  function cancelTimeOff(e, list) {
    e.preventDefault();
   
    let requestData = {
      'id' : list.id
    };
    console.log(requestData);
    if (confirm("Are you sure?")) {
      props.cancelTimeOffFunction(requestData, props.coachId)
        .then((response) => {
          if(response.data.status) {
            toast.success("Your time-off has been cancelled successfully.");
            setAutoSubDisplay(true);
          } else {
            toast.error("Something went wrong.");  
          }
          setSaveOn(false);
          //setCreateTimeOffToggle(!createTimeOffToggle);
      });
    }
  }

  const [startDate, setStartDate] = useState(new Date(Math.ceil(new Date().getTime() / roundOFF) * roundOFF));
  const [endDate, setEndDate] = useState(new Date(Math.ceil(currentDate.getTime() / roundOFF) * roundOFF));
  const [reason, setReason] = useState('');
  const [editCreateToggle, setEditCreateToggle] = useState(false);

  function mySubmitHandler(e) {
    e.preventDefault();
    
    let start_date_submit = new Date(startDate);
    if(start_date_submit.getDate() == new Date().getDate()) {
      if(start_date_submit.getTime() < new Date().getTime()) {
        alert("Please select future time.");
        setSaveOn(false);
        return false;
      }
    }
    if(new Date(startDate) < new Date(endDate) == false) {
      alert("Start date should be greater than end date.");
      setSaveOn(false);
      return false;
    }

    // console.log("Time off List" + props.getTimeOffListResponse);
    
    if (reason == "") {
      alert("Please enter a valid reason.");
      setSaveOn(false);
      return false;
    } 
  
   let requestData = {}
    if(editCreateToggle) {
      requestData = {
        'id' : editList.id,
        'coach_id' : props.coachId,
        'start_date': moment.utc(startDate).format("YYYY-MM-DD HH:mm:ss"),
        'end_date': moment.utc(endDate).format("YYYY-MM-DD HH:mm:ss"),
        'comments' : reason
      }
    } else {
      requestData = {
        'coach_id' : props.coachId,
        'start_date': moment.utc(startDate).format("YYYY-MM-DD HH:mm:ss"),
        'end_date': moment.utc(endDate).format("YYYY-MM-DD HH:mm:ss"),
        'comments' : reason
      }
    }

    // var currentDate = new Date();
    
    // var minDate = new Date('01/01/1753');
    // var maxDate =  new Date('12/31/9999');

    // if (currentDate > minDate && currentDate < maxDate ){
    //      alert('Correct Date')
    // }
    // else{
    //     alert('Out Side range !!')
    // }
    
    // props.getTimeOffListResponse.map((timeOff) => {
    //   console.log("StartDate moment" + moment(startDate).toISOString());
    //   console.log("Time off loopstart" + timeOff.start_date);

    //   console.log("endDate moment" + moment(endDate).toISOString());
    //   console.log("Time off loopend" + timeOff.end_date);
    //   console.log("First check" + (moment(startDate).toISOString() >= timeOff.start_date  &&  moment(endDate).toISOString() <= timeOff.start_date));
    //   console.log("second check" + (moment(startDate).toISOString() >= timeOff.end_date  &&  moment(endDate).toISOString() <= timeOff.end_date));

    //   if (!(moment(startDate).toISOString() >= timeOff.start_date  &&  moment(endDate).toISOString() <= timeOff.start_date))
    //   {
    //     if (!(moment(startDate).toISOString() >= timeOff.end_date  &&  moment(endDate).toISOString() <= timeOff.end_date))
    //     {

    //     alert("Chosen date is overlapping with the existing timeoff, please choose different dates");
    //     return;
    //     }
    //   }
    // })
    console.log(requestData)
    props.createTimeOffFunction(requestData, props.coachId)
      .then((response) => {
        //  console.log("timeoff" + response);
         if(response.data.timeOffStatusMessage)
         {
         toast.info(response.data.timeOffStatusMessage, {position: "top-center",
         autoClose: 7000});
         }
         else 
         {
          toast.success("Your requested time off is created successfully and is awaiting approval.", {autoClose: 5000});  
         } 
         setCreateTimeOffToggle(!createTimeOffToggle);
         setSaveOn(false);
  });
    return false;
  }
  let pendingStatus = 0;
  let approveStatus = 0;
  return (
    <div className="container">
      <div
        className="modal"
        style={{ display: timeOffToggle ? "block" : "none" }}
        id="edit_rstv_modal">
        <div className="modal-dialog">
          <div className="modal-content">
          <div className="modal_header">
              <h4 className="modal-title">TIME OFF</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => setTimeoffToggle(!timeOffToggle)} >
                <img src={close} title="close" className="close_image" />
              </button>
            </div>
            <div className="modal-body">
              <div className='pending-list'>
              {props.getTimeOffListResponse && props.getTimeOffListResponse.map((timeOffList, idx) => ( 
                <>
              {pendingStatus == 0 &&
              <>
              <div className="pending" style={{ display: timeOffList.approval_status == 0 ? "block" : "none" }}>
                    Pending
              </div>
              <span className='hide'>{pendingStatus = 1}</span>
              </>
              }

              <p key={idx} className="pendingList">
                { timeOffList.approval_status == 0 && 
                <>
                <span >{moment(timeOffList.start_date).format('MMMM Do YYYY, h:mm A') } to {moment(timeOffList.end_date).format('MMMM Do YYYY, h:mm A')}</span>

                    <span><a href='' onClick={(e) => toggleEditTimeOff(e, timeOffList)}>Edit</a></span>
                    <span><a href='' onClick={(e) => cancelTimeOff(e, timeOffList)}>Cancel</a></span>
                    </>
                }
              </p>
                </>
))}
              </div>
            {props.getTimeOffListResponse && props.getTimeOffListResponse.map((timeOffList, idx) => ( 
              <>
              
              
              {approveStatus == 0 && timeOffList.approval_status == 1 &&
              <>
              <div className="approved" style={{ display: timeOffList.approval_status == 1 ? "block" : "none" }}>
              Approved
              </div>
              <span className='hide'>{approveStatus = 1}</span>
              </>
              } 
              
              <p key={idx} className="approvedList">
                { timeOffList.approval_status == 1 && 
                <>
                <span >{moment.utc(timeOffList.start_date.substring(0, 19))
                  .tz(momenttimezone.tz.guess())
                  .format("llll")
                  } to {moment.utc(timeOffList.end_date.substring(0, 19))
                    .tz(momenttimezone.tz.guess())
                    .format("llll")}</span>
                <span><a href='' onClick={(e) => toggleEditTimeOff(e, timeOffList)}>Edit</a></span>
                <span><a href='' onClick={(e) => cancelTimeOff(e, timeOffList)}>Cancel</a></span>
                    
                    </>
                }
              </p>
                </>
            ))}
            
            <div className="response" style={{ display: props.getTimeOffListResponse.length == 0  ? "block" : "none" }}
        id="">
 No existing timeoffs !! 
          </div>   
            <div className="scheduler-btn">
              <a href="#" className="first-link txt-center" onClick={(e) => toggleCreateTimeOff(e)}>
              Create Time Off
              </a>
            </div>
            </div>
            
          </div>
        </div>
      </div>

      <div
        className="modal"
        style={{ display: createTimeOffToggle ? "block" : "none" }}
        id="create_timeoff">
        <div className="modal-dialog">
          <div className="modal-content">
          <div className="modal_header">
              <h4 className="modal-title">
                {editCreateToggle ? 'EDIT TIME OFF' : 'CREATE TIME OFF'}</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => setCreateTimeOffToggle(!createTimeOffToggle)} >
                <img src={close} title="close" className="close_image" />
              </button>
            </div>
            <div className="modal-body">
            <div className="scheduler-start-data">
            {/* <DatePicker 
                selected={startDate}
                // onChange={(date, e) => {
                //   setCreateTimeOff({
                //     ...createTimeOff,
                //     startTime: date,
                //   });
                //   console.log(createTimeOff);
                //   e.target.value = date;
                // }}   
                onChange={date => setStartDate(date)}       
               // minDate={new Date()}
                showTimeSelect
                showDisabledMonthNavigation
                //value={moment(createTimeOff.startTime).startOf('day').format('MMMM DD, YYYY HH:MM A')}
                className="date-picker"
                includeTimes={[
                  setHours(setMinutes(new Date(), 0), 17),
                  setHours(setMinutes(new Date(), 30), 18),
                  setHours(setMinutes(new Date(), 30), 19),
                  setHours(setMinutes(new Date(), 30), 17)
                ]}
                dateFormat="MMMM d, yyyy h:mm aa"
              /> */}
              <label>Start Time: </label>
              <DatePicker
      selected={startDate}
      onChange={date => setStartDate(date)}
      showTimeSelect
      timeFormat="p"
      locale="en-GB"
      timeIntervals={30}
      minDate={new Date()}
      dateFormat="Pp"
      showDisabledMonthNavigation
      className="date-picker"
    /><span></span>
            </div>

            <div className="scheduler-end-data">
            <label>End Time: </label>
              <DatePicker
      selected={endDate}
      onChange={date => setEndDate(date)}
      showTimeSelect
      timeFormat="p"
      timeIntervals={30}
      minDate={new Date()}
      dateFormat="Pp"
      showDisabledMonthNavigation
      className="date-picker"
    /><span></span>
            </div>

            <div className="scheduler-comments-data">
            <label>Reason: </label>
            <textarea cols="150" rows="10" value={reason} id="reason" name="reason" onChange={(e) => setReason(e.target.value)}  >
              test edit
            </textarea>
            </div>

            <div className="scheduler-btn">
              <a href="#" className="first-link" onClick={(e)=> cancel(e)}>
              Cancel
              </a>
              {saveOn ? <a href="#" className="last-link disabled-button" style={{"background": "#bdbcbc", "color": "#848383"}}>Save
              </a> : <a href="#" className="last-link" onClick={(e) => {
                setSaveOn(true)
                mySubmitHandler(e)}
                } disabled={saveOn}>
              Save
              </a>}
            </div>
            </div>
            
          </div>
        </div>
      </div>
      <AutoSubModal setAutoSubDisplay={setAutoSubDisplay} autoSubDisplay={autoSubDisplay} fromInactiveSub={null} getInactiveCoachSub={null}/>
    </div>
  );
}
