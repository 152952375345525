import axios from "axios";
import momenttimezone from "moment-timezone";

const { ACC_API_URL, ESC_API_URL } = getRoute();
const accountBaseUrl = ACC_API_URL + "/api";
const eSchoolBaseUrl = ESC_API_URL + "/api";
let userTimeZone = momenttimezone.tz.guess();
export async function handleResponse(response) {
  if (response.ok) return await response.json();
  if (response.status === 400) {
    // So, a server-side  validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }

  if (response.status === 401) {
    // authentication failed  -- Unauthorized
    // console.log(await response.json());
    const error = await response.text();
    throw new Error(error);
  }

  if (response.status <= 500) {
    //backend server failed to process the data.
    const error = await response.text();
    throw new Error(error);
  }
  // throw new Error("Network response was not ok.");
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  console.error(" err " + error);
  throw error;
}

export const AccountAPI = axios.create({
  baseURL: accountBaseUrl,
  headers: {
    "content-type": "application/json",
    "x-access-token": localStorage.getItem("token"),
    "Time-Zone": userTimeZone
  },
});

export const EschoolAPI = axios.create({
  baseURL: eSchoolBaseUrl,
  headers: {
    "content-type": "application/json",
    "x-access-token": localStorage.getItem("token"),
    "Time-Zone": userTimeZone
  },
});

export function getRoute() {
  switch (window.location.hostname) {
    case "csp-ui-dev.dev.rosettastone.com":
      return {
        AUTH_API_URL:
          "https://csp-authentication-service-dev.dev.rosettastone.com",
        ACC_API_URL: "https://csp-account-service-dev.dev.rosettastone.com",
        ESC_API_URL: "https://csp-eschool-service-dev.dev.rosettastone.com",
      };
    case ("csp-ui-qa3.dev.rosettastone.com" || "coachportal-qa3.dev.rosettastone.com"):
      return {
        AUTH_API_URL:
          "https://csp-authentication-service-qa3.dev.rosettastone.com",
        ACC_API_URL: "https://csp-account-service-qa3.dev.rosettastone.com",
        ESC_API_URL: "https://csp-eschool-service-qa3.dev.rosettastone.com",
      };
    case "coachportal-qa3.dev.rosettastone.com":
      return {
        AUTH_API_URL:
          "https://csp-authentication-service-qa3.dev.rosettastone.com",
        ACC_API_URL: "https://csp-account-service-qa3.dev.rosettastone.com",
        ESC_API_URL: "https://csp-eschool-service-qa3.dev.rosettastone.com",
      };
    case "csp-ui.rosettastone.com":
      return {
        AUTH_API_URL: "https://csp-authentication-service.rosettastone.com",
        ACC_API_URL: "https://csp-account-service.rosettastone.com",
        ESC_API_URL: "https://csp-eschool-service.rosettastone.com",
      };
    case "coachportal.rosettastone.com":
      return {
        AUTH_API_URL: "https://csp-authentication-service.rosettastone.com",
        ACC_API_URL: "https://csp-account-service.rosettastone.com",
        ESC_API_URL: "https://csp-eschool-service.rosettastone.com",
      };
    default:
      return {
        AUTH_API_URL: "http://localhost:3001",
        ACC_API_URL: "http://localhost:3000",
        ESC_API_URL: "http://localhost:3002",
      };
  }
}
