import React, { useState, useEffect } from "react";
import moment from "moment";
import "./AppointmentDetails.css";
import { useHistory } from "react-router-dom";
import AppointmentCreate from "./AppointmentCreate";
import AppointmentEdit from "./AppointmentEdit";
import * as coachShedulerAction from "../../redux/actions/CoachShedulerAction";
import * as appointmentAction from "../../redux/actions/appointmentAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { modCoachAvailabilityApi } from "../../apis/SubstitutionApi";
import { toast } from "react-toastify";
import useStateWithCallback, { useStateWithCallbackInstant, useStateWithCallbackLazy } from "use-state-with-callback";
import CancelSessionModal from "../CoachSchedulerComponent/CancelSessionModal";
import CreateSlotObject from "../../business_layer/model/CreateSlotObject";

function AppointmentDetails(props) {
    const {
        settings, setSettings,
        appointmentDetails,
        setDouble
    } = props;
    // const createObject = new CreateSlotObject();
    const history = useHistory();
    const [appointmentInfo, setSAppointmentInfo] = useState([appointmentDetails]);
    const [appointmentRecord, setAppointmentRecord] = useState({coach_id: "", appointment_type_id: "all", details: "", is_recurring: false, recurring_frequency: 1, recurring_end_date: ""});
    const [appointmentOrigRecord, setAppointmentOrigRecord] = useState({coach_id: "", appointment_type_id: "all", details: "", is_recurring: false, recurring_frequency: 1, recurring_end_date: ""});
    const [coaches, setCoaches] = useState([]);
    const [cancelModalOpen, setCancelModalOpen] = useStateWithCallbackLazy({ toggle: false, value: null });
    // const [currentAppointment, setCurrentAppointment] =  useState(createObject)

    useEffect(() => {
        if(appointmentDetails != []) {
            console.log("SESSION DETAILS" + appointmentDetails);
            setSAppointmentInfo(appointmentDetails);
        }
    }, [appointmentDetails]);

    useEffect(() => {
        if(settings.isFromEvent == false) {
            setSAppointmentInfo([]);
        }
    }, [settings.appointment_date]);

    const appointmentStatus = (status) => {
        switch (status) {
            case 1:
                return "Active";
            case 0:
                return "InActive";
        }
    };

    // useEffect(() => {
    //     // getCoachNames("Coach").then((res) => {
    //     //   setCoaches(res.data);
    //     // });
    //     // if (props.sessionInfo.id != undefined)
    //     // {
    //       modCoachAvailabilityApi({"session_start_time": moment(props.settings.appointment_date).utc().format("YYYY-MM-DD HH:mm:ss")}).then((res) => {
    //         setCoaches(res.data);
    //       // if(props.sessionInfo.type == "Appointment")
    //       // {
    //       //   setLoading(false);
    //       // }
    //       });
    //    // }
    //   }, [props.settings.appointment_date]);

    function overToProfile(coach_id, e) {
       // e.preventDefault();
        /*history.push({
            pathname: '/view-coach-profiles',
            search: '?coach_id=' + coach_id
        })*/
        window.location="/view-coach-profiles?coach_id=" + coach_id;
    }

    function onSessionCancel(cancelModalResult = null, appointment = null){
            //    showCancelLocalSessionConfirmation(index);
            let cancelAll = false;

            setDouble(true);

            let canceledAppointment = appointment ? appointment : cancelModalOpen.currentAppointment;

            if (canceledAppointment.recurring_schedule_id) {
                // cancelAll = confirm(
                //   "Do you want to cancel this session only, or cancel both this session and all future occurrenes?"
                // );
                //cancelAll = cancelModalOpen.value == "CANCEL WITH RECURRENCE" ? true : false;
                cancelAll = cancelModalResult == "CANCEL WITH RECURRENCE" ? true : false;
              }

            let data = {id: canceledAppointment.id, type: "appointment", action_type: "cancel", cancellation_reason: "test", recurring: cancelAll}
            let getAppointmentParams={
                appointment_type_id: settings.appointment_type,
                start_date: settings.start_date,
                day_index: settings.day_index
            }
            
            if(!confirm("Are you sure you want to cancel the appointment?")) 
            {
              setDouble(false);
              return;
            }

            props.actions.cancelAppointment(data, props.appointmentFilter, getAppointmentParams).then((response) => {
            setDouble(false);
            setCancelModalOpen({ ...cancelModalOpen, toggle: false, currentAppointment: null});
            
                if(response.status)
                {
                    toast.success("Appointment cancelled successfully")
                }
                else
                {
                    toast.error("Failed to cancel appointment")
                }
            }).catch((err) => {
                setDouble(false);
                setCancelModalOpen({ ...cancelModalOpen, toggle: false, currentAppointment: null });
                toast.error("Failed to cancel appointment")
            })
            
    }

    return (
        <>
            <div>
                {appointmentInfo != undefined && appointmentInfo != [] && (
                    <div className="modal coach-schedule-session-details-modal"
                        id="appointment-details-modal"
                        style={{ display: settings.displayAppointmentDetails ? "block" : "none" }}
                    >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal_header">
                                    <h4 className="modal-title">APPOINTMENT DETAILS -- {moment(settings.appointment_date).format("ddd MM/DD/YY hh:mm A")}</h4>
                                    <a className="close-icon" title="Close" aria-label="Close" role="button" data-dismiss="modal"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSettings((settings) => ({ ...settings, ["displayAppointmentDetails"]: false, ["displayAppointmentEdit"]: false, ["displayAppointmentCreate"]: false }));
                                        }}
                                    >
                                        <span>×</span>
                                    </a>
                                </div>
                                {appointmentInfo.length > 0 ?
                                    <div className="modal-body">
                                        <table className="appointment-table">
                                            <thead>
                                                {/* <tr> */}
                                                    <th>Coach Name</th>
                                                    <th>Appointment Type</th>
                                                    <th>Status</th>
                                                    <th>Recurrence Ends</th>
                                                    <th>Actions</th>
                                                {/* </tr> */}
                                            </thead>
                                            <tbody>
                                                {appointmentInfo.map((appointment, index) => {
                                                    if (appointment.type != "recurring_appointment") {
                                                        return (
                                                            <tr key={index}>
                                                                <td><a href={"/view-coach-profiles?coach_id=" + appointment.coach_id}  className="underline" target="_blank" rel="noreferrer">{appointment.full_name}</a></td> {/*onClick={(e) => { overToProfile(appointment.coach_id, e) }} */}
                                                                <td>{appointment.title}</td>
                                                                <td>{appointment.cancelled == 1 ? "Cancelled" : appointmentStatus(appointment.status)}</td>
                                                                <td>{appointment.recurring_end_date ? moment(moment.utc(appointment.recurring_end_date).toDate()).format("MM/DD/YY") : "--"}</td>
                                                                {moment(settings.sessionDate).isBefore(new Date()) || appointment.cancelled == 1 ? (
                                                                <td>--</td>
                                                                ) : (
                                                                <td>
                                                                    <a
                                                                    href="#"
                                                                    data-toggle="modal"
                                                                    onClick={() => {
                                                                        //    showCancelLocalSessionConfirmation(index);
                                                                       // onSessionCancel(null, appointment)
                                                                      // setCurrentAppointment(appointment)
                                                                        if (appointment.recurring_schedule_id) {
                                                                            // setCurrentAppointment(appointment)
                                                                            setCancelModalOpen({ ...cancelModalOpen, toggle: true, currentAppointment: appointment});
                                                                            // setCurrentAppointment(appointment)
                                                                          } else {
                                                                          //  setCancelConfirmationModal(true);
                                                                        //   setCurrentAppointment(appointment)
                                                                          onSessionCancel(null, appointment);
                                                                          }
                                                                       }}
                                                                    >
                                                                    Cancel
                                                                    </a>{" "}
                                                                    |{" "}
                                                                    <a
                                                                    href="#"
                                                                    data-toggle="modal"
                                                                    data-target="#master-schedule-edit-details"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setDouble(true);
                                                                        props.actions
                                                                        .getSessionDetails({
                                                                          coach_id: appointment.coach_id,
                                                                          session_id: appointment.id,
                                                                          //session_start_time: moment.utc(e.event.start).format("YYYY-MM-DD HH:mm:ss"),
                                                                          type: "appointment",
                                                                          session_start_time: appointment.session_start_time
                                                                           
                                                                        }).then((response) => {
                                                                           
                                                                            if (response){
                                                                                //fetchCoachSubstitutionApi(response.id).then((res) => {
                                                                                modCoachAvailabilityApi({type: "appointment"}).then((res) => {
                                                                                    setCoaches(res.data);
                                                                                    setSettings((settings) => ({ ...settings, ["displayAppointmentEdit"]: true }));
                                                                                    setAppointmentRecord(response);
                                                                                    setDouble(false);
                                                                                  })
                                                                                  .catch((err) => setDouble(false));
                                                                            }
                                                                        })
                                                                        .catch((error) => {
                                                                            setDouble(false);
                                                                            console.log(error);
                                                                        });
                                                                        
                                                                    }}
                                                                    >
                                                                    Edit
                                                                    </a>
                                                                </td>
                                                                )}
                                                            </tr>
                                                        )
                                                    }
                                                })
                                                }
                                            </tbody>
                                        </table>
                                        <div className="rec-app-container">
                                            <div className="locals-header bold-font">Not yet Confirmed Appointments</div>
                                            <table className="appointment-table">
                                                <thead>
                                                    {/* <tr> */}
                                                        <th>Coach Name</th>
                                                        <th>Appointment Type</th>
                                                        <th>Status</th>
                                                        <th>Recurrence Ends</th>
                                                        <th>Actions</th>
                                                    {/* </tr> */}
                                                </thead>
                                                <tbody>
                                                    {appointmentInfo.map((appointment, index) => {
                                                        if (appointment.type == "recurring_appointment") {
                                                            return (
                                                                <tr key={index}>
                                                                    <td><a href={"/view-coach-profiles?coach_id=" + appointment.coach_id} className="underline" target="_blank" rel="noreferrer">{appointment.full_name}</a></td> {/* onClick={(e) => { overToProfile(appointment.coach_id, e) }} */}
                                                                    <td>{appointment.title}</td>
                                                                    <td>Not Active-Recurring</td>
                                                                    <td>{appointment.recurring_end_date ?moment(moment.utc(appointment.recurring_end_date).toDate()).format("MM/DD/YY") : "--"}</td>
                                                                    {moment(settings.sessionDate).isBefore(new Date()) || appointment.cancelled == 1 ? (
                                                                        <td>--</td>
                                                                        ) : (
                                                                        <td>
                                                                            <a
                                                                            href="#"
                                                                            data-toggle="modal"
                                                                            onClick={() => {
                                                                            //    showCancelLocalSessionConfirmation(index);
                                                                            let data = {id: appointment.id, type: "localappointment", action_type: "cancel", cancellation_reason: "test", recurring: appointment.is_recurring};
                                                                            let getAppointmentParams={
                                                                            appointment_type_id: settings.appointment_type,
                                                                            start_date: settings.start_date,
                                                                            day_index: settings.day_index
                                                                            }
                                                                            props.actions.cancelAppointment(data, props.appointmentFilter, getAppointmentParams).then((response) => {
                                                                                toast.success("Appointment cancelled successfully")
                                                                            }).catch((err) => {
                                                                                toast.error("Failed to Cancel appointment")
                                                                            })
                                                                            }}
                                                                            >
                                                                            Delete
                                                                            </a>{" "}
                                                                            |{" "}
                                                                            <a
                                                                            href="#"
                                                                            data-toggle="modal"
                                                                            data-target="#master-schedule-edit-details"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                setDouble(true)
                                                                                props.actions
                                                                                .getSessionDetails({
                                                                                coach_id: appointment.coach_id,
                                                                                id: appointment.id,
                                                                                //session_start_time: moment.utc(e.event.start).format("YYYY-MM-DD HH:mm:ss"),
                                                                                // session_start_time: appointment.session_start_time
                                                                                session_start_time: moment.utc(settings.appointment_date).format("YYYY-MM-DD HH:mm:ss"),
                                                                                type:"localappointment"
                                                                                // type:"appointment"
                                                                                
                                                                                }).then((response) => {
                                                                                    setDouble(false)
                                                                                    if (response){
                                                                                        // fetchCoachSubstitutionApi(response.id).then((res) => {
                                                                                        //     setCoaches(res.data);
                                                                                        //     setSettings((settings) => ({ ...settings, ["displayAppointmentEdit"]: true }));
                                                                                        //     setAppointmentRecord(response);
                                                                                        //     setAppointmentOrigRecord(response);
                                                                                        // });
                                                                                        let localCoach = []
                                                                                        localCoach.push({id: appointment.coach_id, full_name: appointment.full_name})
                                                                                        setCoaches(localCoach);
                                                                                        setSettings((settings) => ({ ...settings, ["displayAppointmentEdit"]: true }));
                                                                                        setAppointmentRecord(response);
                                                                                        setAppointmentOrigRecord({...response});
                                                                                    }
                                                                                })
                                                                                .catch((error) => {
                                                                                    setDouble(false)
                                                                                    console.log(error);
                                                                                });
                                                                                
                                                                            }}
                                                                            >
                                                                            Edit
                                                                            </a>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            )
                                                        }
                                                    })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> : 
                                    <div style={{marginLeft:"10px"}}>No Appointments Exists</div>
                                }
                                <div>
                                    <button
                                        className="create-session-button"
                                        style={{marginRight:"10px"}}
                                        disabled={moment(settings.sessionDate).isBefore(new Date())}
                                        onClick={(e) => {
                                            setSettings((settings) => ({ ...settings, ["displayAppointmentCreate"]: true }));
                                        }}
                                        >
                                        Create New Appointments
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                       
                    </div>
                )}
            </div>
            <AppointmentCreate 
            settings={settings}
            setSettings={setSettings}
            // appointmentDetails={props.appointmentDetails}
            isFromAppoinment={props.isFromAppointment}
            appointmentFilter={props.appointmentFilter}
            />

            <AppointmentEdit
            settings={settings}
            setSettings={setSettings}
            appointmentRecord={appointmentRecord}
            setAppointmentRecord={setAppointmentRecord}
            appointmentOrigRecord={appointmentOrigRecord}
            isFromAppoinment={props.isFromAppointment}
            appointmentFilter={props.appointmentFilter}
            coaches={coaches}
            setCoaches={setCoaches}
            />

            <CancelSessionModal
                modalOpen={cancelModalOpen}
                setModalOpen={setCancelModalOpen}
                onSessionCancel={onSessionCancel}
            />
        </>
        
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            getSessionDetails: bindActionCreators(
                coachShedulerAction.getSessionDetails,
                dispatch
              ),
              cancelAppointment: bindActionCreators(
                appointmentAction.cancelAppointment,
                dispatch
              )
        },
    };
}


export default connect(null,
  mapDispatchToProps
)(AppointmentDetails);