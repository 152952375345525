import { useState, useEffect } from "react";
import * as sessionsApi from "../apis/SessionsApi";
import { getLanguageByIdentifier } from "../utils/languageUtils";

export default function useLanguages() {
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    sessionsApi
      .getLanguages()
      .then((response) => {
        console.log(response);
        response.data.forEach((row, id) => {
          response.data[id]["language_name"] = getLanguageByIdentifier(response.data[id]["identifier"]);
        });
        setLanguages(response.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  return languages;
}
