import React from 'react'
import { triggerAvailability } from "../apis/CoachProfileApi";
function executeTrigger(){
    console.log("Executing Trigger....");
    triggerAvailability().then((response) => {
        console.log("Request Sent");
    })
    .catch((error) =>
    {
        console.log("Request not Successful");
    })
}
function trigger(){
    return(
        <div className="right-content">
            <input value="Update Availability" style={{textAlign: "center"}} type="button" onClick={executeTrigger}></input>
        </div>
    )
}


export default trigger;