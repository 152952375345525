import classes from "../CoachSchedulerComponent/CreateSessionModal.module.css";
import React, {
  useState,
  useEffect,
  useMemo,
  useReducer,
  useCallback,
} from "react";
import { bindActionCreators } from "redux";
import moment from "moment";
import clsx from "clsx";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { sortCoachNamesBy, sortAppointmentNamesBy } from "../../utils/languageUtils";
import { modCoachAvailabilityApi } from "../../apis/SubstitutionApi";
import { useAppointmentTypes } from "../../hooks";
import * as appointmentAction from "../../redux/actions/appointmentAction";
import RecurringSessionModal from "../MasterSchedulerComponent/RecurringSessionModal";




function AppointmentEdit({ ...props }) {
    const {settings, setSettings, appointmentRecord, setAppointmentRecord, appointmentFilter, coaches, setCoaches, appointmentOrigRecord} = props;
    const [double, setDouble] = useState(false);
    const appointmentTypes = useAppointmentTypes();
    const [saveModalOpen, setSaveModalOpen] = useState(false);
    // const [coaches, setCoaches] = useState([]);

    
    function onSessionChange(e){
        let name = e.target.name
        let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        setAppointmentRecord({...appointmentRecord, [name]: value})
    }

    function onSessionSubmit(recurrall = ""){
        setDouble(true);
      
      //
      let action = "this";
      let getAppointmentParams={
        appointment_type_id: settings.appointment_type,
        start_date: settings.start_date,
        day_index: settings.day_index
      }
      if (
        (appointmentRecord.is_recurring && appointmentOrigRecord.is_recurring) ||
        (!appointmentRecord.is_recurring && appointmentOrigRecord.is_recurring)) {
        // if (
        //   (session.is_recurring && sessionDetails.is_recurring) ||
        //   (!session.is_recurring && sessionDetails.is_recurring)|| (!props.sessionDetails.is_recurring && session.is_recurring)) {
        if (recurrall == "SAVE ALL") action = "all";
        setSaveModalOpen(false);
       // saveAll = confirm("Do you want this session to persist in all recurrk ing sessions?");
      }
      //

        props.actions.editAppointment({...appointmentRecord, 
        type: appointmentRecord.recurring_type == "recurring_appointment" ? "localappointment" : "appointment",
        day_index: moment.utc(props.settings.appointment_date).day(),
        recurring_start_date: appointmentRecord.is_recurring ? moment(props.settings.appointment_date).utc().format("YYYY-MM-DD HH:mm:ss") : null,
        save: action
        }, appointmentFilter, getAppointmentParams).then((response) => {
            setDouble(false);
            setSaveModalOpen(false);
            if(response.status)
            {
                toast.success("Appointment updated successfully");
                setSettings((settings) => ({
                  ...settings,
                  ["displayAppointmentEdit"]: false,
                }));
            }
            else
            {
              toast.error("Failed to update appointment");
            }
        })
        .catch((err) => 
        {
            setDouble(false);
            setSaveModalOpen(false);
            setSettings((settings) => ({
              ...settings,
              ["displayAppointmentEdit"]: false,
            }));
            toast.error("Appointment update failed")
        });
    }

    function formStyle(prop = "") {
      const recurringInput = { display: appointmentRecord.is_recurring ? prop : "none" };
      const appointmentInput = { display: appointmentRecord.type == "appointment" ? prop : "none" };
      console.log("Recurring Input" + JSON.stringify(recurringInput));
      return {
        appointment_type: appointmentInput,
        appointment_details: appointmentInput,
        is_recurring: recurringInput,
      };
    }

    // useEffect(() => {
    //     // getCoachNames("Coach").then((res) => {
    //     //   setCoaches(res.data);
    //     // });
    //     if(settings.displayAppointmentEdit)
    //     {
    //       modCoachAvailabilityApi({"session_start_time": moment(settings.appointment_date).utc().format("YYYY-MM-DD HH:mm:ss")}).then((res) => {
    //         setCoaches(res.data);
    //       // if(props.sessionInfo.type == "Appointment")
    //       // {
    //       //   setLoading(false);
    //       // }
    //       });
    //     }
    //   }, [settings.displayAppointmentEdit]);

//checkRecurring
    // coachShedulerAction
    // .checkRecurrings({
    //   coach_id: e.target.value,
    //   session_start_time: moment
    //     .utc(settings.sessionDate)
    //     .format("yyyy-MM-DD HH:mm:ss"),
    //   day_index: moment.utc(settings.sessionDate).day(),
    // })
    // .then((result) => {
    //   if(result.disable_recurring)
    //   {
    //   //  setSession({...session, ["is_recurring"]: false})
    //     setNotes(
    //       "This coach is already scheduled to have a recurring Session/Appointment starting on " +
    //         "the following week. You can only schedule a one-off Session/Appointment dialog at this time."
    //     )
    //   }
    //   else
    //   {
    //    // setSession({...session, ["is_recurring"]: true})
    //    setNotes(null)
    //   }
    //   setCheckRecurring(result);
    // });

return (
    <>
      <div
        className={classes.blockOverlay}
        style={{ display: double ? "block" : "none" }}
      ></div>
      <div
        className={classes.blockMsg}
        style={{ display: double ? "block" : "none" }}
      >
        <h1>Please wait...</h1>
      </div>
      
      <div
        className={clsx(classes.modal, classes.coach_schedule_create_session)}
        id="coach-schedule-create-session"
        style={{
          display: settings.displayAppointmentEdit ? "block" : "none",
          //display: "block",
          // left: settings.xDistance > 610 ? settings.xAxis - 237 + "px" : settings.xAxis + 240 + "px",
          left: settings.xAxis + "px",
          top: settings.yAxis + "px",
        }}
      >
        <div className={classes.modal_dialog}>
          <div className={classes.modal_content}>
            <div className={classes.modal_header}>
              <h4 className={classes.modal_title}>
                EDIT APPOINTMENT at{" "}
                <span style={{ color: "#1090CF" }}>
                  {moment(props.settings.appointment_date).format(
                    "ddd MM/DD/YYYY hh:mmA"
                  )}
                </span>
              </h4>
              <a
                className={classes.close_icon}
                title="Close"
                aria-label="Close"
                role="button"
                data-dismiss="modal"
                id="coach-modal-close"
                onClick={(e) => {
                  e.preventDefault();
                //   setSession(createObject); 
                //setSession({...createObject, language_identifier: "all"});
                  //dispatch({ language: "totale" })
                  
                  setSettings((settings) => ({
                    ...settings,
                    ["displayAppointmentEdit"]: false,
                  }));
                }}
              >
                <span>×</span>
              </a>
            </div>
            <div className={classes.modal_body}>
              
              <div className={classes.session_section}>
              <div className={classes.session_type}>
                <div>
                  <select
                    name="coach_id"
                    className={clsx(
                      classes.dropdown,
                    )}
                    onChange={onSessionChange}
                    value={appointmentRecord.coach_id}
                    // disabled={filter.language == ""}
                    style={{ width: "150px" }}
                    disabled={true}
                  >
                    <option value="">Select a Coach</option>
                    {sortCoachNamesBy(coaches, "full_name").map((coach) => {
                    // if (sessionInfo.rstv_guid != null)
                    // {
                    //   return( 
                    //   <option key={coach.id} value={coachDetail(coach)} style={{backgroundColor: coach?.have_session ? "#ffa500" : "" }}>
                    //   {coach.full_name}
                    //   </option>
                    //   )
                    // }
                    // else
                    // {
                      return(
                      <option key={coach.id} value={coach.id}>
                      {coach.full_name}
                      </option>
                      )
                    // }
                    })}
                  </select>
                </div>
                <br />
                <select
                  className={clsx(
                    classes.dropdown,
                  )}
                  name="appointment_type_id"
                  onChange={onSessionChange}
                  value={appointmentRecord.appointment_type_id}
                  disabled={appointmentRecord.recurring_type != "recurring_appointment"}
                  // style={formStyle().appointment_type}
                >
                  <option value="all">Select appointment type</option>
                  {appointmentTypes.length > 0 &&
                    sortAppointmentNamesBy(appointmentTypes, "title").map(
                      ({ id, title }) => (
                        <option key={id} value={id}>
                          {title}
                        </option>
                      )
                    )}
                </select>
              </div>
                <div
                  className={classes.appointment_content}
                  // style={formStyle().appointment_details}
                >
                  <div className={clsx(classes.title, classes.bold_font)}>
                    Appointment details:
                  </div>
                  <textarea
                    cols="34"
                    name="details"
                    maxLength="500"
                    className={classes.textarea}
                    value={appointmentRecord.details == null ? "" : appointmentRecord.details}
                    onChange={onSessionChange}
                    style={{ display: "block", clear: "both" }}
                    disabled={appointmentRecord.recurring_type == "recurring_appointment"}
                  ></textarea>
                  <div className={classes.appointment_char}>
                    <span>Characters remaining:</span>
                    <span className={classes?.char_count}>
                      {appointmentRecord.details?.length
                        ? 500 - appointmentRecord.details?.length
                        : "500"}
                    </span>
                  </div>
                </div>
                <div className={classes.row2}>
                  <div className={classes.left_section}>
                    <div className={classes.recurring_checkbox}>
                      <span>Recurring</span>
                      <span>
                        <input
                          name="is_recurring"
                          type="checkbox"
                          checked={appointmentRecord.is_recurring ? "checked" : ""}
                          onChange={onSessionChange}
                          
                          // disabled={
                          //   pathname.includes("my_schedule") ||
                          //   (settings.fromDetails != true
                          //     ? checkRecurring.disable_recurring
                          //     : false) ||
                          //   (appointmentDetails.cancelled == "true" &&
                          //     settings.fromDetails == true &&
                          //     checkRecurring.disable_recurring == true) || (appointmentRecord.language_identifier == "all" && appointmentRecord.type == "session")
                          // }
                          disabled={appointmentRecord.recurring_type == "recurring_appointment"}
                        />
                      </span>
                    </div>
                    <div className={classes.recurring_config}>
                      <div style={formStyle().is_recurring}>
                        <span>Recurring Frequency</span>
                        <span>
                          <select
                            id="recurring_freq"
                            name="recurring_frequency"
                            value={appointmentRecord.recurring_frequency}
                            onChange={onSessionChange}
                            disabled={true}
                            style={{ verticalAlign: "bottom" }}
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                          </select>
                        </span>
                      </div>
                      <div style={formStyle().is_recurring}>
                        <span style={{ float: "left" }}>End at</span>
                        <span className={classes.end_recurring_at}>
                          {/* <input
                            id="end_recurring_at"
                            name="recurring_end_date"
                            value={appointmentRecord.recurring_end_date}
                            min={moment(new Date())
                              .subtract("years", 10)
                              .format("YYYY-01-01")}
                            max={moment(new Date())
                              .add("years", 10)
                              .format("YYYY-01-01")}
                            type="date"
                            onChange={onSessionChange}
                          /> */}
                          <div className={classes.customDatePickerWidth}>
                            <DatePicker
                              selected={
                                moment(
                                  appointmentRecord.recurring_end_date,
                                  "yyyy-MM-DD"
                                ).isValid()
                                  ? moment(
                                      appointmentRecord.recurring_end_date
                                    ).toDate() //.format("MM/DD/yyyy")
                                  : null
                              }
                              minDate={moment(props.settings.dateStr).toDate()}
                              id="end_recurring_at"
                              // className={{
                              //   border: "1px solid #444",
                              //   width: "160px !important",
                              //   height: "80% !important",
                              // }}
                              // popperClassName={{ border: "1px solid #444", width: "160px", height: "80%" }}
                              name="recurring_end_date"
                              onChange={(date) => {
                                setAppointmentRecord({
                                  ...appointmentRecord,
                                  recurring_end_date: moment(
                                    date,
                                    "MM/DD/yyyy"
                                  ).format("yyyy-MM-DD"), //date, //moment(date, "MM/DD/YYYY").format("yyyy-MM-DD "),
                                });
                              }}
                            />
                          </div>
                        </span>
                      </div>
                      <div
                        className={classes.teacher_confirmed_div}
                        style={{ float: "left" }}
                      >
                        <span>Confirmed</span>
                        <span>
                          <input
                            // checked={appointmentRecord.teacher_confirmed ? "checked" : ""}
                            checked="checked"
                            id="teacher_confirmed"
                            name="teacher_confirmed"
                            type="checkbox"
                            onChange={onSessionChange}
                            disabled={true}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={classes.right_section}>
                    <input
                      type="button"
                      disabled={double}
                      value="SAVE"
                      className={classes.create_button}
                      onClick={() => {
                          if (((!appointmentOrigRecord.is_recurring && appointmentRecord.is_recurring) ||
                            props.appointmentOrigRecord.is_recurring) && appointmentRecord.recurring_type != "recurring_appointment")
                          {
                            setSaveModalOpen(true);
                          } 
                          else
                          {
                            onSessionSubmit();
                          }
                        }
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="appointment-section"></div>
            </div>
            {/* {getNotes && (
              <div
                className={clsx(classes.footer, classes.bold_font)}
                style={{ maxWidth: "475px" }}
              >
                Note: {getNotes.join(". ")}.
              </div>
            )} */}
          </div>
        </div>
      </div>
      <RecurringSessionModal
        modalOpen={saveModalOpen}
        setModalOpen={setSaveModalOpen}
        onSessionCancel={onSessionSubmit}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
   
  });
  
  const mapDispatchToProps = (dispatch) => {
    return {
      actions: {
        editAppointment: bindActionCreators(
          appointmentAction.editAppointment,
          dispatch
        ),
      },
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentEdit);