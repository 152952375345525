import React from "react";

import "./Detail.css";
import close from "../images/closelabel.gif";

function Detail({ ...props }) {
  const { link, showToggle, setShowToggle } = props;

  return (
    <div className="container">
      <div
        className="modal"
        style={{ display: showToggle ? "block" : "none" }}
        id="show_link_modal"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal_header">
              <h4 className="modal-title">Show link</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => setShowToggle(!showToggle)}
              >
                <img src={close} title="close" className="close_image" />
              </button>
            </div>
            <div className="modal-body">
              <p>
                <b>Name: </b>
                {link?.name || ""}
              </p>
              <p>
                <b>URL: </b>
                <a href={link?.url || "#"} target="_blank" rel="noreferrer">
                  {link?.url || ""}
                </a>
              </p>
            </div>
            <div className="footer">
              {/* <a href="">Edit</a> |
                <a href="">Back</a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Detail;
