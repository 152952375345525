import {
  GET_SCHEDULINGTHRESHOLD_SUCCESS, 
  UPD_SCHEDULINGTHRESHOLD_SUCCESS,
} from "../constants";

import initialState from "./initialState";

export default function schedulingThresholdReducer(state = initialState.schedulingThresholds, action) {
  switch (action.type) {
    case GET_SCHEDULINGTHRESHOLD_SUCCESS:
      return action.schedulingThreshold;
    case UPD_SCHEDULINGTHRESHOLD_SUCCESS:
      return state.map((schedulingThreshold) =>
      schedulingThreshold.id === action.updateSchedulingThreshold.id ? action.updateSchedulingThreshold : schedulingThreshold
      );
    default:
      return state;
  }
}
