import React, { useState, useEffect, useRef } from "react";
import close from "../images/closelabel.gif";
import { toast } from "react-toastify";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default function ReAssignSubstitute({ ...props }) {
  const { reAssignToggle, setReAssignToggle, substitutionCoachList, selectedCoach, apiCoachSessionIDRequest, reassignSubstitutionFunction, id  } = props;
  // const [apiCoachRequest, setApiCoachRequest] = useState('');
  const [apiCoachRequest, setApiCoachRequest] = useState(`{"coach_id": "", "scheduled_session_id": "", "scheduled_eschool_session_id": ""}`);
  const [loader, setLoader] = useState(false);
  const assignDrop = useRef();

  function reAssign(e) {
    e.preventDefault();
    let parsedValue = JSON.parse(apiCoachRequest)    
    if(parsedValue.coach_id == '') {
      alert('Please select a Coach');
      return false;
    }
    setLoader(true);
    let request = {
        "filter_selected_coach_value": selectedCoach,     
        "from_cs": false,     
        "assigned_coach": parsedValue.coach_id,     
        "reason": 'Auto Subrequest',     
        "coach_session_id": apiCoachSessionIDRequest,
        "id": id,
        scheduled_session_id: parsedValue.scheduled_session_id ? parsedValue.scheduled_session_id : "",
        scheduled_eschool_session_id: parsedValue.scheduled_eschool_session_id ? parsedValue.scheduled_eschool_session_id : ""
    }
    console.log(request);
    //return false;
    reassignSubstitutionFunction(request).then((response) => {
      setLoader(false);
      setReAssignToggle(!reAssignToggle);
      if(props.fromInactiveSub && props.getInactiveCoachSub)
      {  
        props.getInactiveCoachSub();
        assignDrop.current.value = "";
      }
    });
  }
  return (
    <>
    {loader && (
      <>
        <div className="blockOverlay" style={{ display: "block" }}></div>
        <div className="blockMsg" style={{ display: "block" }}>
          <h1>Please wait...</h1>
        </div>
      </>
    )}
    <div className="container">
      <div
        className="modal"
        style={{ display: reAssignToggle ? "block" : "none" }}
        id="add_rstv_modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal_header">
              <h4 className="modal-title">Assign Substitute</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => 
                {
                  setReAssignToggle(!reAssignToggle)
                  assignDrop.current.value = "";
                  }}>
                <img src={close} title="close" className="close_image" />
              </button>
            </div>
            <div className="modal-body">
              
              {/* <select name="coachRequesting" value={apiCoachRequest}  onChange={(e) => setApiCoachRequest(e.target.value)}> */}
              <select name="coachRequesting" ref={assignDrop} onChange={(e) => setApiCoachRequest(e.target.value)}>
                <option value="">--AVAILABLE--</option>
                {substitutionCoachList.length > 0 && (substitutionCoachList).map((response, i) => (
                    // <option key={i} value={response.id}>
                    <option key={i} value={ '{"coach_id":' + response.id + ', "scheduled_session_id": ' + JSON.stringify(response.scheduled_session_id) + ', "scheduled_eschool_session_id": ' + JSON.stringify(response.scheduled_eschool_session_id) + '}'} style={{backgroundColor: response?.have_session ? "#ffa500" : "" }}>
                        {response.full_name}
                    </option>
                    ))}
            </select>

            </div>
            <div className="footer">
              <p>
                {" "}
                <input
                  id="Reassign"
                  type="submit"
                  value="Assign"
                  onClick={(e) => reAssign(e)}
                />
                <input
                  id="cancel"
                  type="button"
                  value="Cancel"
                  onClick={() => setReAssignToggle(!reAssignToggle)}
                />
                {" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
