import { 
      ADD_COACH_ALERT_SUCCESS
    , GET_COACH_ALERT_SUCCESS
    , DEL_COACH_ALERT_SUCCESS
} from "../constants"
import initialState from "./initialState";

export default function coachAlertReducer(state = initialState.alerts, action){
    
    switch(action.type) {
        case ADD_COACH_ALERT_SUCCESS:                               
            return [...state, { ...action.addAlert}];
        case GET_COACH_ALERT_SUCCESS:    
            return action.alerts;
        case DEL_COACH_ALERT_SUCCESS:
            return state.filter((alert) => alert.id !== action.alert.id);    
        default: 
            return state;    

    }
}