import React, { useState, useEffect, useRef, createContext } from "react";
// import propTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
// import DateRangePicker from "react-bootstrap-daterangepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import bigSpinner from "../components/images/big_spinner.gif";
import previous from "../components/images/previous.png";
import next from "../components/images/next.png";
import warning from "../components/images/warning_red_16_16.png";
import MasterSchedulerCalendar from "../components/MasterSchedulerComponent/MasterSchedulerCalendar";
import { getCoachNames } from "../apis/CoachProfileApi";
import {sortByDates} from "../utils/languageUtils"
// import { sortCoachNamesBy } from "../utils/languageUtils";
import { getLanguageByIdentifier, sortLanguagesBy } from "../utils/languageUtils";
import * as notificationsAction from "../redux/actions/notificationsAction";
import * as languageActions from "../redux/actions/languageAction";
import * as regionAction from "../redux/actions/regionAction";
import * as villageAction from "../redux/actions/villageAction";
import * as masterSchedulerAction from "../redux/actions/MasterSchedulerAction";
import { showAlert } from "../utils/sessionUtils";
import moment from "moment";
import { CSVLink } from "react-csv";

import "./MasterSchedulerPage.css";


const title = `Customer Success Portal: Master Scheduler`;

function MasterSchedulerPage(props) {
  const {
    languages,
    regions,
    villages,
    actions: { getLanguages },
    masterScheduler,
  } = props;

  const calRef = useRef(null);

  const [coachs, setCoachs] = useState([]);
  const [config, setConfig] = useState({
    display: false,
    slotDuration: "00:30:00",
  });

  const [filter, setFilter] = useState({
    languageId: "",
    villageId: "",
    region: "All",
    classRoomType: "All",
  });
  const [loaded, setLoaded] = useState(false);
  const [modifiedSubstitutions, setModifiedSubstitutions] = useState([]);
  let modified = [];
  const [showSubstitute, setShowSubstitute] = useState(false);

  const [masterFilter, setMasterFilter] = useState({
    languageId: "",
    villageId: "All",
    region: "All",
    classRoomType: "All",
    filterDate: moment()
      .startOf("day")
      .utc()
      .toISOString(),
  });

  const [schedulerList, setSchedulerList] = useState();
  const [showCalendar, setShowCalendar] = useState(false);
  const [typeBasedLang, setTypeBasedLang] = useState([]);
  const [type, setType] = useState({
    disable: false,
    sessionType: "All",
    classrommType: "All",
  });
  const [classroomType, setClassroomType] = useState(false);
  const [countItems, setCountItems] = useState({});
  const [lastPushedDate, setLastPushedDate] = useState("");
  const [goLoader, setGoLoader] = useState(false);
  const [pushScheduleNotice, setpushScheduleNotice] = useState(false);
  const [headerSection, setHeaderSection] = useState(false);
  const [typeName, setTypeName] = useState("Foundations");
  // const [isFromNavigation, setIsFromNavigation] = useState(false);
  const [updatedTimeZone, setUpdatedTimeZone] = useState();
  const [bulkSession, setBulkSession] = useState(false);

  useEffect(() => {
    getLanguages();
    getCoachNames("Coach").then((res) => setCoachs(res.data));
    regions.length == 0 && props.actions.getRegions();
    villages.length == 0 && props.actions.getVillages();
  }, []);

  useEffect(() => {
    if (props.additionalSubstitutions.length == 0 && !loaded) {
      props.actions.getSubstitutions({ time_range: "upcoming", alert: "true" });
      setLoaded(true);
    }
    if(props.additionalSubstitutions.length == 0){
      setShowSubstitute(false)
    } else {
    props.additionalSubstitutions.map((substitution) => {
      if (showAlert(substitution.data[0].session_start_time))
      {
      modified.push({ lang: substitution.lang[0], session_start_time: substitution.data[0].session_start_time, level_unit_lesson:substitution.data[0].level_unit_lesson, row_id:substitution.data[0].id, sub_needed_for: substitution.data[0].sub_needed_for});
      }
    });
    console.log("modified value" + modified);
    if(modified.length != 0) { setShowSubstitute(true)}
    setModifiedSubstitutions(modified);
  }
  },[props.additionalSubstitutions]);

  useEffect(() => {
    if (masterScheduler != {}) {
      if(masterScheduler.final_resullt?.length == 0)
      {
        // alert("No sessions found")
        setGoLoader(false);
        // return;
      }
      setSchedulerList(masterScheduler.final_resullt);
      setCountItems(masterScheduler.counts);
      setLastPushedDate(masterScheduler.last_pushed_week);
    }
  }, [masterScheduler]);

  useEffect(() => {
    if (languages.length != 0) {
      setTypeBasedLang(filterTypeBasedLang("totale"));
    }
  }, [languages]);

  const loaderCallBack = (data) => {
    setGoLoader(data);
  }


  function Loading() {
    return <img src={bigSpinner} />;
  }

  function onFilterChange(e) {
    setGoLoader(false);
    // setIsFromNavigation(false);
    const { name, value } = e.target;
    setType({ ...type, sessionType: e.target.name });
    setFilter({ ...filter, [name]: value });
    setMasterFilter({ ...masterFilter, [name]: value });
    let slotDuration = {};
    if (name == "languageId") slotDuration = { slotDuration: value == "totale" ? "00:30:00" : "00:30:00" };
    setConfig({ ...config, display: true });
  }

  function onTypeFilterChange(e) {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
    setMasterFilter({ ...masterFilter, [name]: value });
    setTypeBasedLang(filterTypeBasedLang(e.target.value));
    let slotDuration = {};
    if (name == "languageId") slotDuration = { slotDuration: value == "totale" ? "00:30:00" : "00:30:00" };
    setConfig({ ...config, display: true });
    switch (value) {
      case "fb":
        setType({ ...type, disable: true, sessionType: "All", classRoomType: "group" });
        setTypeName(e.target.options[e.target.options.selectedIndex].text)
        break;
      case "fbsolo":
        setType({ ...type, disable: true, sessionType: "All", classRoomType: "solo" });
        setTypeName(e.target.options[e.target.options.selectedIndex].text)
        break;
      case "cp":
      case "yp":
      case "wwe":
        setType({ ...type, disable: true, sessionType: "All", classRoomType: "All" });
        setTypeName(e.target.options[e.target.options.selectedIndex].text)
        break;
      case "rstv":
        setType({ ...type, disable: false, sessionType: "", classRoomType: "All" });
        setTypeName(e.target.options[e.target.options.selectedIndex].text)

        break;
      default:
        setType({ ...type, disable: false, sessionType: "", classRoomType: "All" });
        setTypeName(e.target.options[e.target.options.selectedIndex].text)
    }
  }

  function filterTypeBasedLang(langType) {
    return languages.filter((lang) => {
      return extractIdentifier(lang.identifier) == langType;
    });
  }

  function extractIdentifier(language) {
    let identifier = language.split("-");
    return identifier.length == 2 ? identifier[0].toLowerCase() : identifier.length == 3 ? "fbsolo" : (identifier[0] !== "ALL" ? "totale": "" );
  }

  function onSubmitHandler(selectedDate = null, action = null) {
    if (filter.languageId == "") {
      alert("Please Select a Language.");
      return;
    }
    // setGoLoader(true);
    setpushScheduleNotice(false);
    setHeaderSection(true);
    // setSchedulerList([]);
    let modals= document.querySelectorAll(".modal .close-icon, .rcmodal .cancel, #coach-modal-close, #recurring-close, #cancel-modal-close");
    for (let modal of modals) {
      modal.click();
    }

    if (action == "custom") {
      props.actions
      .pushSessionStatus({
        appointments_push: "false",
        language_identifier: masterFilter.languageId,
      })
      .then((response) => {
        if (response.locked == 0) {
          props.actions.getMasterScheduler({ ...masterFilter, filterDate: selectedDate });
          setShowCalendar(true);
          if (calRef.current != null) {
            let calendarApi = calRef.current.getApi();
            calendarApi.gotoDate(selectedDate);
          }
          setGoLoader(true);
          return;
        } else {
          setpushScheduleNotice(true);
          setShowCalendar(false);
          setGoLoader(false);
          return;
        }
      });
      // setMasterFilter({ ...masterFilter, filterDate: selectedDate });
      // setShowCalendar(true);
      // setGoLoader(true);
      return;
    }
    props.actions
      .pushSessionStatus({
        appointments_push: "false",
        language_identifier: masterFilter.languageId,
      })
      .then((response) => {
        if (response.locked == 0) {
          props.actions.getMasterScheduler(masterFilter);
          setShowCalendar(true);
          let currentDate = new Date().toString();
          let CurrentTimeZone = currentDate.split("(")[1];
          setUpdatedTimeZone(CurrentTimeZone);
          if (calRef.current != null) {
            let calendarApi = calRef.current.getApi();
            calendarApi.gotoDate(masterFilter.filterDate);
          }
          setGoLoader(true);
          return;
        } else {
          // alert("Please wait the push is not completed");
          setpushScheduleNotice(true);
          setShowCalendar(false);
          setGoLoader(false);
          return;
        }
      });
  }

  function handlePushScheduleClicked() {
    if (masterFilter.languageId == "") {
      alert("Please Select a Language.");
      return;
    }
    props.actions
      .pushMasterSchedulerSessions(
        {
          language_identifier: masterFilter.languageId,
          // start_time: masterFilter.filterDate,
          start_time: moment(masterFilter.filterDate)
            .endOf("week")
            .startOf("day")
            .toISOString(),
          appointments_push: "false",
        },
        masterFilter
      )
      .then((response) => {
        toast.success(response);
        setpushScheduleNotice(true);
        setShowCalendar(false);
      })
      .catch((err) => {
        toast.error("Failed to Push Master Schedules, Try again.");
      });
  }

  function createBulkSession(){
    setBulkSession(true);
  }

  function generateCSV(){
    let populateSessions = []
    let list = schedulerList || []
    populateSessions.push(['Session Date', 'Session Time', 'Tutor Name', 'Language'])
    sortByDates(list, "startTime").forEach((session) => {
        session.coaches?.forEach((coach) => {
        populateSessions.push([moment(session.startTime).format("MM/DD/YYYY"), moment(session.startTime).format("hh:mmA"), coach, getLanguageByIdentifier(masterFilter.languageId)])
        })
    })
    return populateSessions;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="right-content">
        <div className="master-schedule">
          <div className="title">
            <h2>Master Scheduler {(getLanguageByIdentifier(masterFilter.languageId) === " -- none --" || getLanguageByIdentifier(masterFilter.languageId) === "RSTV")? "" : " - " + typeName + " - " + getLanguageByIdentifier(masterFilter.languageId)}</h2>
          </div>
          <div className="week-info">
            <div className="date-info">
              <div className="today-date-section">
                <span className="today-date">Today&apos;s Date:</span>
                <span className="today-date-in-dashboard"> {moment().format("dddd, MMMM DD,YYYY")}</span>
              </div>
              <div style={{display: headerSection ? "block" : "none"}}>
                <span className="bold-font">First day of the Last Pushed Week:</span>
                <span>
                  {moment(lastPushedDate)
                    .startOf("week")
                    .format("MMMM DD, YYYY")}
                </span>
              </div>
            </div>
            <div className="session-info" style={{display: headerSection ? "block" : "none"}}>
              {/* <div>
                <span className="bold-font">Classes held historically (January 31 - February 27) </span>
                <span>338</span>
              </div> */}
              <div>
                <span className="bold-font">Scheduled Sessions </span>
                <span>{countItems?.scheduled && countItems.scheduled}</span>
              </div>
              <div>
                <span className="bold-font">Draft Sessions </span>
                <span>
                  {countItems?.new && countItems.new} new : {countItems?.removed && countItems.removed} removed
                </span>
              </div>
            </div>
          </div>
          <div className="substitution-alert-div"  style={{
              display: showSubstitute ? "block" : "none",
            }}>
            <div className="substitution-alert">
              <table className="sub-alert-table">
                <tbody>
                  <tr>
                    <td className="substitution-needed">SUBSTITUTION NEEDED!</td>
                    <td className="sub-data-div">
                      <table className="sub-data">
                        <tbody>
                        {modifiedSubstitutions.map((substitution, i) => (
                          <tr  key={i}>
                            <td className="width3">
                                <img className="warning_icon" src={warning} />
                            </td>
                            <td className="width25 substitution_needed">{substitution.sub_needed_for ? (substitution.sub_needed_for == "Coach" ? "Coach Required!" : "Moderator Required!") : ""}</td>
                            <td className="width10 substitution-needed"></td>
                            <td className="width25">{substitution.lang}</td>
                            <td className="width25">{moment(substitution.session_start_time).format("ddd, MMMM DD,YYYY hh:mm A")}</td>
                            <td className="width15">{substitution.level_unit_lesson}</td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </td>
                    <td className="see-all-substitutions">
                      <font className="substitution-font">
                        <a href="/substitutions"> see all sub requests</a>
                      </font>
                    </td>
                    <td>
                      <div className="substitution-close-button" onClick={() => {setShowSubstitute(false)}}>X</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="scheduler-form-data">
            <input
              className="today-button"
              type="button"
              value="Today"
              onClick={(e) => {
                setMasterFilter({ ...masterFilter, filterDate: moment().endOf("day").toISOString() });
                if(filter.languageId !== ""){
                onSubmitHandler(
                  moment()
                    .endOf("day")
                    .toISOString(),
                  "custom"
                );
                }
                // setGoLoader(false);
              }}
            />
            <div className="calendar-data">
              <button className="arrow">
                <img
                  alt="Previous Week"
                  onClick={(e) => {
                    setMasterFilter({ ...masterFilter, filterDate:  moment(masterFilter.filterDate).subtract(7, "days").endOf("day").toISOString() });
                    if(filter.languageId !== ""){
                    onSubmitHandler(
                      moment(masterFilter.filterDate)
                        .subtract(7, "days")
                        .endOf("day")
                        .toISOString(),
                      "custom"
                    );
                    }
                    // setGoLoader(false);
                  }}
                  className="previous-week"
                  src={previous}
                  title="Previous Week"
                />
              </button>
              <DatePicker
                selected={new Date(masterFilter.filterDate)}
                onChange={(date, e) => {
                  console.log("Data and event", date, e.target)
                  e.preventDefault;
                  setMasterFilter({
                    ...masterFilter,
                    filterDate: date,
                  });
                  // e.target.value = date;
                  if(filter.languageId !== ""){
                    onSubmitHandler(moment(date).endOf("day").toISOString(), "custom");
                  }
                }}
                value={
                  moment(masterFilter.filterDate)
                    .startOf("week")
                    .format("MMMM DD, YYYY") +
                  "    -    " +
                  moment(masterFilter.filterDate)
                    .endOf("week")
                    .format("MMMM DD, YYYY")
                }
                className="date-picker"
              />
              <button href="#" className="arrow">
                <img
                  alt="Next Week"
                  onClick={(e) => {
                    setMasterFilter({ ...masterFilter, filterDate:   moment(masterFilter.filterDate).add(7, "days").startOf("week").endOf("day").toISOString() });
                    if(filter.languageId !== ""){
                    onSubmitHandler(
                      moment(masterFilter.filterDate)
                        .add(7, "days")
                        .startOf("week")
                        .endOf("day")
                        .toISOString(),
                      "custom"
                    );
                    }
                    // setGoLoader(false);
                  }}
                  className="next-week"
                  src={next}
                  title="Next Week"
                />
              </button>
            </div>
            <div className="scheduler-language-category">
              <select className="dropdown" id="language_category" name="languageId" onChange={onTypeFilterChange}>
                <option value="totale">Foundations</option>
                <option value="fbsolo">Fluency Builder Solo</option>
                <option value="fb">Fluency Builder Group</option>
                {/* <option value="cp">Cross Product</option>
                <option value="yp">Your Plan</option> */}
                <option value="rstv">Live Streaming</option>
                {/* <option value="wwe">World Wide English</option> */}
                
              </select>
            </div>
            <div className="scheduler-language">
              <select name="languageId" onChange={onFilterChange} className="dropdown longer-dropdown language">
                <option value="">Select a Language</option>
                {sortLanguagesBy(typeBasedLang, "language_name").map(
                  (language) =>
                    getLanguageByIdentifier(language.identifier) != " -- none --" && (
                      <option key={language.id} value={language.identifier}>
                        {getLanguageByIdentifier(language.identifier)}
                      </option>
                    )
                )}
              </select>
            </div>
            <div className="cs-coach">
              <select name="villageId" onChange={onFilterChange} className="dropdown language" disabled={type.disable}>
                <option value="all" selected={type.sessionType === "All" ? true : false}>
                  All Sessions
                </option>
                {props.villages.map((village) => (
                  <option
                    key={village.id}
                    value={village.id}
                    selected={type.sessionType === village.name ? true : false}
                  >
                    {village.name}
                  </option>
                ))}
              </select>
            </div>
            <input className="go-button" type="button" value="GO" onClick={onSubmitHandler} />
          </div>
          <div className="filter-row2">
            <div className="classroom-type-div">
              <span>Classroom Type: </span>
              <select
                className="dropdown classroom-type"
                name="classRoomType"
                onChange={onFilterChange}
                disabled={type.disable}
              >
                <option value="all" selected={type.classRoomType == "All" ? true : false}>
                  All
                </option>
                <option value="solo" selected={type.classRoomType == "solo" ? true : false}>
                  Solo
                </option>
                <option value="group" selected={type.classRoomType == "group" ? true : false}>
                  Group
                </option>
              </select>
            </div>
            <div className="scheduler-region-vendor">
              <span>Region: </span>
              <select className="dropdown region-vendor" name="region" onChange={onFilterChange}>
                <option value="all" selected="selected">
                  All Regions
                </option>
                {props.regions
                  ?.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                  })
                  .map((region) => (
                    <option key={region.id} value={region.id}>
                      {region.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="scheduler-coach" style={{display: "none"}}>
              <select className="dropdown coach-avail" name="coach_avail">
                <option value="">Coach Availability</option>
                <option value="8960">56821Coach2</option>
                <option value="9034">Abigail Thomas</option>
              </select>
            </div>
            {showCalendar && masterFilter.languageId.split("-")[0] != "RSTV" && (<div className="master-bulk-session-link">
              <button href="#" onClick={createBulkSession}>Create bulk extra sessions</button>
            </div>)}
          </div>
        </div>
        {pushScheduleNotice && (
          <div className="push-notice">
            <p>
              Currently, sessions are being pushed to eSchool for this language.
              <span style={{ display: "none" }}>Out of 68 sessions, 0 have been pushed.</span>
            </p>
            <p style={{ display: "none" }}>
              Korean sessions Pushed : 0 | Korean sessions in eSchool queue : 68 | Total sessions in eSchool queue : 68
            </p>
          </div>
        )}
        <div className="week-calendar">
          {/* {schedulerList != [] && ( */}
             {/* <masterContext.Provider value={masterContext}> */}
            <MasterSchedulerCalendar
              config={config}
              schedulerList={schedulerList}
              showCalendar={showCalendar}
              calRef={calRef}
              masterFilter={masterFilter}
              goLoader={goLoader}
              // isFromNavigation={isFromNavigation}
              onDoubleChange={(data) => loaderCallBack(data)}
              updatedTimeZone={updatedTimeZone}
              bulkSession={bulkSession}
              setBulkSession={setBulkSession}
            />
             {/* </masterContext.Provider> */}
          {/* )} */}
        </div>
        {showCalendar && (
          <div id="ms-legend">
            <div className="legend-box" id="orange-box"></div>
            <div className="legend-text">Fewer sessions are scheduled than have been historically needed.</div>
            <div className="legend-box" id="dark-tan-box"></div>
            <div className="legend-text">Not Pushed to eSchool</div>
            <div className="legend-box" id="light-tan-box"></div>
            <div className="legend-text">Pushed to eSchool</div>
            <div className="legend-box" id="recurring_sessions_legend"></div>
            <div className="legend-text">Recurring Sessions</div>
            <div className="legend-box" id="extra_sessions_legend"></div>
            <div className="legend-text">Extra Sessions</div>
            <div className="legend-box" id="service_not_available_legend"></div>
            <div className="legend-text">Service Down</div>
          </div>
        )}
        {showCalendar && (
          <>
            <div className="download-csv">
              <CSVLink
                data={generateCSV()}
                filename={`Sessions_${getLanguageByIdentifier(masterFilter.languageId)?.replace(" ", "")}_${moment().format('MMDDYYYYThh:mmA(Z)')}.csv`} className="" target="_blank" style={{"float": "right", "color":"#222",
                  "padding": "5px 17px","border": "1px solid #555","marginTop": "10px","marginRight": "18px"}}
              >
                Download CSV
              </CSVLink>
            </div>
            <div className="push-schedule">
              <input type="submit" value="PUSH SCHEDULE" name="commit" onClick={handlePushScheduleClicked}></input>
            </div>
          </>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  regions: state.regions,
  languages: state.languages,
  villages: state.villages,
  notifications: state.notifications,
  masterScheduler: state.masterScheduler,
  additionalSubstitutions: state.additionalSubstitutions,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      getNotifications: bindActionCreators(notificationsAction.getNotifications, dispatch),
      getLanguages: bindActionCreators(languageActions.getLanguages, dispatch),
      getRegions: bindActionCreators(regionAction.getRegions, dispatch),
      getVillages: bindActionCreators(villageAction.getVillages, dispatch),
      getMasterScheduler: bindActionCreators(masterSchedulerAction.getMasterScheduler, dispatch),
      pushSessionStatus: bindActionCreators(masterSchedulerAction.pushSessionStatus, dispatch),
      pushMasterSchedulerSessions: bindActionCreators(masterSchedulerAction.pushMasterSchedulerSessions, dispatch),
      getSubstitutions: bindActionCreators(notificationsAction.getAdditionalSubstitutions,dispatch)
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterSchedulerPage);
