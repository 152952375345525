import initialState from "./initialState";
import {
  GET_MASTER_SCHEDULER_SUCCESS,
  GET_SHIFT_SESSION_DETAILS_SUCCESS,
  ADD_MASTER_SCHEDULER_SUCCESS,
  GET_SHIFT_SESSION_DETAILS_LOADING,
  GET_SHIFT_SESSION_DETAILS_FAILURE,
  CLEAR_ERRORS,
} from "../constants";

export const MasterSchedulerReducer = (state = initialState.masterScheduler, action) => {
  if (action.type == GET_MASTER_SCHEDULER_SUCCESS) return action.masterScheduler;
  // if (action.type == ADD_MASTER_SCHEDULER_SUCCESS) return;
  return state;
};

export const MasterShiftSessionReducer = (state = initialState.shiftSessionDetails, action) => {
  if (action.type === GET_SHIFT_SESSION_DETAILS_LOADING) return { ...state, loading: true };
  if (action.type === GET_SHIFT_SESSION_DETAILS_SUCCESS) return { loading: false, sessionDetail: action.payload };
  if (action.type === GET_SHIFT_SESSION_DETAILS_FAILURE) return { ...state, error: action.payload };
  if (action.type === CLEAR_ERRORS) return { ...state, error: null };
  return state;
};
