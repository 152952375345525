/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useMemo, useReducer } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "underscore";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import * as coachShedulerAction from "../../redux/actions/CoachShedulerAction";

import * as masterShedulerAction from "../../redux/actions/MasterSchedulerAction";
import { useVillage, useAvailableCoach } from "../../hooks";
import * as RstvAction from "../../redux/actions/RSTVAction";

import { topicTypes } from "../../utils/sessionUtils"

import "./EditShiftModal.css";
import RecurringSessionModal from "./RecurringSessionModal";
import classes from "../CoachSchedulerComponent/CreateSessionModal.module.css";
import { getCoachRstvTopic } from "../../apis/CoachSchedulerApi";
import { getCoachMaxUnits } from "../../apis/CoachProfileApi";
import clsx from "clsx";

// function extractIdentifier(language) {
//   let identifier = language.split("-");
//   return identifier.length == 2 ? identifier[0].toLowerCase() : "totale";
// }

// eslint-disable-next-line no-unused-vars
function languageReducer(state = "", action) {
  for (let language of ["totale", "fb", "yp", "rstv", "cp"]) {
    if (action.language.toLowerCase().includes(language.toLowerCase())) return language;
  }

  return "totale";
}

function getRecurring(data) {
  const { is_recurring, recurring_end_date, recurring_frequency, ...rest } = data;
  if (is_recurring == false) return rest;
  else {
    const endDate = moment(recurring_end_date, "YYYY-MM-DD").isValid() ? { recurring_end_date } : {};
    return { ...data, ...endDate, recurring_frequency, recurring_start_date: data.session_start_time };
  }
}

function sessionPayload(action) {
  const recurring = getRecurring(action.payload);

  if (
    action.payload.type === "session" ||
    action.payload.type == "ConfirmedSession" ||
    action.payload.type == "ExtraSession" ||
    action.payload.type == "LocalSession" ||
    action.payload.type == "recurring_session"
  ) {
    // eslint-disable-next-line no-unused-vars
    const { teacher_confirmed, appointment_type_id, details, ...restSession } = recurring;
    if (["totale", "solo", "fb", "yp", "cp"].includes(action.type)) {
      const { moderator_id, ...remain } = restSession;
      return remain;
    }
    if (action.type === "rstv") {
      const { number_of_seats, lesson, level, unit, ...remain } = restSession;
      return remain;
    }
  }
}

function EditShiftModal({ ...props }) {
  const [session, setSession] = useState({
    language_identifier: "all", //eg:. ENG
    language_id: "",
    number_of_seats: null, // int
    type: "session",
    is_recurring: false,
    teacher_confirmed: false,
    name: "", //optional parameter
    lesson: "", //4, //optional parameter
    level: "", //1, //optional parameter
    unit: "", //1, //optional parameter
    moderator_id: "",
    topic_id: "",
    coach_id: "",
    session_start_time: null,
    session_end_time: null,
    recurring_end_date: "", //"2021-04-25 12:00:00", // optional
    external_village_id: "", //1, // optional
    recurring_frequency: 1, // 1 – weekly, 2 – biweekly, default value is 1 if not passed
    wildcard: false,
    id: "",
    recurring_session_start_time: "",
    topic_restrictions: "",
  });
  const {
    settings,
    setSettings,
    openModal,
    setOpenModal,
    sessionDetails: extraSession,
    sessionDetail,
    actions,
    masterFilter,
    shiftDetailParams
  } = props;
  const { error: detailError } = extraSession;
  const { coachs, setCoachParams } = useAvailableCoach(false);
  // const { moderators, removeModerators, setModeratorParams } = useModerator();
  const villages = useVillage();
  const [store, dispatch] = useReducer(languageReducer, { language: "totale" });
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);  
  const calMultiRef = useRef();
  const coachTime = useRef("");
  const [checkRecurring, setCheckRecurring] = useState({
    disable_recurring: false,
  });
  const [notes, setNotes] = useState(null);
  const [rstvTopics, setRstvTopics] = useState([]);
  const [intimation, setIntimation] = useState(null);
  const [isCoachQualifiedForCP, setIsCoachQualifiedForCP] = useState(false)

  // Update `isCoachQualifiedForCP` whenever a coach is selected from the dropdown in the edit modal (standard session)
  // Note that this modal doesn't have a language selector. `coachs.conv_pract`'s value already accounts 
  // for the selected language before the edit modal
  useEffect(() => setIsCoachQualifiedForCP(coachs.find(c => c.id == session.coach_id)?.conv_pract ?? 0), [session.coach_id, coachs]);

  const coaches = useMemo(() => session.topic_restrictions === "none" ? coachs : coachs.filter(c => c.conv_pract), [session.topic_restrictions, coachs]);

  useEffect(()=>{
    if(store == "totale" && props.maxUnitLesson != "")
    {
      let int = `Max Level:${props.maxUnitLesson.maxLevel || 0} Unit:${props.maxUnitLesson.maxUnit || 0} Lesson:4`;
      setIntimation(int);
    }
  },[props.maxUnitLesson])
  useEffect(() => {
    if (Object.keys(sessionDetail).length > 0) {
      setSession(sessionDetail);
      if (sessionDetail.type == "ExtraSession") {
        actions.getSessionDetails({ id: sessionDetail.id, region_id: "all" }); //sessionDetail); //{ id: "909320", region_id: "all" });
        if (detailError) {
          toast.error(detailError);
          actions.clearErrors(); // dispatch(masterShedulerAction.clearErrors());
        }
      }
      dispatch({ language: sessionDetail?.language_identifier || masterFilter.languageId || "totale" });
      if ("coach_id" in sessionDetail && sessionDetail["coach_id"] != "")
        //setCoachParams({ sessionId: sessionDetail.id });
        console.log(sessionDetail, "session details");
        setCoachParams({ sessionId: sessionDetail.id, localRecurringSession: (sessionDetail.type == "recurring_session" ? true : false), currentDate: sessionDetail.current_slot_date});

      //Disable recurring
    // if(e.target.name == "coach_id")
    // {
      if(sessionDetail.coach_id != "" && sessionDetail.coach_id != null && sessionDetail.type != "ExtraSession" && !sessionDetail.is_recurring)
      {
        coachShedulerAction
        .checkRecurrings({
          coach_id: sessionDetail.coach_id,
          session_start_time: moment
            .utc(sessionDetail.session_start_time)
            .format("yyyy-MM-DD HH:mm:ss"),
          day_index: moment.utc(sessionDetail.session_start_time).day(),
        })
        .then((result) => {
          if(result.disable_recurring)
          {
          //  setSession({...session, ["is_recurring"]: false})
            setNotes(
              "This coach is already scheduled to have a recurring Session/Appointment starting on " +
                "the following week. You can only schedule a one-off Session/Appointment dialog at this time."
            )
          }
          else
          {
           // setSession({...session, ["is_recurring"]: true})
           setNotes(null)
          }
          setCheckRecurring(result);

        
        });
      }
      {sessionDetail && sessionDetail.language_identifier && sessionDetail.language_identifier.toLowerCase().includes("rstv") &&
          getCoachRstvTopic(sessionDetail.language_identifier).then((response) => {
            setRstvTopics(response.data)
          })}
      //Disable recurring
    }
    
  }, [sessionDetail]);

  useEffect(() => {
    if (typeof extraSession?.sessionDetail != "undefined") {
      if (Object.keys(extraSession?.sessionDetail).length > 0) {
        let modifiedSession = {};
        let { freq_in_weeks, sessionDetail } = extraSession;
        sessionDetail = { ...sessionDetail, ...sessionDetail.extra_session };
        if (sessionDetail.disable_recurring && sessionDetail.is_recurring == false)
          sessionDetail = { ...sessionDetail, is_recurring: false };

        if (sessionDetail.is_recurring) {
          modifiedSession = {
            ...sessionDetail,
            recurring_frequency: freq_in_weeks,
          };
        } else modifiedSession = { ...sessionDetail };
        setSession(modifiedSession);
        // if (modifiedSession.type == "ExtraSession") {
        // if (session.type == "ExtraSession") {
        // setOptions([
        //   { label: "All", value: "*" },
        //   ...coachs.map(({ id, full_name }) => ({ label: full_name, value: id })),
        // ]);
        // // "excluded_coaches" : [9034, 9548]
        // setSelectedOptions(
        //   coachs.map(({ id, full_name }) => {
        //     if (!session?.excluded_coaches.includes(id)) {
        //       return { label: full_name, value: id };
        //     }
        //   })
        // );
        // }
      } //else dispatch({ language: "totale" });
    }
  }, [extraSession]);

  useEffect(() => {
    if (session.type == "ExtraSession") {
      if (Object.keys(coaches).length) {
              
        // coachTime.current != session.session_start_time && // what is the purpose of this check?
          setOptions([
            { label: "All", value: "*" },
            ...coaches.map(({ id, full_name, conv_pract }) => ({ label: full_name, value: id, conv_pract }))
          ]);
        // coachTime.current = session.session_start_time;
      }
      
      let selectedCoaches = [
        ...coaches
          .filter(({ id }) => !session?.excluded_coaches?.includes(id))
          .filter(c => session.topic_restrictions != "none" ? c.conv_pract : c)
          .map(({ id, full_name, conv_pract }) => ({ label: full_name, value: id, conv_pract}))
      ]
      Object.keys(options).length && setSelectedOptions(selectedCoaches);
      Object.keys(options).length && console.log(selectedOptions);
    }
  }, [coaches, session.excluded_coaches]); //.type, coachs, options


  // When topic_restrictions value change, pre-select appropriate coaches,
  // but don't filter by `excluded_coaches`, because otherwise we'd be pre-selecting
  // coaches scheduled for this session (which is already done in the hook above) instead
  // of all available coaches (for CP, or otherwise). Basically, when topic_restrictions is changed
  // we reset the coaches dropdown to select all qualified CP tutors, or all tutors if no restrictions.
  useEffect(() => {
    if (session.type == "ExtraSession") {
      let selectedCoaches = [
        ...coachs
          .filter(c => session.topic_restrictions != "none" ? c.conv_pract : c)
          .map(({ id, full_name, conv_pract }) => ({ label: full_name, value: id, conv_pract}))
      ]
      Object.keys(options).length && setSelectedOptions(selectedCoaches);
    }
    
  }, [session.topic_restrictions]); 


  useEffect(() => {
    let additional = {};
    if (store === "cp") {
      additional.unit = "";
      additional.lesson = "";
      additional.number_of_seats = "";
    }
    if (store === "yp") additional.lesson = "";
    setSession({ ...session, ...additional });
  }, [store]);

  useEffect(() => {
    if (props.closeAllModals) {
      setCancelModalOpen(false);
    }
  }, [props.closeAllModals]);

  // useEffect(() => {
  //   setModeratorParams({
  //     language: props.masterFilter.languageId,
  //     session_start_time: moment.utc(props.shiftDetailParams.start_date).format("YYYY-MM-DD HH:mm:ss"),
  //   });
  // }, [session.moderator_id]); // , props.settings.dateStr]);

  const getInputDisableConfig = useMemo(() => {
    const disable = {
      village: false,
      seat: false,
      wildcard: false,
      unit: false,
      lesson: false,
      topic: false,
      moderator: false,
    };
    if (+session.learner_count) {
      return {
        cp: disable,
        yp: disable,
        www: disable,
        fb: disable,
        solo: disable,
        totale: { ...disable, village: false, seat: false, unit: true, lesson: true, wildcard: true },
        rstv: { ...disable, topic: true, moderator: true, wildcard: true },
      };
    } else {
      return {
        cp: disable,
        yp: disable,
        www: disable,
        fb: disable,
        solo: disable,
        totale: { ...disable, village: true, seat: true, unit: true, lesson: true },
        rstv: { ...disable, topic: true, moderator: true },
      };
    }
  });

  function onChange(selectedItems, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (event.action === "deselect-option" && event.option.value === "*") {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(selectedItems.filter((o) => o.value !== "*"));
    } else if (selectedItems.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(selectedItems);
    }
  }

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `All`;
    } else if (value.length === 0) {
      return `Select`;
    } else {
      return `${value.length} selected`;
    }
  }

  function onSessionChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setSession({ ...session, [target.name]: value });
    //Disable recurring
    if(e.target.name == "coach_id" && store == "totale" && session.type != "ExtraSession")
    {
      let requestData = {
        'coach_id' : e.target.value,
        'language_id': session.language_identifier
      };
      getCoachMaxUnits(requestData).then((response) => {
        let int = `Max Level:${response.data.maxLevel || 0} Unit:${response.data.maxUnit || 0} Lesson:4`;
            setIntimation(int);
        }).catch((err) => {
          console.log("Error occured during MaxUnitLevel Fetch :" + err);
          setIntimation(null)
        })
    }
    if(e.target.name == "coach_id")
    {
      if(e.target.value != "")
      {
        coachShedulerAction
        .checkRecurrings({
          coach_id: e.target.value,
          session_start_time: moment
            .utc(settings.sessionDate)
            .format("yyyy-MM-DD HH:mm:ss"),
          day_index: moment.utc(settings.sessionDate).day(),
        })
        .then((result) => {
          if(result.disable_recurring)
          {
          //  setSession({...session, ["is_recurring"]: false})
            setNotes(
              "This coach is already scheduled to have a recurring Session/Appointment starting on " +
                "the following week. You can only schedule a one-off Session/Appointment dialog at this time."
            )
          }
          else
          {
           // setSession({...session, ["is_recurring"]: true})
           setNotes(null)
          }
          setCheckRecurring(result);
        });
      }
      //Disable recurring
    }    
  }

  const promt = (msg) => {
    setLoading(false);
    alert(msg);
    return false;
  };
  function validate(payload) {
    if (store == "rstv") {
      for (const required of ["coach_id", "moderator_id", "topic_id"]) {
        if (payload[required] == "" || payload[required] == null)
          return promt(`Please select a ${required.split("_")[0]}`);
      }
      if (payload.coach_id == payload.moderator_id) return promt("Coach and Moderator should be different");
    }

    if (store == "totale") {
      if (session.type != "ExtraSession") {
        for (const required of ["coach_id"]) {
          if (payload[required] == "" || payload[required] == null)
            return promt(`Please select a ${required.split("_")[0]}`);
        }
      } else {
        const Soptions = [...selectedOptions.map((opt) => opt.value)];
        if (Soptions.length == 0 || selectedOptions == []) {
          return promt(`Please select a Coach`);
        }
      }
    }
    return true;
  }

  function onSessionSubmit(recurrall = "") {
    setLoading(true);
    const payload = sessionPayload({ type: store, payload: session });
    if (!validate(payload)) return;

    let action = "edit";
    if ((session.is_recurring && sessionDetail.is_recurring) || (!session.is_recurring && sessionDetail.is_recurring)) {
    //  if ((session.is_recurring && sessionDetail.is_recurring) || (!session.is_recurring && sessionDetail.is_recurring)|| (!sessionDetail.is_recurring && session.is_recurring)) {
      if (recurrall == "SAVE ALL") action = "edit_all";
      setCancelModalOpen(false);
    }
    const Soptions = ["*", ...selectedOptions.map((opt) => opt.value)];

    if(store == "totale" && payload.unit != '' && (payload.type == "session" || payload.type == "LocalSession" || payload.type == "ConfirmedSession"))
    {
        if (payload.unit > props.maxUnitLesson.maxUnit) {
          alert("Coach is not qualified to teach selected unit");
          setLoading(false);
          setOpenModal(false);
          return;
        }
    }
    props.actions
      .updateSession(
        {
          ...payload,
          ...(session.type == "ExtraSession"
            ? { excluded_coaches: options.filter((option) => !Soptions.includes(option.value)).map((opt) => opt.value) }
            : { coach_id: payload.coach_id }),
          ...(session.type == "LocalSession" ? { local_session_id: session.id } : {}),
          //To solve recurring session saving issue
          ...(session.type == "recurring_session" ? { recurring_session_start_time: shiftDetailParams.start_date } : {}),
          //
          ...(session.type == "StandardLocalSession"
            ? { recurring_schedule_id: session.id, language_identifier: session.language_identifier }
            : {}),
          language_type: store,
          //is_recurring: session.is_recurring,
          is_recurring: checkRecurring.disable_recurring ? false : session.is_recurring,
          action,
        },
        props.masterFilter
      )
      .then(() => {
        setCancelModalOpen(false);
        setLoading(false);
        setSession({
          id: "",
          language_identifier: "all", //eg:. ENG
          language_id: "",
          number_of_seats: null, // int
          type: session.type,
          is_recurring: false,
          teacher_confirmed: false,
          details: "", //optional parameter
          lesson: "", //4, //optional parameter
          level: "", //1, //optional parameter
          unit: "", //1, //optional parameter
          moderator_id: "",
          topic_id: "",
          coach_id: "",
          session_start_time: "",
          session_end_time: "",
          recurring_end_date: "", //"2021-04-25 12:00:00", // optional
          external_village_id: "", //1, // optional
          recurring_frequency: 1, // 1 – weekly, 2 – biweekly, default value is 1 if not passed
          recurring_session_start_time: "",
          topic_restrictions: session.topic_restrictions
        });
        // Update values in parent `ShiftDetails` component
        props.setSessionDetails(session)
        setOpenModal(false);
        setOptions([]);
        setSelectedOptions([]);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Failed to process the data...");
        setSession({
          id: "",
          language_identifier: "all", //eg:. ENG
          language_id: "",
          number_of_seats: null, // int
          type: session.type,
          is_recurring: false,
          teacher_confirmed: false,
          details: "", //optional parameter
          lesson: "", //4, //optional parameter
          level: "", //1, //optional parameter
          unit: "", //1, //optional parameter
          moderator_id: "",
          topic_id: "",
          coach_id: "",
          session_start_time: "",
          session_end_time: "",
          recurring_end_date: "", //"2021-04-25 12:00:00", // optional
          external_village_id: "", //1, // optional
          recurring_frequency: 1, // 1 – weekly, 2 – biweekly, default value is 1 if not passed
          recurring_session_start_time: ""
        });
        setOpenModal(false);
        setOptions([]);
        setSelectedOptions([]);
      });
  }

  function formStyle(prop = "") {
    const sessionType = true;
    const sessionInput = { display: sessionType ? prop : "none" };
    const sessionLanguageInput = { ...(session.language_identifier == "all" ? { display: "none" } : sessionInput) };
    const recurringInput = { display: session.is_recurring && !checkRecurring.disable_recurring ? prop : "none" };
    return {
      language: sessionInput,
      village: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      seats: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      unit: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      level: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      is_recurring: recurringInput,
    };
  }

  function fieldStyle() {
    return getInputDisableConfig[store];
  }

  return (
    <>
      <div className="blockOverlay" style={{ display: loading ? "block" : "none" }}></div>
      
      <div className="blockMsg" style={{ display: loading ? "block" : "none" }}>
        <h1>Please wait...</h1>
      </div>
      <div
        className="modal coach-schedule-session-details-modal master-schedule-create-edit-modal"
        style={{
          display: openModal ? "block" : "none",
        }}
        id="master-schedule-edit-session"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal_header">
              <h4 className="modal-title">
                EDIT SESSION at{" "}
                <span style={{ color: "#1090CF" }}>
                  {moment(moment(settings.sessionDate).format()).format("ddd MM/DD/YY hh:mm A")}
                </span>
              </h4>
              <a
                className="close-icon"
                title="Close"
                aria-label="Close"
                role="button"
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  // setSettings((settings) => ({ ...settings, ["displaySession"]: true }));
                  setOpenModal(false);
                }}
              >
                <span>×</span>
              </a>
            </div>
            <div className="modal-body">
              <div className="left-section">
                <div>
                {/FB-.+-Solo/i.test(session.language_identifier) && 
                      <div className="topic-restriction">
                        <div>Allowed Topics</div>    
                        <select name='topic_restrictions' 
                          onChange={onSessionChange}
                          disabled={["session", "LocalSession", "recurring_session"].includes(session.type) && !isCoachQualifiedForCP}
                        >
                          {topicTypes.map(({ name, value }) => (
                            <option 
                              key={value} 
                              value={value}
                              selected={session.topic_restrictions === value}
                            >
                              { name }
                            </option>
                          ))}
                        </select>                    
                      </div>
                    }
                
                  {session.type == "session" || session.type == "LocalSession" || (session.type == "ConfirmedSession" && <span>Coach</span> )} 
                  {session.type == "ExtraSession" ? (
                    <>
                      <div>
                        <span>Session Name</span>
                        
                        <input
                          name="session_name"
                          type="text"
                          value={session?.session_name}
                          onChange={onSessionChange}
                        />
                      </div>
                      <div className="coach-availabe">
                        <span>Included Coaches:</span>
                        <ReactMultiSelectCheckboxes
                          options={options}
                          value={selectedOptions}
                          onChange={onChange}
                          ref={calMultiRef}
                          setState={setSelectedOptions}
                          getDropdownButtonLabel={getDropdownButtonLabel}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                    <select
                      className="session_dropdown"
                      id="session-dropdown"
                      name="coach_id"
                      value={session.coach_id}
                      onChange={onSessionChange}
                      disabled={session.type=="LocalSession" || session.type=="recurring_session"}
                    >
                      <option value="">--AVAILABLE--</option>
                      {coaches?.map(({ id, full_name }) => (
                        <option key={id} value={id}>
                          {full_name}
                        </option>
                      ))}
                    </select>
                    </>
                  )}
                </div>
                {store == "rstv" ? (
                  <>
                    <div>
                      <select
                        id="moderator"
                        name="moderator_id"
                        value={session.moderator_id}
                        onChange={onSessionChange}
                      >
                        <option value="">Select Moderator</option>
                        {coachs.map(({ id, full_name }) => (
                          <option key={id} value={id}>
                            {full_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <select
                        className="appointment-dropdown"
                        name="topic_id"
                        value={session.topic_id}
                        onChange={onSessionChange}
                      >
                        <option value="">-Topic-</option>
                        {/* {props.rstvResponse.length > 0 &&
                          props.rstvResponse.map((rstv) => (
                            <option key={rstv.id} value={rstv.id}>
                              {rstv.title}
                            </option>
                          ))} */}

                        {rstvTopics.length > 0 &&
                          rstvTopics.map((rstv) => (
                            <option key={rstv.id} value={rstv.id}>
                              {rstv.title.length > 50 ? rstv.title.substring(0,50) : rstv.title}
                            </option>
                        ))}
                      </select>
                    </div>
                  </>
                ) : session.type == "recurring_session" ? (
                  <>
                    <div>
                      <span>(optional)</span>
                      <select
                        name="recurring_external_village_id"
                        value={session.recurring_external_village_id ? session.recurring_external_village_id : ""}
                        onChange={onSessionChange}
                        disabled={!fieldStyle()?.village}
                      >
                        <option value="">Select a Village</option>
                        {villages.length > 0 &&
                          villages.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div>
                      <span>Number of Seats</span>
                      <select
                        name="number_of_seats"
                        onChange={onSessionChange}
                        value={session.number_of_seats ? session.number_of_seats : ""}
                        disabled={!fieldStyle()?.seat}
                      >
                        <option value="">Select</option>
                        {_.range(1, 11).map((num) => (
                          <option key={num} value={num}>
                            {num}
                          </option>
                        ))}
                      </select>
                    </div>                    
                  </>
                ) : (
                  <>
                    <div>
                      <span>(optional)</span>
                      <select
                        name="external_village_id"
                        value={session.external_village_id ? session.external_village_id : ""}
                        onChange={onSessionChange}
                        disabled={!fieldStyle()?.village}
                      >
                        <option value="">Select a Village</option>
                        {villages.length > 0 &&
                          villages.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div>
                      <span>Number of Seats</span>
                      <select
                        name="number_of_seats"
                        onChange={onSessionChange}
                        value={session.number_of_seats ? session.number_of_seats : ""}
                        disabled={!fieldStyle()?.seat}
                      >
                        {_.range(1, 11).map((num) => (
                          <option key={num} value={num}>
                            {num}
                          </option>
                        ))}
                      </select>
                    </div>                                  
                  </>
                )}
              </div>
              <div className="right-section">
                <div style={{ float: "left" }} className="margin-right-label">
                  <span className="margin-right-label">Wildcard</span>
                  <input
                    className="wildcard-input"
                    name="wildcard"
                    type="checkbox"
                    checked={session.wildcard ? "checked" : ""}
                    onChange={onSessionChange}
                    disabled={(session.learners_signed_up > 0 && session.learner_details.attendance != []) ? true : !fieldStyle()?.wildcard}
                  />
                </div>
                {session.type != "ExtraSession" && (
                  <div style={{ float: "left" }} className="margin-right-label">
                    <span className="margin-right-label">Recurring</span>
                    <input
                      name="is_recurring"
                      type="checkbox"
                      checked={session.is_recurring && !checkRecurring.disable_recurring ? "checked" : ""}
                      onChange={onSessionChange}
                      disabled={session.disable_recurring || checkRecurring.disable_recurring} // checkRecurring.disable_recurring}
                    />
                  </div>
                )}
                <div style={formStyle().is_recurring} className="clear-fix">
                
                  <span className="margin-right-label">Recurring Frequency</span>
                  <select
                    value={session.recurring_frequency ? session.recurring_frequency : 1}
                    name="recurring_frequency"
                    onChange={onSessionChange}
                    disabled={true}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </select>
                </div>
                <div style={formStyle().is_recurring}>
                  <div className="calendar-section">
                    <span className="end-at margin-right-label" style={{ float: "left" }}>
                      End at{" "}
                    </span>
                    <span>
                      <DatePicker
                        selected={
                          moment(session.recurring_end_date, "yyyy-MM-DD").isValid()
                            ? moment.utc(session.recurring_end_date).toDate() //.format(""yyyy-MM-DD"")
                            : null
                        }
                        minDate={moment(settings.sessionDate).toDate()}
                        id="end_recurring_at"
                        className={{ border: "1px solid #444", width: "160px", height: "80%" }}
                        name="recurring_end_date"
                        onChange={(date) => {
                          setSession({
                            ...session,
                            recurring_end_date: moment(date, "MM/DD/yyyy").format("yyyy-MM-DD"), //date, //moment(date, "MM/DD/YYYY").format("yyyy-MM-DD "),
                          });
                        }}
                        // onChange={date => setStartDate(date)}
                      />
                    </span>
                  </div>
                </div>
                {session.type != "ExtraSession" && (
                  <div style={{ float: "left" }}>
                    <span className="margin-right-label">Confirmed</span>
                    <input
                      type="checkbox"
                      value="true"
                      checked={session.teacher_confirmed ? "checked" : ""}
                      onChange={onSessionChange}
                    />
                  </div>
                )}
                <div className="unit-lesson-section clear-fix" style={{ display: store != "rstv" ? "block" : "none" }}>
                  <div>
                    <span>Unit</span>
                    <select
                      name="unit"
                      onChange={onSessionChange}
                      value={session.language_identifier.includes("FB") ? "--" : session.unit}
                      disabled={(session.wildcard || session.type == "recurring_session") ? true : !fieldStyle()?.unit}
                    >
                      <option value="--">-Unit-</option>
                      {_.range(1, 21).map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <span>Lesson</span>
                    <select
                      name="lesson"
                      onChange={onSessionChange}
                      value={session.language_identifier.includes("FB") ? "--" : session.lesson}
                      disabled={(session.wildcard || session.type == "recurring_session") ? true : !fieldStyle()?.lesson}
                    >
                      <option value="--">-Lesson-</option>
                      <option value="2">2</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                </div>
                {/* {store != "rstv" && <div className="max-text bold-font">Max Level:5 Unit:4 Lesson:4</div>} */}
                {store == "totale" && session.type != "ExtraSession" &&
                  <div className="max-unit-lesson">{intimation}</div>
                }
              </div>
            </div>
            <div className="footer">
              <input
                className="footer-button"
                type="button"
                value="SAVE"
                onClick={() => {
                  if (
                    (props.sessionDetail.is_recurring != 1 && session.is_recurring) ||
                    props.sessionDetail.is_recurring
                  )
                  {
                    setCancelModalOpen(true);
                  } else {
                    onSessionSubmit();
                  }
                }}
              />
            </div>
          </div>
          {notes && (
              <div
                className={clsx(classes.footer, classes.bold_font)}
                style={{ maxWidth: "475px" }}
              >
                Note: {notes}
              </div>
          )}
          {/* {getNotes && <div className="footer bold-font">Note: {getNotes.join(". ")}.</div>} */}
        </div>
      </div>
      <RecurringSessionModal
        modalOpen={cancelModalOpen}
        setModalOpen={setCancelModalOpen}
        onSessionCancel={onSessionSubmit}
      />
    </>
  );
}

EditShiftModal.defaultProps = {
  sessionDetail: {},
};

EditShiftModal.propTypes = {
  settings: propTypes.object,
  sessionDetail: propTypes.object.isRequired,
  sessionDetails: propTypes.object,
  rstvResponse: propTypes.array,
  masterFilter: propTypes.object,
  setSettings: propTypes.func,
  openModal: propTypes.bool.isRequired,
  setOpenModal: propTypes.func,
  setSessionDetails: propTypes.func,
  actions: propTypes.exact({
    updateSession: propTypes.func,
    getSessionDetails: propTypes.func,
    getRstvfunction: propTypes.func,
    clearErrors: propTypes.func,
  }),
};

const mapStateToProps = (state) => ({
  coachProfile: state.coachProfile,
  rstvResponse: state.rstvStore.rstvResponse,
  sessionDetails: state.masterSchedulerShiftDetail,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      updateSession: bindActionCreators(masterShedulerAction.updateSession, dispatch),
      getSessionDetails: bindActionCreators(masterShedulerAction.getShiftSessionDetails, dispatch),
      getRstvfunction: bindActionCreators(RstvAction.getRstv, dispatch),
      clearErrors: bindActionCreators(masterShedulerAction.clearErrors, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditShiftModal);
