import { AccountAPI } from "./apiUtils";

// GET:  http://localhost:3000/api/studio-team?on_duty=0,1
// Note: 0, 1 for filter if you send 0 will bring on_duty = 0 if you send both will fetch all the user
export const getStudioTeam = async (data) => await AccountAPI.get("/studio-team", { params: { on_duty: data } });

// Update Studiod team:
// PATCH: http://localhost:3000/api/studio-team
// {
// "id":8,
// "on_duty": 0,
// "available_start": "10:00:00",
// "Available_end":"11:00:00"
// }
export const updateStudioTeam = async (data) => await AccountAPI.patch("/studio-team", data);

// Update global settings:
// PATCH: http://localhost:3000/api/global-settings
// {
// "attribute_name":"on_duty_text_message",
// "attribute_value": "lister1232"
// }
export const globalSettings = async (data) => await AccountAPI.patch("/global-settings", data);
