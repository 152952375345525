import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./SessionDetail.css";
import { getCoachNames } from "../../apis/CoachProfileApi";
import { fetchCoachSubstitutionApi } from "../../apis/SubstitutionApi";
import { sortCoachNamesBy } from "../../utils/languageUtils";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as coachShedulerAction from "../../redux/actions/CoachShedulerAction";

function AssignSubstitute(props) {
  const { settings, setSettings, sessionInfo } = props;

  const [filter, setFilter] = useState({
    filter_selected_coach_value: "",
    from_cs: true,
    assigned_coach: sessionInfo.coach_id,
    reason: "",
    coach_session_id: props.sessionInfo.id,
    scheduled_session_id: "",
    scheduled_eschool_session_id: ""
  });

  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState(false);

  // useEffect(() => {
    // getCoachNames("Coach").then((res) => {
    //   setCoaches(res.data);
    // });
  // }, [props.sessionInfo]);
  useEffect(() => {
    if(settings.displayAssignSubstitute){
      if (props.sessionInfo.id != undefined && props.sessionInfo.type != "Appointment")
      {
        setLoading(true); 
        fetchCoachSubstitutionApi(props.sessionInfo.id).then((res) => {
          // Check for conversational practice session -- if so filter on coaches that are qualified
          var coachs = res.data;
          if (props.sessionInfo.topic_restrictions === 'cp_only' || props.sessionInfo.topic_restrictions === 'all_topics_plus_cp') {
            coachs = coachs.filter(coach => coach.conv_pract === 1);
          }
          setCoaches(coachs);
          setLoading(false);
        });
      }
    } 
  }, [settings.displayAssignSubstitute])

  function onFilterChange(e) {
    const { name, value } = e.target;
    if (name == 'filter_selected_coach_value')
    {
      let parsedValue = JSON.parse(value);
      setFilter({...filter, ["filter_selected_coach_value"]: parsedValue.coach_id, ["scheduled_session_id"]: parsedValue.scheduled_session_id ? parsedValue.scheduled_session_id : "", ["scheduled_eschool_session_id"]: parsedValue.scheduled_eschool_session_id ? parsedValue.scheduled_eschool_session_id : "" })
    }
    else
    {
      setFilter({ ...filter, [name]: value });
    }
  }

  function textChange(e){
    const { name, value } = e.target;
    setText(value);
  }

  function btnCancelClicked(e) {
    e.preventDefault();
    setFilter({
      filter_selected_coach_value: "",
      from_cs: true,
      assigned_coach: sessionInfo.coach_id,
      reason: "",
      coach_session_id: props.sessionInfo.id,
      scheduled_session_id: "",
      scheduled_eschool_session_id: ""

    });

    setSettings((settings) => ({ ...settings, ["displayAssignSubstitute"]: false }));
    document.getElementById("assign-other-reasons").value = "";
  }

  function btnAssignClicked(e) {
    setLoading(true);
    if (filter.filter_selected_coach_value == "") {
      alert("Please Select a Coach.");
      setLoading(false);
      return;
    }

    if (parseInt(filter.filter_selected_coach_value) == sessionInfo.coach_id || parseInt(filter.filter_selected_coach_value) == sessionInfo.moderator_id)
    {
      alert("You can't select existing Coach/Moderator for Re Assigning");
      setLoading(false);
      return;
    }

    if (filter.reason == "") {
      alert("Please Select a Reason");
      setLoading(false);
      return;
    } else if(filter.reason == "Other"){
      if(text == ""){
        setLoading(false);
        alert("Please provide a reason");
        return;
      }
    }

    props.actions
      .assignSubstituteCoach(
        {
          //filter_selected_coach_value: sessionInfo.coach_id,
          filter_selected_coach_value: props.coachFilter.coach_id,
          from_cs: true,
          assigned_coach: parseInt(filter.filter_selected_coach_value),
          reason: filter.reason == "Other" ? text : filter.reason,
          coach_session_id: sessionInfo.id,
          scheduled_session_id: filter.scheduled_session_id,
          scheduled_eschool_session_id: filter.scheduled_eschool_session_id
        },
        props.coachFilter
      )
      .then((response) => {
        setLoading(false);
        setSettings((settings) => ({ ...settings, ["displayAssignSubstitute"]: !settings.displayAssignSubstitute }));
        if(response != false)
        {
          toast.success("Coach has been reassigned successfully");
        }
        //toast.success(response.data);
        setFilter({
          filter_selected_coach_value: "",
          from_cs: true,
          assigned_coach: sessionInfo.coach_id,
          reason: "",
          coach_session_id: props.sessionInfo.id,
        });
        props.onAssignSuccess(!settings.displayAssignSubstitute);
        document.getElementById("assign-other-reasons").value = "";
        document.getElementById("filter-language").value="";
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setFilter({
          filter_selected_coach_value: "",
          from_cs: true,
          assigned_coach: sessionInfo.coach_id,
          reason: "",
          coach_session_id: props.sessionInfo.id,
          scheduled_session_id: "",
          scheduled_eschool_session_id: ""
        });
        toast.error("Failed to reassign Substitute");
        document.getElementById("assign-other-reasons").value = "";
      });
  }

  const coachDetail = (coach) => {
    try{
      if (sessionInfo.rstv_guid != null)
      {
        return '{"coach_id":' + coach.id + '}'
      }
      else
      {
        return '{"coach_id":' + coach.id + ', "scheduled_session_id": ' + JSON.stringify(coach.scheduled_session_id) + ', "scheduled_eschool_session_id":' + JSON.stringify(coach.scheduled_eschool_session_id) + '}'
      }
    } catch (e) {
      return ""
    }
  }

  return (
    <>
      <div className="blockOverlay" style={{ display: loading ? "block" : "none" }}></div>
      <div className="blockMsg" style={{ display: loading ? "block" : "none" }}>
        <h1>Please wait...</h1>
      </div>
      <div
        className="modal coach-schedule-session-details-modal"
        id="coach-schedule-assign-subsitute"
        style={{
          display: settings.displayAssignSubstitute ? "block" : "none",
          left: settings.xAxis + "px",
          top: settings.yAxis + "px",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal_header">
              <h4 className="modal-title">ASSIGN SUBSTITUTE</h4>
              <a
                className="close-icon"
                title="Close"
                aria-label="Close"
                role="button"
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  setFilter({
                    filter_selected_coach_value: "",
                    from_cs: true,
                    assigned_coach: sessionInfo.coach_id,
                    reason: "",
                    coach_session_id: props.sessionInfo.id,
                    scheduled_session_id: "",
                    scheduled_eschool_session_id: ""
                  });
                  setSettings((settings) => ({ ...settings, ["displayAssignSubstitute"]: false }));
                  document.getElementById("assign-other-reasons").value = "";
                }}
              >
                <span>×</span>
              </a>
            </div>
            <div className="modal-body">
              <div>
                <select
                  name="filter_selected_coach_value"
                  className="coach"
                  onChange={onFilterChange}
                  // value={filter.filter_selected_coach_value}
                  //value={filter.filter_selected_coach_value}
                  value={filter.filter_selected_coach_value != "" ? coachDetail(coaches.find((coach) => coach.id == filter.filter_selected_coach_value)) : ""}
                  disabled={filter.language == ""}
                  style={{ width: "150px" }}
                >
                  <option value="">Select a Coach</option>
                  {sortCoachNamesBy(coaches, "full_name").map((coach) => {
                   if (sessionInfo.rstv_guid != null)
                   {
                     return( 
                     <option key={coach.id} value={coachDetail(coach)} style={{backgroundColor: coach?.have_session ? "#ffa500" : "" }}>
                     {coach.full_name}
                     </option>
                     )
                   }
                   else
                   {
                     return(
                     <option key={coach.id} value={coachDetail(coach)} style={{backgroundColor: coach?.have_session ? "#ffa500" : "" }}>
                     {coach.full_name}
                     </option>
                     )
                   }
                  })}
                </select>
              </div>
              <div>
                Please select the reason that best explains why a substitute is being assigned for this session.
              </div>
              <div>
                <select name="reason" id="AssignReasonList" value={filter.reason} onChange={onFilterChange}>
                  <option value="">--REASON--</option>
                  <option value="Sickness">Sickness</option>
                  <option value="System Issue">System Issue</option>
                  <option value="Personal Emergency">Personal Emergency</option>
                  <option value="Internet outage">Internet outage</option>
                  <option value="Electricity outage">Electricity outage</option>
                  <option value="No-show">No-show</option>
                  <option value="Schedule glitch">Schedule glitch</option>
                  <option value="Personnel change">Personnel change</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div style={{ display: filter.reason == "Other" ? "block" : "none" }}>
                <textarea cols="55" id="assign-other-reasons" rows="3" title="Other reasons" onChange={textChange}></textarea>
              </div>
            </div>
            <div className="footer">
              <input
                type="button"
                className="button medium_button"
                value="Assign"
                onClick={(e) => {
                  btnAssignClicked(e);
                }}
              />
              <input
                type="button"
                className="button medium_button"
                value="Cancel"
                onClick={(e) => {
                  btnCancelClicked(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      assignSubstituteCoach: bindActionCreators(coachShedulerAction.assignSubstituteCoach, dispatch),
      getAvailableCoaches: bindActionCreators(coachShedulerAction.getAvailableCoaches, dispatch)
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignSubstitute);
