import React, {
  useState,
  useEffect,
  useMemo,
  useReducer,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";
import propTypes from "prop-types";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "underscore";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";

import * as coachShedulerAction from "../../redux/actions/CoachShedulerAction";
import * as masterSchedulerApi from "../../apis/MasterSchedulerApi";
import { getMaxLevelUnit } from "../../apis/CoachSchedulerApi";
import {
  getLanguageByIdentifier,
  sortAppointmentNamesBy,
  sortLanguagesBy,
} from "../../utils/languageUtils";
import { useVillage, useAppointmentTypes, useModerator } from "../../hooks";
import * as RstvAction from "../../redux/actions/RSTVAction";
import CreateSlotObject from "../../business_layer/model/CreateSlotObject";
import { getQualifiedLanguages } from "../../apis/CoachProfileApi";
import { getCoachRstvTopic } from "../../apis/CoachSchedulerApi";
import { topicTypes } from "../../utils/sessionUtils"

import "react-datepicker/dist/react-datepicker.css";
import "./CreateSessionModal.css";

function languageReducer(state = "", action) {
  console.log("Languageee=======>" + action.language);

  for (let language of ["totale", "solo", "fb", "yp", "rstv", "cp"]) {
    if (action.language.toLowerCase().includes(language.toLowerCase()))
      return language;
  }

  return "totale";
}

function extractIdentifier(language) {
  let identifier = language.split("-");
  return identifier.length == 2
    ? identifier[0].toLowerCase()
    : identifier.length == 3
    ? "fbsolo"
    : "totale";
}

function getRecurring(data) {
  const {
    is_recurring,
    recurring_end_date,
    recurring_frequency,
    ...rest
  } = data;
  if (is_recurring == false) return rest;
  else {
    const endDate = moment(recurring_end_date, "YYYY-MM-DD").isValid()
      ? { recurring_end_date }
      : {};
    return {
      ...data,
      ...endDate,
      recurring_frequency,
      recurring_start_date: data.session_start_time,
    };
  }
}

function sessionPayload(action, appointmentTypes = []) {
  const recurring = getRecurring(action.payload);

  if (action.payload.type === "session") {
    const {
      teacher_confirmed,
      appointment_type_id,
      details,
      ...restSession
    } = recurring;
    if (["totale", "solo", "fb", "yp", "cp"].includes(action.type)) {
      const { moderator_id, ...remain } = restSession;
      return remain;
    }
    if (action.type === "rstv") {
      const { number_of_seats, lesson, level, unit, ...remain } = restSession;
      return remain;
    }
  }

  if (action.payload.type === "appointment") {
    const {
      teacher_confirmed,
      number_of_seats,
      lesson,
      level,
      unit,
      language_identifier,
      moderator_id,
      topic_id,
      appointment_type_id,
      ...restAppointments
    } = recurring;
    let label;
    if (appointment_type_id === "" || appointment_type_id === "all") {
      label = "appointment";
    } else {
      label = appointmentTypes.find((type) => type.id == appointment_type_id)
        .title;
    }
    return { ...restAppointments, appointment_type_id, label };
  }
}

function CreateSessionModal({ ...props }) {
  const createObject = new CreateSlotObject();
  const [session, setSession] = useState(createObject);
  const [languages, setLanguages] = useState([]);
  const [intimation, setIntimation] = useState(null);
  const appointmentTypes = useAppointmentTypes();
  const { removeModerators, setModeratorParams } = useModerator();
  const villages = useVillage();
  const { pathname } = useLocation();
  const [store, dispatch] = useReducer(languageReducer, "totale");
  const { settings, setSettings, SessionDetails, maxUnitInfo } = props;
  const [double, setDouble] = useState(false);
  const [checkRecurring, setCheckRecurring] = useState({
    disable_recurring: false,
  });
  const [rstvTopics, setRstvTopics] = useState([]);
  const [filteredLanguage, setFilteredLanguage] = useState([]);
  const { bulkSessions, setBulkSessions, bulkSessionsObject } = props;
  useEffect(() => {
    if (
      typeof settings?.dateStr != "undefined" &&
      session.language_identifier != "all" &&
      store == "rstv"
    )
      setModeratorParams({
        language: session.language_identifier,
        session_start_time: moment(settings.dateStr)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
      });
  }, [session.language_identifier, settings.dateStr]);

  useEffect(()=>{
    dragElement(document.getElementById("bulkSessionDraggable"));
  },[settings.displayCreate])

  const getNotes = useMemo(() => {
    const notes = [];
    // if (
    //   (settings.fromDetails != true
    //     ? checkRecurring.disable_recurring
    //     : false) ||
    //   (SessionDetails.cancelled == "true" &&
    //     settings.fromDetails == true &&
    //     settings.sessionDisableRecurring == true)
    // )
    if (
      (settings.fromDetails != true
        ? checkRecurring.disable_recurring
        : false) ||
      (SessionDetails.cancelled == "true" &&
        settings.fromDetails == true &&
        checkRecurring.disable_recurring == true)
    )
      notes.push(
        "This coach is already scheduled to have a recurring Session/Appointment starting on " +
          "the following week. You can only schedule a one-off Session/Appointment dialog at this time"
      );
    return notes.length <= 0 ? false : notes;
  }, [checkRecurring, settings.dateStr, settings.fromDetails, SessionDetails]);

  const getInputDisableConfig = useMemo(() => {
    const disable = {
      village: false,
      seat: false,
      unit: false,
      lesson: false,
      topic: false,
      moderator: false,
    };
    return {
      cp: disable,
      yp: disable,
      www: disable,
      fb: disable,
      solo: disable,
      totale: {
        ...disable,
        village: true,
        seat: true,
        unit: true,
        lesson: true,
      },
      //   fb: { ...disable, seat: false },
      rstv: { ...disable, topic: true, moderator: true },
    };
  });

  useEffect(() => {
    props.coachProfile.length > 0 &&
      getQualifiedLanguages(props.coachProfile[0][0].id).then((response) => {
        return setLanguages(response.data);
      });

    //props.rstvResponse.length == 0 && props.actions.getRstvfunction();
    console.log("Date format check before utc conversion" + settings.dateStr);
    console.log(
      "Date format check" +
        moment(settings.dateStr)
          .utc()
          .format("yyyy-MM-DD HH:mm:ss")
    );
    if (
      props.coachProfile.length > 0 &&
      typeof settings?.dateStr != "undefined" &&
      settings?.dateStr != ""
    ) {
      coachShedulerAction
        .checkRecurrings({
          coach_id: props.coachProfile[0][0].id,
          // session_start_time: moment
          //   .utc(settings.dateStr)
          //   .format("yyyy-MM-DD HH:mm:ss"),
          session_start_time: moment(settings.dateStr)
            .utc()
            .format("yyyy-MM-DD HH:mm:ss"),
          day_index: moment(props.settings.dateStr)
            .utc()
            .day(),
        })
        .then((result) => {
          setCheckRecurring(result);
        });
    }
  }, [props.coachProfile, settings.dateStr]);

  //CSP-2584
  function checkRecurringModerator(moderatorId, startTime){
    // if (
    //   moderatorId &&
    //   typeof settings?.dateStr != "undefined" &&
    //   settings?.dateStr != "" 
    // ) {
    return new Promise((resolve, reject) => {
      if (moderatorId){
      coachShedulerAction
      .checkRecurrings({
        coach_id: moderatorId,
        session_start_time: moment(startTime).utc()
        .format("yyyy-MM-DD HH:mm:ss"),
        day_index: moment(startTime).utc().day()
      })
      .then((result) => {
        resolve(result)
        // setBulkSessions({
        //   ...bulkSessions,
        //   sessions: [
        //     ...bulkSessions.sessions,
        //     {
        //       session_start_time: start_time,
        //       day_index: moment.utc(slotTime).day(),
        //       is_recurring: false,
        //       moderator_id: null,
        //       recurring_start_date: "",
        //       recurring_end_date: "",
        //       recurring_frequency: 1,
        //       disable_recurring: result.disable_recurring,
        //       optionList: [],
        //     },
        //   ],
        // });

      // if (checkRecurring?.disable_recurring || result.disable_recurring)
      // {
      //   setCheckRecurring({...result, disable_recurring: true});
      // }
      // else
      // {
      //   setCheckRecurring({...result, disable_recurring: false});
      // }

      }).catch((err) => {
        reject(err);
      });
    }
    })
  }

  const hasCPQualificationsForLang = useMemo(() => props.coachFilter.CPLanguages?.includes(props.bulkSessions.language_identifier), 
    [props.bulkSessions.language_identifier, props.coachFilter.CPLanguages, session.topic_restrictions]);

  useEffect(() => {
    setBulkSessions({ ...bulkSessions, topic_restrictions: session.topic_restrictions})
  }, [])

  useEffect(() => {
    console.log("Entered the filteredLang", languages);
    let filterlang = [];
    if (
      languages.length > 0 &&
      moment(props.settings.dateStr).format("mm") != "30"
    ) {
      languages.map((language) => {
        if (getLanguageByIdentifier(language.identifier) != "--none--") {
          filterlang.push({
            language_code: language.identifier,
            language_name: getLanguageByIdentifier(language.identifier),
          });
        }
      });
    }
    if (
      languages.length > 0 &&
      moment(props.settings.dateStr).format("mm") == "30"
    ) {
      languages.map((language) => {
        if (
          getLanguageByIdentifier(language.identifier) != "--none--" &&
          extractIdentifier(language.identifier) != "fb"
        ) {
          filterlang.push({
            language_code: language.identifier,
            language_name: getLanguageByIdentifier(language.identifier),
          });
        }
      });
    }
    setFilteredLanguage(filterlang);
  }, [languages]);

  useEffect(() => {
    if (session.topic_restrictions != bulkSessions.topic_restrictions) {
      setBulkSessions({ ...bulkSessions, topic_restrictions: session.topic_restrictions })
    }
  }), [session.topic_restrictions]

  // Set session.topic_restriction to "none" if hasCPQualificationsForLang is not qualified
  useEffect(() => {
    if (!hasCPQualificationsForLang) {
      setSession({...session, topic_restrictions: "none"})
    } else {
      setSession({...session, topic_restrictions: "all_topics_plus_cp"})
    }
  }, [hasCPQualificationsForLang, props.bulkSession])


  function onSessionChange(e) {
 
    const target = e.target;
    console.log("target" + e.target.value);
    const value = target.type === "checkbox" ? target.checked : target.value;
    let additional = {};
    if (target.name === "language_identifier") {
      dispatch({ language: value });
      if (!["cp", "yp", "fb", "rstv"].includes(value.toLowerCase())) {
        const field = languages.find((lang) => lang.identifier == value);

        getMaxLevelUnit({
          coach_id:
            props.coachProfile.length > 0 ? props.coachProfile[0][0].id : "",
          language_id: value,
        })
          .then((response) => {
            //let int = `Max Level:5 Unit:${field?.max_unit || 0} Lesson:4`;
            let int = `Max Level:${response.data.maxLevel || 0} Unit:${response
              .data.maxUnit || 0} Lesson:4`;
            setIntimation(int);
          })
          .catch((err) => {
            console.log("Error occured during MaxUnitLevel Fetch :" + err);
            setIntimation(null);
          });

        additional.number_of_seats = 5;
      } else setIntimation(null);

      if (session.type != "appointment") {
        {
          value.toLowerCase().includes("rstv") &&
            getCoachRstvTopic(value).then((response) => {
              setRstvTopics(response.data);
            });
        }
      }

      if (value.toLowerCase().includes("cp")) additional.number_of_seats = 0;
      if (value.toLowerCase().includes("yp")) additional.number_of_seats = 4;
      if (value.toLowerCase().includes("fb")) additional.number_of_seats = 6;
      if (value.toLowerCase().includes("solo")) additional.number_of_seats = 1;
    }
    //   setBulkSessions({ ...bul, [target.name]: value, ...additional });
  }

  function resetBulkSelection(){
    props.setSchedules(props.schedules.filter((slot) =>{ return slot.slotType != "on-select" }));
    props.setBulkSessions(bulkSessionsObject);
  }
 
  function onCommonFieldChange(e) {
    const target = e.target;
    console.log("target" + e.target.value);
    let additional = {};
    const value = target.type === "checkbox" ? target.checked : target.value;

    if(target.name == "type") {
      resetBulkSelection()
    }

    if (target.name === "language_identifier") {
      resetBulkSelection()
      dispatch({ language: value });
      props.setSelectedLanguage(value);
      if (!["cp", "yp", "fb", "rstv"].includes(value.toLowerCase())) {
        const field = languages.find((lang) => lang.identifier == value);

        getMaxLevelUnit({
          coach_id:
            props.coachProfile.length > 0 ? props.coachProfile[0][0].id : "",
          language_id: value,
        })
          .then((response) => {
            //let int = `Max Level:5 Unit:${field?.max_unit || 0} Lesson:4`;
            let int = `Max Level:${response.data.maxLevel || 0} Unit:${response
              .data.maxUnit || 0} Lesson:4`;
            setIntimation(int);
          })
          .catch((err) => {
            console.log("Error occured during MaxUnitLevel Fetch :" + err);
            setIntimation(null);
          });

        additional.number_of_seats = 5;
      } else setIntimation(null);

      if (session.type != "appointment") {
        {
          value.toLowerCase().includes("rstv") &&
            getCoachRstvTopic(value).then((response) => {
              setRstvTopics(response.data);
            });
        }
      }

      if (value.toLowerCase().includes("cp")) additional.number_of_seats = 0;
      if (value.toLowerCase().includes("yp")) additional.number_of_seats = 4;
      if (value.toLowerCase().includes("fb")) additional.number_of_seats = 6;
      if (value.toLowerCase().includes("solo")) additional.number_of_seats = 1;     
    }  

    setBulkSessions({ ...bulkSessions, sessions: target.name === "language_identifier" ? [] : bulkSessions.sessions, [target.name]: value, ...additional });
  }

  function modifiedSession(start_time, name, value) {
    bulkSessions.sessions.map((session) => {
      if (session.session_start_time == start_time) {
        if (name == "recurring_end_date") {
          session[name] = moment(value).format("yyyy-MM-DD");
        } else {
          if (name == "disable_recurring") {
            // if(value)
              session[name] = value;
          }
          else
          {
            session[name] = value;
          }
        }
      }
    });
    return bulkSessions.sessions;
  }

  function onIndividualFieldChange(e) {
    const target = e.target;
    const startTime = target.getAttribute("data-start-time");
    console.log("target" + target.value);
    const value = target.type === "checkbox" ? target.checked : target.value;
  
    if(target.name == "moderator_id")
    {
      checkRecurringModerator(target.value, startTime).then((response) => {
          if(response)
          {
            bulkSet(startTime, "disable_recurring", response.disable_recurring)
          }
      })
      //To save moderator ID value
      bulkSet(startTime, target.name, value)
    }
    else{
      bulkSet(startTime, target.name, value)
    // setBulkSessions({
    //   ...bulkSessions,
    //   sessions: modifiedSession(startTime, target.name, value),
    // });
    }
    console.log("Individual Field Change" + JSON.stringify(bulkSessions));
  }

  function bulkSet(startTime, targetName, targetValue)
  {
    setBulkSessions({
      ...bulkSessions,
      sessions: modifiedSession(startTime, targetName, targetValue),
    });
  }

  const promt = (msg) => {
    setDouble(false);
    alert(msg);
    return false;
  };

  function slotSelectCheck() {
    if(bulkSessions.sessions?.length == 0){
      alert("Please select atleast one slot");
      setDouble(false);
      return false;
    }
    return true
  }

  function validate(payload) {
    console.log("Session Type========>" + bulkSessions.type);
    if (bulkSessions.type != "appointment") {
      if (store == "rstv") {
        for (const required of [
          "language_identifier",
          "topic_id",
        ]) {
          if (
            payload[required] == "all" ||
            payload[required] == "" ||
            payload[required] == null
          )
            return promt(`Please select a ${required.split("_")[0]}`);
        }

       if (!slotSelectCheck()) return false

        for(const rsSession of payload?.sessions) {
          if(!rsSession.moderator_id)
          {
           alert(`Please select a moderator for the session ${moment(rsSession.session_start_time).format("ddd MM/DD/YYYY hh:mmA")}`);
           return false;
          }

          // if(getLanguageByIdentifier(bulkSessions.language_identifier) != "--none--" && extractIdentifier(bulkSessions.language_identifier) == "fb") {
          //   if(moment(rsSession.session_start_time).format("mm") == "30"){
          //     alert(`FB Group session is not allowed in this slot: ${moment(rsSession.session_start_time).format("ddd MM/DD/YYYY hh:mmA")}`);
          //     return false;
          //   }
          // }


        }
        // if (payload.coach_id == payload.moderator_id)
        //   return promt("Coach and Moderator should be different");
      } else {
        for (const required of ["language_identifier"]) {
          if (payload[required] == "all" || payload[required] == null)
            return promt(`Please select a ${required.split("_")[0]}`);
        }
        if (!slotSelectCheck()) return false
      }
    } else if (bulkSessions.type == "appointment") {
      if (!slotSelectCheck()) return false
      if (payload.label == "appointment") {
        return promt(`Please select an appointment type to continue.`);
      }
    }
    return true;
  }

  function search(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].identifier === nameKey) {
        return myArray[i];
      }
    }
  }

  function onSessionSubmit() {
    console.log("===============>" + JSON.stringify(bulkSessions));
   
    if (checkRecurring.local_session) {
      alert("Coach already has a unpushed session in this slot");
      return;
    }
    
    //const {optionList, disable_recurring, ...payloadFields} = bulkSessions;
    const payload = sessionPayload(
      { type: store, payload: bulkSessions },
      appointmentTypes
    );
    if (!validate(payload)) return;
    setDouble(true);

    // const start_time =
    //   store == "fb"
    //     ? moment(props.settings.dateStr).format("mm") == "30"
    //       ? moment(props.settings.dateStr).subtract(30, "minutes")
    //       : props.settings.dateStr
    //     : props.settings.dateStr;

    //     console.log("check unit====================" + checkMaxUnitTreshold(payload.language_identifier, payload.unit));
    // if(checkMaxUnitTreshold(payload.language_identifier, payload.unit))
    // {
    //   alert("Please select a lesser unit")
    // }

    // To check unit treshold of the coach for the selected langauage

    // if (maxUnitInfo.length != 0) {
    //   maxUnitInfo.map((unitInfo) => {
    //     if (payload.language_identifier == unitInfo.identifier) {
    //       if (payload.unit >= unitInfo.max_unit) {
    //         alert("Please select a lesser unit");
    //         setDouble(false);

    //         return;
    //       }
    //     }
    //   });
    // } else {
    if (store == "totale" && payload.unit != "" && payload.type == "session") {
      if (maxUnitInfo.length != 0) {
        const unitInfo = search(payload.language_identifier, maxUnitInfo);
        if (payload.unit > unitInfo.max_unit) {
          alert("Coach is not qualified to teach the selected unit");
          setDouble(false);
          return;
        }
      }
    }

    props.actions
      .addBulkSessions(
        {
          ...payload,
          language_type: store,
          coach_id:
            props.coachProfile.length > 0 ? props.coachProfile[0][0].id : "",
          sessions: payload.sessions.map((session) => {
            const start_time =
              store == "fb"
                ? moment(session.session_start_time).format("mm") == "30"
                  ? moment(session.session_start_time).subtract(30, "minutes")
                  : session.session_start_time
                : session.session_start_time;

            session.session_start_time = moment(start_time)
              .utc()
              .format("YYYY-MM-DD HH:mm:ss");
            (session.session_end_time = moment(start_time)
              .add(store == "fb" ? 60 : 30, "minutes")
              .utc()
              .format("YYYY-MM-DD HH:mm:ss")),
              (session.teacher_confirmed = true);
            // const startTime = moment(session.session_start_time, "YYYY-MM-DD HH:mm:ss").toISOString();
            // const endTime = moment(session.session_end_time, "YYYY-MM-DD HH:mm:ss").toISOString();
            if (session.is_recurring) {
              session.recurring_start_date = moment(start_time)
                .utc()
                .format("YYYY-MM-DD HH:mm:ss");

              return session;
              // session.recurring_end_date = session.recurring_end_date
              //   ? moment(session.recurring_end_date)
              //       .utc()
              //       .format("YYYY-MM-DD HH:mm:ss")
              //   : "";
              // const day_index = moment.utc(recurring_start_date).day();
            } else {
              const {
                is_recurring,
                recurring_end_date,
                recurring_start_date,
                recurring_frequency,
                ...rest
              } = session;
              return rest;
            }
          }),
          // session_start_time: moment(start_time).format("YYYY-MM-DD HH:mm:ss"),
          // session_end_time: moment(start_time)
          // .add(store == "fb" ? 60 : 30, "minutes")
          // .format("YYYY-MM-DD HH:mm:ss"),
        },
        props.coachFilter
      )
      .then((response) => {
        setDouble(false);
        setBulkSessions(bulkSessionsObject);
        //setSession({...createObject, language_identifier: "all"});
        dispatch({ language: "totale" }); //Throw totale form by default
        if(!response) {
          toast.error("Failed to create...");
        }
        setSettings((settings) => ({
          ...settings,
          ["displayCreate"]: false,
        }));
        document.getElementById("filter-language").value="";
      })
      .catch((err) => {
        setDouble(false);
        // console.log(err);
        // if (err?.message?.error_message) toast.error(err.message.error_message);
        // else if (err?.message?.error) toast.error(err.message.error);
        // else if (err?.message) toast.error(err.message);
        // else if (err) toast.error(err);
        // else toast.error("Failed to create...");
        toast.error("Failed to create...");
        setBulkSessions(bulkSessionsObject);
        //setSession({...createObject, language_identifier: "all"});
        dispatch({ language: "totale" }); //Throw totale form by default
        //
        setSettings((settings) => ({
          ...settings,
          ["displayCreate"]: false,
        }));
      });
  }

  function formStyle(prop = "", commonFields, IndividualFields) {
    const sessionInput = {
      display: commonFields.type == "session" ? prop : "none",
    };
    const sessionLanguageInput = {
      ...(commonFields.language_identifier == "all"
        ? { display: "none" }
        : sessionInput),
    };
    const recurringInput = {
      display: IndividualFields?.is_recurring ? prop : "none",
    };
    const appointmentInput = {
      display: commonFields.type == "appointment" ? prop : "none",
    };

    return {
      language: sessionInput,
      village: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      seats: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      unit: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      level: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      appointment_type: appointmentInput,
      appointment_details: appointmentInput,
      is_recurring: recurringInput,
    };
  }

  const fieldStyle = useCallback(() => {
    return getInputDisableConfig[store];
  }, [store]);

  function dragElement(elmnt) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById(elmnt.id + "Header")) {
      document.getElementById(elmnt.id + "Header").onmousedown = dragMouseDown;
    } else {
      elmnt.onmousedown = dragMouseDown;
    }
    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    }
    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      var winW = document.documentElement.clientWidth || document.body.clientWidth;
      var winH = document.documentElement.clientHeight || document.body.clientHeight;
      var maxX = winW - elmnt.offsetWidth - 1;
      var maxY = winH - elmnt.offsetHeight - 1;
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      if((elmnt.offsetTop - pos2) <= maxY && (elmnt.offsetTop - pos2) >= 0){
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      }
      if((elmnt.offsetLeft - pos1) <= maxX && (elmnt.offsetLeft - pos1) >= 0){
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      }
    }
    function closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  return (
    <>
      <div
        className="blockOverlay"
        style={{ display: double ? "block" : "none" }}
      ></div>
      <div className="blockMsg" style={{ display: double ? "block" : "none" }}>
        <h1>Please wait...</h1>
      </div>
      <div id="bulkSessionDraggable">
        <div
          className="modal bulk-session-modal"
          id="coach-schedule-create-session"
          style={{
            display: settings.displayCreate ? "block" : "none",
            // left: settings.xDistance > 610 ? settings.xAxis - 237 + "px" : settings.xAxis + 240 + "px",
            left: settings.xAxis + "px",
            top: settings.yAxis + "px",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal_header" id="bulkSessionDraggableHeader">
                <h4 className="modal-title">BULK SESSION CREATION</h4>
                <a
                  className="close-icon"
                  title="Close"
                  aria-label="Close"
                  role="button"
                  data-dismiss="modal"
                  id="coach-modal-close"
                  onClick={(e) => {
                    e.preventDefault();
                    setSession(createObject);
                    //setSession({...createObject, language_identifier: "all"});
                    dispatch({ language: "totale" }); //Throw totale form by default
                    setSettings((settings) => ({
                      ...settings,
                      ["displayCreate"]: false,
                    }));
                    props.setBulkSessions({...bulkSessions, sessions: [], language_identifier: "all"});
                    props.setSchedules(props.schedules.filter((slot) =>{ return slot.slotType != "on-select" }));
                  }}
                >
                  <span>×</span>
                </a>
              </div>
              <div className="modal-body">
                <div className="common-section">
                  <div className="left-section">
                    <select
                      className="dropdown session-dropdown"
                      id="session-dropdown"
                      name="type"
                      value={bulkSessions.type}
                      onChange={onCommonFieldChange}
                    >
                      <option value="session">Session</option>
                      <option value="appointment">Appointment</option>
                    </select>
                    <div style={formStyle("", bulkSessions, null).language}>
                      <select
                        className="dropdown"
                        id="language_id"
                        name="language_identifier"
                        value={bulkSessions.language_identifier}
                        style={{ display: "inline-block" }}
                        onChange={onCommonFieldChange}
                      >
                        <option value="all">Select a language</option>
                        {filteredLanguage.length > 0 &&
                          sortLanguagesBy(filteredLanguage, "language_name").map(
                            (lang) => {
                              return (
                                <option
                                  key={lang.language_code}
                                  value={lang.language_code}
                                >
                                  {lang.language_name}
                                </option>
                              );
                            }
                          )}
                      </select>
                    </div>
                    {/* only show conv topics for fb solo. IDs are in the format FB-ITA-Solo */}
                    {bulkSessions.type == "session" &&
                      <div className="topic-restriction">
                        <div>Allowed Topics</div>    
                        <select 
                          name='topic_restrictions' 
                          onChange={e => setSession({ ...session, topic_restrictions: e.target.value})} 
                          disabled={!hasCPQualificationsForLang} 
                          className="dropdown"
                          >
                            {topicTypes.map(({ name, value }) => (
                              <option key={value} value={value} selected={session.topic_restrictions === value}>                        
                                { name }
                              </option>
                            ))}
                          </select>                    
                      </div>
                    }
                    <div>
                      <select
                        className="appointment_type_id"
                        name="appointment_type_id"
                        onChange={onCommonFieldChange}
                        value={bulkSessions.appointment_type_id}
                        style={formStyle("", bulkSessions, null).appointment_type}
                      >
                        <option value="all">Select appointment type</option>
                        {appointmentTypes.length > 0 &&
                          sortAppointmentNamesBy(appointmentTypes, "title").map(
                            ({ id, title }) => (
                              <option key={id} value={id}>
                                {title}
                              </option>
                            )
                          )}
                      </select>
                    </div>
                    <div
                      className="village_dropdown"
                      style={formStyle("", bulkSessions, null).village}
                    >
                      <select
                        className="dropdown village"
                        name="external_village_id"
                        value={bulkSessions.external_village_id}
                        onChange={onCommonFieldChange}
                        disabled={!fieldStyle().village}
                      >
                        <option value="">Select a Village</option>
                        <option value="no-village">No Village</option>
                        {villages.length > 0 &&
                          villages.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div
                      className="appointment-content"
                      style={
                        formStyle("", bulkSessions, null).appointment_details
                      }
                    >
                      <div className="title bold-font">Appointment details:</div>
                      <textarea
                        cols="34"
                        name="details"
                        maxLength="500"
                        className="textarea"
                        value={bulkSessions.details}
                        onChange={onCommonFieldChange}
                        style={{ display: "block", clear: "both" }}
                      ></textarea>
                      <div className="appointment-char">
                        <span>Characters remaining:</span>
                        <span className="char-count">
                          {bulkSessions.details.length
                            ? 500 - bulkSessions.details.length
                            : "500"}
                        </span>
                      </div>
                    </div>
                    {/* <div
                          className="teacher-confirmed-div"
                          style={{ float: "left" }}
                          >
                              <span>Confirmed</span>
                              <span>
                                  <input
                                  // checked={session.teacher_confirmed ? "checked" : ""}
                                  checked="checked"
                                  id="teacher_confirmed"
                                  name="teacher_confirmed"
                                  type="checkbox"
                                  onChange={onSessionChange}
                                  disabled={true}
                                  />
                              </span>
                          </div> */}
                  </div>
                  <div className="right-section">
                    <select
                      className="dropdown appointment_dropdown"
                      style={
                        store != "rstv"
                          ? { display: "none" }
                          : bulkSessions.type === "appointment"
                          ? { display: "none" }
                          : { display: "block" }
                      }
                      name="topic_id"
                      value={bulkSessions.topic_id}
                      onChange={onCommonFieldChange}
                    >
                      <option value="">-Topic-</option>

                      {/* {props.rstvResponse.length > 0 &&
                              props.rstvResponse.map((rstv) => (
                                  <option key={rstv.id} value={rstv.id}>
                                  {rstv.title.length > 50 ? rstv.title.substring(0,50) : rstv.title}
                              </option>
                              ))} */}
                      {rstvTopics.length > 0 &&
                        rstvTopics.map((rstv) => (
                          <option key={rstv.id} value={rstv.id}>
                            {rstv.title.length > 50
                              ? rstv.title.substring(0, 50)
                              : rstv.title}
                          </option>
                        ))}
                    </select>
                    <div style={formStyle("", bulkSessions, null).seats}>
                      <div className="number-of-seats-div">
                        <span>Number of Seats : </span>
                        <span>
                          <select
                            name="number_of_seats"
                            value={bulkSessions.number_of_seats}
                            onChange={onCommonFieldChange}
                            disabled={!fieldStyle().seat}
                          >
                            <option value="">Select</option>
                            {_.range(1, 11).map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                        </span>
                      </div>
                      <div
                        className="level-unit-div"
                        style={formStyle("block", bulkSessions, null).unit}
                      >
                        <select
                          className="level-unit-dropdown"
                          name="unit"
                          value={bulkSessions.unit}
                          onChange={onCommonFieldChange}
                          disabled={!fieldStyle().unit}
                        >
                          <option value="">-Unit-</option>
                          {_.range(1, 21).map((num) => (
                            <option key={num} value={num}>
                              {num}
                            </option>
                          ))}
                        </select>
                        <select
                          className="level-unit-dropdown"
                          name="lesson"
                          value={bulkSessions.lesson}
                          onChange={onCommonFieldChange}
                          disabled={!fieldStyle().lesson}
                        >
                          <option value="">-Lesson-</option>
                          <option value="2">2</option>
                          <option value="4">4</option>
                        </select>
                        <div className="bold-font clear-fix max-text">
                          {intimation || "Max Level:1 Unit:1 Lesson:4"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sessions-recurring-section">
                  {props.bulkSessions?.sessions.map((session, index) => {
                    return (
                      <div className="individual-session" key={index}>
                        <span>
                          <div className="bold-font session-start-time">
                            {moment(session.session_start_time).format(
                              "ddd MM/DD/YYYY hh:mmA"
                            )}
                            {/* {moment(new Date()).format( "ddd MM/DD/YYYY hh:mmA")} */}
                          </div>
                          <div className="moderator-div">
                            <select
                              className="dropdown"
                              id="moderator"
                              name="moderator_id"
                              value={session.moderator_id}
                              data-start-time={session.session_start_time}
                              onChange={(e) => onIndividualFieldChange(e)}
                              style={
                                store != "rstv"
                                  ? { display: "none" }
                                  : session.type === "appointment"
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                            >
                              <option value="">Select Moderator</option>
                              {
                                (session.optionList || []).map(({ id, full_name }) => {
                                  return(<option key={id} value={id}>
                                    {full_name}
                                  </option>)
                                })
                              }
                            </select>
                          </div>
                          <div className="recurring-checkbox">
                            <span>
                              <input
                                name="is_recurring"
                                className="is_recurring"
                                type="checkbox"
                                checked={session.is_recurring ? "checked" : ""}
                                data-start-time={session.session_start_time}
                                onChange={(e) => onIndividualFieldChange(e)}
                                disabled={
                                  pathname.includes("my_schedule") ||
                                  (settings.fromDetails != true
                                    ? session.disable_recurring
                                    : false) ||
                                  (SessionDetails.cancelled == "true" &&
                                    settings.fromDetails == true &&
                                    session.disable_recurring == true) ||
                                  (session.language_identifier == "all" &&
                                    session.type == "session")
                                }
                              />
                            </span>
                            <span>Recurring</span>
                            {/* <div
                            style={formStyle("", bulkSessions, session).is_recurring}
                            className="frequency-section"
                          >
                            <span>Recurring Frequency</span>
                            <span>
                              <select
                                id="recurring_freq"
                                name="recurring_frequency"
                                value={session.recurring_frequency}
                                onChange={onIndividualFieldChange}
                                style={{ verticalAlign: "bottom" }}
                              >
                                <option value="1">1</option>
                                <option value="2">2</option>
                              </select>
                            </span>
                          </div> */}
                            <div
                              style={
                                formStyle("", bulkSessions, session).is_recurring
                              }
                              className="end-at-section"
                            >
                              <span style={{ float: "left" }}>End at</span>
                              <span className="end-recurring-at">
                                <div className="customDatePickerWidth">
                                  <DatePicker
                                    selected={
                                      moment(
                                        session.recurring_end_date,
                                        "yyyy-MM-DD"
                                      ).isValid()
                                        ? moment(
                                            session.recurring_end_date,
                                            "yyyy-MM-DD"
                                          ).toDate() //.format("MM/DD/yyyy")
                                        : null
                                    }
                                    data-start-time={session.session_start_time}
                                    minDate={moment(
                                      props.settings.dateStr
                                    ).toDate()}
                                    id="end_recurring_at"
                                    name="recurring_end_date"
                                    onChange={(date) => {
                                      setBulkSessions({
                                        ...bulkSessions,
                                        sessions: modifiedSession(
                                          session.session_start_time,
                                          "recurring_end_date",
                                          date
                                        ),
                                      });
                                    }}

                                    //   (date) => {
                                    //   setBulkSessions({
                                    //     ...bulkSessions,
                                    //     session:
                                    //     recurring_end_date: moment(
                                    //       date,
                                    //       "MM/DD/yyyy"
                                    //     ).format("yyyy-MM-DD"), //date, //moment(date, "MM/DD/YYYY").format("yyyy-MM-DD "),
                                    //   });
                                    // }
                                    //  }
                                  />
                                </div>
                              </span>
                            </div>
                          </div>
                        </span>
                        <br />
                      </div>
                    );
                  })}
                </div>
                <div className="footer-section">
                  <input
                    type="button"
                    disabled={double}
                    value="CREATE"
                    className="create_button"
                    onClick={onSessionSubmit}
                  />
                </div>
              </div>
              {getNotes && (
                <div className="footer bold-font" style={{ maxWidth: "475px" }}>
                  Note: {getNotes.join(". ")}.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

CreateSessionModal.propTypes = {
  coachProfile: propTypes.array.isRequired,
  rstvResponse: propTypes.array.isRequired,
  actions: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  coachProfile: state.coachProfile,
  rstvResponse: state.rstvStore.rstvResponse,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      addBulkSessions: bindActionCreators(
        coachShedulerAction.addBulkCoachSessions,
        dispatch
      ),
      getRstvfunction: bindActionCreators(RstvAction.getRstv, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSessionModal);
