
import { 
    GET_RECIPIENTS_SUCCESS
 } from "../constants";

import initialState from "./initialState";


export default function recipientsReducer(state = initialState.recipients, action){

    switch(action.type) {
        case GET_RECIPIENTS_SUCCESS:    
            return action.recipients;
    //     case UPD_LINKS_SUCCESS:            
    //         return state.map(link => link.id === action.updateLink.id? action.updateLink : link)    
    //     case DEL_LINKS_SUCCESS:            
    //         return state.filter(link => link.id !== action.link.id);    
        default: 
            return state;    
    }
}