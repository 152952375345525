import { GET_ADDITIONAL_SUBSTITUTIONS_SUCCESS, CANCEL_SUBSTITUTIONS, REASSIGN_SUBSTITUTIONS } from "../constants";

import initialState from "./initialState";

// export default function additionalSubstitutionsReducer(state = initialState.additionalSubstitutions, action) {
//     if (action.type == GET_ADDITIONAL_SUBSTITUTIONS_SUCCESS) 
//       return action.additionalSubstitutions;
//       // if (action.type == GET_ADDITIONAL_SUBSTITUTIONS_SUCCESS) 
//       // return {...state, ["additionalSubstitutions"]: state.additionalSubstitutions.filter((sess) =>  sess.data[0].id != action.request.row_id)};
//       if(action.type == CANCEL_SUBSTITUTIONS)
//       return {...state, ["additionalSubstitutions"]: state.additionalSubstitutions.filter((sess) =>  sess.data[0].coach_session_id != action.request.coach_session_id)};
  
//     return state;
//   }

  export default function additionalSubstitutionsReducer(state = initialState.additionalSubstitutions, action) {
    switch (action.type) {
      case GET_ADDITIONAL_SUBSTITUTIONS_SUCCESS:
        return action.additionalSubstitutions;
      case CANCEL_SUBSTITUTIONS:
        return state.filter((sess) =>  sess.data[0].coach_session_id != action.request.coach_session_id);
      case REASSIGN_SUBSTITUTIONS:
        return state.filter((sess) =>  sess.data[0].coach_session_id != action.request.coach_session_id && sess.data[0].coach_id != action.request.filter_selected_coach_value);
      default:
        return state;
    }
  }
  