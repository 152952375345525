import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import decode from "jwt-decode";
import { useStateWithCallbackLazy } from "use-state-with-callback";

import "./SessionDetail.css";
import AssignSubstitute from "./AssignSubstitute";
import RequestSubstitute from "./RequestSubstitute";
import { getLanguageByIdentifier } from "../../utils/languageUtils";
import { bindActionCreators } from "redux";
import * as coachShedulerAction from "../../redux/actions/CoachShedulerAction";
import EditSessionModal from "./EditSessionModal";
import LearnerDetailsModal from "./LearnerDetailsModal";
import CancelSessionModal from "./CancelSessionModal";
import { toast } from "react-toastify";

import { useVillage } from "../../hooks";

function SessionDetail(props) {
  const { settings, setSettings, sessionDetails, session, loading, cancelLoading, setSession, displayEdit, setDisplayEdit, setDouble } = props;
  const [sessionInfo, setSessionInfo] = useState({ ...sessionDetails });
//  const [displayEdit, setDisplayEdit] = useState(false);
  const [positionAttributes, setPositionAttributes] = useState({ xAxis: "0px", yAxis: "0px" });
  const [modalOpen, setModalOpen] = useState({ open: false, email: "", timeZone: "", guid: "", xAxis: "0px", yAxis: "0px" });
  const villages = useVillage();
  const user = decode(localStorage.getItem("token"));
  const [cancelModalOpen, setCancelModalOpen] = useStateWithCallbackLazy({ toggle: false });

  const handleRequestSuccess = (value) => {
    setSettings((settings) => ({ ...settings, ["displayDetails"]: !settings.displayDetails }));
  };
  const handleAssignSuccess = (value) => {
    setSettings((settings) => ({ ...settings, ["displayDetails"]: !settings.displayDetails }));
  };

  const [assignSubstituteDisplay, setAssignSubstituteDisplay] = useState({
    displayAssignSubstitute: false,
    xAxis: "0px",
    yAxis: "0px",
  });

  const [requestSubstituteDisplay, setRequestSubstituteDisplay] = useState({
    displayRequestSubstitute: false,
    xAxis: "0px",
    yAxis: "0px",
  });

  useEffect(()=>{
    if(assignSubstituteDisplay["displayAssignSubstitute"]){
      let session_modal = document.getElementById("coach-schedule-session-details");
      session_modal.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  },[assignSubstituteDisplay]);

  useEffect(()=>{
    if(requestSubstituteDisplay["displayRequestSubstitute"]){
      let session_modal = document.getElementById("coach-schedule-session-details");
      session_modal.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  },[requestSubstituteDisplay]);

  useEffect(()=>{
    if(modalOpen["open"]){
      let session_modal = document.getElementById("coach-schedule-session-details");
      session_modal.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  },[modalOpen]);

  useEffect(()=>{
    if(displayEdit){
      let edit_modal = document.getElementById("coach-schedule-edit-session");
      edit_modal.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
    }
  },[displayEdit]);

  useEffect(() => {
    if (sessionDetails != {}) {
      console.log("SESSION DETAILS" + sessionDetails);
      setSessionInfo(sessionDetails);
      //setisActiveSession(moment() < moment(sessionInfo.session_start_time))
    }
  }, [sessionDetails]);

  function onSessionCancel(cancelModalResult = null) {
    let cancelAll = false;
    if (session.is_recurring) {
      // cancelAll = confirm(
      //   "Do you want to cancel this session only, or cancel both this session and all future occurrenes?"
      // );
      cancelAll = cancelModalResult == "CANCEL WITH RECURRENCE" ? true : false;
    }

    props.actions
      .cancelSession(
        {
          id: sessionDetails.id,
          recurring: cancelAll,
        },
        props.coachFilter
      )
      .then(() => {
        setSettings((setting) => ({ ...setting, displayDetails: false }));
        setDisplayEdit(false);
        toast.success("Session Cancelled Successfully");
        setCancelModalOpen({ ...cancelModalOpen, toggle: false });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to request Substitute");
        setCancelModalOpen({ ...cancelModalOpen, toggle: false });
      });
    // .finally(function() {
    //   setCancelModalOpen({ ...cancelModalOpen, toggle: false });
    // });
  }

  const isActiveSession = (end_time) => {
    return (moment() < moment(end_time))
  }

  const isCurrentSession = (start_time, end_time) => {
    // console.log("================start" + moment(start_time))
    // console.log("================end" + moment(end_time))
    // console.log("================current SEssion start" + (moment() < moment(start_time)))
    // console.log("================current SEssion end" + (moment() < moment(end_time)))
    // console.log("================current session?" + moment() > moment(start_time) && moment() < moment(end_time))
    return (moment() > moment(start_time) && moment() < moment(end_time))
  }

  const [isFromAssignSubstitute, setIsFromAssignSubstitute] = useState(false);
  // const [session, setSession] = useState({ dateStr: "", displayCreate: false });
  const [violationDate, setViolationDate] = useState();

  const [assignSubConfirmationDisplay, setAssignSubConfirmationDisplay] = useState({
    displayAssignSubConfirmation: false,
  });

  function extractIdentifier(language) {
    let identifier = language.split("-");
    return identifier.length == 2
      ? identifier[0].toLowerCase()
      : identifier.length == 3
      ? "fbsolo"
      : "totale";
  }

  const launchOnline = function(launch_string) {
    const config =
      "menubar=0,resizable=1,scrollbars=0,toolbar=0,location=0,directories=0,status=0,top=0,left=0";
    let child_window;
    child_window = window.open(launch_string, "launch_window", config);

    // continually refresh the parent window as long as the child window is still open
    // to keep the session alive
    // const keepAlive = function() {
    //   if (childIsClosed(child_window)) {
    //     return;
    //   } else {
    //     axios.get("/session_keep_alive").then(function() {
    //       setTimeout(keepAlive(), 60000);
    //     });
    //   }
    // };

    // keepAlive();
  };

  function handleSubstituteclicked(e, isFromAssignSubstitute) {
    setAssignSubConfirmationDisplay({ ...assignSubConfirmationDisplay, ["displayAssignSubConfirmation"]: false });
    props.actions
      .checkViolationPolicy({
        start_time: moment.utc(sessionInfo.session_start_time).format("yyyy-MM-DD HH:mm:ss"),
        session_id: sessionInfo.id,
        //coach_id: sessionInfo.coach_id || props?.coachFilter?.coach_id,
        coach_id: (sessionInfo.coach_id == props.coachFilter.coach_id) ? (sessionInfo.coach_id || props?.coachFilter?.coach_id) : sessionInfo.moderator_id
      })
      .then((response) => {
        setViolationDate(response.at_session);
        // setTimeout(function(){

        if (response.violation == true) {
          setAssignSubConfirmationDisplay({ ...assignSubConfirmationDisplay, ["displayAssignSubConfirmation"]: true });
        } else {
          if (isFromAssignSubstitute == true) {
            setRequestSubstituteDisplay({ ...requestSubstituteDisplay, ["displayRequestSubstitute"]: false });
            // , () => {
            /*120-> width of modal
                164-> height of session modal
                108-> height of appointment modal*/
            if (sessionInfo.type === "ConfirmedSession") {
              setAssignSubstituteDisplay({
                ...assignSubstituteDisplay,
                ["displayAssignSubstitute"]: true,
                xAxis: settings.xDistance > 610 ? settings.xAxis - 50 : settings.xAxis + 380 - 50,
                yAxis: settings.yAxis - 164 + 75,
              });
            } else {
              setAssignSubstituteDisplay({
                ...assignSubstituteDisplay,
                ["displayAssignSubstitute"]: true,
                xAxis: settings.xDistance > 610 ? settings.xAxis - 50 : settings.xAxis + 380 - 50,
                yAxis: settings.yAxis - 108 + 75,
              });
            }
            // });
          } else if (isFromAssignSubstitute == false) {
            setAssignSubstituteDisplay({ ...assignSubstituteDisplay, ["displayAssignSubstitute"]: false });
            // , () => {
            if (sessionInfo.type === "ConfirmedSession") {
              setRequestSubstituteDisplay({
                ...requestSubstituteDisplay,
                ["displayRequestSubstitute"]: true,
                xAxis: settings.xDistance > 610 ? settings.xAxis - 120 : settings.xAxis + 380 - 120,
                yAxis: settings.yAxis - 164 + 75,
              });
            } else {
              setRequestSubstituteDisplay({
                ...requestSubstituteDisplay,
                ["displayRequestSubstitute"]: true,
                xAxis: settings.xDistance > 610 ? settings.xAxis - 120 : settings.xAxis + 380 - 120,
                yAxis: settings.yAxis - 108 + 75,
              });
            }
            // });
          }
        } // }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // function handleRequestSubstituteclicked(e) {
  //   setAssignSubConfirmationDisplay({ ...assignSubConfirmationDisplay, ["displayAssignSubConfirmation"]: false });
  //   setRequestSubstituteDisplay({ ...requestSubstituteDisplay, ["displayRequestSubstitute"]: true });
  // }

  function handleConfirmAssignSubstituteClicked(e) {
    setAssignSubConfirmationDisplay({ ...assignSubConfirmationDisplay, ["displayAssignSubConfirmation"]: false });
    if (isFromAssignSubstitute == true) {
      if (sessionInfo.type === "ConfirmedSession") {
        setAssignSubstituteDisplay({
          ...assignSubstituteDisplay,
          ["displayAssignSubstitute"]: true,
          xAxis: settings.xDistance > 610 ? settings.xAxis - 50 : settings.xAxis + 380 - 50,
          yAxis: settings.yAxis - 164 + 75,
        });
      } else {
        setAssignSubstituteDisplay({
          ...assignSubstituteDisplay,
          ["displayAssignSubstitute"]: true,
          xAxis: settings.xDistance > 610 ? settings.xAxis - 50 : settings.xAxis + 380 - 50,
          yAxis: settings.yAxis - 108 + 75,
        });
      }
    } else {
      if (sessionInfo.type === "ConfirmedSession") {
        setRequestSubstituteDisplay({
          ...requestSubstituteDisplay,
          ["displayRequestSubstitute"]: true,
          xAxis: settings.xDistance > 610 ? settings.xAxis - 120 : settings.xAxis + 380 - 120,
          yAxis: settings.yAxis - 164 + 75,
        });
      } else {
        setRequestSubstituteDisplay({
          ...requestSubstituteDisplay,
          ["displayRequestSubstitute"]: true,
          xAxis: settings.xDistance > 610 ? settings.xAxis - 120 : settings.xAxis + 380 - 120,
          yAxis: settings.yAxis - 108 + 75,
        });
      }
    }
  }
  const sessionType = (type) => {
    switch (type) {
      case "ConfirmedSession":
        return "SESSION DETAILS";
      case "Appointment":
        return "APPOINTMENT DETAILS";
      case "ExtraSession":
        return "SUBSTITUTION DETAILS";
      default:
        return "SESSION DETAILS";
    }
  };

  function handleCheckRecurring(e) {
    // props.actions
    //   .checkRecurrings({
    //     coach_id: sessionInfo.coach_id || props?.coachFilter?.coach_id,
    //     session_start_time: moment.utc(sessionInfo.session_start_time).format("yyyy-MM-DD HH:mm:ss"),
    //     day_index: moment.utc(sessionInfo.session_start_time).day(),
    //   })
    //   .then((response) => {})
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  function grab(){
    console.log("Grab is clicked");
    console.log("Substitution Data", settings.substitution);
    if(!confirm("Are you sure you want to grab the session?")) return;
    let substitution = settings.substitution

    if(substitution)
    {
      setDouble(true);
      setSettings((setting) => ({ ...setting, displayDetails: true }))
      props.grabSession({
        filter_selected_coach_value : substitution.currentCoachID,
        from_cs: false,
        assigned_coach: user.id,
        reason:"Auto Subrequest",
        coach_session_id:substitution.coach_session_id,
        scheduled_session_id: substitution.scheduled_session_id,
        scheduled_eschool_session_id: substitution.scheduled_eschool_session_id,
        is_grabing: true
      }).then((response) => {
        props.getCoachScheduler(props.coachFilter).then(() => {
          setDouble(false);
          setSettings((setting) => ({ ...setting, displayDetails: false }))
          if (response == "Coach has been reassigned successfully") {
            toast.success("Session grabbed successfully");
          } else {
            toast.error(response);
          }
        }).catch((err) => {
          setDouble(false);
          console.log("Error on Coach Scheduer Fetch", err);
          setSettings((setting) => ({ ...setting, displayDetails: false }))
          toast.error("Failed to Grab the session");
        })
        props.getSubstitutions({ time_range: "upcoming", alert: "true" });
      }).catch((err) => {
        setDouble(false);
        console.log("Error on grabbing the session", err);
        setSettings((setting) => ({ ...setting, displayDetails: false }))
        toast.error("Failed to Grab the session");
      });
    }
  }

  return (
    <>
      <div>
        {sessionInfo != undefined && sessionInfo != {} && (
          <div
            className="modal coach-schedule-session-details-modal"
            id="coach-schedule-session-details"
            style={{
              display: settings.displayDetails ? "block" : "none",
              left: settings.xDistance > 610 ? settings.xAxis + "px" : settings.xAxis + 380 + "px",
              top: sessionInfo.type === "ConfirmedSession" ? settings.yAxis - 164 + "px" : settings.yAxis - 108 + "px",
            }}
          >
            <div className="modal-dialog">
              {loading ? (
                <div>LOADING....</div>
              ) : (
                <div className="modal-content">
                  <div className="modal_header">
                    <h4 className="modal-title">
                      {sessionInfo.cancelled == "false"
                        ? sessionType(sessionInfo.type)
                        : "SESSION / APPOINTMENT DETAILS"}
                    </h4>
                    <a
                      className="close-icon"
                      title="Close"
                      aria-label="Close"
                      role="button"
                      data-dismiss="modal"
                      onClick={(e) => {
                        e.preventDefault();
                        cancelLoading();
                        setSessionInfo({});
                        setSettings((settings) => ({ ...settings, ["displayDetails"]: false }));
                      }}
                    >
                      <span>×</span>
                    </a>
                  </div>
                  <div className="modal-body">
                    <div className="row1">
                      <div className="left-section">
                        <div>
                          <span>
                            {moment(moment(settings.start_date).format()).format("ddd MM/DD/YY hh:mm A")}
                          </span>
                          {sessionInfo.rstv_guid == null &&
                            sessionInfo.cancelled == "false" &&
                            sessionInfo.sub_requested == 0 && isActiveSession(sessionInfo.session_start_time) &&
                            (user.type == "CoachManager" || sessionInfo.created_by) && (
                              <input
                                className="edit-button"
                                type="button"
                                value="Edit"
                                onClick={(e) => {
                                  handleCheckRecurring(e);
                                  setSettings((setting) => ({ ...setting, displayDetails: false }));
                                  setDisplayEdit(true);
                                  /* 475-> width of edit modal
                                47-> width of nav section
                                295-> height of edit modal
                                103-> height of header section */
                                  let xDistance = settings.xDistance - 475 - 47;
                                  let yDistance = settings.yDistance - 147 - 103;
                                  setPositionAttributes({ ...positionAttributes, xAxis: xDistance, yAxis: yDistance });
                                  // let edit_modal = document.getElementById("coach-schedule-edit-session");
                                  // edit_modal.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                                }}
                              />
                            )}


                          {/* {sessionInfo.rstv_guid == null && */}
                          {sessionInfo.cancelled == "false" && sessionInfo.sub_requested == 1 && sessionInfo.rstv_guid == null && isActiveSession(sessionInfo.session_start_time) && (
                            <input
                              className="create-new-button"
                              type="button"
                              value="Create New"
                              onClick={(e) => {
                                handleCheckRecurring(e);
                                let xDistance =settings.xDistance > 670 ? settings.xDistance - 750 : settings.xDistance - 280;
                                let yDistance = settings.yDistance - 147 - 93;
                                setSession({
                                  ...session,
                                  ["dateStr"]: moment(moment(sessionInfo.session_start_time).format()).format(
                                    "ddd MM/DD/YY hh:mm A"
                                  ),
                                  ["displayCreate"]: true,
                                  ["xAxis"]: xDistance,
                                  ["yAxis"]: yDistance,
                                });
                                setSettings((settings) => ({
                                  ...settings,
                                  ["displayDetails"]: !settings.displayDetails,
                                  ["fromDetails"]: true,
                                }));
                              }}
                            />
                          )}
                          {/* {sessionInfo.rstv_guid == null && sessionInfo.cancelled == "true" && ( */}
                          {(sessionInfo.cancelled == "true" || sessionInfo.cancelled == 1) && sessionInfo.rstv_guid == null && isActiveSession(sessionInfo.session_start_time) && (
                           <>
                           <input
                              className="create-new-button"
                              type="button"
                              value="Create New"
                              onClick={(e) => {
                                let xDistance = settings.xDistance > 670 ? settings.xDistance - 750 : settings.xDistance - 280;
                                let yDistance = settings.yDistance - 147 - 93;
                                handleCheckRecurring(e);
                                setSession({
                                  ...session,
                                  // ["dateStr"]: moment(moment(sessionInfo.session_start_time).format()).format(
                                  //   "ddd MM/DD/YY hh:mm A"
                                  // ),
                                  ["dateStr"]: moment(moment(settings.start_date).format()).format(
                                    "ddd MM/DD/YY hh:mm A"
                                  ),
                                  ["displayCreate"]: true,
                                  ["fromDetails"]: true,
                                  ["xAxis"]: xDistance,
                                  ["yAxis"]: yDistance,
                                  ["sessionDisableRecurring"]: sessionInfo.disable_recurring
                                });
                                setSettings((settings) => ({
                                  ...settings,
                                  ["displayDetails"]: !settings.displayDetails,
                                }));
                              }}
                            />
                            </>
                          )}

                          {sessionInfo.cancelled == "false" && isActiveSession(sessionInfo.session_start_time) && settings.substitution && (
                            <input
                              className="create-new-button detail-grab-button"
                              type="button"
                              value="GRAB"
                              onClick={() => {
                               grab()
                              }}
                            />
                          )}

                          {sessionInfo.rstv_guid != null &&
                            sessionInfo.moderator_id != null &&
                            sessionInfo.coach_id == props.coachFilter.coach_id &&
                            sessionInfo.cancelled == "false" &&
                            (user.type == "CoachManager" || sessionInfo.created_by) && isActiveSession(sessionInfo.session_start_time) && (
                              <input
                                className="edit-button"
                                type="button"
                                value="Edit"
                                onClick={(e) => {
                                  handleCheckRecurring(e);
                                  setSettings((setting) => ({ ...setting, displayDetails: false }));
                                  setDisplayEdit(true);
                                  let xDistance = settings.xDistance - 475 - 47;
                                  let yDistance = settings.yDistance - 295 - 103;
                                  setPositionAttributes({ ...positionAttributes, xAxis: xDistance, yAxis: yDistance });
                                }}
                              />
                            )}
                            {sessionInfo.rstv_guid == null &&
                            sessionInfo.cancelled == "false" &&
                            sessionInfo.sub_requested == 0 &&
                        (user.type == "CoachManager" || sessionInfo.created_by) && isCurrentSession(sessionInfo.session_start_time, sessionInfo.session_end_time) && (
                             <input
                              className="footer-button"
                              type="button"
                              value="CANCEL"
                              onClick={() => {
                                if(sessionInfo.is_recurring)
                                {
                                setCancelModalOpen({ ...cancelModalOpen, toggle: true })
                                }
                                else
                                {
                                  onSessionCancel()
                                }
                              }
                            }
                            />
                            )}
                          {/* <input className="cancel-button" type="button" value="CANCEL" /> */}
                        </div>

                        {sessionInfo.type != "Appointment" &&
                          sessionInfo.rstv_guid != null &&
                          (sessionInfo.cancelled == "true" || sessionInfo.cancelled == 1) && (
                            <div>
                              <div>{getLanguageByIdentifier(sessionInfo.language_identifier)}</div>
                            </div>
                          )}
                        {sessionInfo.type != "Appointment" && sessionInfo.rstv_guid == null && (
                          <div>
                            <div>{getLanguageByIdentifier(sessionInfo.language_identifier)}</div>
                            {sessionInfo.teacher_confirmed == "" && sessionInfo.sub_requested == 0 && (
                              <div className="red">Unconfirmed</div>
                            )}
                            {villages.length > 0 &&
                              villages.map(({ id, name }) =>
                                sessionInfo.external_village_id == id ? <div key={id}>{name}</div> : null
                              )}
                          </div>
                        )}
                      </div>
                      {sessionInfo.cancelled == "false" && isActiveSession(sessionInfo.session_end_time) && (
                        <div className="right-section">
                          {user.type === "CoachManager" && sessionInfo.type != "Appointment" && (
                            <div>
                              <a
                                className="underline session-links"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setIsFromAssignSubstitute(true);
                                  handleSubstituteclicked(e, true);
                                }}
                              >
                                Assign a Sub
                              </a>
                            </div>
                          )}
                          {sessionInfo.sub_requested == 0 && (
                            <div>
                              <a
                                className="underline session-links"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setIsFromAssignSubstitute(false);
                                  handleSubstituteclicked(e, false);
                                }}
                              >
                                Request a Sub
                              </a>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    {/* {sessionInfo.type == "ConfirmedSession" && sessionInfo.cancelled == "false" && ( */}
                    {sessionInfo.type == "ConfirmedSession" && (
                      <div>
                        <div className="row2">
                          <div className="left-section">
                            {sessionInfo.learners_signed_up != null && sessionInfo.rstv_guid == null && (
                              <div>
                                {sessionInfo.learners_signed_up == 0 &&
                                  extractIdentifier(sessionInfo.language_identifier) != "cp" && (
                                    <div>No Learners have signed up.</div>
                                  )}
                                {sessionInfo.learners_signed_up > 0 && sessionInfo.learner_details.attendance != [] && sessionInfo.learner_details.attendance != undefined && (
                                  // <div>No of Learners have signed up: {sessionInfo.learners_signed_up}</div>
                                  <div>
                                    {sessionInfo.topic &&
                                      <div>
                                         { sessionInfo.topic.title &&
                                          <>
                                            <span style={{float: "left"}}>Topic:</span>

                                            <div style={{float: "left"}}>

                                              {sessionInfo.topic.title}
                                            </div>
                                            <br />
                                          </>
                                        }
                                      </div>
                                    }

                                        <span style={{float: "left"}}>Learners:</span>
                                        {sessionInfo.learners_signed_up == 1 ? (
                                          <div style={{float: "left"}}>
                                            <a href="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                console.log("event-----",e)
                                                let xDistance = e.clientX - 485;
                                                let yDistance = e.pageY - 230;
                                                setModalOpen((data) => ({
                                                  ...data,
                                                  open: true,
                                                  guid: sessionInfo.learner_details.attendance?.student_guid,
                                                  email: sessionInfo.learner_details.attendance?.student_email,
                                                  timeZone: sessionInfo.learner_details.attendance?.student_time_zone,
                                                  xAxis: xDistance,
                                                  yAxis: yDistance,
                                                }));
                                              }}
                                            >
                                              {sessionInfo.learner_details.attendance?.first_name}{" "}
                                              {sessionInfo.learner_details.attendance?.last_name}
                                            </a>
                                            <br />
                                          </div>
                                        ) : (
                                          <div>
                                            {" "}
                                            {(sessionInfo.learner_details.attendance || []).map((learner) => (
                                              <div key={learner.student_guid}>
                                                <a href="#"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    let xDistance = e.clientX - 485;
                                                    let yDistance = e.pageY - 230;
                                                    setModalOpen((data) => ({
                                                      ...data,
                                                      guid: learner?.student_guid,
                                                      email: learner?.student_email,
                                                      timeZone: learner?.student_time_zone,
                                                      open: true,
                                                      xAxis: xDistance,
                                                      yAxis: yDistance,
                                                    }));
                                                  }}
                                                >
                                                  {learner?.first_name} {learner?.last_name}
                                                </a>
                                                <br />
                                              </div>
                                            ))}{" "}
                                          </div>
                                        )}
                                  </div>
                                )}
                              </div>
                            )}
                            {sessionInfo.rstv_guid == null &&
                              extractIdentifier(sessionInfo.language_identifier) != "cp" && (
                                <div style={{clear: "both"}}>
                                  {" "}
                                  Avg attendance/session :{" "}
                                  {sessionInfo.average_attendance != null ? parseFloat(sessionInfo.average_attendance).toFixed(1) : 0.0}
                                </div>
                              )}
                            {sessionInfo.rstv_guid != null && sessionInfo.cancelled == "false" && (
                              <div>
                                <div>Language: {getLanguageByIdentifier(sessionInfo.language_identifier)}</div>
                                <div>Coach: {sessionInfo.coach_name ? sessionInfo.coach_name : "Substitute Requested"}</div>
                                <div>Topic: {sessionInfo.rstv_topic_title}</div>
                                <div>Moderator: {sessionInfo.moderator_name ? sessionInfo.moderator_name : "Substitute Requested"}</div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row3">
                          <div className="left-section">
                            {sessionInfo.rstv_guid == null && (
                              <div>
                                Eschool Session Id :
                                {sessionInfo.eschool_session_id != null ? sessionInfo.eschool_session_id : 0}
                              </div>
                            )}
                          </div>
                          {sessionInfo.cancelled == "false" &&
                            extractIdentifier(sessionInfo.language_identifier) != "cp" &&
                            sessionInfo.sub_requested == 0 && (
                              <div className="right-section">
                                {user.type == "Coach" &&
                                  (extractIdentifier(sessionInfo.language_identifier) == "totale" ? (
                                    (moment() > moment(sessionInfo.session_start_time).subtract(15, "minutes") && isActiveSession(sessionInfo.session_end_time)) ? (
                                      <>
                                        <div className="session-feedback-button">
                                          <a
                                            onClick={(e) => {
                                              e.preventDefault();
                                              let arr = sessionInfo.launch_session_url
                                                ? sessionInfo.launch_session_url.split("'")[1]
                                                : "";
                                              //   .split(",");
                                              launchOnline(arr); //[0], arr[1]);
                                            }}
                                            rel="noreferrer"
                                            target="_blank"
                                          >
                                            Launch Session
                                          </a>
                                        </div>
                                        <div className={`session-feedback-button ${isActiveSession(sessionInfo.session_start_time) ? "feedback-disabled" : ""}`}>
                                        {/* <div className="session-feedback-button"> */}
                                        <a
                                            href={sessionInfo.feedback_url}
                                            rel="noreferrer"
                                            target="_blank"
                                          >Session Feedback</a>
                                        </div>
                                      </>
                                    ) : (
                                      <div className={`session-feedback-button ${isActiveSession(sessionInfo.session_start_time) ? "feedback-disabled" : ""}`}>
                                      {/* <div className="session-feedback-button"> */}
                                        <a
                                            href={sessionInfo.feedback_url}
                                            rel="noreferrer"
                                            target="_blank"
                                          >Session Feedback</a>
                                        </div>
                                    )
                                  ) : (moment() > moment(sessionInfo.session_start_time).subtract(15, "minutes") && isActiveSession(sessionInfo.session_end_time) && (
                                    <div className="session-feedback-button">
                                      <a onClick={(e) => {
                                          e.preventDefault();
                                          let arr = sessionInfo.launch_session_url ? sessionInfo.launch_session_url.split("'")[1] :""
                                          //   .split(",");
                                          launchOnline(arr); //[0], arr[1]);
                                        }}
                                        rel="noreferrer"
                                        target="_blank"
                                      >
                                        Launch Session
                                      </a>
                                    </div>
                                  )
                                  ))
                                // <div className="session-feedback-button">
                                //   <a href={sessionInfo.launch_session_url} target="_blank" rel="noreferrer">
                                //     Launch Session
                                //   </a>
                                // </div>
                                }

                                {user.type != "Coach" &&
                                  (extractIdentifier(sessionInfo.language_identifier) == "totale" ? (
                                    <div className={`session-feedback-button ${isActiveSession(sessionInfo.session_start_time) ? "feedback-disabled" : ""}`}>
                                     {/* <div className="session-feedback-button"> */}
                                      <a href={sessionInfo.feedback_url} target="_blank" rel="noreferrer">
                                    Session Feedback
                                  </a></div>
                                  ) : (isActiveSession(sessionInfo.session_end_time) && (
                                    <div className="session-feedback-button">
                                      <a
                                        onClick={(e) => {
                                          e.preventDefault();
                                          let arr = sessionInfo.launch_session_url
                                            ? sessionInfo.launch_session_url.split("'")[1]
                                            : "";
                                          launchOnline(arr);
                                        }}
                                        rel="noreferrer"
                                        target="_blank"
                                      >
                                        Launch Session
                                      </a>
                                    </div>)
                                  ))}
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                    {sessionInfo.type == "Appointment" && (
                      <div>
                        <div className="row2">
                          <div className="left-section">
                            <div>Appointment: {sessionInfo?.appointment_title}</div>
                            <br />
                            <div>
                              <strong>Appointment Details:</strong>
                              <br />
                              {sessionInfo?.details}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {assignSubConfirmationDisplay.displayAssignSubConfirmation && (
          <div className="rcmodal" id="coach-schedule-request-confirmation">
            <div className="rcmodal-dialog">
              {loading ? (
                <div> LOADING....</div>
              ) : (
                <div className="rcmodal-content">
                  <div className="rcmodal-body">
                    <div>
                      This substitution request is in violation of the Studio team&apos;s Substitution policy.
                      Substitution(s) were already requested for session at : {violationDate}. Would you like to
                      proceed?
                    </div>
                  </div>
                </div>
              )}
              <div className="rcfooter">
                <button
                  className="cancel"
                  data-dismiss="modal"
                  onClick={(e) => {
                    console.log("assignSubConfirmationDisplay", assignSubConfirmationDisplay);
                    setAssignSubConfirmationDisplay((assignSubConfirmationDisplay) => ({
                      ...assignSubConfirmationDisplay,
                      ["displayAssignSubConfirmation"]: false,
                    }));
                    cancelLoading();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="ok-btn"
                  onClick={(e) => {
                    handleConfirmAssignSubstituteClicked(e);
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <AssignSubstitute
        settings={assignSubstituteDisplay}
        setSettings={setAssignSubstituteDisplay}
        sessionInfo={sessionInfo}
        coachFilter={props.coachFilter}
        onAssignSuccess={handleAssignSuccess}
        isFromAppoinment={props.isFromAppointment}
      />
      <RequestSubstitute
        settings={requestSubstituteDisplay}
        setSettings={setRequestSubstituteDisplay}
        sessionInfo={sessionInfo}
        coachFilter={props.coachFilter}
        onRequestSuccess={handleRequestSuccess}
        isFromAppoinment={props.isFromAppointment}
      />
      <EditSessionModal
        displayEdit={displayEdit}
        setDisplayEdit={setDisplayEdit}
        settings={settings}
        setSettings={setSettings}
        sessionDetails={sessionDetails}
        coachFilter={props.coachFilter}
        positionAttributes={positionAttributes}
        maxUnitInfo={props.maxUnitInfo}
        coachName={props.coachName}
      />
      <LearnerDetailsModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <CancelSessionModal
        modalOpen={cancelModalOpen}
        setModalOpen={setCancelModalOpen}
        onSessionCancel={onSessionCancel}
      />
    </>
  );
}

SessionDetail.defaultProps = {
  loading: false,
  SessionDetail: {},
};

SessionDetail.propTypes = {
  settings: propTypes.object.isRequired,
  setSettings: propTypes.func.isRequired,
  sessionDetails: propTypes.object.isRequired,
  loading: propTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      checkViolationPolicy: bindActionCreators(coachShedulerAction.checkViolationPolicy, dispatch),
      checkRecurrings: bindActionCreators(coachShedulerAction.checkRecurrings, dispatch),
      cancelSession: bindActionCreators(coachShedulerAction.cancelCoachSession, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionDetail);
