import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import propTypes from "prop-types";
import moment from "moment";
import { toast } from "react-toastify";

import * as studioTeamAction from "../../redux/actions/studioTeamAction";
import close from "../images/closelabel.gif";
import "./StudioTeamOndutyModal.css";

function StudioTeamOndutyModal({ ...props }) {
  const [toggleOnDuty, setToggleOnDuty] = useState(false);
  const [message, setMessage] = useState({});
  const [saved, setSaved] = useState(false);

  const {
    toggle,
    changeToggle,
    studioTeam,
    actions: { getStudioTeam },
    userType
  } = props;

  useEffect(() => {
    getStudioTeam("0,1").catch(console.log);
  }, []);

  useEffect(() => {
    Object.keys(props.message).length > 0 && setMessage(props.message);
  }, [props.message]);

  function onMessageSubmit(e) {
    e.preventDefault();
    const { attribute_name, attribute_value } = message;

    props.actions
      .mdyGlobalSettings({ attribute_name, attribute_value })
      .then(() => {
        setSaved(true);
        setTimeout(() => setSaved(false), 5000);
      })
      .catch((err) => {
        toast.error("Failed to Update Studio Message...");
      });
  }

  return (
    <>
      <div
        className="modal"
        id="studio-team-onduty-modal"
        style={{ display: toggle ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal_header">
              <h4 className="modal-title">Studio Team On Duty</h4>
              <button type="button" className="close" onClick={() => changeToggle(!toggle)}>
                <img src={close} title="close" className="close_image" />
              </button>
            </div>
            <div className="modal-body">
              <div className="on-duty-table duty-table">
                <table cellSpacing="20">
                  <tbody>
                    <tr>
                      <th className="name-wrap">Name</th>
                      <th>Phone (cell)</th>
                      <th>Phone (office)</th>
                      <th className="email-wrap">Email</th>
                      <th className="schedule">Available</th>
                    </tr>
                    {studioTeam.filter((st) => st.on_duty == 1).length > 0 ? (
                      studioTeam
                        .filter((st) => st.on_duty == 1)
                        .map((team) => (
                          <tr key={team.id}>
                            <td className="name-wrap">{team.name}</td>
                            <td>{team.phone_cell ? team.phone_cell : <center>--</center>}</td>
                            <td>{team.phone_desk ? team.phone_desk : <center>--</center>}</td>
                            <td className="email-wrap">{team.email}</td>
                            <td className="schedule">{`${moment(
                              team.available_start ? team.available_start : "00:00:00",
                              "hh:mm:ss"
                            ).format("hh:mm A")} to
                                ${moment(team.available_end ? team.available_end : "00:00:00", "hh:mm:ss").format(
                                  "hh:mm A"
                                )} ET`}</td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan="5">There are currently no managers or supervisors on duty.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <textarea
                rows="5"
                maxLength="255"
                title="Studio team on duty message"
                placeholder="Enter message here"
                name={message?.attribute_name}
                value={message?.attribute_value}
                onChange={(e) => setMessage({ ...message, ["attribute_value"]: e.target.value })}
              />
            </div>
            <div className="footer" style={{textAlign: 'center', display: userType !="Coach" ? "block" : "none" }}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  changeToggle(false);
                  setToggleOnDuty(true);
                }}
              >
                Edit On Duty List
              </a>
              <a href="#" onClick={onMessageSubmit}>
                Save Message
              </a>
            </div>
            {saved && (
              <div className="message-saved" style={{textAlign: "center"}}>
                Message Saved
              </div>
            )}
          </div>
        </div>
      </div>
      <EditStudioTeamOndutyModal toggle={toggleOnDuty} changeToggle={setToggleOnDuty} onSubmitSuccess={changeToggle} />
    </>
  );
}

const EditStudioTeamOndutyModal = connect(
  function(state) {
    const {
      studioTeam: { onDuty },
    } = state;
    return { studioTeams: onDuty[0] };
  },
  function(dispatch) {
    return {
      actions: {
        updateStudioTeam: bindActionCreators(studioTeamAction.updateStudioTeamOnDuty, dispatch),
      },
    };
  }
)(({ ...props }) => {
  const { toggle, changeToggle } = props;
  const [studioTeam, setStudioTeam] = useState([]);

  useEffect(() => {
    props.studioTeams.length > 0 && setStudioTeam(props.studioTeams);
  }, [props.studioTeams]);

  function onStudioChange(e, index, refName = null) {
    let name = e.target.name;
    let value;
    if (e.target.type === "checkbox") value = e.target.checked;
    else {
      value = e.target.value;
      if (refName) {
        // const date = studioTeam.find(team => team.id == index)[refName]||"00:00:00";
        console.log(value);
        if (name == "hours")
          value = moment(
            `${value} ${moment(studioTeam.find((team) => team.id == index)[refName] || "00:00:00", "HH:mm:ss").format(
              "A"
            )}`,
            "hh:mm A"
          ).format("HH:mm:ss");
        if (name == "seconds")
          value = moment(
            `${moment(studioTeam.find((team) => team.id == index)[refName] || "00:00:00", "HH:mm:ss").format(
              "hh:mm"
            )} ${value}`,
            "hh:mm A"
          ).format("HH:mm:ss");

        console.log(refName + " " + value + " " + name);
        name = refName;
      }
    }
    setStudioTeam((teams) => teams.map((team) => (team.id == index ? { ...team, [name]: value } : team)));
  }

  // console.log(studioTeam);
  function onStudioSubmit() {
    props.actions
      .updateStudioTeam(studioTeam)
      .then(() => {
        toast.success("Studio Team Updated Successfully");
        changeToggle(false);
        props.onSubmitSuccess(true);
      })
      .catch((err) => {
        toast.error("Failed to Update Studio Team...");
        console.error(err);
      });
  }

  return (
    <div className="modal" id="edit-onduty-modal" style={{ display: toggle ? "block" : "none" }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal_header">
            <h4 className="modal-title">Edit Studio Team On Duty</h4>
            <button type="button" className="close" onClick={() => changeToggle(false)}>
              <img src={close} title="close" className="close_image" />
            </button>
          </div>
          <div className="modal-body">
            <div className="edit-on-duty-table duty-table">
              <table cellSpacing="20">
                <thead>
                  <tr>
                    <th className="checkbox-field">&nbsp;&ensp;&ensp;</th>
                    <th className="name-field">Studio Team Members:</th>
                    <th className="time-field">Start Time: </th>
                    <th>End Time: </th>
                  </tr>
                </thead>
                <tbody>
                  {studioTeam.length > 0 &&
                    studioTeam.map((team) => (
                      <tr key={team.id}>
                        <td className="checkbox-field">
                          <input
                            className="on-duty"
                            type="checkbox"
                            name="on_duty"
                            onChange={(e) => onStudioChange(e, team.id)}
                            checked={team.on_duty == 1 ? "checked" : ""}
                          />
                        </td>
                        <td className="name-field">{team.name}</td>
                        <td className="time-field">
                          <TimeDropdown
                            defaultValue={team.available_start}
                            onChange={(e) => onStudioChange(e, team.id, "available_start")}
                          />
                        </td>
                        <td className="time-field">
                          <TimeDropdown
                            defaultValue={team.available_end}
                            onChange={(e) => onStudioChange(e, team.id, "available_end")}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="footer">
            <p style={{textAlign: "left"}}>Please enter times in Eastern Time.</p>
            <div style={{textAlign: "center"}}>
              <input type="button" value="Save" onClick={onStudioSubmit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const TimeDropdown = (props) => {
  const { defaultValue, onChange } = props;
  const getHours = useMemo(() => {
    const hours = [];
    for (let hour = 0; hour < 12; hour++) {
      hours.push(moment({ hour }).format("hh:mm"));
      hours.push(moment({ hour, minute: 30 }).format("hh:mm"));
    }
    return hours;
  });

  return (
    <>
      <select value={moment(defaultValue, "hh:mm:ss").format("hh:mm")} name="hours" onChange={onChange}>
        {getHours.map((hour) => (
          <option key={hour} value={hour}>
            {hour}
          </option>
        ))}
      </select>
      <select value={moment(defaultValue, "hh:mm:ss").format("A")} name="seconds" onChange={onChange}>
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </select>
    </>
  );
};

// studioTeam
StudioTeamOndutyModal.propTypes = {
  studioTeam: propTypes.array.isRequired,
  actions: propTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    studioTeam: { onDuty },
  } = state;
  return { studioTeam: onDuty[0], message: onDuty[1][0] };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      getStudioTeam: bindActionCreators(studioTeamAction.getStudioTeamOnDuty, dispatch),
      mdyGlobalSettings: bindActionCreators(studioTeamAction.mdyGlobalSettings, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudioTeamOndutyModal);
