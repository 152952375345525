import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux"; 


import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App.js";
import configureStore from "./redux/store";

const store = configureStore();

ReactDOM.render(
    <ReduxProvider store={store} >
      <Router>
        <App />
      </Router>
    </ReduxProvider>,
  document.getElementById("root")
);