import { AccountAPI } from "./apiUtils";

export const getRstvTopic = async () => await AccountAPI.get("/rstv-topics");

export const getRstvTopicUpdate = async () => await AccountAPI.get("/rstvtopic");

export const updateRSTVTopic = async(data) =>    
     await AccountAPI.post("/rstvtopic",{...data});

export const addRSTVTopic = async(data) =>    
{ return await AccountAPI.post("/rstvtopic",{...data}) };