export default {
  user: {},
  alerts: [],
  links: [],
  appointments: [],
  learners: { loading: "InProgress", consumables: { loading: "InProgress" } },
  notifications: [],
  substitutions: [],
  sessions: [],
  studioTeam: { onDuty: [[], [[]]] },
  recipients: [],
  contactPref: [],
  coachProfile: [],
  studioLibrary: [],
  languages: [],
  villages: [],
  villagesList: [],
  regions: [],
  comment: [],
  resolutions: [],
  comments: [],
  trackUsers: [],
  trackUserNames: [],
  trackUserRoles: [],
  rstvResponse: [],
  updateRSTV: {},
  addRSTV: {},
  manageLanguageResponse: [],
  nonManageLanguageResponse: [],
  manageLanguageCoachId: "",
  admindashboardSettings: {},
  unAssignedCoachResponse: [],
  coachManagerResponse: [],
  assignedCoachResponse: [],
  assignCoachManagerResponse: [],
  // apiCallsInProgress: 0,
  status: {},
  auditLogs: [],
  auditLogsTableNames: [],
  coachSession: [],
  additionalSubstitutions: [],
  coachSheduler: {},
  regionsList: [],
  coachRosters: [],
  managementRosters: [],
  sessionDetails: {},
  // requestSubstituteResponse: {}
  appointmentSchedules: [],
  getGenerateReportResponse: [],
  getGenerateReportRange: "",
  appointmentDetails: [],
  qualifiedCoaches: [],
  masterScheduler: {},
  schedulingThresholds: [],
  getTimeOffListResponse: [],
  shiftDetails: [],
  shiftSessionDetails: { sessionDetail: {} },
  substitutionResponse: [],
  substitutionReportResponse: [],
  substitutionFullReportResponse: [],
  substitutionFilterList: {},
  substitutionCoachList: [],
  futureSession: [],
  qaFormsList: [],
  qaFormsScores: [],
  qaFormsPercentages: [],
  qaFormsCategories:[],
  qaFormsCriteria:[],
  qaFormsUniqueList:[],
  qaFormsNonUniqueList:[],
  qaCoachSessionList:[],
  qaCoachRatingList:[],
  qaCoachScoreList:[],
  qaCoachEvalList:[],
  qaComparativeEvalList:[],
  weeklyThreshold:[],
  productRights:[],
  consumablesReport:[],
  autoRenewalReport:[],
  score:"",
  InactiveCoachSub:[],
};
