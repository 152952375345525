import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import decode from "jwt-decode";

import * as linkActions from "../../redux/actions/linksAction";
import show from "../images/show.gif";
import edit from "../images/edit.png";
import deletes from "../images/delete.png";
import Detail from "./Detail";
import Edit from "./Edit";
import Add from "./Add";

function List({ ...props }) {
  console.log("========Props from Coach List component" + JSON.stringify(props));
  const [addToggle, setAddToggle] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  const [editToggle, setEditToggle] = useState(false);
  const [link, setLink] = useState({});
  const user = decode(localStorage.getItem("token"));
  let links;

  if (props.length) {
    links = props.links.slice(0, props.length);
  } else {
    links = props.links;
  }

  useEffect(() => {
    console.log("props before====>" + JSON.stringify(props));
    const { links, actions } = props;
    if (links.length === 0) actions.getLinks().catch((err) => alert(err));
  }, [link]);

  function toggleShow(e, link) {
    e.preventDefault();
    setLink(link);
    setShowToggle(!showToggle);
  }

  function toggleAdd(e) {
    e.preventDefault();
    setAddToggle(!addToggle);
  }

  function toggleEdit(e, link) {
    e.preventDefault();
    setLink(link);
    setEditToggle(!editToggle);
  }

  function deleteLink(e, link) {
    e.preventDefault();
    let del = confirm("Are you sure?");
    del && props.actions.removeLinks(link);
  }
  console.log("props Link====>" + JSON.stringify(props));
  return (
    <>
      <Detail link={link} showToggle={showToggle} setShowToggle={setShowToggle} />
      <Add add={props.actions.addLink} addToggle={addToggle} setAddToggle={setAddToggle} />
      <Edit link={link} edit={props.actions.updateLink} editToggle={editToggle} setEditToggle={setEditToggle} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 style={{ float: "none" }}>{user.type === "CoachManager" ? "Rosetta Links" : "Listing links"}</h2>
        {!props.length && user.type === "CoachManager" && (
          <a
            className="right"
            style={{
              fontStyle: "italic",
              color: "#1C0AFF",
              fontWeight: "bold",
              fontSize: "12px",
              cursor: "pointer",
            }}
            onClick={(e) => toggleAdd(e)}
          >
            ADD
          </a>
        )}
      </div>
      <hr />
      {/* <a href="" className="right">SEE ALL</a> */}
      {props.length && props.length > 0 && (
        <Link to={"/links"} className="right">
          SEE ALL
        </Link>
      )}
      <table className="home-table">
        <tbody>
          <tr>
            <th>Name</th>
            <th>URL</th>
            <th>Options</th>
          </tr>
          {links.map((link, idx) => (
            <tr key={idx}>
              <td className="name" style={{ color: "#666" }}>
                {link.name}
              </td>
              <td className="desc">
                <a href={link.url} target="_blank" rel="noreferrer">
                  {link.url}
                </a>
              </td>
              <td className="links">
                <a href="" onClick={(e) => toggleShow(e, link)}>
                  <img alt="Show" src={show} title="Show" />
                </a>
                {user.type === "CoachManager" && (
                  <>
                    <a href="" onClick={(e) => toggleEdit(e, link)}>
                      <img alt="Edit" src={edit} title="Edit" className="edit" />
                    </a>
                    <a href="" onClick={(e) => deleteLink(e, link)}>
                      <img alt="Delete" src={deletes} title="Delete" />
                    </a>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

List.propTypes = {
  links: propTypes.array.isRequired,
  length: propTypes.number,
  actions: propTypes.object.isRequired,
};

function mapStateToProps(state) {
  return { links: state.links };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      addLink: bindActionCreators(linkActions.addLink, dispatch),
      getLinks: bindActionCreators(linkActions.getLinks, dispatch),
      updateLink: bindActionCreators(linkActions.updateLink, dispatch),
      removeLinks: bindActionCreators(linkActions.removeLinks, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
