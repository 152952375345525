import {
  GET_COACH_PROFILE_SUCCESS,
  GET_CONTACT_PREFERENCE_SUCCESS,
  UPD_CONTACT_PREFERENCE_SUCCESS,
  UPD_COACH_PROFILE_SUCCESS,
  GET_QUALIFIED_COACHES
} from "../constants";
import * as coachProfileApi from "../../apis/CoachProfileApi";

export function getCoach(id) {
  console.log(id);
  return function(dispatch) {
    return coachProfileApi
      .getCoachProfile(id.id)
      .then((response) => {
        dispatch({
          type: GET_COACH_PROFILE_SUCCESS,
          coachProfile: response.data,
        });
        return response.data;
      })
      .catch((err) => {
        alert("Coach Profile does not exist.");
        window.history.back();
        throw err;
      });
  };
}

export function updateCoach(coachProfile, type, qualLang = []) {
  console.log("---------->" + JSON.stringify(coachProfile));
  return function(dispatch) {
    return coachProfileApi
      .updateCoachProfile(coachProfile, type, qualLang)
      .then((updateResponse) => {
        coachProfileApi
          .getCoachProfile(coachProfile.id)
          .then((response) => {
            dispatch({
              type: UPD_COACH_PROFILE_SUCCESS,
              coachProfile: response.data,
            });
          })
          .catch((err) => {
            throw err;
          });
          return updateResponse;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function createCoach(data) {
  console.log("---------->" + JSON.stringify(data));
  return function(dispatch) {
    return coachProfileApi
      .createCoachProfile(data)
      .then((response) => {
        console.log(response.data)
        dispatch({
          type: UPD_COACH_PROFILE_SUCCESS,
          coachProfile: response.data,
        });
        return response;
      })
      .catch((err) => {
        throw err;
      });                 
  };
}

export function getContactPreference(id) {
  return function(dispatch) {
    return coachProfileApi
      .getContactPreference(id)
      .then((response) => {
        dispatch({
          type: GET_CONTACT_PREFERENCE_SUCCESS,
          contactPref: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function updateContactPreference(contact) {
  return function(dispatch) {
    return coachProfileApi
      .updateContactPreference(contact)
      .then((response) => {
        dispatch({
          type: UPD_CONTACT_PREFERENCE_SUCCESS,
          updateContact: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getQualifiedCoaches(identifier) {
  return function(dispatch) {
    return coachProfileApi
      .getQualifiedCoaches(identifier)
      .then((response) => {
        console.log("quali in action ------------------->" + JSON.stringify(response.data))
        dispatch({
          type: GET_QUALIFIED_COACHES,
          qualifiedCoaches: response.data,
        })
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getCoachLockedStatus(coach_id) {
  return function(dispatch) {
    return coachProfileApi
      .getCoachLockedStatus(coach_id.coach_id)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getActiveStatus(params) {
  return function(dispatch) {
    return coachProfileApi
      .getActiveStatus(params)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}
