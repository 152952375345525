import { GET_SESSIONS_SUCCESS } from "../constants"
import initialState from "./initialState";


export default function sessionsReducer(state = initialState.sessions, action){
    switch(action.type){
        case GET_SESSIONS_SUCCESS:
            {
                if (action.page_num != 1)
                {
                    return [...state, ...action.sessions];
                }
                return action.sessions;
            }
        default:
            return state;    
    }
}