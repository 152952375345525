import {
    GET_SUBSTITUTION_LIST_SUCCESS,
    GET_SUBSTITUTION_FILTER_LIST_SUCCESS,
   // GET_SUBSTITUTION_FETCH_COACH_LIST_SUCCESS,
    GET_SUBSTITUTION_REPORT_LIST_SUCCESS,
    GET_SUBSTITUTION_FULL_REPORT_LIST_SUCCESS,
    GET_WEEKLY_THRESHOLD,
    CANCEL_SUBSTITUTE,
    REASSIGN_SUBSTITUTE,
} from "../constants";

import initialState from "./initialState";

export default function substitutionReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBSTITUTION_LIST_SUCCESS:
      console.log(action.payload)
      return {...state, substitutionResponse:getData(action.payload)};
    case GET_SUBSTITUTION_FILTER_LIST_SUCCESS:
      console.log(action.payload)
      return {...state, substitutionFilterList:action.payload};
    // case GET_SUBSTITUTION_FETCH_COACH_LIST_SUCCESS:
    //   console.log(action.payload)
    //   return {...state, substitutionCoachList:action.payload};
    case GET_SUBSTITUTION_REPORT_LIST_SUCCESS:
      console.log(action.payload)
      return {...state, substitutionReportResponse:getData(action.payload)};
    case GET_SUBSTITUTION_FULL_REPORT_LIST_SUCCESS:
      console.log(action.payload)
      return {...state, substitutionFullReportResponse:action.payload};
    case GET_WEEKLY_THRESHOLD:
      return {...state, weeklyThreshold:action.payload};
    case REASSIGN_SUBSTITUTE: {
      console.log(state);
      return {
        ...state,
        ["substitutionResponse"]: state.substitutionResponse.filter((sess) =>  sess.coach_session_id != action.request.coach_session_id)
      };
    }
    case CANCEL_SUBSTITUTE:
      return {
        ...state,
        ["substitutionResponse"]: state.substitutionResponse.filter((sess) =>  sess.coach_session_id != action.request.coach_session_id)
      };
     default:
      return state;
  }
}

function getData(response) {
  let responseArray = [];
  for (let i=0 ; i<response.length; i++) {
    responseArray.push(response[i].data[0]);
  }
  console.log(responseArray)
  return responseArray;
 
}