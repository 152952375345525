import {
    GET_AUDIT_LOGS_SUCCESS
} from "../constants";

import initialState from "./initialState";

export default function auditLogsReducer(state = initialState.auditLogs, action) {
    switch (action.type) {
        case GET_AUDIT_LOGS_SUCCESS:
            return action.auditLogs;
        default:
            return state;
    }
}
