import {
  GET_APPOINTMENT_SUCCESS, 
  ADD_APPOINTMENT_SUCCESS, 
  UPD_APPOINTMENT_SUCCESS,
} from "../constants";

import initialState from "./initialState";

export default function appointmentsReducer(state = initialState.appointments, action) {
  switch (action.type) {
    case ADD_APPOINTMENT_SUCCESS:
      return [...state, { ...action.addAppointment }];
    case GET_APPOINTMENT_SUCCESS:
      return action.appointment;
    case UPD_APPOINTMENT_SUCCESS:
      return state.map((appointment) =>
      appointment.id === action.updateAppointment.id ? action.updateAppointment : appointment
      );
    default:
      return state;
  }
}
