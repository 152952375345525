import { USER_LOGIN_SUCCESS } from "../constants";
import initialState from "./initialState";
import decode from "jwt-decode";

export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return decode(action.token);
    default:
      return state;
  }
}
