import initialState from "./initialState"
import {GET_COMMENTS, UPDATE_COMMENT, ADD_COMMENT} from "../constants";
export default function trackCommentsReducer(state = initialState.comments, action)
{
    switch(action.type){
    case GET_COMMENTS:
        return action.comments
    case UPDATE_COMMENT:
        return state.map((comment) => comment.id == action.updComments.id ? action.updComments : comment)
        // return action.comments
    case ADD_COMMENT:
        return action.comments
    default:
    return state;
    }
}