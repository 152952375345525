import { GET_VILLAGE_SUCCESS } from "../constants";

import initialState from "./initialState";

export default function villageReducer(state = initialState.villages, action) {
  switch (action.type) {
    case GET_VILLAGE_SUCCESS:
      return action.villages;
    default:
      return state;
  }
}
