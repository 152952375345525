import { USER_LOGIN_SUCCESS } from "../constants";
import * as userApi from "../../apis/UserApi";
import momenttimezone from "moment-timezone"

export function login(user) {
   return function(dispatch) {
    let userTimeZone = momenttimezone.tz.guess();
    return userApi
      .login(user, userTimeZone)
      .then((response) => {
        // dispatch({
        //   type: USER_LOGIN_SUCCESS,
        //   token: response.authKey,
        // });
        return response.authKey;
      })
      .catch((err) => {
        throw err;
      });
   };
}

export function switchUser(user) {
  // return function(dispatch) {
    let userTimeZone = momenttimezone.tz.guess();
    return userApi
      .switchUser(user, userTimeZone)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  // };
}
