import { useState, useEffect } from "react";
import * as appointmentApi from "../apis/appointmentsApi";

export default function useAppointmentTypes() {
  const [appointmentTypes, setAppointmentTypes] = useState([]);

  useEffect(() => {
    appointmentApi
      .getActiveAppointment()
      .then((response) => {
        setAppointmentTypes(response.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  return appointmentTypes;
}
