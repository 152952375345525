import { GET_NOTIFICATIONS_SUCCESS, GET_SUBSTITUTIONS_SUCCESS, GET_ADDITIONAL_SUBSTITUTIONS_SUCCESS, GET_SUBSTITUTION_FETCH_COACH_LIST_SUCCESS, CANCEL_SUBSTITUTIONS, REASSIGN_SUBSTITUTIONS } from "../constants";
import * as notificationApi from "../../apis/NotificationsApi";
import * as SubstitutionApi from "../../apis/SubstitutionApi";
import { toast } from "react-toastify";

export function getNotifications(params) {
  return function (dispatch) {
    return notificationApi
      .getNotifications(params)
      .then((response) => {
        dispatch({
          type: GET_NOTIFICATIONS_SUCCESS,
          notifications: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}


export function getSubstitutions(params) {
  return function (dispatch) {
    return notificationApi
      .getSubstitutions(params)  //Notifications(params)
      .then((response) => {
        dispatch({
          type: GET_SUBSTITUTIONS_SUCCESS,
          substitutions: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}


export function getAdditionalSubstitutions(params) {
  return function (dispatch) {
    return notificationApi
      .getAdditionalSubstitutions(params)  //Notifications(params)
      .then((response) => {
        dispatch({
          type: GET_ADDITIONAL_SUBSTITUTIONS_SUCCESS,
          additionalSubstitutions: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function cancelSubstitutionHomePage(request) {
  console.log(request);
  return function (dispatch) {
    return SubstitutionApi
      .cancelSubstitutionApi(request)
      .then((response) => 
        // dispatch(getAdditionalSubstitutions({ time_range: "upcoming" }))
        // console.log(response)
        // return notificationApi.getAdditionalSubstitutions({ time_range: "upcoming" }).then((response) => {
        //   dispatch({
        //     type: GET_ADDITIONAL_SUBSTITUTIONS_SUCCESS,
        //     additionalSubstitutions: response.data,
        //   });
        // })
       { if(response.data.status == "Success")
        dispatch({
          type: CANCEL_SUBSTITUTIONS,
          request,
        })
       })
      .catch((err) => {
        throw err;
      });
  };
}
export function fetchCoachSubstitutionHomePage(request) {
  return function (dispatch) {
    return SubstitutionApi
      .fetchCoachSubstitutionApi(request)
      .then((response) => {
        console.log("substitution fetch" + JSON.stringify(response))
        dispatch({
          type: GET_SUBSTITUTION_FETCH_COACH_LIST_SUCCESS,
          substitutionCoachList: response.data
        })
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function ModCoachAvailable(request) {
  return function (dispatch) {
    return SubstitutionApi
      .modCoachAvailabilityApi(request)
      .then((response) => {
        console.log("substitution fetch" + JSON.stringify(response))
        dispatch({
          type: GET_SUBSTITUTION_FETCH_COACH_LIST_SUCCESS,
          substitutionCoachList: response.data
        })
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function reassignSubstitution(request) {
  return function (dispatch) {
    return SubstitutionApi.reassignSubstitutionApi(request)
      .then((response) =>
      //  {
      //   console.log(response);
      //   return response.data;
      //   // if (strict_edit) return;
      //   // return dispatch(getSubstitutionList());
      // }
      
      {
        if(response.data == "This session was reassigned recently!")
        {
          alert(response.data);
        }
        else
        {
          toast.success(response.data)
        }
        if(response.data == "Coach has been reassigned successfully")
        dispatch({
          type: REASSIGN_SUBSTITUTIONS,
          request,
        })
       }
      )
      .catch((err) => {
        throw err;
      });
  };
}

export function grabSession(request) {
  return function (dispatch) {
    return SubstitutionApi.reassignSubstitutionApi(request)
      .then((response) =>
      {
        if(response.data == "Coach has been reassigned successfully")
        {
          dispatch({
            type: REASSIGN_SUBSTITUTIONS,
            request,
          })
          return response.data;
        }
        else
        {
          return response.data;
        }
      }
      )
      .catch((err) => {
        throw err;
      });
  };
}

