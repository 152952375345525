import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import decode from "jwt-decode";
import propTypes from "prop-types";

import Header from "./components/Commons/Header.jsx";
import Header_login from "./components/Commons/Header_login.jsx";
import Sidebar from "./components/Commons/Sidebar.jsx";
import Loading from "./components/Commons/Loading.jsx";

const VillageAdmin = lazy(() => import("./pages/VillageAdmin.jsx"));
const RSTVTopicPage = lazy(() => import("./pages/RSTVTopicPage.jsx"));
const ManageLanguagePage = lazy(() => import("./pages/ManageLanguagePage.jsx"));
const CoachProfilePage = lazy(() => import("./pages/CoachProfilePage.jsx"));
const CoachProfileEditPage = lazy(() => import("./pages/CoachProfileEditPage.jsx"));
const CoachProfileCreatePage = lazy(() => import("./pages/CoachProfileCreatePage.jsx"));
const UserProfilePage = lazy(() => import("./pages/UserProfilePage.jsx"));
const UserProfileEditPage = lazy(() => import("./pages/UserProfileEditPage.jsx"));
const LinkPage = lazy(() => import("./pages/LinkPage.jsx"));
const ProfilePage = lazy(() => import("./pages/ProfilePage.jsx"));
const ContactPreferencePage = lazy(() => import("./pages/ContactPreferencePage.jsx"));
const StudioLibraryPage = lazy(() => import("./pages/StudioLibraryPage.jsx"));
const LearnerDetailPage = lazy(() => import("./pages/LearnerDetailsPage.jsx"));
const LearnerSearchPage = lazy(() => import("./pages/LearnerSearchPage.jsx"));
const DashboardPage = lazy(() => import("./pages/DashboardPage.jsx"));
const StudioHistoryPage = lazy(() => import("./pages/StudioHistoryPage.jsx"));
const AppointmentPage = lazy(() => import("./pages/AppointmentPage.jsx"));
const CommentsPage = lazy(() => import("./pages/CommentsPage.jsx"));
const ResolutionsPage = lazy(() => import("./pages/ResolutionsPage.jsx"));
const TrackCommentsPage = lazy(() => import("./pages/TrackCommentsPage.jsx"));
const TrackUsersPage = lazy(() => import("./pages/TrackUsersPage.jsx"));
const AdminDashboardPage = lazy(() => import("./pages/AdminDashboardPage.jsx"));
const AuditLogPage = lazy(() => import("./pages/AuditLogPage.jsx"));
// const CoachSchedulerPage = lazy(() => import("./pages/CoachSchedulerPage.jsx"));
// const MySchedulerPage = lazy(() => import("./pages/MySchedulerPage.jsx"));
// const AppointmentSchedulerPage = lazy(() => import("./pages/AppointmentSchedulerPage.jsx"));
// const MasterSchedulerPage = lazy(() => import("./pages/MasterSchedulerPage.jsx"));
const RegionsPage = lazy(() => import("./pages/RegionsPage.jsx"));
const AssignCoachesPage = lazy(() => import("./pages/AssignCoachesPage.jsx"));
const CoachRosterPage = lazy(() => import("./pages/CoachRosterPage.jsx"));
const EditManagementTeamPage = lazy(() => import("./pages/EditManagementTeamPage.jsx"));
const NotificationPage = lazy(() => import("./pages/NotificationPage.jsx"));
const CoachTimeOffPage = lazy(() => import("./pages/CoachTimeOffPage.jsx"));
const SchedulingThresholdPage = lazy(() => import("./pages/SchedulingThresholdPage.jsx"));
const QaFormsPage = lazy(() => import("./pages/QaFormsPage.jsx"));
const QaFormsCategoriesPage = lazy(() => import("./pages/QaFormsCategoriesPage.jsx"));
const QaFormsEntriesPage = lazy(() => import("./pages/QaFormsEntriesPage.jsx"));
const ViewCoachSessionPage = lazy(() => import("./pages/ViewCoachSessionPage.jsx"));
const SubstitutionPage = lazy(() => import("./pages/SubstitutionPage.jsx"));
const SubstitutionPageReport = lazy(() => import("./pages/SubstitutionPageReport.jsx"));
const CoachSessionRatingPage = lazy(() => import("./pages/CoachSessionRatingPage.jsx"));
const QaScoreSheetPage = lazy(() => import("./pages/QaScoreSheetPage.jsx"));
const PreviewEvaluationPage = lazy(() => import("./pages/PreviewEvaluationPage.jsx"));

import LoginPage from "./pages/LoginPage.jsx";
import HomePage from "./pages/HomePage.jsx";
import CoachSchedulerPage from "./pages/CoachSchedulerPage.jsx";
import MySchedulerPage from "./pages/MySchedulerPage.jsx";
import AppointmentSchedulerPage from "./pages/AppointmentSchedulerPage.jsx";
import MasterSchedulerPage from "./pages/MasterSchedulerPage.jsx";
import ApplicationStatusPage from "./pages/ApplicationStatusPage.jsx";
import ProductRightsPage from "./pages/ProductRightsPage.jsx";
import ConsumablesReportPage from "./pages/ConsumablesReportPage.jsx";
import AutoRenewalReportPage from "./pages/AutoRenewalReportPage.jsx";
import TriggerPage from "./pages/TriggerPage.jsx";
import ScoreUpdatePage from "./pages/ScoreUpdatePage.jsx";

import "react-toastify/dist/ReactToastify.css";

const isAuth = () => {
  const token = localStorage.getItem("token");
  if (!token) return false;

  try {
    const { exp } = decode(token);
    if (exp < new Date().getTime() / 1000) return false;
  } catch (e) {
    return false;
  }
  return true;
};

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuth() ? (
        <div className="body-content">
          <aside className="sideBar">
            <Sidebar />
          </aside>
          <Component {...props} />
        </div>
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: {
              from: props.location,
              loggedOut: true,
            },
          }}
        />
      )
    }
  />
);

AuthRoute.propTypes = {
  component: propTypes.any.isRequired,
  location: propTypes.any,
};

function App() {
  return (
    <div>
      {isAuth() ? <Header /> : <Header_login />}
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <AuthRoute exact path="/homes" component={HomePage} />
        <AuthRoute path="/coach_scheduler" component={CoachSchedulerPage} />
        <AuthRoute path="/my_schedule" component={MySchedulerPage} />
        <AuthRoute path="/appointments" component={AppointmentSchedulerPage} />
        <AuthRoute path="/schedules" component={MasterSchedulerPage} />
        <AuthRoute path="/application-status" component={ApplicationStatusPage} />
        <AuthRoute path="/product-rights" component={ProductRightsPage} />
        <AuthRoute path="/consumables-report" component={ConsumablesReportPage} />
        <AuthRoute path="/autoRenewal-report" component={AutoRenewalReportPage} />
        <Suspense fallback={<Loading />}>
          <AuthRoute path="/homes/track_users" component={TrackUsersPage} />
          <AuthRoute path="/homes/admin_dashboard" component={AdminDashboardPage} />
          <AuthRoute path="/homes/audit_logs" component={AuditLogPage} />
          {/* <AuthRoute path="/announcements" component={DashboardPage} /> */}
          <AuthRoute path="/dashboard" component={DashboardPage} />
          <AuthRoute path="/links" component={LinkPage} />
          <AuthRoute path="/profile" component={ProfilePage} />
          <AuthRoute path="/contact-preference" component={ContactPreferencePage} />
          <AuthRoute path="/box_links" component={StudioLibraryPage} />
          <AuthRoute path="/edit-coach-profiles/:id" component={CoachProfileEditPage} />
          <AuthRoute path="/create-coach" component={CoachProfileCreatePage} />
          <AuthRoute path="/view-coach-profiles" component={CoachProfilePage} />
          <AuthRoute path="/view-profile" component={UserProfilePage} />
          <AuthRoute path="/edit-profile" component={UserProfileEditPage} />
          <AuthRoute path="/view_learner" component={LearnerDetailPage} />
          <AuthRoute exact path="/learners" component={LearnerSearchPage} />
          <AuthRoute path="/learner/:guid" component={StudioHistoryPage} />
          <AuthRoute path="/village-admin" component={VillageAdmin} />
          <AuthRoute path="/rstv_topics" component={RSTVTopicPage} />
          <AuthRoute path="/manage-languages" component={ManageLanguagePage} />
          <AuthRoute path="/appointment_types" component={AppointmentPage} />
          <AuthRoute path="/comment_categories" component={CommentsPage} />
          {<AuthRoute path="/resolutions" component={ResolutionsPage} />}
          {/* <Route component={PageNotFound} /> */}
          <AuthRoute path="/comments" component={TrackCommentsPage} />
          <AuthRoute path="/manager-notifications" component={NotificationPage} />
          <AuthRoute path="/regions" component={RegionsPage} />
          <AuthRoute path="/assign-coaches" component={AssignCoachesPage} />
          <AuthRoute path="/coach-roster" component={CoachRosterPage} />
          <AuthRoute path="/edit_management_team" component={EditManagementTeamPage} />
          <AuthRoute path="/coach_time_off" component={CoachTimeOffPage} />
          <AuthRoute path="/scheduling_thresholds" component={SchedulingThresholdPage} />
          <AuthRoute path="/qa_forms" component={QaFormsPage} />
          <AuthRoute path="/qa_forms_categories/:id" component={QaFormsCategoriesPage} />
          <AuthRoute path="/get_qa_entry_form" component={QaFormsEntriesPage} />
          <AuthRoute path="/view-coach-sessions" component={ViewCoachSessionPage} />
          <AuthRoute path="/coach_session_rating" component={CoachSessionRatingPage} />
          <AuthRoute path="/substitutions" component={SubstitutionPage} />
          <AuthRoute path="/substitutions-report" component={SubstitutionPageReport} />
          <AuthRoute path="/qa_score_sheet" component={QaScoreSheetPage} />
          <AuthRoute path="/view-preview-evaluation" component={PreviewEvaluationPage} />
          <AuthRoute path="/trigger" component={TriggerPage} />
          <AuthRoute path="/score-update" component={ScoreUpdatePage} />
        </Suspense>
      </Switch>
      <ToastContainer autoClose={3000} hideProgressBar />
    </div>
  );
}

export default App;
