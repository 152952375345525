import { GET_SCORE_UPDATE } from "../constants";
import { getScoreUpdate } from "../../apis/ScoreUpdateApi";

export function getFinalScore(params) {
  return function(dispatch) {
    return getScoreUpdate(params)
      .then((response) => {
        dispatch({
          type: GET_SCORE_UPDATE,
          score: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}