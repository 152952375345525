import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./SessionDetail.css";
import * as coachShedulerAction from "../../redux/actions/CoachShedulerAction";

function RequestSubstitute(props) {
  const { settings, setSettings, sessionInfo, requestSubstituteResponse } = props;

  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [textboxDisplay, setTextboxDisplay] = useState(false);
  const [text, setText] = useState("");

  function handleRequestClicked(e) {
    if (filter == "" || filter.reason == "") {
      setLoading(false);
      alert("Please Select a Reason.");
      return;
    } else if(filter == "Other"){
      if(text == ""){
        setLoading(false);
        alert("Please provide a reason");
        return;
      }
    }
    if (!confirm("Are you sure?")) {
      setSettings((settings) => ({ ...settings, ["displayRequestSubstitute"]: false }));
      document.getElementById("reasonList").value = "Select";
      setFilter("");
      setTextboxDisplay(false);
      document.getElementById("other-reasons").value = "";
      return;
    }
    setLoading(true);
    props.actions
      .requestSubstituteCoach(
        {
          //coach_id: sessionInfo.coach_id,
          coach_id: (sessionInfo.coach_id == props.coachFilter.coach_id) ? (sessionInfo.coach_id || props?.coachFilter?.coach_id) : sessionInfo.moderator_id,
          coach_session_id: sessionInfo.id,
          session_start_date: sessionInfo.session_start_time,
          session_end_date: sessionInfo.session_end_time,
          reason: filter == "Other" ? text : filter,
        },
        props.coachFilter
      )
      .then((response) => {
        setLoading(false);
        setSettings((settings) => ({ ...settings, ["displayRequestSubstitute"]: false }));
        toast.success("Request Substitute successfully");
        // console.log("request dilog", !settings.displayRequestSubstitute);
        props.onRequestSuccess(false); //!settings.displayRequestSubstitute);
        document.getElementById("reasonList").value = "Select";
        setFilter("");
        setTextboxDisplay(false);
        document.getElementById("other-reasons").value = "";
        if(document.getElementById("filter-language")){
          document.getElementById("filter-language").value=""; 
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error("Failed to request Substitute");
        document.getElementById("reasonList").value = "Select";
        setFilter("");
        setTextboxDisplay(false);
        document.getElementById("other-reasons").value = "";
      });
  }

  function handleCancelClicked(e) {
    e.preventDefault();
    setSettings((settings) => ({ ...settings, ["displayRequestSubstitute"]: false }));
    document.getElementById("reasonList").value = "Select";
    setFilter("");
    setTextboxDisplay(false);
    document.getElementById("other-reasons").value = "";
  }

  function onFilterChange(e) {
    const { name, value } = e.target;
    console.log("value========", value)
    if(value !== "Other"){
      setTextboxDisplay(false);
    } else{
      setTextboxDisplay(true);
    }
    setFilter(value);
  }

  function textChange(e){
    const { name, value } = e.target;
    setText(value);
  }

  return (
    <>
      <div className="blockOverlay" style={{ display: loading ? "block" : "none" }}></div>
      <div className="blockMsg" style={{ display: loading ? "block" : "none" }}>
        <h1>Please wait...</h1>
      </div>
      <div
        className="modal coach-schedule-session-details-modal"
        id="coach-schedule-request-subsitute"
        style={{
          display: settings.displayRequestSubstitute ? "block" : "none",
          left: settings.xAxis + "px",
          top: settings.yAxis + "px",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal_header">
              <h4 className="modal-title">REQUEST SUBSTITUTE</h4>
              <a
                className="close-icon"
                title="Close"
                aria-label="Close"
                role="button"
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  setSettings((settings) => ({ ...settings, ["displayRequestSubstitute"]: false }));
                  document.getElementById("reasonList").value = "Select";
                  setFilter("");
                  setTextboxDisplay(false);
                  document.getElementById("other-reasons").value = "";
                }}
              >
                <span>×</span>
              </a>
            </div>
            <div className="modal-body">
              <div>
                Please select the reason that best explains why a substitute is being requested for this session.
              </div>
              <div>
                <select name="reason" id = "reasonList" onChange={onFilterChange}>
                  <option value="Select">--REASON--</option>
                  <option value="Sickness">Sickness</option>
                  <option value="System Issue">System Issue</option>
                  <option value="Personal Emergency">Personal Emergency</option>
                  <option value="Internet outage">Internet outage</option>
                  <option value="Electricity outage">Electricity outage</option>
                  <option value="No-show">No-show</option>
                  <option value="Schedule glitch">Schedule glitch</option>
                  <option value="Personnel change">Personnel change</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div style={{ display: textboxDisplay ? "block" : "none" }}>
                <textarea cols="55" id="other-reasons" rows="3" title="Other reasons" onChange={textChange}></textarea>
              </div>
            </div>
            <div className="footer">
              <input
                type="button"
                value="Request"
                onClick={(e) => {
                  handleRequestClicked(e);
                }}
              />
              <input
                type="button"
                value="Cancel"
                onClick={(e) => {
                  handleCancelClicked(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  requestSubstituteResponse: state.requestSubstituteResponse,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      requestSubstituteCoach: bindActionCreators(coachShedulerAction.requestSubstituteCoach, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestSubstitute);
