import { GET_AUTORENEWAL_REPORT } from "../constants";
import { getAutoRenewal } from "../../apis/AutoRenewalReportApi";

export function getAutoRenewalReport(params) {
  return function(dispatch) {
    return getAutoRenewal(params)
      .then((response) => {
        dispatch({
          type: GET_AUTORENEWAL_REPORT,
          autoRenewalReport: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}