import { GET_CONSUMABLES_REPORT } from "../constants";
import { getConsumables } from "../../apis/ConsumablesReportApi";

export function getConsumablesReport(params) {
    console.log("inside action====")
  return function(dispatch) {
    return getConsumables(params)
      .then((response) => {
        console.log("response========",response);
        dispatch({
          type: GET_CONSUMABLES_REPORT,
          consumablesReport: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}