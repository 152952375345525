import _, { property } from "underscore";

export const getLanguageByIdentifier = _.memoize((identifier) => {
  const languages = [
    { language: "RS Unlimited", identifier: "ALL" },
    //rstv products
    { language: "English (US) - RSTV", identifier: "RSTV-ENG" },
    { language: "Arabic - RSTV", identifier: "RSTV-ARA" },
    { language: "Chinese (Mandarin) - RSTV", identifier: "RSTV-CHI" },
    { language: "Dutch - RSTV", identifier: "RSTV-NED" },
    { language: "English (British) - RSTV", identifier: "RSTV-EBR" },
    { language: "Spanish (Spain) - RSTV", identifier: "RSTV-ESC" },
    { language: "Spanish (Latin America) - RSTV", identifier: "RSTV-ESP" },
    { language: "Persian (Farsi) - RSTV", identifier: "RSTV-FAR" },
    { language: "French - RSTV", identifier: "RSTV-FRA" },
    { language: "Greek - RSTV", identifier: "RSTV-GRK" },
    { language: "Hebrew - RSTV", identifier: "RSTV-HEB" },
    { language: "Hindi - RSTV", identifier: "RSTV-HIN" },
    { language: "Italian - RSTV", identifier: "RSTV-ITA" },
    { language: "Japanese - RSTV", identifier: "RSTV-JPN" },
    { language: "Korean - RSTV", identifier: "RSTV-KOR" },
    { language: "Polish - RSTV", identifier: "RSTV-POL" },
    { language: "Portuguese (Brazil) - RSTV", identifier: "RSTV-POR" },
    { language: "Russian - RSTV", identifier: "RSTV-RUS" },
    { language: "Swedish - RSTV", identifier: "RSTV-SVE" },
    { language: "Filipino (Tagalog) - RSTV", identifier: "RSTV-TGL" },
    { language: "Turkish - RSTV", identifier: "RSTV-TUR" },
    { language: "Vietnamese - RSTV", identifier: "RSTV-VIE" },
    { language: "Irish - RSTV", identifier: "RSTV-GLE" },
    { language: "German - RSTV", identifier: "RSTV-DEU" },
    // Totale
    { language: "English (American)", identifier: "ENG" },
    { language: "Arabic ", identifier: "ARA" },
    { language: "Chinese (Mandarin) ", identifier: "CHI" },
    { language: "Dutch ", identifier: "NED" },
    { language: "English (British) ", identifier: "EBR" },
    { language: "Spanish (Spain) ", identifier: "ESC" },
    { language: "Spanish (Latin America) ", identifier: "ESP" },
    { language: "Persian (Farsi) ", identifier: "FAR" },
    { language: "French ", identifier: "FRA" },
    { language: "Greek ", identifier: "GRK" },
    { language: "Hebrew ", identifier: "HEB" },
    { language: "Hindi ", identifier: "HIN" },
    { language: "Italian ", identifier: "ITA" },
    { language: "Japanese ", identifier: "JPN" },
    { language: "Korean ", identifier: "KOR" },
    { language: "Polish ", identifier: "POL" },
    { language: "Portuguese (Brazil) ", identifier: "POR" },
    { language: "Russian ", identifier: "RUS" },
    { language: "Swedish ", identifier: "SVE" },
    { language: "Filipino (Tagalog) ", identifier: "TGL" },
    { language: "Turkish ", identifier: "TUR" },
    { language: "Vietnamese ", identifier: "VIE" },
    { language: "Irish ", identifier: "GLE" },
    { language: "German ", identifier: "DEU" },
    // your plan
    { language: "English (US) - YP-ENG", identifier: "YP-ENG" },
    { language: "English (UK) - YP-EBR", identifier: "YP-EBR" },
    { language: "French - YP-FRA", identifier: "YP-FRA" },
    { language: "Italian - YP-ITA", identifier: "YP-ITA" },
    { language: "German - YP-DEU", identifier: "YP-DEU" },
    { language: "Spanish (Spain) - YP-ESC", identifier: "YP-ESC" },
    { language: "Spanish (LA) - YP-ESP", identifier: "YP-ESP" },
    // fluency builder
    { language: "English (US) - FB-ENG", identifier: "FB-ENG" },
    { language: "English (UK) - FB-EBR", identifier: "FB-EBR" },
    { language: "French - FB-FRA", identifier: "FB-FRA" },
    { language: "Italian - FB-ITA", identifier: "FB-ITA" },
    { language: "German - FB-DEU", identifier: "FB-DEU" },
    { language: "Spanish (Spain) - FB-ESC", identifier: "FB-ESC" },
    //cross product
    { language: "English (US) - CP-ENG", identifier: "CP-ENG" },
    { language: "English (UK) - CP-EBR", identifier: "CP-EBR" },
    { language: "French - CP-FRA", identifier: "CP-FRA" },
    { language: "Italian - CP-ITA", identifier: "CP-ITA" },
    { language: "German - CP-DEU", identifier: "CP-DEU" },
    { language: "Spanish (Spain) - CP-ESC", identifier: "CP-ESC" },
    { language: "Spanish (LA) - CP-ESP", identifier: "CP-ESP" },
    //rstv
    { language: "RSTV", identifier: "rstv" },
    //fB solo
    { language: "English (US) - FB-ENG-Solo", identifier: "FB-ENG-Solo" },
    { language: "English (UK) - FB-EBR-Solo", identifier: "FB-EBR-Solo" },
    { language: "French - FB-FRA-Solo", identifier: "FB-FRA-Solo" },
    { language: "German - FB-DEU-Solo", identifier: "FB-DEU-Solo" },
    { language: "Italian - FB-ITA-Solo", identifier: "FB-ITA-Solo" },
    { language: "Spanish (Spain) - FB-ESC-Solo", identifier: "FB-ESC-Solo" },

  ];
  return { ...languages.find((lang) => lang.identifier === identifier) }.language || " -- none --";
});

export const makeAllFirst = _.memoize((languages) => {
  return [
    languages.find((language) => language.identifier == "ALL"),
    ...languages.filter((language) => language.identifier != "ALL"),
  ];
});

export const removeAllfromLanguages = _.memoize((languages) =>
  languages.filter((language) => language.identifier != "ALL")
);

export const removeAllAndCPfromLanguages = _.memoize((languages) =>
  languages.filter(
    (language) =>
      language.identifier != "ALL" &&
      language.identifier != "CP-ENG" &&
      language.identifier != "CP-EBR" &&
      language.identifier != "CP-FRA" &&
      language.identifier != "CP-ITA" &&
      language.identifier != "CP-DEU" &&
      language.identifier != "CP-ESC" &&
      language.identifier != "CP-ESP"
  )
);

export const sortLanguagesBy = ((languages, property) => {
  return languages.sort(function(a, b) {
    if ((a[property] || "").toLowerCase() < (b[property] || "").toLowerCase()) return -1;
    if ((a[property] || "").toLowerCase() > (b[property] || "").toLowerCase()) return 1;
    return 0;
  });
});

// export const sortCoachNamesBy = (coach, property) => {
//   return coach.sort(function(a, b) {
//     if (a[property] < b[property]) return -1;
//     if (a[property] > b[property]) return 1;
//     return 0;
//   });
// }

//CSP-1914
export const sortCoachNamesBy = (coach, property) => {
  return coach.sort(function(a,b) {
    return a[property].localeCompare(b[property]);
  })
}
//

//CSP-2557  
export const sortByDates = ((session, property) => {
  return session.sort(function(a, b) {
    if ((a[property] || "").toLowerCase() < (b[property] || "").toLowerCase()) return -1;
    if ((a[property] || "").toLowerCase() > (b[property] || "").toLowerCase()) return 1;
    return 0;
  });
});
//



export const getLocaleByIdentifier = _.memoize((identifier) => {
  const languages = [
    { language: "Chinese", identifier: "zh-CN" },
    { language: "English", identifier: "en-US" },
    { language: "French", identifier: "fr-FR" },
    { language: "German", identifier: "de-DE" },
    { language: "Italian", identifier: "it-IT" },
    { language: "Japanese", identifier: "ja-JP" },
    { language: "Korean", identifier: "ko-KR" },
    { language: "Portuguese", identifier: "pt-BR" },
    { language: "Russian", identifier: "ru-RU" },
    { language: "Spanish", identifier: "es-419" },
  ];
  if (identifier != "") {
    return { ...languages.find((lang) => lang.identifier === identifier) }.language;
  } else {
    return languages;
  }
});
//  array.find(arr => arr.identifier == "FB-ESC").language

export const removeAllRSTVandAddOne = _.memoize((languages) => {
  const lan = [
    ...languages.filter((lang) => !(lang.identifier.toLowerCase().includes("rstv") ? lang : null)),
    {
      connection_type: null,
      duration: 30,
      external_scheduler: "eschool",
      id: 100093,
      identifier: "rstv",
      language_name: "rstv",
      last_pushed_week: "2015-06-28T08:00:00.000Z",
      type: "RSTV",
    },
  ];

  console.log(lan);
  return lan;
});

export const getOtherLanguagesByIdentifier = _.memoize((identifier) => {
  const otherlanguages = [
    { language: "AEB US", identifier: "AUS" },
    { language: "AEB UK", identifier: "AUK" },
    { language: "Advanced English", identifier: "ADE" },
    { language: "Advanced English", identifier: "KLE" },
    { language: "RSA DEU - Phone", identifier: "TMM-DEU-P" },
    { language: "RSA ENG - Phone", identifier: "TMM-ENG-P" },
    { language: "RSA NED - Phone", identifier: "TMM-NED-P" },
    { language: "RSA FRA - Phone", identifier: "TMM-FRA-P" },
    { language: "RSA ITA - Phone", identifier: "TMM-ITA-P" },
    { language: "RSA ESP - Phone", identifier: "TMM-ESP-P" },
    { language: "RSA NED - Tutoring", identifier: "RSA_DUTCH" },
    { language: "RSA ENG - Tutoring", identifier: "RSA_ENGLISH" },
    { language: "RSA FRA - Tutoring", identifier: "RSA_FRENCH" },
    { language: "RSA DEU - Tutoring", identifier: "RSA_GERMAN" },
    { language: "RSA ITA - Tutoring", identifier: "RSA_ITALIAN" },
    { language: "RSA ESP - Tutoring", identifier: "RSA_SPANISH" },
    { language: "Michelin French", identifier: "TMM-MCH-L" },
    { language: "World Wide English", identifier: "WW-ENG" },
    { language: "English (American-PAL)", identifier: "PA-ENG" },
    { language: "English (Japan)", identifier: "JLE" },
    { language: "English (Korea)", identifier: "KLE" },
    { language: "English (Brazil)", identifier: "BLE" },
    { language: "English (China)", identifier: "CLE" },
  ];
  return { ...otherlanguages.find((lang) => lang.identifier === identifier) }.language;
});

export const getStartPageURL = _.memoize((identifier) => {
  const startPageUrl = [
    { url: "announcements", identifier: "ANNOUNCEMENTS" },
    { url: "dashboard", identifier: "DASHBOARD" },
    { url: "events", identifier: "EVENTS" },
    { url: "homes", identifier: "HOME" },
    { url: "schedules", identifier: "CM_MS" },
    { url: "manager-notifications", identifier: "CM_NOTIFICATIONS" },
    { url: "manager-notifications", identifier: "COACH_NOTIFICATIONS" },
    { url: "substitutions", identifier: "CM_SUBSTITUTIONS" },
    { url: "substitutions", identifier: "COACH_SUBSTITUTIONS" },
    { url: "learners", identifier: "LEARNERS_SEARCH" },
    { url: "view-profile", identifier: "VIEW_SUPPORT_PROFILE" },
    { url: "my_schedule", identifier: "MY_SCHEDULE" },
    { url: "my_schedule", identifier: "CALENDAR" }
  ];
  return { ...startPageUrl.find((url) => url.identifier === identifier) }.url || "learners";
});

export const sortAppointmentNamesBy = ((appointment, property) => {
  return appointment.sort(function(a, b) {
    if (a[property] < b[property]) return -1;
    if (a[property] > b[property]) return 1;
    return 0;
  });
});

export const sortCommentsNamesBy = ((comment, property) => {
  return comment.sort(function(a, b) {
    if (a[property] < b[property]) return -1;
    if (a[property] > b[property]) return 1;
    return 0;
  });
});

export const sortResolutionNamesBy = ((resolution, property) => {
  return resolution.sort(function(a, b) {
    if (a[property] < b[property]) return -1;
    if (a[property] > b[property]) return 1;
    return 0;
  });
});

export const sortRegionNamesBy = ((region, property) => {
  return region.sort(function(a, b) {
    if (a[property] < b[property]) return -1;
    if (a[property] > b[property]) return 1;
    return 0;
  });
});

export const sortAlphaNumeric = ((input, property) =>{
  return input.sort(function(a, b)  {
    // convert to strings and force lowercase
    a[property] = typeof a[property] === 'string' ? a[property].toLowerCase() : a[property].toString();
    b[property] = typeof b[property] === 'string' ? b[property].toLowerCase() : b[property].toString();

    return a[property].localeCompare(b[property]);
  }); 
});

export const sortWithAllCase = (input, property) => {
  return input.sort(function(a,b) {
    return a[property].localeCompare(b[property]);
  })
}

export const removeAllNonRSTV = _.memoize((languages) => {
  const lan = [
    ...languages.filter((lang) => (lang.identifier.toLowerCase().includes("rstv") ? lang : null)),
  ];

  console.log(lan);
  return lan;
});