import  React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import "./ScoreUpdate.css";
import * as ScoreUpdateAction from "../redux/actions/ScoreUpdateAction";

function ScoreUpdatePage(props) {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    function getScore(){
        props.actions.getFinalScore({"start_date_time": moment.utc(startDate).format("YYYY-MM-DD HH:mm:ss"),
        "end_date_time": moment.utc(endDate).format("YYYY-MM-DD HH:mm:ss")})
        .then((response)=>{toast.success("Success")})
        .catch((err)=>{toast.error("Failure")});
    }
    return (
        <>
            <div className="right-content">
                <div className="center_left">
                    <div className="score-update">
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            timeInputLabel="Time:"
                            dateFormat="dd/MM/yyyy h:mm aa"
                            showTimeInput
                            showDisabledMonthNavigation
                            className="date-picker"
                        />
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            endDate={endDate}
                            minDate={startDate}
                            timeInputLabel="Time:"
                            dateFormat="dd/MM/yyyy h:mm aa"
                            showTimeInput
                            showDisabledMonthNavigation
                            className="date-picker"
                        />
                        <button className="score-update-btn" onClick={getScore}>Submit</button>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    scoreUpdate: state.score
  });
  
  const mapDispatchToProps = (dispatch) => {
    return {
      actions: {
        getFinalScore: bindActionCreators(ScoreUpdateAction.getFinalScore, dispatch)
      },
    };
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(ScoreUpdatePage);