import {
  GET_STUDIO_LIBRARY_SUCCESS,
  ADD_STUDIO_LIBRARY_SUCCESS,
  UPD_STUDIO_LIBRARY_SUCCESS,
  DEL_STUDIO_LIBRARY_SUCCESS,
} from "../constants";

import initialState from "./initialState";

export default function studioLibraryReducer(state = initialState.studioLibrary, action) {
  switch (action.type) {
    case ADD_STUDIO_LIBRARY_SUCCESS:
      return [...state, { ...action.addStudioLibrary }];
    case GET_STUDIO_LIBRARY_SUCCESS:
      return action.studioLibrary;
    case UPD_STUDIO_LIBRARY_SUCCESS:
      return state.map((StudioLibrary) =>
        StudioLibrary.id === action.updateStudioLibrary.id ? action.updateStudioLibrary : StudioLibrary
      );
    case DEL_STUDIO_LIBRARY_SUCCESS:
      return state.filter((studioLibrary) => studioLibrary.id !== action.studioLibrary.id);
    default:
      return state;
  }
}
