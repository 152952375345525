import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import {Helmet} from "react-helmet";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import "./Reports.css";
import loaderIcon from "../components/images/big_spinner.gif";

import * as productReportActions from "../redux/actions/ProductReportAction";

const title = `Customer Success Portal: Product Rights Modification Report`;

function ProductRights(props) {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [loader, setLoader] = useState(false);
    const [noData, setNoData] = useState(false);
    const [reportTable, setReportTable] = useState(false);

    useEffect(() => {
        if(props.productRights.length === 0 && startDate !== undefined){
            setNoData(true);
        } else{
            setNoData(false);
        }
        if(props.productRights.length > 0){
            setReportTable(true);
        }
        setLoader(false);
    }, [props.productRights])

    function mySubmitHandler(e) {
        e.preventDefault();
        console.log("startDate=======", startDate);
        console.log("endDate=======", endDate);
        let start_date_submit = new Date(startDate);
        if(start_date_submit.getDate() == new Date().getDate()) {
          if(start_date_submit.getTime() < new Date().getTime()) {
            alert("Please select future time.");
            return false;
          }
        }
        if(new Date(startDate) < new Date(endDate) == false) {
          alert("Start date should be greater than end date.");
          return false;
        }
        setReportTable(false);
        setNoData(false);
        setLoader(true);
        let requestData = {};
        requestData = {
            'start_date': moment.utc(startDate).format("YYYY-MM-DD HH:mm:ss"),
            'end_date': moment.utc(endDate).format("YYYY-MM-DD HH:mm:ss")
        }
        props.actions.getProductRights(requestData);
    }
    const generateData = () => {
        let reportArray =[];
        reportArray.push(["Support User", "License Identifier", "License GUID", "Reason", "Ticket Number", "Duration", "Original End Date", "New End Date", "Date Extended"]);
        props.productRights.map((report) => {
            reportArray.push([report.support_name, report.email_id, report.license_guid, report.reason, report.ticket_number, report.duration, report.extendable_ends_at, report.new_end_date, report.created_at])
        })
        return reportArray
    }
  return (
    <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <div className="right-content">
            <div className="center_left">
                <div className="product-rights reports">
                    <div className="title">
                        <h2>Product Rights Modification Report</h2>
                    </div>
                    <div className="today-date-section">
                        <span className="today-date">Today&apos;s Date:</span>
                        <span className="today-date-in-dashboard">{moment(new Date()).format("dddd MMMM DD,YYYY")}</span>
                    </div>
                    <div className="rights-filter">
                        <div className="left-form">
                            <div>
                                <label>Show Records from: </label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={date => setStartDate(date)}
                                    className="date-picker"
                                    dateFormat="MMMM dd,yyyy"
                                />
                            </div>
                            <div>
                                <label>through: </label>
                                <DatePicker
                                    selected={endDate}
                                    onChange={date => setEndDate(date)}
                                    className="date-picker"
                                    dateFormat="MMMM dd,yyyy"
                                />
                            </div>
                        </div>
                        <div className="right-form">
                            <input type="button" className="generate-report" value="GENERATE REPORT" onClick={(e) => mySubmitHandler(e)}/>
                        </div>
                    </div>
                    {noData && (
                    <div className="no-extension-log">
                        <div>No Extension Logs available for the given time frame.</div>
                    </div>
                    )}
                    { loader && (
                    <div className="loaderImage">
                        <img src={loaderIcon}/>
                    </div>
                    )}
                    {reportTable && (
                    <div className="rights-content">
                        <table>
                            <thead>
                                <tr>
                                    <td style={{width: "100px"}}>Support User</td>
                                    <td style={{width: "120px"}}>License Identifier</td>
                                    <td style={{width: "130px"}}>License GUID</td>
                                    <td style={{width: "95px"}}>Reason</td>
                                    <td style={{width: "80px"}}>Ticket Number</td>
                                    <td style={{width: "70px"}}>Duration</td>
                                    <td style={{width: "125px"}}>Original End Date</td>
                                    <td style={{width: "120px"}}>New End Date</td>
                                    <td style={{width: "120px"}}>Date Extended</td>
                                </tr>
                            </thead>
                            <tbody>
                                {props.productRights?.map((report, idx) => (
                                <tr key={idx}>
                                    <td style={{width: "100px"}}>{report.support_name}</td>
                                    <td style={{width: "120px"}}>{report.email_id}</td>
                                    <td style={{width: "130px"}}>{report.license_guid}</td>
                                    <td style={{width: "95px"}}>{report.reason}</td>
                                    <td style={{width: "80px"}}>{report.ticket_number}</td>
                                    <td style={{width: "70px"}}>{report.duration}</td>
                                    <td style={{width: "125px"}}>{moment(report.extendable_ends_at).format("MM/DD/YY hh:mm A")}</td>
                                    <td style={{width: "120px"}}>{moment(report.new_end_date).format("MM/DD/YY hh:mm A")}</td>
                                    <td style={{width: "120px"}}>{moment(report.created_at).format("MM/DD/YY hh:mm A")}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    )}
                    {reportTable && (
                    <div className="export-csv-section">
                        <CSVLink
                        data={generateData()}
                        filename={"ProductRights_Report.csv"} className="csv-link" target="_blank"
                        >
                        EXPORT AS .CSV
                        </CSVLink>
                        <span>A complete set of Product Rights modification records are available as a downloadable .csv file</span>
                    </div>
                    )}
                </div>
            </div>
        </div>
    </>
  );
}

function mapStateToProps(state) {
    return {      
        productRights: state.productRights
      };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      actions: {
          getProductRights: bindActionCreators(productReportActions.getProductRights, dispatch)
      },
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ProductRights);
