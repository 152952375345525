import { AccountAPI, EschoolAPI } from "./apiUtils";

export const getCoachProfile = async (id = null) => await AccountAPI.get("/coachdetails", id && { params: { id } });

export const getContactPreference = async (id = null) =>
  await AccountAPI.get("/contact-preference", id && { params: { id } });

export const updateContactPreference = async (contact) => await AccountAPI.patch("/contact-preference", contact);

export const updateCoachProfile = async (coachProfile, type = null, qualficationLanguage = []) => {
  const { coach_manager, supervisor, ...profile } = coachProfile;
  return await AccountAPI.patch(
    "/accounts",
    [
      [profile],
      qualficationLanguage, 
      [{ coach_manager, supervisor }],
    ],
    type && { params: { type } } //{ params: { type: "profile" } }
  );
};

export const createCoachProfile = async(data) =>    
{return await AccountAPI.post("/accounts",{...data}) };

export const getRegions = async () => EschoolAPI.get("/regions");

export const getCoachNames = async (type) => await AccountAPI.get("/coachnames", type && { params: { type } });
export const getQualifiedCoaches = async (language_identifier) => await AccountAPI.get("/qualified-coach-list", { params: { language_identifier } });
export const getQualifiedLanguages = async (id) => await AccountAPI.get("/get-qualifications", { params: {id} });
export const getCoachMaxUnits = async (params) => await EschoolAPI.get(`/max-unit-level-for-coach?coachId=${params.coach_id}&langCode=${params.language_id}`);
export const getAllMaxUnits = async () => await AccountAPI.get("/max-unit-all-languages");
//To update Availability
export const triggerAvailability = async () => await AccountAPI.get("/coach-availability");
//export const getAllMaxUnits = async (id) => await AccountAPI.get("/max-unit-all-languages");


// http://localhost:3000/api/coachnames?type=CoachManager
// export const addCoachProfile = async message =>
// await API.post("/coachProfile",{
//     ...message,
//     "created_by": 16,
// });
//export const getCoachProfile = () => { return "test"};

export const getActiveStatus = async(params) => {return await EschoolAPI.post("/active-status", params)};

export const getCoachLockedStatus = async(coach_id) => {return await EschoolAPI.get(`check-coach-locked?coach_id=${coach_id}`)};