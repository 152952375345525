import { GET_RSTV_SUCCESS, UPD_RSTV_SUCCESS } from "../constants";

import * as rstvApi from "../../apis/RSTVTopicApi";

export function getRstv() {
  return function(dispatch) {
    return rstvApi
      .getRstvTopic()
      .then((response) => {
        console.log("ref");
        console.log(response.data);
        dispatch({
          type: GET_RSTV_SUCCESS,
          rstvResponse: [],
        });
        dispatch({
          type: GET_RSTV_SUCCESS,
          rstvResponse: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getRstvUpdate() {
  return function(dispatch) {
    return rstvApi
      .getRstvTopicUpdate()
      .then((response) => {
        dispatch({
          type: UPD_RSTV_SUCCESS,
          rstvResponse: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function updateRSTV(data) {
  return function(dispatch) {
    return rstvApi
      .updateRSTVTopic(data)
      .then((response) => {
        console.log(response);
        let message = response.data;
        if (message.length == undefined) {
          dispatch(getRstv());
        } else {
          return message[0].error_message;
        }
      })
      .catch((err) => {
        throw err;
      });
  };
}
export function addRSTV(data) {
  return function(dispatch) {
    return rstvApi
      .addRSTVTopic(data)
      .then((response) => {
        console.log(response);
        let message = response.data;
        if (message.length == undefined) {
          dispatch(getRstv());
        } else {
          return message[0].error_message;
        }
      })

      .catch((err) => {
        throw err;
      });
  };
}
