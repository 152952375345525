import { GET_LINKS_SUCCESS, ADD_LINKS_SUCCESS, UPD_LINKS_SUCCESS, DEL_LINKS_SUCCESS } from "../constants";

import * as linksApi from "../../apis/linksApi";

export function getLinks(id) {
  return function(dispatch) {
    return linksApi
      .getLinks(id)
      .then((response) =>
        dispatch({
          type: GET_LINKS_SUCCESS,
          links: response.data,
        })
      )
      .catch((err) => {
        throw err;
      });
  };
}

export function addLink(link) {
  return function(dispatch) {
    return linksApi
      .addLink(link)
      .then((addLink) =>
        dispatch({
          type: ADD_LINKS_SUCCESS,
          addLink: {
            ...link,
            ["id"]: addLink.insertId,
          },
        })
      )
      .catch((err) => {
        throw err;
      });
  };
}

export function updateLink(link, id) {
  return function(dispatch) {
    return linksApi
      .updateLink(link, id)
      .then(() =>
        dispatch({
          type: UPD_LINKS_SUCCESS,
          updateLink: {
            ...link,
            ["id"]: id,
          },
        })
      )
      .catch((err) => {
        throw err;
      });
  };
}

export function removeLinks(link) {
  return function(dispatch) {
    return linksApi
      .deleteLink(link.id)
      .then(() =>
        dispatch({
          type: DEL_LINKS_SUCCESS,
          link,
        })
      )
      .catch((err) => {
        throw err;
      });
  };
}
