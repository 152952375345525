import {
    GET_AUDIT_LOGS_TABLE_NAME_SUCCESS
} from "../constants";

import initialState from "./initialState";

export default function auditLogsTableNamesReducer(state = initialState.auditLogsTableNames, action) {
    switch (action.type) {
        case GET_AUDIT_LOGS_TABLE_NAME_SUCCESS:
            return action.auditLogsTableNames;
        default:
            return state;
    }
}
