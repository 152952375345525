import React, { useState, useEffect } from "react";
import close from "../images/closelabel.gif";
import { toast } from "react-toastify";

export default function CancelSubstitute({ ...props }) {
  const { cancelToggle, setCancelToggle, apiIDRequest, apiCoachSessionIDRequest, cancelSubstitutionFunction  } = props;
  const [apiReasonRequest, setApiReasonRequest] = useState('');
  const [loader, setLoader] = useState(false);
  const [textboxDisplay, setTextboxDisplay] = useState(false);
  const [text, setText] = useState("");

  function cancelSession(e) {
    e.preventDefault();
    setLoader(true);
    if(apiReasonRequest == '') {
      setLoader(false);
      alert('Please select a valid reason');
      return false;
    } else if(apiReasonRequest == "Other"){
      if(text == ""){
        setLoader(false);
        alert("Please provide a reason");
        return;
      }
    }
    let request = {
      "id":apiIDRequest, 
      "coach_session_id":apiCoachSessionIDRequest,
      "reason": apiReasonRequest == "Other" ? text : apiReasonRequest,
    }
    cancelSubstitutionFunction(request).then((response) => {
      setLoader(false);
      setCancelToggle(!cancelToggle);
      toast.success("Session cancelled successfully");
    })
    .catch(() => {
      setLoader(false);
      toast.error("Failed to cancel...");
      setCancelToggle(!cancelToggle);
    });
    document.getElementById("cancel-reasons").value = "Select";
    setApiReasonRequest("");
    setTextboxDisplay(false);
    document.getElementById("cancel-other-reasons").value = "";
  }

  function textChange(e){
    const { name, value } = e.target;
    setText(value);
  }

  return (
    <div className="container">
      <div className="blockOverlay" style={{ display: loader ? "block" : "none" }}></div>
      <div className="blockMsg" style={{ display: loader ? "block" : "none" }}>
        <h1>Please wait...</h1>
      </div>
      <div
        className="modal"
        style={{ display: cancelToggle ? "block" : "none" }}
        id="add_rstv_modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal_header">
              <h4 className="modal-title">Cancel Substitute</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => {
                    setCancelToggle(!cancelToggle);
                    document.getElementById("cancel-reasons").value = "Select";
                    setApiReasonRequest("");
                    setTextboxDisplay(false);
                    document.getElementById("cancel-other-reasons").value = "";
                  }
                } >
                <img src={close} title="close" className="close_image" />
              </button>
            </div>
            <div className="modal-body">
              <p className="subnote">
              Please select the reason that best explains why this session is being cancelled.
              </p>
             <p>
               <select id="cancel-reasons" value={apiReasonRequest} name="reasons" onChange={(e) => {
                  if(e.target.value !== "Other"){
                    setTextboxDisplay(false);
                  } else{
                    setTextboxDisplay(true);
                  }
                  setApiReasonRequest(e.target.value);
                }}>
                 <option value="">--REASON--</option>
                <option value="Sickness">Sickness</option>
                <option value="System Issue">System Issue</option>
                <option value="Personal Emergency">Personal Emergency</option>
                <option value="Internet outage">Internet outage</option>
                <option value="Electricity outage">Electricity outage</option>
                <option value="No-show">No-show</option>
                <option value="Schedule glitch">Schedule glitch</option>
                <option value="Personnel change">Personnel change</option>
                <option value="Other">Other</option>
                </select>
              </p>
              <div style={{ display: textboxDisplay ? "block" : "none" }}>
                <textarea cols="55" id="cancel-other-reasons" rows="3" title="Other reasons" onChange={textChange}></textarea>
              </div>
            </div>
            <div className="footer">
              <p>
                {" "}
                <input
                  id="rstvData_submit"
                  type="submit"
                  value="Cancel Session"
                  onClick={(e) => cancelSession(e)}
                />{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
