/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useMemo } from "react";
import decode from "jwt-decode";
import { Helmet } from "react-helmet";
import moment from "moment";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import close from "../components/images/closelabel.gif";
import * as notificationsAction from "../redux/actions/notificationsAction";
import * as coachProfileAction from "../redux/actions/coachProfileAction";
import * as CoachTimeOffAction from "../redux/actions/CoachTimeOffAction";
import List from "../components/LinkComponent/List";
import { toast } from "react-toastify";
import "./HomePage.css";
import { useHistory } from "react-router-dom";
import AutoSubModal from "../components/CoachSchedulerComponent/AutoSubModal";
import * as substitutionAction from "../redux/actions/SubstitutionAction";

function Notification(props) {
  const { notification, notificationData, substitution, className, id, onClickHandler,  profile, setAutoSubDisplay, setSubCoachName, getSubstitutionFilterApplyListFunction, getSubstitutionFilterListFunction} = props;
  const [approvedenyText, setapprovedenyText] = useState(' Approve | Deny');
  const [enableClick, setEnableClick]= useState(true);

  function autoSub(list){
   getSubstitutionFilterApplyListFunction({...profile, coachId: list.coach_id})
    .then(() => {
      getSubstitutionFilterListFunction({time_range: profile.timeRange.toLowerCase(), language: profile.langCode}).then(console.log());
      setAutoSubDisplay(true);
    });
  }
  // console.log(props);
  // console.log(substitution);
  if (typeof notification != "undefined" && !notification?.includes("<a>"))
    return React.createElement("p", null, notification);
  if (typeof substitution != "undefined" && !substitution?.includes("<a>"))
    return React.createElement("p", null, substitution);
  const history = useHistory();
  if (typeof notification != "undefined") {
    const coachName = notification.substring(notification.indexOf("<a>") + 3, notification.indexOf("</a>"));
    const notificationText = notification.substring(notification.indexOf("</a>") + 4, notification.length - 1);

    return React.createElement("p", null, [
      React.createElement(
        "a",
        {
          className,
          onClick: (e) => {
            e.preventDefault();
            onClickHandler({ id: id });
            history.push("/view-coach-profiles?coach_id=" + props.id);
          },
        },
        coachName
      ),
      notificationText,
      (props.status == 0 && enableClick ?
        [React.createElement(
          "a",
          {
            className,
            onClick: function(e) {
              e.preventDefault();
              let request = {
                "id": props.time_off_id,
                "status": true
              }

            props.setResponseTimeOffHomePageFunction(request)
              .then((response) => {
                  if(response.data == true) {
                    props.getNotifications({
                      home: true,
                      time_range: "in the last 30 days",
                    }).then((response) => {
                      setEnableClick(false);
                      toast.success("Approved Successfully");
                      autoSub(notificationData);
                      setSubCoachName(notification.substring(notification.indexOf("<a>") + 3, notification.indexOf("</a>")));
                    });
                  }
              });
            },
          },
          ' Approve'
        ), ' | ',
        React.createElement(
          "a",
          {
            className,
            onClick: function(e) {
              e.preventDefault();
              setEnableClick(false);
              let request = {
                "id": props.time_off_id,
                "status": false
              }

            props.setResponseTimeOffHomePageFunction(request)
              .then((response) => {
                  if(response.data == true) {
                    props.getNotifications({
                      home: true,
                      time_range: "in the last 30 days",
                    }).then((response) => {

                      toast.success("Denied Successfully");
                    });
                  }
              });
            },
          },
          'Deny'

        ) ]: (props.status == 1 ? <b> Approved</b> : (props.status == 2 && <b> Denied</b>))
      )
    ]);
  }

  if (typeof substitution != "undefined") {
    const coachName = substitution.substring(substitution.indexOf("<a>") + 3, substitution.indexOf("</a>"));
    const substitutionText = substitution.substring(0, substitution.indexOf("<a>"));

    return React.createElement("p", null, [
      substitutionText,
      React.createElement(
        "a",
        {
          className,
          onClick: (e) => {
            e.preventDefault();
            onClickHandler({ id: id });
            history.push("/view-coach-profiles?coach_id=" + props.id);
          },
        },
        coachName
      ),
    ]);
  }
}


function SubstitutionNoGrab(props) {
  const { notification, substitution, className, id, onClickHandler, substitutionCoachList, subId } = props;
  const [loader, setLoader] = useState(false);

  //console.log('test 12', reAssignToggleHP);
  const user = decode(localStorage.getItem("token"));
  // console.log(props);
  console.log("Notification" + notification);
  console.log("Substitution" + substitution);
  // if (typeof notification != "undefined" && !notification?.includes("<a>"))
  //   return React.createElement("p", null, notification);
  if (typeof substitution != "undefined" && !substitution?.includes("<a>"))
    return React.createElement("p", null, substitution);
  const history = useHistory();
  if (typeof notification != "undefined" && Object.keys(notification).length > 0) {
    const coachName = notification?.includes("<a>") ? (notification.substring(
      notification.indexOf("<a>") + 3,
      notification.indexOf("</a>"))) : ""
    ;
    const notificationText = notification?.includes("<a>") ? (notification.substring(
      notification.indexOf("</a>") + 4,
      notification.length)) : notification;
    // const notificationText = notification;
    console.log("======Notification text" + notificationText);
console.log(props)
return (
  <>
    {loader && (
      <>
        <div className="blockOverlay" style={{ display: "block" }}></div>
        <div className="blockMsg" style={{ display: "block" }}>
          <h1>Please wait...</h1>
        </div>
      </>
    )}
    { React.createElement("p", null, [ (coachName != null ?
      (React.createElement(
        "a",
        {
          className,
          onClick: (e) => {
            e.preventDefault();
            onClickHandler({ id: id });
            history.push("/view-coach-profiles?coach_id=" + props.id);
          },
        },
        coachName
      )) : ""),
     [notificationText,
      (user.type == "CoachManager" ?
      [React.createElement(
        "a",
        {
				className,
				onClick: function(e) {
          e.preventDefault();
          setLoader(true);
          let request = {
            "id":props.row_id,
            "coach_session_id":props.coach_session_id,
            "reason":"substituted and cancelled"
          }
          console.log(request)
          props.cancelSubstitutionHomePageFunction(request)
          .then((response) => {
            setLoader(false);
            console.log(response)
            toast.success("Cancelled Successfully");
          });
				},
			},
		' Cancel'
			), ' | ',
      React.createElement(
        "a",
        {
          className,
          onClick: function(e) {
            e.preventDefault();
            props.setReAssignToggleHP(!props.reAssignToggleHP);
            props.setReAssignRequest({ ...props.reAssignRequest, ["filter_selected_coach_value"]: id, ["coach_session_id"]:props.coach_session_id, ["id"]: subId});
            // props.fetchCoachSubstitutionHomePageFunction(props.coach_session_id).then((response) => {
            // //   props.actions.getSubstitutions({ home: true });
            // });
            props.fetchAvailableCoachFunction({
              language: props.language,
              session_start_time : props.start_time,
              from_sub: true
            }).then((response) => {

              });
          },
        },
        'Reassign'
      )]: (props.can_grab == true && props.grab_disable == false) ? React.createElement(
        "a",
        {
          className,
          onClick: function(e) {
            e.preventDefault();
            props.reassignSubstitutionFunction({
              filter_selected_coach_value : id,
              from_cs: false,
              assigned_coach: user.id,
              reason:"Auto Subrequest",
              coach_session_id:props.coach_session_id,
              scheduled_session_id: props.scheduled_session_id,
              scheduled_eschool_session_id: props.scheduled_eschool_session_id,
              is_grabing: true,
              id: subId
          }).then((response) => {
            props.getAdditionalSubstitutions({ time_range: "upcoming" });
            //toast.success("Grabbed Successfully");
          })
          },
        },
        ' Grab'
        ) : " Grab"
        )
     ]
    ])}
    </>
)
  }

  if (typeof substitution != "undefined") {
    console.log("======Substitution text" + substitution);
    const coachName = substitution.substring(
      substitution.indexOf("<a>") + 3,
      substitution.indexOf("</a>")
    );
    const substitutionText = substitution.substring(
      0,
      substitution.indexOf("<a>")
    );

    return React.createElement("p", null, [
      substitutionText,
      React.createElement(
        "a",
        {
          className,
          onClick: (e) => {
            e.preventDefault();
            onClickHandler({ id: id });
            history.push("/view-coach-profiles");
          },
        },
        coachName
      ),
      [substitutionText,
        (user.type == "CoachManager" ?
        [React.createElement(
          "a",
          {
          className,
          onClick: function(e) {
            e.preventDefault();
            let request = {
              "id":props.row_id,
              "coach_session_id":props.coach_session_id,
              "reason":"substituted and cancelled"
            }
            console.log(request)
            props.cancelSubstitutionHomePageFunction(request)
            .then((response) => {
              console.log(response)
              toast.success("Cancelled Successfully");
            });
          },
        },
      ' Cancel'
        ), ' | ',
        React.createElement(
          "a",
          {
            className,
            onClick: function(e) {
              e.preventDefault();
              props.setReAssignToggleHP(!props.reAssignToggleHP);
              console.log(props.reAssignToggleHP);
              // props.fetchCoachSubstitutionHomePageFunction(props.coach_session_id).then((response) => {
              // //   props.actions.getSubstitutions({ home: true });
              // });
              props.fetchAvailableCoachFunction({
                language: props.language,
                session_start_time : props.start_time,
                from_sub: true
              }).then((response) => {

                });
            },
          },
          'Reassign'
        )]:(props.can_grab == true && props.grab_disable == false) ? React.createElement(
          "a",
          {
            className,
            onClick: function(e) {
              e.preventDefault();
              props.reassignSubstitutionFunction({
                filter_selected_coach_value : id,
                from_cs: false,
                assigned_coach: user.id,
                reason:"Auto Subrequest",
                coach_session_id:props.coach_session_id,
                scheduled_session_id: props.scheduled_session_id,
                scheduled_eschool_session_id: props.scheduled_eschool_session_id,
                is_grabing: true
            }).then((response) => {
              props.getAdditionalSubstitutions({ time_range: "upcoming" });
              //toast.success("Grabbed Successfully");
            })
            },
          },
          ' Grab'
        ) : " Grab"
        )
       ]
    ]);
  }
}

const title = "Customer Success Portal:";

function HomePage(props) {
  const user = decode(localStorage.getItem("token"));
  const [modifiedSubstitutions, setModifiedSubstitutions] = useState([]);
  const [reAssignToggleHP, setReAssignToggleHP] = useState(false);
  const {substitutionCoachList} = props;
  let modified = [];
  const [selectedCoach, setSelectedCoach] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [reAssignRequest, setReAssignRequest] = useState({
      filter_selected_coach_value : "",
      from_cs: false,
      assigned_coach: "",
      reason:"Auto Subrequest",
      coach_session_id:0,
      id: "",
      scheduled_session_id: "",
      scheduled_eschool_session_id: ""
  });


  const [autoSubDisplay, setAutoSubDisplay]  = useState(false);
  const [profile, setProfile] = useState({
    "coachId": "all",
    "langCode": "all",
    "learnersCount": "all",
    "timeRange": "Upcoming",
    "auto_sub": true
  });



  const [subCoachName, setSubCoachName] = useState("");

  useEffect(() => {
    props.actions.getSubstitutions({ home: true });

    props.actions.getNotifications({
      home: true,
      time_range: "in the last 30 days",
      // time_range: "custom",
      // start_date: "2018-01-01",
      // end_date: moment(new Date()).format("YYYY-MM-DD"),
    });
  }, []);

  useEffect(() => {
    if (props.additionalSubstitutions.length == 0 && !loaded) {
      props.actions.getAdditionalSubstitutions({ time_range: "upcoming" });
      setLoaded(true);
    }
    props.additionalSubstitutions.map((substitution) => {
      modified.push(
        { notification: substitution.message,
          reason: "",
          coach_id: substitution.coach_id,
          coach_session_id:substitution.data[0].coach_session_id,
          row_id:substitution.data[0].id,
          language: substitution.data[0].language_identifier,
          start_time: substitution.data[0].session_start_time,
          id: substitution.data[0].id,
          scheduled_session_id: substitution.data[0].scheduled_session_id,
          scheduled_eschool_session_id: substitution.data[0].scheduled_eschool_session_id,
          can_grab: substitution.data[0].can_grab,
          grab_disable: substitution.data[0].grab_disable
        });
    });
    console.log("modified value" + modified);
    setModifiedSubstitutions(modified);
  }, [props.additionalSubstitutions]);

  const getNotificationInTimeSeries = useMemo(() => {
    const groups = props.notifications?.reduce((groups, item) => {
      const timedata = moment(item.created_at).format("YYYY-MM-DD");
      const group = groups[timedata] || [];
      group.push(item);
      groups[timedata] = group;
      return groups;
    }, {});
   return groups;
  });

   function reassignSubstitute(e){
     props.actions.reassignSubstitutionFunction(reAssignRequest).then((response) => {
     // props.actions.getSubstitutions({ home: true });
      setReAssignToggleHP(!reAssignToggleHP);
     // props.actions.getAdditionalSubstitutions({ time_range: "upcoming" });
      //toast.success("Reassigned Successfully");
      //toast.success(response.data);
     })
   }

   function onFilterChange(e){
    const { name, value } = e.target;
    let parsedValue = JSON.parse(value);
    setReAssignRequest((reAssignRequest) => ({ ...reAssignRequest, ["assigned_coach"]: parsedValue.coach_id, ["scheduled_session_id"]: parsedValue.scheduled_session_id ? parsedValue.scheduled_session_id : "",["scheduled_eschool_session_id"]: parsedValue.scheduled_eschool_session_id ? parsedValue.scheduled_eschool_session_id : "" }))
   }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container">
      <div
        className="modal"
        style={{ display: reAssignToggleHP ? "block" : "none" }}
        id="add_rstv_modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal_header">
              <h4 className="modal-title">Assign Substitute</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => setReAssignToggleHP(!reAssignToggleHP)} >
                <img src={close} title="close" className="close_image" />
              </button>
            </div>
            <div className="modal-body">

              <select name="assigned_coach" onChange={onFilterChange}>
                <option value="">--AVAILABLE--</option>
               {props.substitutionCoachList != undefined && props.substitutionCoachList.length > 0 && (props.substitutionCoachList).map((response, i) => (
                    // <option key={i} value={response.id}>
                    <option key={i} value={'{"coach_id":' + response.id + ', "scheduled_session_id": ' + JSON.stringify(response.scheduled_session_id) + ', "scheduled_eschool_session_id": ' + JSON.stringify(response.scheduled_eschool_session_id) + '}'} style={{backgroundColor: response?.have_session ? "#ffa500" : "" }}>
                        {response.full_name}
                    </option>
                    ))}
            </select>

            </div>
            <div className="footer">
              <p>
                {" "}
                <input
                  id="Reassign"
                  type="submit"
                  value="Assign"
                   onClick = {(e) => reassignSubstitute()}
                />
                <input
                  id="cancel"
                  type="button"
                  value="Cancel"
                  onClick={() => setReAssignToggleHP(!reAssignToggleHP)}
                />
                {" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

      <div className="right-content" test-id="right-content">
      {queryString.parse(window.location.search).switched && (
        <div className="login-notice"><p>Signed back in as {JSON.stringify(user) != "{}" && user.full_name} from {queryString.parse(window.location.search).prevUser}</p></div>
      )}
      <div className="home-left-align">
        <div className="subsitutions">
          <span style={{ float: "left" }}>
            <h2>Substitutions</h2>
          </span>
          <br />
          <hr />
          <a href="/substitutions" className="right">
            SEE ALL
          </a>
          <div>
          <table
            id="home_page_subs"
            className="table_list_subs substitutions-table"
            style={{
              margin: "25px auto",
              marginTop: "2px",
              width: "100%",
              border: "0px solid black",
            }}
          >
            <tbody>
            {modifiedSubstitutions.map((substitution, i) => (
                <tr
                  key={i}
                  style={{
                    textAlign: "left",
                    fontSize: "11px",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  <td
                    className="left-border sub_center left-indent"
                    style={{ border: "0px" }}
                  >
                    <SubstitutionNoGrab
                      notification={substitution.notification}
                      className="anchor"
                      subId={substitution.id}
                      id={substitution.coach_id}
                      coach_session_id = {substitution.coach_session_id}
                      row_id = {substitution.row_id}
                      start_time = {substitution.start_time}
                      language = {substitution.language}
                      onClickHandler={props.actions.getCoach}
                      cancelSubstitutionHomePageFunction={props.actions.cancelSubstitutionHomePageFunction}
                      fetchCoachSubstitutionHomePageFunction={props.actions.fetchCoachSubstitutionHomePageFunction}
                      fetchAvailableCoachFunction={props.actions.fetchAvailableCoachFunction}
                      reAssignToggleHP={reAssignToggleHP}
                      setReAssignToggleHP ={setReAssignToggleHP}
                      substitutionCoachList={props.substitutionCoachList}
                      reAssignRequest={reAssignRequest}
                      setReAssignRequest={setReAssignRequest}
                      reassignSubstitutionFunction={props.actions.reassignSubstitutionFunction}
                      getAdditionalSubstitutions={props.actions.getAdditionalSubstitutions}
                      scheduled_session_id={substitution.scheduled_session_id}
                      scheduled_eschool_session_id={substitution.scheduled_eschool_session_id}
                      can_grab={substitution.can_grab}
                      grab_disable={substitution.grab_disable}
                    />
                  </td>
                  <td className="left-border" style={{ border: "0px" }}>
                    <a
                      href="#"
                      id="cancel_subbed_session_30837"
                      onClick="cancelSubbedSessionConfirmation(30837); return false;"
                    ></a>
                  </td>
                  <td
                    style={{ width: "180px", border: "0px" }}
                    className="right-border"
                  >
                    <div
                      style={{ verticalAlign: "middle" }}
                      id="reassign_block30837"
                    >
                      <a
                        href="#"
                        onClick="fixedFaceBoxWithHeaderAndID('/fetch_available_coaches?sub_id=30837','ASSIGN SUBSTITUTE','reassign_block30837'); return false;"
                      ></a>
                    </div>
                  </td>
                </tr>
              ))}
              <tr></tr>

              {props.substitutions.map((substitution, i) => (
                <tr
                  key={i}
                  style={{
                    textAlign: "left",
                    fontSize: "11px",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  <td
                    className="left-border sub_center"
                    style={{ border: "0px" }}
                  >
                    <Notification
                      substitution={substitution.notification}
                      className="anchor"
                      id={substitution.coach_id}
                      onClickHandler={props.actions.getCoach}
                    />
                    {/* <p dangerouslySetInnerHTML={{ __html: substitution.notification }} /> */}
                  </td>
                  <td className="left-border" style={{ border: "0px" }}>
                    <a
                      href="#"
                      id="cancel_subbed_session_30837"
                      onClick="cancelSubbedSessionConfirmation(30837); return false;"
                    ></a>
                  </td>
                  <td
                    style={{ width: "180px", border: "0px" }}
                    className="right-border"
                  >
                    <div
                      style={{ verticalAlign: "middle" }}
                      id="reassign_block30837"
                    >
                      <a
                        href="#"
                        onClick="fixedFaceBoxWithHeaderAndID('/fetch_available_coaches?sub_id=30837','ASSIGN SUBSTITUTE','reassign_block30837'); return false;"
                      ></a>
                    </div>
                  </td>
                </tr>
              ))}
              <tr></tr>

            </tbody>
          </table>
        </div>
        </div>
        <div className="notifications">
          <span style={{ float: "left" }}>
            <h2>Notifications</h2>
          </span>
          <br />
          <hr />
          <a href="/manager-notifications" className="right">
            SEE ALL
          </a>
          <div style={{ width: "100%" }}>
            <table
              style={{ borderLeft: "none", borderTop: "none", width: " 100%" }}
            >
              {Object.keys(getNotificationInTimeSeries).map((timeSeries) => (
                <tbody key={timeSeries}>
                  <tr>
                    <th
                      className="notification_header"
                      colSpan="2"
                      style={{ textAlign: "left" }}
                    >
                      <b>
                        {moment(timeSeries, "YYYY-MM-DD").isSame(
                          moment(new Date())
                            .add(-1, "days")
                            .format("YYYY-MM-DD")
                        )
                          ? "Yesterday"
                          : moment(timeSeries, "YYYY-MM-DD").isSame(
                              moment(new Date()).format("YYYY-MM-DD")
                            )
                          ? "Today"
                          : moment(timeSeries, "YYYY-MM-DD").format(
                              "dddd, MMMM DD,YYYY"
                            )}
                      </b>
                    </th>
                  </tr>
                  {getNotificationInTimeSeries[timeSeries].map((data, i) => (
                    <tr key={i}>
                      <td className="notification_msg">
                      <Notification
                        notification={data.notification}
                        notificationData={data}
                        className="anchor"
                        id={data.coach_id}
                        time_off_id={data.id}
                        status={data.approval_status}
                        onClickHandler={props.actions.getCoach}
                        setResponseTimeOffHomePageFunction={props.actions.setResponseTimeOffHomePageFunction}
                        getNotifications={props.actions.getNotifications}
                        profile={profile}
                        setProfile={setProfile}
                        autoSubDisplay={autoSubDisplay}
                        setAutoSubDisplay={setAutoSubDisplay}
                        getSubstitutionFilterApplyListFunction={props.actions.getSubstitutionFilterApplyListFunction}
                        getSubstitutionFilterListFunction={props.actions.getSubstitutionFilterListFunction}
                        setSubCoachName={setSubCoachName}
                      />
                      </td>
                      {/* <td className="notification_link"></td> */}
                    </tr>
                  ))}
                </tbody>
              ))}
            </table>
          </div>
          </div>
        <div>
          {(user.type === "CoachManager" || user.type === "Coach") && <List length={10} />}
          {/* <ClearFix/> */}
        </div>
      </div>
      </div>
      <AutoSubModal setAutoSubDisplay={setAutoSubDisplay} autoSubDisplay={autoSubDisplay} substitutionResponse={props.substitutionResponse} substitutionFilterList={props.substitutionFilterList} coachName={subCoachName}/>
    </>
  );
}

HomePage.propTypes = {
  home_notification: propTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { notifications, substitutions, additionalSubstitutions, substitutionCoachList } = state;
  return { notifications, substitutions, additionalSubstitutions, substitutionCoachList };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      getNotifications: bindActionCreators(
        notificationsAction.getNotifications,
        dispatch
      ),
      getSubstitutions: bindActionCreators(
        notificationsAction.getSubstitutions,
        dispatch
      ),
      getCoach: bindActionCreators(coachProfileAction.getCoach, dispatch),
      getAdditionalSubstitutions: bindActionCreators(
        notificationsAction.getAdditionalSubstitutions,
        dispatch
      ),
      setResponseTimeOffHomePageFunction: bindActionCreators(CoachTimeOffAction.setResponseTimeOffHomePage, dispatch),
      cancelSubstitutionHomePageFunction: bindActionCreators(notificationsAction.cancelSubstitutionHomePage, dispatch),
      fetchCoachSubstitutionHomePageFunction: bindActionCreators(notificationsAction.fetchCoachSubstitutionHomePage, dispatch),
      reassignSubstitutionFunction: bindActionCreators(notificationsAction.reassignSubstitution, dispatch),
      fetchAvailableCoachFunction: bindActionCreators(notificationsAction.ModCoachAvailable,dispatch),
      getSubstitutionFilterApplyListFunction: bindActionCreators(substitutionAction.getSubstitutionFilterApplyList, dispatch),
      getSubstitutionFilterListFunction: bindActionCreators(substitutionAction.getSubstitutionFilterList, dispatch),
    },
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(HomePage);