import { GET_AUTORENEWAL_REPORT } from "../constants";
import initialState from "./initialState";

export default function AutoRenewalReportReducer(state = initialState.autoRenewalReport, action) {
  switch(action.type){
    case "GET_AUTORENEWAL_REPORT":
      return action.autoRenewalReport;
    default: 
      return state;
  }
}