import {
    GET_TRACK_USERS_NAMES_SUCCESS
} from "../constants";

import initialState from "./initialState";

export default function trackUserNamesReducer(state = initialState.trackUserNames, action) {
    switch (action.type) {
        case GET_TRACK_USERS_NAMES_SUCCESS:
            return action.trackUserNames;
        default:
            return state;
    }
}
