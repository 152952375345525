import {
    GET_APPOINTMENT_SCHEDULER_SUCCESS
  } from "../constants";

import initialState from "./initialState";

export default function AppointmentSchedulesReducer(state = initialState.appointmentSchedules, action) {
    switch (action.type) {
        case GET_APPOINTMENT_SCHEDULER_SUCCESS:
            return action.appointmentSchedules;
        default:
        return state;
    }
}