import React from "react";
import Sidebar from "./Sidebar.jsx";
import loaderIcon from "../images/spinner.gif";

export default function Loading() {
  return (
    <div className="body-content">
      <aside className="sideBar">
        <Sidebar />
      </aside>
      <div className="right-content" style={{ height: "16rem" }}>
      <div className="spinnerImage">
        <img src={loaderIcon} title=""/>
      </div>
      </div>
    </div>
  );
}
