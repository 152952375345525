import React, { useState, useEffect, useRef, useReducer } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import decode from "jwt-decode";
import momenttimezone from "moment-timezone";
import solo from "../images/solo.png";
// import { toast } from "react-toastify";
import * as appointmentShedulerAction from "../../redux/actions/appointmentAction";
import * as coachShedulerAction from "../../redux/actions/CoachShedulerAction";
import CreateSessionModal from "./CreateSessionModal";
import CreateBulkSessionModal from "./CreateBulkSessionModal";
import SessionDetail from "./SessionDetail";
import AppointmentDetails from "../AppointmentSchedulerComponent/AppointmentDetails";
import * as masterSchedulerApi from "../../apis/MasterSchedulerApi";
import * as notificationsAction from "../../redux/actions/notificationsAction";
import { toast } from "react-toastify";
// function checkButton(){
//   return <input type="button" value="Clickss the Button" onClick="changeDate()"/>;
// }

const LOADING = "LOADING";
const LOADED = "LOADED";
function loadingReducer(state = false, action) {
  if (action.type === LOADING) return true;
  if (action.type === LOADED) return false;
  return state;
}

// useEffect(() => {
//   if (loading) loadingDispatch({ type: LOADED });
//   else loadingDispatch({ type: LOADING });
// }, [sessionDetails]);

function CoachSchedulerCalendar(props) {
  const { sessionDetails, appointmentDetails, maxUnitInfo } = props;

  const [sessionParams, setSessionParams] = useState([]);

  const [loading, loadingDispatch] = useReducer(loadingReducer, false);
  const [double, setDouble] = useState(props.double);
  const [eventTitle, setEventTitle] = useState("");
  const [displayEdit, setDisplayEdit] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("totale");
  const user = decode(localStorage.getItem("token"));
  const bulkSessionsObject = {
    sessions: [],
    language_identifier: "all",
    language_id: "",
    number_of_seats: "",
    type: "session",
    lesson: "",
    level: "",
    unit: "",
    topic_id: "",
    external_village_id: "",
    coach_id: "",
    moderator_id: 0,
    details: "",
    appointment_type_id: ""
  };
  const [bulkSessions, setBulkSessions] = useState(bulkSessionsObject);

  useEffect(() => {
    if (props.double) {
      setDouble(props.double);
    }
  }, [props.double]);
  useEffect(() => {
    props.onDoubleChange(double);
  }, [double]);
  useEffect(() => {
    if (props.showCalendar) {
      document.querySelector(
        "th.fc-timegrid-axis .fc-timegrid-axis-frame"
      ).innerHTML = `TIME (${props.updatedTimeZone}`;
    }
  }, [props.updatedTimeZone]);
  // useEffect(() => {
  //   setDouble(false);
  // }, [eventTitle]);

  // useEffect(() =>{
  //   if(props.isFromAppointment || props.isFromMyScheduler){
  //     setDouble(props.double)
  //   }
  // },[]);

  useEffect(() => {
    // if(props.isFromNavigation == true){
    //   setDouble(true);
    // }
    setSessionParams({
      coach_id: props.coachFilter.coach_id,
      session_start_time: props.coachFilter.filterDate,
    });
  }, [props.coachFilter]);

  const [schedules, setSchedules] = useState([]);
  let scheduleAry = [];
  let withoutFBCancelledSlots = [];
  let onlyCanceledFBSlots = [];

  const [updatedSchedules, setUpdatedSchedules] = useState([]);
  const [isSchedulesChanged, setIsSchedulesChanged] = useState(false);
  useEffect(() => {
    if (props.schedulerList != undefined && props.schedulerList.length != 0) {
      console.log("Entered the schedule ====>", schedules, props.schedulerList);
      //To show FB Cancelled session in the second slot
      withoutFBCancelledSlots = props.schedulerList.filter((session) => {
        // if(session.slotType == 'cs_cancelled'){cs_substituted
        if (
          (session.slotType == "cs_cancelled" ||
            session.slotType == "cs_substituted" ||
            session.slotType == "cs_sub_needed_solid_session_slot") &&
          session.lang?.includes("FB-") && !session.lang?.includes("-Solo")
        ) {
          onlyCanceledFBSlots.push(session);
          return false;
        }
        return true;
      });
      let splittedFBCancelledSlots = [];
      // console.log("Only canceled fb slot");
      // console.log(onlyCanceledFBSlots);
      onlyCanceledFBSlots.forEach((slot) => {
        let clonedSessionSlot;
        clonedSessionSlot = { ...slot };
        slot.endTime = moment(slot.endTime)
          .subtract(30, "minutes")
          .toISOString();
        splittedFBCancelledSlots.push(slot);
        clonedSessionSlot.startTime = moment(clonedSessionSlot.endTime)
          .subtract(30, "minutes")
          .toISOString();
        splittedFBCancelledSlots.push(clonedSessionSlot);
      });
      // console.log("======================");
      // console.log(splittedFBCancelledSlots);

      setSchedules([...withoutFBCancelledSlots, ...splittedFBCancelledSlots]);

      //
      //setSchedules(props.schedulerList);
      setIsSchedulesChanged(true);
    }
    // if (props.schedulerList != undefined && calRef.current != null)
    // {
    //   let calendarApi = calRef.current.getApi();
    //   calendarApi.gotoDate(props.coachFilter.filterDate)
    // }
    if (props.schedulerList != undefined && props.schedulerList.length == 0) {
      setSchedules([]);
      setDouble(false);
    }
    if (props.calRef.current != null) {
      let calendarApi = props.calRef.current.getApi();
      calendarApi.gotoDate(moment(props.coachFilter.filterDate).format());
    }
  }, [props.schedulerList]);

  const [events, setEvents] = useState(updatedSchedules);

  const [session, setSession] = useState({
    dateStr: "",
    displayCreate: false,
    fromDetails: false,
    xAxis: "0px",
    yAxis: "0px",
    xDistance: "0px",
    sessionDisableRecurring: false,
  });

  const [sessionDisplay, setSessionDisplay] = useState({
    displayDetails: false,
    xAxis: "0px",
    yAxis: "0px",
    xDistance: "0px",
    yDistance: "0px",
    start_date: "",
  });

  const [bulkSessionDisplay, setBulkSessionDisplay] = useState({
    dateStr: "",
    displayCreate: false,
    fromDetails: false,
    xAxis: "0px",
    yAxis: "0px",
    xDistance: "0px",
    sessionDisableRecurring: false,
  });

  const [appointmentDisplay, setAppointmentDisplay] = useState({
    displayAppointmentDetails: false,
    appointment_date: props.coachFilter.filterDate,
    displayAppointmentCreate: false,
    isFromEvent: false,
    displayAppointmentEdit: false,
    xAxis: "0px",
    yAxis: "0px",
    start_date: "",
    day_index: ""
  });

  var header_selection_array = document.querySelectorAll(
    "th.fc-col-header-cell.fc-day, .fc-timegrid-slot-label, .fc-timegrid-axis-frame"
  );

  useEffect(() => {
    if (session["displayCreate"]) {
      let create_modal = document.getElementById(
        "coach-schedule-create-session"
      );
      create_modal.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [session]);

  useEffect(() => {
    if (sessionDisplay["displayDetails"]) {
      let session_modal = document.getElementById(
        "coach-schedule-session-details"
      );
      session_modal.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [sessionDisplay]);

  useEffect(() => {
    if (props.bulkSession) {
      setBulkSessionDisplay({ ...bulkSessionDisplay, ["displayCreate"]: true });
      header_selection_array.forEach((el) => el.classList.add("bulk-enabled"));
    }
  }, [props.bulkSession]);

  useEffect(() => {
    if (bulkSessionDisplay.displayCreate == false) {
      props.setBulkSession(false);
      header_selection_array.forEach((el) =>
        el.classList.remove("bulk-enabled")
      );
    }
  }, [bulkSessionDisplay?.displayCreate]);

  function extractIdentifier(language) {
    let identifier = language.split("-");
    return identifier.length == 2
      ? identifier[0].toLowerCase()
      : identifier.length == 3
      ? "fbsolo"
      : "totale";
  }

  function fetchModerators(params, profileId = null) {
    return new Promise((resolve, reject) => {
      masterSchedulerApi
        .getModerators(params)
        //  : appointmentApi.getModerators(params))
        .then((response) => {
          //   setModerators(response.data);
          if (response?.data) {
            resolve(
              response.data?.filter((moderator) => moderator.id != profileId)
            );
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          reject([]);
          throw err;
        });
    });
  }

  function handleDateClick(e) {
    //Bulk creation login
    let slotTime = e.dateStr || e.event?.startStr;
    if (props.bulkSession) {
      console.log(e.jsEvent);
      if (moment(slotTime).isBefore(new Date())) return;
      if (
        bulkSessions.type !== "appointment" &&
        bulkSessions.language_identifier == "all"
      ) {
        alert("Please Select a Language");
        return;
      }
      if (
        props.coachProfile.length > 0 &&
        typeof slotTime != "undefined" &&
        slotTime != ""
      ) {
        console.log(
          "Selected Language" +
            extractIdentifier(selectedLanguage)?.toLowerCase()
        );
        //30 min slot check for FB-Group session
        if (
          extractIdentifier(selectedLanguage?.toLowerCase()) == "fb" &&
          moment(slotTime).format("mm") == "30"
        ) {
          alert(
            `FB-Group sessions are not allowed in 30 minutes slots - ${moment(
              slotTime
            ).format("ddd MM/DD/YYYY hh:mmA")}`
          );
          return;
        }
        //
        setDouble(true);
        const start_time =
          extractIdentifier(selectedLanguage?.toLowerCase()) == "fb"
            ? moment(slotTime).format("mm") == "30"
              ? moment(slotTime).subtract(30, "minutes")
              : slotTime
            : slotTime;

        coachShedulerAction
          .checkRecurrings({
            coach_id: props.coachProfile[0][0].id,
            // session_start_time: moment
            //   .utc(settings.dateStr)
            //   .format("yyyy-MM-DD HH:mm:ss"),
            session_start_time: moment(slotTime)
              .utc()
              .format("yyyy-MM-DD HH:mm:ss"),
            day_index: moment(slotTime)
              .utc()
              .day(),
          })
          .then((result) => {
            //setCheckRecurring(result);
            console.log("Check Recurring Value", result.disable_recurring);
            if (extractIdentifier(selectedLanguage) == "rstv") {
              fetchModerators(
                {
                  language: selectedLanguage,
                  sessions_start_time: session.session_start_time,
                },
                props.coachProfile[0][0].id
              )
                .then((response) => {
                  let optionList = [];
                  response.map(({ id, full_name }) => {
                    //optionList.push(`<option key=${id} value=${id}>${full_name}</option>`)
                    optionList.push({ id: id, full_name: full_name });
                  });
                  setBulkSessions({
                    ...bulkSessions,
                    sessions: [
                      ...bulkSessions.sessions,
                      {
                        session_start_time: start_time,
                        day_index: moment.utc(slotTime).day(),
                        is_recurring: false,
                        moderator_id: null,
                        recurring_start_date: "",
                        recurring_end_date: "",
                        recurring_frequency: 1,
                        disable_recurring: result.disable_recurring,
                        optionList: optionList,
                      },
                    ],
                  });
                  // return optionList;
                  setDouble(false);
                })
                .catch((err) => {
                  setDouble(false);
                });
            } else {
              setBulkSessions({
                ...bulkSessions,
                sessions: [
                  ...bulkSessions.sessions,
                  {
                    session_start_time: start_time,
                    day_index: moment.utc(slotTime).day(),
                    is_recurring: false,
                    moderator_id: null,
                    recurring_start_date: "",
                    recurring_end_date: "",
                    recurring_frequency: 1,
                    disable_recurring: result.disable_recurring,
                    optionList: [],
                  },
                ],
              });
              setDouble(false);
            }
            // setBulkSessions({...bulkSessions, sessions: [ ...bulkSessions.sessions, {session_start_time: start_time, day_index: moment.utc(slotTime).day(), is_recurring: false, moderator_id: null, recurring_start_date: "", recurring_end_date: "", recurring_frequency: 1, disable_recurring: result.disable_recurring }]})

            if (e.event?.extendedProps.type == "cs_substituted") {
              setSchedules(removeandAddSlot(start_time));
            } else {
              setSchedules([
                ...schedules,
                {
                  startTime: start_time,
                  // endTime: moment(slotTime).add(30, 'minutes').toISOString(),
                  endTime: moment(start_time)
                    .add(
                      extractIdentifier(selectedLanguage?.toLowerCase()) == "fb"
                        ? 60
                        : 30,
                      "minutes"
                    )
                    .format("YYYY-MM-DD HH:mm:ss"),
                  lang: "ENG",
                  eventDisplay: "block",
                  slotType: "on-select",
                  textEscape: true,
                  label: "",
                },
              ]);
            }
          });
      }
      return;
    }
    //

    setDisplayEdit(false);
    let xDistance = e.jsEvent.clientX;
    let yDistance = e.jsEvent.pageY;
    let xOffset = e.jsEvent.offsetX;
    let yOffset = e.jsEvent.offsetY;
    if (props.isFromAppointment == false) {
      if (moment(e.date).isBefore(new Date())) return;
      //console.log(e.date); //["date"]: e.date,
      //console.log(moment(new Date()).isBefore(new Date(e.date)));
      // if (!moment(new Date()).isBefore(new Date(e.date))) {return;}
      /*47-> left nav bar width
      475-> width of create modal
      266-> height of modal and header section*/
      if (e.dateStr == undefined) {
        setSession({
          ...session,
          ["dateStr"]: e.event.start,
          ["displayCreate"]: true,
          ["fromDetails"]: false,
          ["xAxis"]:
            xDistance > 610
              ? xDistance - 47 - 475 - 237
              : xDistance - 47 - 475 + 240,
          ["yAxis"]: yDistance - yOffset - 266 - 15,
          ["xDistance"]: xDistance,
        });
      } else {
        setSession({
          ...session,
          ["dateStr"]: e.dateStr,
          ["displayCreate"]: true,
          ["fromDetails"]: false,
          ["xAxis"]:
            xDistance > 610
              ? xDistance - 47 - 475 - 237
              : xDistance - 47 - 475 + 240,
          ["yAxis"]: yDistance - yOffset - 266 - 15,
          ["xDistance"]: xDistance,
        });
      }
    } else if (props.isFromAppointment == true) {
      const appointment_date = e.dateStr == undefined ? e.event.start : e.dateStr
      setAppointmentDisplay({
        ...appointmentDisplay,
        ["xAxis"]:
          xDistance > 610
            ? xDistance - 47 - 475 - 237
            : xDistance - 47 - 475 + 240,
        ["yAxis"]: yDistance - yOffset - 266 - 60,
        ["displayAppointmentDetails"]: true,
        ["displayAppointmentCreate"]: false,
        ["displayAppointmentEdit"]: false,
        appointment_date: appointment_date,
        isFromEvent: false,
        start_date: moment.utc(appointment_date).format("YYYY-MM-DD HH:mm:ss"),
        day_index: moment.utc(appointment_date).day(),
      });
    }
  }

  function removeandAddSlot(startTimeString) {
    let removedCancelledSlot = schedules.filter((slot) => {
      return (
        slot.startTime != startTimeString &&
        slot.startTime != moment(startTimeString).toISOString() &&
        slot.slotType != "cs_cancelled" &&
          slot.slotType != "cs_sub_needed_solid_session_slot" &&
          slot.slotType != "cs_substituted"
      );
    });
    removedCancelledSlot.push({
      startTime: startTimeString,
      // endTime: moment(e.dateStr).add(30, 'minutes').toISOString(),
      endTime: moment(startTimeString)
        .add(
          extractIdentifier(selectedLanguage?.toLowerCase()) == "fb" ? 60 : 30,
          "minutes"
        )
        .format("YYYY-MM-DD HH:mm:ss"),
      lang: "ENG",
      eventDisplay: "block",
      slotType: "on-select",
      textEscape: true,
      label: "",
    });
    return removedCancelledSlot;
  }

  function handleEventClick(e) {
    //Bulk Session creation
    //To remove the selection if exists

    if (
      props.bulkSession &&
      (e.event.extendedProps.type == "cs_cancelled" ||
        e.event.extendedProps.type == "cs_sub_needed_solid_session_slot" ||
        e.event.extendedProps.type == "on-select" ||
        e.event.extendedProps.type == "cs_substituted")
    ) {
      // if(bulkSessions.sessions.find((slot) => slot.session_start_time == e.event.startStr) || schedules.find((slot) => { return ((slot.startTime == e.event.startStr || slot.startTime == moment(e.event.startStr).toISOString()) && (slot.slotType == "cs_cancelled"))}))
      if (
        bulkSessions.sessions.find(
          (slot) => slot.session_start_time == e.event.startStr
        )
      ) {
        setBulkSessions({
          ...bulkSessions,
          sessions: bulkSessions.sessions.filter((slot) => {
            return slot.session_start_time != e.event.startStr;
          }),
        });
        setSchedules(
          schedules.filter((slot) => {
            return (
              slot.startTime != e.event.startStr &&
              slot.startTime != moment(e.event.startStr).toISOString()
            );
          })
        );
        return;
      }

      // setSchedules(schedules.filter((slot) => { return ((slot.startTime != e.event.startStr && slot.startTime != moment(e.event.startStr).toISOString()) && (slot.slotType != "cs_cancelled"))}))

      console.log(e.jsEvent);
      if (moment(e.event.startStr).isBefore(new Date())) return;
      if (
        bulkSessions.type !== "appointment" &&
        bulkSessions.language_identifier == "all"
      ) {
        alert("Please Select a Language");
        return;
      }
      if (
        props.coachProfile.length > 0 &&
        typeof e.event.startStr != "undefined" &&
        e.event.startStr != ""
      ) {
        console.log(
          "Selected Language" +
            extractIdentifier(selectedLanguage)?.toLowerCase()
        );

        //30 min slot check for FB-Group session
        if (
          extractIdentifier(selectedLanguage?.toLowerCase()) == "fb" &&
          moment(e.event.startStr).format("mm") == "30"
        ) {
          alert(
            `FB-Group session is not allowed in this slot: ${moment(
              e.event.startStr
            ).format("ddd MM/DD/YYYY hh:mmA")}`
          );
          return;
        }
        //

        setDouble(true);
        const start_time =
          extractIdentifier(selectedLanguage?.toLowerCase()) == "fb"
            ? moment(e.event.startStr).format("mm") == "30"
              ? moment(e.event.startStr).subtract(30, "minutes")
              : e.event.startStr
            : e.event.startStr;

        coachShedulerAction
          .checkRecurrings({
            coach_id: props.coachProfile[0][0].id,
            // session_start_time: moment
            //   .utc(settings.dateStr)
            //   .format("yyyy-MM-DD HH:mm:ss"),
            session_start_time: moment(e.event.startStr)
              .utc()
              .format("yyyy-MM-DD HH:mm:ss"),
            day_index: moment(e.event.startStr)
              .utc()
              .day(),
          })
          .then((result) => {
            //setCheckRecurring(result);
            console.log("Check Recurring Value", result.disable_recurring);
            if (extractIdentifier(selectedLanguage) == "rstv") {
              fetchModerators(
                {
                  language: selectedLanguage,
                  sessions_start_time: session.session_start_time,
                },
                props.coachProfile[0][0].id
              )
                .then((response) => {
                  let optionList = [];
                  response.map(({ id, full_name }) => {
                    //optionList.push(`<option key=${id} value=${id}>${full_name}</option>`)
                    optionList.push({ id: id, full_name: full_name });
                  });
                  setBulkSessions({
                    ...bulkSessions,
                    sessions: [
                      ...bulkSessions.sessions,
                      {
                        session_start_time: start_time,
                        day_index: moment.utc(start_time).day(),
                        is_recurring: false,
                        moderator_id: null,
                        recurring_start_date: "",
                        recurring_end_date: "",
                        recurring_frequency: 1,
                        disable_recurring: result.disable_recurring,
                        optionList: optionList,
                      },
                    ],
                  });
                  // return optionList;
                  setDouble(false);
                })
                .catch((err) => {
                  setDouble(false);
                });
            } else {
              setBulkSessions({
                ...bulkSessions,
                sessions: [
                  ...bulkSessions.sessions,
                  {
                    session_start_time: start_time,
                    day_index: moment.utc(start_time).day(),
                    is_recurring: false,
                    moderator_id: null,
                    recurring_start_date: "",
                    recurring_end_date: "",
                    recurring_frequency: 1,
                    disable_recurring: result.disable_recurring,
                    optionList: [],
                  },
                ],
              });
              setDouble(false);
            }
            setSchedules(removeandAddSlot(start_time));
          });
      }
      return;
    }
    //
    // Normal Session Detail starts without Bulk
    let xDistance = e.jsEvent.clientX;
    let yDistance = e.jsEvent.pageY;
    let xOffset = e.jsEvent.offsetX;
    let yOffset = e.jsEvent.offsetY;
    console.log("selected Date ==============>" + e.eventStart);
    console.log("xDistance", xDistance);
    if (e.eventStart < new Date()) return;
    /*380-> session modal width
      105-> session modal height */
    if (props.isFromAppointment == true) {
      setAppointmentDisplay({
        ...appointmentDisplay,
        ["displayAppointmentDetails"]: true,
        ["displayAppointmentCreate"]: false,
        ["displayAppointmentEdit"]: false,
        appointment_date: e.event.start,
        isFromEvent: true,
        ["substitution"]:
          e.event.extendedProps.type == "grab_enable"
            ? e.event.extendedProps?.substitution
            : null,
        ["xAxis"]:
        xDistance > 610
          ? xDistance - 47 - 475 - 237
          : xDistance - 47 - 475 + 240,
        ["yAxis"]: yDistance - yOffset - 266 - 60,
        start_date: moment.utc(e.event.start).format("YYYY-MM-DD HH:mm:ss"),
        day_index: moment.utc(e.event.start).day(),
      });
      loadingDispatch({ type: LOADING });
      props.actions
        .getAppointmentDetails({
          appointment_type_id: props.coachFilter.appointment_type,
          start_date: moment.utc(e.event.start).format("YYYY-MM-DD HH:mm:ss"),
          day_index: moment.utc(e.event.start).day(),
        })
        .then(() => {
          loadingDispatch({ type: LOADED });
        })
        .catch(() => {
          loadingDispatch({ type: LOADED });
        });
    } else if (props.isFromAppointment == false) {
      console.log("selected Start Date ==============>" + e.event.start);
      setSessionDisplay({
        ...sessionDisplay,
        ["displayDetails"]: true,
        ["xAxis"]: xDistance - 380 - 94,
        ["yAxis"]: yDistance - 105,
        ["xDistance"]: xDistance,
        ["yDistance"]: yDistance,
        ["start_date"]: e.event.start,
        ["substitution"]:
          e.event.extendedProps.type == "grab_enable"
            ? e.event.extendedProps.substitution
            : null,
      });
      loadingDispatch({ type: LOADING });
      props.actions
        .getSessionDetails({
          coach_id: props.coachFilter.coach_id,
          session_id: e.event.id,
          //session_start_time: moment.utc(e.event.start).format("YYYY-MM-DD HH:mm:ss"),
          session_start_time:
            moment(e.event.start).format("mm") == "30" &&
            e.event.extendedProps.type == "cs_cancelled" &&
            e.event.extendedProps.lang?.includes("FB-") &&
              !e.event.extendedProps.lang?.includes("-Solo")
              ? moment
                  .utc(e.event.start)
                  .subtract(30, "minutes")
                  .format("YYYY-MM-DD HH:mm:ss")
              : moment.utc(e.event.start).format("YYYY-MM-DD HH:mm:ss"),
        })
        .then(() => {
          loadingDispatch({ type: LOADED });
        })
        .catch(() => {
          loadingDispatch({ type: LOADED });
        });
    }
  }

  const decideBgColor = (slotType) => {
    if (slotType.includes("on-going-sessions-colour")) return "#FFA500";
    else {
      switch (slotType) {
        case "cs_available_slot":
          return "#E3E2D5";
        case "cs_recurring_slot":
          return "#D6E4EA";
        case "cs_solid_session_slot":
          return "#BFD3DD";
        case "cs_solid_session_slot_coach_created":
          return "#911557";
        case "cs_solid_session_slot_coach_created_no_learner":
          return "#fde3f1";
        case "cs_reassigned_session_slot":
          return "#FFFF66";
        case "cs_extra_session_slot":
          return "#660099";
        case "cs_reassigned_appointment_session_slot":
          return "#FFFF66";
        case "cs_time_off_slot":
          return "#D5E5AF";
        case "cs_wwe_feed_back_provided":
          return "#B6B6AB";
        case "cs_appointment_session_slot":
          return "#33CC66";
        case "cs_appointment_recurring_session_slot":
          return "#33FF99";
        case "cs_recurring_appointment_session_slot":
          return "#7CFC00";
        case "cs_sub_needed_solid_session_slot":
          return "#BFD3DD";
        case "cs_sub_needed_appointment_session_slot":
          return "#33CC66";
        case "cs_cancelled":
          return "#B6B6AB";
        case "cs_substituted":
          return "#FFFFFF";
        case "on-going-sessions-colour":
          return "#FFA500";
        case "on-select":
          return "#9ed9f7";
        case "cs_service_down":
          return "#f44147";
        case "grab_enable":
          return "#e1ebe1"
      }
    }
  };

  const decideBorderColor = (slotType) => {
    if (slotType.includes("on-going-sessions-colour")) return "#FFA500";
    else {
      switch (slotType) {
        case "cs_available_slot":
          return "#E3E2D5";
        case "cs_recurring_slot":
          return "#D6E4EA";
        case "cs_solid_session_slot":
          return "#BFD3DD";
        case "cs_solid_session_slot_coach_created":
          return "#911557";
        case "cs_solid_session_slot_coach_created_no_learner":
          return "#fde3f1";
        case "cs_reassigned_session_slot":
          return "#FFFF66";
        case "cs_extra_session_slot":
          return "#660099";
        case "cs_reassigned_appointment_session_slot":
          return "#FFFF66";
        case "cs_time_off_slot":
          return "#D5E5AF";
        case "cs_wwe_feed_back_provided":
          return "#B6B6AB";
        case "cs_appointment_session_slot":
          return "#33CC66";
        case "cs_appointment_recurring_session_slot":
          return "#33FF99";
        case "cs_recurring_appointment_session_slot":
          return "#7CFC00";
        case "cs_sub_needed_solid_session_slot":
          return "#FF0000	";
        case "cs_sub_needed_appointment_session_slot":
          return "#33CC66";
        case "cs_cancelled":
          return "#B6B6AB";
        case "cs_substituted":
          return "#FFFFFF";
        case "on-going-sessions-colour":
          return "#FFA500";
        case "grab_enable":
          return "#e1ebe1"
      }
    }
  };

  function validSlot(info) {
    if (
      info.event.extendedProps.type != "cs_recurring_slot" &&
      info.event.extendedProps.type != "daylight_switch" &&
      info.event.extendedProps.type !=
        "cs_appointment_recurring_session_slot" &&
      info.event.extendedProps.type != "cs_service_down"
    ) {
      return true;
    } else {
      return false;
    }
  }

  function grab(substitutionData) {
    console.log("Grab is clicked");
    console.log("Substitution Data", substitutionData);
    if(!confirm("Are you sure you want to grab the session?")) return;
    let substitution = substitutionData;
    if (substitution) {
      setDouble(true);
      props.actions
        .grabSession({
          filter_selected_coach_value: substitution.currentCoachID,
          from_cs: false,
          assigned_coach: user.id,
          reason: "Auto Subrequest",
          coach_session_id: substitution.coach_session_id,
          scheduled_session_id: substitution.scheduled_session_id,
          scheduled_eschool_session_id:
            substitution.scheduled_eschool_session_id,
          is_grabing: true,
        })
        .then((response) => {
          props.actions
            .getCoachScheduler(props.coachFilter)
            .then(() => {
              setDouble(false);
              if (response == "Coach has been reassigned successfully") {
                toast.success("Session grabbed successfully");
              } else {
                toast.error(response);
              }
            })
            .catch((err) => {
              console.log("Error on Coach Scheduer Fetch", err);
              setDouble(false);
              toast.error("Failed to Grab the session");
            });
          props.actions.getSubstitutions({
            time_range: "upcoming",
            alert: "true",
          });
        })
        .catch((err) => {
          console.log("Error on grabbing the session", err);
          setDouble(false);
          toast.error("Failed to Grab the session");
        });
    }
  }

  const isActiveSession = (start_time) => {
    return (moment() < moment(start_time))
  }

  function renderEventContent(eventInfo) {
    var text = eventInfo.event.title;
    if (
      eventInfo.event.extendedProps.count === schedules.length &&
      isSchedulesChanged
    ) {
      setDouble(false);
      setIsSchedulesChanged(false);
    }
    if (text.split("<br/>").length > 1) {
      setEventTitle(text);
      // setDouble(false);
      return (
        <>
          <div className="event-title">
            <p>{text.split("<br/>")[0]}</p>
            <div
              className={
                text
                  .split("<br/>")[2]
                  ?.split("<span class='substitute-info'>")[1]
                  ?.split("</span>")[0] == "Reassigned" ||
                text.split("<br/>")[2] == "Recurring"
                  ? ""
                  : "village-container"
              }
            >
              <p className="learner">
                <span
                  style={{
                    display:
                      text.split("<br/>")[1].indexOf("<img") != -1
                        ? "block"
                        : "none",
                  }}
                >
                  <img className="solo_icon" src={solo} alt="solo"></img>{" "}
                </span>
                <span>
                  {text.split("<br/>")[1] != undefined
                    ? text.split("<br/>")[1].indexOf("<img") != -1
                      ? text
                          .split("<br/>")[1]
                          .split("<img src='/images/solo.png'>")[0]
                      : text.split("<br/>")[1].replace(/<[^>]+>/g, "")
                    : ""}
                </span>
              </p>
              <p
                className={
                  text
                    .split("<br/>")[2]
                    ?.split("<span class='substitute-info'>")[1]
                    ?.split("</span>")[0] == "Reassigned"
                    ? "reassigned"
                    : "village"
                }
              >
                <span
                  style={{
                    display:
                      text.split("<br/>")[2] != undefined
                        ? text.split("<br/>")[2].indexOf("<img") != -1
                          ? "block"
                          : "none"
                        : "none",
                  }}
                >
                  <img className="solo_icon" src={solo} alt="solo"></img>{" "}
                </span>

                <span>
                  {" "}
                  {text.split("<br/>")[2] != undefined
                    ? text.split("<br/>")[2].split("<font color='red'>")[1] !=
                      undefined
                      ? text
                          .split("<br/>")[2]
                          .split("<font color='red'>")[1]
                          .split("</font>")[0]
                          .replace("<img src='/images/solo.png'>", "")
                      : text
                          .split("<br/>")[2]
                          .split("<span class='substitute-info'>")[1] !=
                        undefined
                      ? text
                          .split("<br/>")[2]
                          .split("<span class='substitute-info'>")[1]
                          .split("</span>")[0]
                          .replace("<img src='/images/solo.png'>", "")
                      : text.split("<br/>")[2].split(".. ")[0] != undefined
                      ? text.split("<br/>")[2].split("&nbsp")[0]
                      : text
                          .split("<br/>")[2]
                          .replace("<img src='/images/solo.png'>", "")
                    : ""}
                </span>
              </p>
            </div>
            <p>
              {text.split("<br/>")[3] != undefined
                ? text.split("<br/>")[3].split("<font color='red'>")[1] !=
                  undefined
                  ? text
                      .split("<br/>")[3]
                      .split("<font color='red'>")[1]
                      .split("</font>")
                  : text
                      .split("<br/>")[3]
                      .split("<span class='substitute-info'>")[1] != undefined
                  ? text
                      .split("<br/>")[3]
                      .split("<span class='substitute-info'>")[1]
                      .split("</span>")
                  : text.split("<br/>")[3]
                : ""}
            </p>
          </div>
        </>
      );
    } else {
      setEventTitle(text);

      if (eventInfo.event.extendedProps.type == "grab_enable" ) {
        const substitutionData = eventInfo.event.extendedProps.substitution;
        return (
          <div className="event-title grab-enable">
              <span className="inner-title">
                {text.split("</br>")[0]} <br /> {text.split("</br>")[1]}
              </span>
              {isActiveSession(eventInfo.event.start) &&
              <>
                <br />
                <button onMouseDown={(eventInfo) => grab(substitutionData)}>
                  GRAB
                </button>
                <span className="subAlert">SUB NEEDED</span>
              </>
              }
          </div>
        );
      } else {
        return (
          <div className="event-title">
            <strong>{text.split("<br/>")[0]}</strong>
          </div>
        );
      }
    }
    //eventInfo.el.querySelector('.fc-event-title').innerHTML = eventInfo.event.title;
  }
  // console.log("count",eventCount)

  return (
    <>
      {/* <checkButton/> */}
      <CreateSessionModal
        settings={session}
        setSettings={setSession}
        config={props.config}
        coachFilter={props.coachFilter}
        SessionDetails={props.sessionDetails}
        maxUnitInfo={maxUnitInfo}
      />
      <CreateBulkSessionModal
        settings={bulkSessionDisplay}
        setSettings={setBulkSessionDisplay}
        config={props.config}
        coachFilter={props.coachFilter}
        SessionDetails={props.sessionDetails}
        maxUnitInfo={maxUnitInfo}
        bulkSessions={bulkSessions}
        setBulkSessions={setBulkSessions}
        bulkSessionsObject={bulkSessionsObject}
        schedules={schedules}
        setSchedules={setSchedules}
        setSelectedLanguage={setSelectedLanguage}
        bulkSession={props.bulkSession}
      />
      {/* { props.schedulerList != undefined && props.schedulerList.length != 0 && */}
      {double && (
        <>
          <div className="blockOverlay" style={{ display: "block" }}></div>
          <div className="blockMsg" style={{ display: "block" }}>
            <h1>Please wait...</h1>
          </div>
        </>
      )}
      {props.showCalendar && (
        <FullCalendar
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          dateClick={handleDateClick}
          initialDate={new Date()}
          displayEventTime={false}
          stickyHeaderDates={true}
          allDaySlot={false}
          events={
            //      schedules.length > 0 &&
            schedules.map((schedule, index) => {
              console.log("Below is the schedule");
              console.log(schedule);
              return {
                id: schedule.id,
                title: schedule.label,
                start: moment(schedule.startTime).format(),
                //    end: (schedule.slotType == "cs_cancelled" && (schedule.lang?.includes("FB-") && !schedule.lang?.includes("-Solo")) ? moment(schedule.endTime).subtract(30, 'minutes').format() : moment(schedule.endTime).format()),
                end: moment(schedule.endTime).format(),
                backgroundColor: decideBgColor(schedule.slotType),
                lang: schedule.lang,
                textColor:
                  (schedule.slotType == "cs_extra_session_slot" ||
                  schedule.slotType == "cs_solid_session_slot_coach_created")
                    ? "white"
                    : "black",
                eventDisplay: "block",
                textEscape: true,
                type: schedule.slotType,
                borderColor: decideBorderColor(schedule.slotType),
                extendedProps: {
                  count: index + 1,
                },
                substitution:
                  schedule.slotType == "grab_enable" && schedule.data
                    ? {...schedule.data[0], currentCoachID: schedule.coach_id ? schedule.coach_id : null}
                    : null,
                // currentCoachID: schedule.coach_id ? schedule.coach_id : null
              };
            })
          }
          eventContent={renderEventContent}
          // eventRender={()=>{
          //   setDouble(false);
          // }}
          slotDuration={props.config.slotDuration} //{"00:30:00"}
          eventClick={(info) => {
            console.log("INFORMATION" + info.event.extendedProps.type);
            setDisplayEdit(false);
            if (props.isFromAppointment == true) {
              console.log("Inside INFORMATION" + info.event.extendedProps.type);
              if (
                info.event.extendedProps.type != "cs_recurring_slot" &&
                info.event.extendedProps.type != "daylight_switch" &&
                info.event.extendedProps.type != "cs_service_down"
              ) {
                if (
                  [
                    "cs_cancelled",
                    "cs_wwe_feed_back_provided",
                    "cs_solid_session_slot_coach_created",
                    "cs_solid_session_slot_coach_created_no_learner",
                    "cs_extra_session_slot",
                    "cs_solid_session_slot",
                    "cs_appointment_session_slot",
                    "cs_sub_canl_session_slot",
                    "cs_sub_needed_solid_session_slot",
                    "cs_sub_needed_appointment_session_slot",
                    "cs_reassigned_session_slot",
                    "cs_reassigned_appointment_session_slot",
                    "cs_appointment_recurring_session_slot",
                  ].indexOf(info.event.extendedProps.type) != -1
                ) {
                  handleEventClick(info);
                } else {
                  handleDateClick(info);
                }
              }
            } else {
              console.log(
                "Inside else INFORMATION" + info.event.extendedProps.type
              );

              if (!validSlot(info)) {
                return;
              }

              if (
                info.event.extendedProps.type == "cs_substituted" ||
                info.event.extendedProps.type == "cs_time_off_slot"
              ) {
                if (info.event.extendedProps.type == "cs_time_off_slot") {
                  let create_timeoff = confirm(
                    "You are about to create an session/appointment on a timeoff slot. Do you want to proceed?"
                  );
                  if (!create_timeoff) {
                    return;
                  }
                }
                handleDateClick(info);
              } else {
                handleEventClick(info);
              }
            }
          }}
          timeFormat={"hh:mm A"}
          dayHeaderFormat={{
            weekday: "long",
            month: "short",
            day: "2-digit",
            year: "numeric",
          }}
          eventTimeFormat={{
            hour: "numeric",
            minute: "2-digit",
            meridiem: "short",
          }}
          ref={props.calRef}
          height="auto"
        />
      )}
      {props.sessionDetails != undefined && props.isFromAppointment == false && (
        <SessionDetail
          settings={sessionDisplay}
          setSettings={setSessionDisplay}
          sessionDetails={props.sessionDetails}
          coachFilter={props.coachFilter}
          session={session}
          setSession={setSession}
          config={props.config}
          loading={loading}
          cancelLoading={() => {
            loadingDispatch({ type: LOADED });
          }}
          coachName={props.coachName}
          displayEdit={displayEdit}
          setDisplayEdit={setDisplayEdit}
          isFromAppoinment={props.isFromAppointment}
          maxUnitInfo={maxUnitInfo}
          grabSession={props.actions.grabSession}
          getCoachScheduler={props.actions.getCoachScheduler}
          getSubstitutions={props.actions.getSubstitutions}
          setDouble={setDouble}
        />
      )}
      {props.appointmentDetails != undefined &&
        props.appointmentDetails != [] &&
        props.isFromAppointment == true && (
          <AppointmentDetails
            settings={appointmentDisplay}
            setSettings={setAppointmentDisplay}
            appointmentDetails={props.appointmentDetails}
            isFromAppoinment={props.isFromAppointment}
            appointmentFilter={props.appointmentFilter}
            setDouble={setDouble}
          />
        )}

    </>
  );
}

CoachSchedulerCalendar.propTypes = {
  // sessions: propTypes.array.isRequired,
  actions: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  // const { regions, languages, notifications } = state;
  // return { regions, languages, notifications };
  sessionDetails: state.sessionDetails,
  appointmentDetails: state.appointmentDetails,
  coachProfile: state.coachProfile,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      addSessions: bindActionCreators(
        coachShedulerAction.addCoachSessions,
        dispatch
      ),
      getSessionDetails: bindActionCreators(
        coachShedulerAction.getSessionDetails,
        dispatch
      ),
      getAppointmentDetails: bindActionCreators(
        appointmentShedulerAction.getAppointmentDetails,
        dispatch
      ),
      getCoachScheduler: bindActionCreators(
        coachShedulerAction.getCoachScheduler,
        dispatch
      ),
      getSubstitutions: bindActionCreators(
        notificationsAction.getAdditionalSubstitutions,
        dispatch
      ),
      grabSession: bindActionCreators(
        notificationsAction.grabSession,
        dispatch
      ),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoachSchedulerCalendar);
