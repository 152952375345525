import { GET_APPOINTMENT_SUCCESS, ADD_APPOINTMENT_SUCCESS, UPD_APPOINTMENT_SUCCESS, GET_APPOINTMENT_SCHEDULER_SUCCESS, GET_APPOINTMENT_DETAILS_SUCCESS } from "../constants";

import * as appointmentsApi from "../../apis/appointmentsApi";
import * as masterSchedulerApi from "../../apis/MasterSchedulerApi";

export function getAppointment() {
  return function (dispatch) {
    return appointmentsApi
      .getAppointment()
      .then((response) => {
        dispatch({
          type: GET_APPOINTMENT_SUCCESS,
          appointment: response.data,
        })
      }
      )
      .catch((err) => {
        throw err;
      });
  };
}

export function addAppointment(appointment) {
  return function (dispatch) {
    return appointmentsApi
      .addAppointment(appointment)
      .then((addAppointment) =>
        dispatch({
          type: ADD_APPOINTMENT_SUCCESS,
          addAppointment: {
            ...appointment,
            ["id"]: addAppointment.data.insertId,
            ["active"]: 1,
          },
        })
      )
      .catch((err) => {
        throw err;
      });
  };
}

export function updateAppointment(appointment, id) {
  return function (dispatch) {
    return appointmentsApi
      .updateAppointment(appointment, id)
      .then(() =>
        dispatch({
          type: UPD_APPOINTMENT_SUCCESS,
          updateAppointment: {
            ...appointment,
            ["id"]: id,
            ["active"]: appointment.active,
          },
        })
      )
      .catch((err) => {
        throw err;
      });
  };
}

export function updateAppointmentStatus(appointment, id) {
  return function (dispatch) {
    return appointmentsApi
      .updateAppointment(appointment, id)
      .then(() => {
        dispatch(getAppointment());
      })
      .catch((err) => {
        throw err;
      });
  };
}


export function getAppointmentSchedules(params) {
  console.log(params);
  return function (dispatch) {
    return appointmentsApi.getAppointmentSchedules(params).then((response) => {
      dispatch({
        type: GET_APPOINTMENT_SCHEDULER_SUCCESS,
        appointmentSchedules: response.data
      })
    })
      .catch((err) => {
        throw err;
      });
  }
}

export function getAppointmentDetails(params) {
  console.log(params);
  return function (dispatch) {
    return appointmentsApi.getAppointmentDetails(params).then((response) => {
      dispatch({
        type: GET_APPOINTMENT_DETAILS_SUCCESS,
        appointmentDetails: response.data
      })
    })
      .catch((err) => {
        throw err;
      });
  }
}

export function pushAppointmentSchedules(params, reqParams) {
  console.log(params);
  return function (dispatch) {
    return appointmentsApi.pushAppointmentSchedules(params).then((response) => {
      if (response.data == "success") {
        return appointmentsApi.getAppointmentSchedules(reqParams).then((response) => {
          dispatch({
            type: GET_APPOINTMENT_SCHEDULER_SUCCESS,
            appointmentSchedules: response.data
          })
        })
      } else {
        return response.data;
      }
    })
      .catch((err) => {
        throw err;
      });
  }
}

export function pushAppointmentStatus(params) {
  console.log(params);
  return function(dispatch) {
    return masterSchedulerApi.pushSessionStatus(params).then((response) => {
      return response.data;
    }).catch((err) => {
      throw err;
    });
  }
}

export function createAppointment(appointment, reqParams, getAppointmentParams) {
  return function (dispatch) {
    return appointmentsApi
      .createAppointment(appointment)
      .then((response) => {
        if (response.data?.status) {
          appointmentsApi.getAppointmentSchedules(reqParams).then((response) => {
            dispatch({
              type: GET_APPOINTMENT_SCHEDULER_SUCCESS,
              appointmentSchedules: response.data
            })
          });
          appointmentsApi.getAppointmentDetails(getAppointmentParams).then((response) => {
            dispatch({
              type: GET_APPOINTMENT_DETAILS_SUCCESS,
              appointmentDetails: response.data
            })
          });
          return response.data
        } else {
          return response.data;
        }
      }
        // (addAppointment) =>
        // dispatch({
        //   type: CREATE_APPOINTMENT_SUCCESS,
        //   createAppointment: {
        //     ...appointment,
        //     ["id"]: addAppointment.data.insertId,
        //     ["active"]: 1,
        //   },
        // })
      )
      .catch((err) => {
        throw err;
      });
  };
}

export function editAppointment(appointment, reqParams, getAppointmentParams) {
  return function (dispatch) {
    return appointmentsApi
      .editAppointment(appointment)
      .then((response) => {
        if (response.data?.status) {
          appointmentsApi.getAppointmentSchedules(reqParams).then((response) => {
            dispatch({
              type: GET_APPOINTMENT_SCHEDULER_SUCCESS,
              appointmentSchedules: response.data
            })
          });
          appointmentsApi.getAppointmentDetails(getAppointmentParams).then((response) => {
            dispatch({
              type: GET_APPOINTMENT_DETAILS_SUCCESS,
              appointmentDetails: response.data
            })
          });
          return response.data
        } else {
          return response.data;
        }
      }
      )
      .catch((err) => {
        throw err;
      });
  };
}

export function cancelAppointment(appointment, reqParams, getAppointmentParams) {
  return function (dispatch) {
    return appointmentsApi
      .cancelAppointment(appointment)
      .then((response) => {
        if (response.data?.status) {
          appointmentsApi.getAppointmentSchedules(reqParams).then((response) => {
            dispatch({
              type: GET_APPOINTMENT_SCHEDULER_SUCCESS,
              appointmentSchedules: response.data
            })
          });
          appointmentsApi.getAppointmentDetails(getAppointmentParams).then((response) => {
            dispatch({
              type: GET_APPOINTMENT_DETAILS_SUCCESS,
              appointmentDetails: response.data
            })
          });
          return response.data;
        } else {
          return response.data;
        }
      }
        // (addAppointment) =>
        // dispatch({
        //   type: CREATE_APPOINTMENT_SUCCESS,
        //   createAppointment: {
        //     ...appointment,
        //     ["id"]: addAppointment.data.insertId,
        //     ["active"]: 1,
        //   },
        // })
      )
      .catch((err) => {
        throw err;
      });
  };
}

export function checkCoachAvailable(params) {
  return function (dispatch) {
    return appointmentsApi
      .checkCoachAvailable(params)
      .then((response) => {
        return response.data;
      }
      )
      .catch((err) => {
        throw err;
      });
  };
}