import { GET_REGION_SUCCESS } from "../constants";
// import * as sessionsApi from "../../apis/SessionsApi";
import * as coanchProfileApi from "../../apis/CoachProfileApi";

export function getRegions() {
  return function(dispatch) {
    return coanchProfileApi
      .getRegions()
      .then((response) => {
        console.log(response);
        dispatch({
          type: GET_REGION_SUCCESS,
          regions: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}
