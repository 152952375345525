import  React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import {Helmet} from "react-helmet";
import "./ApplicationStatus.css";
import * as applicationStatusAction from "../redux/actions/ApplicationStatusAction";
//import { getCronStatus } from "../../apis/ApplicationStatusApi";
import { getCronStatus } from "../apis/ApplicationStatusApi";

const title = `Customer Success Portal: Application Status`;

function ApplicationStatusPage(props) {
    const cronNames = {coach_not_present_email:"Coach Not Present Email Cron", substitution_alert_email_daily: "Daily Sub Alert Cron", substitution_alert_email_daily_notification: "Daily Notification Cron", substitution_alert_email_hourly:"Hourly Sub Alert Cron", substitution_alert_email_hourly_notification: "Hourly Notification Alert Cron", substitution_alert_email_weekly:"Weekly Sub Alert", learner_sync: "Learner Syncing Cron", phl_unassigned_sessions: "PHL Unassigned Session", subneeded_sessions_hourly: "Sub Needed Cron", timeoff_request_daily: "Daily Timeoff Request Cron", archive_sessions_table: "Archive Sessions Cron", update_mails_sent_count:"Update Mail Sent Count", purge_audit_logs:"Purge Audi Log"}
    const [states, setState] = useState([]);

    useEffect(() =>{
        props.actions.getStatus();
        getCronStatus().then((response) => {
            console.log(response.data, "cron status");
            setState(response.data);
        });
    }, [])

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className="right-content">
                <div className="center_left application-status-div">
                    <div className="title">
                        <h2>Application Status</h2>
                    </div>
                    <table className="application-status-table">
                        <tbody>
                            <tr>
                                <th>APPLICATION</th>
                                <th>STATUS</th>
                            </tr>
                            <tr>
                                <td>AUTHENTICATION SERVICE</td>
                                <td className={props.status.acctServiceStatus? "application-status-success" : "application-status-failure"}></td>
                            </tr>
                            <tr>
                                <td>ACCOUNT SERVICE</td>
                                <td className={props.status.authServiceStatus? "application-status-success": "application-status-failure"}></td>
                            </tr>
                            <tr>
                                <td>ESCHOOL SERVICE</td>
                                <td className={props.status.communityStatus? "application-status-success": "application-status-failure"}></td>
                            </tr>
                            <tr>
                                <td>ESCHOOL SESSION API</td>
                                <td className={props.status.eschoolLearnerStatus? "application-status-success": "application-status-failure"}></td>
                            </tr>
                            <tr>
                                <td>ESCHOOL SESSION API SMX</td>
                                <td className={props.status.eschoolServiceStatus? "application-status-success": "application-status-failure"}></td>
                            </tr>
                            <tr>
                                <td>COMMUNITY USER DB</td>
                                <td className={props.status.eschoolSessionStatus? "application-status-success": "application-status-failure"}></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="center_left">
                    <div className="title">
                        <h2>Cron Status</h2>
                    </div>
                    <table className="application-status-table">
                        <tbody>
                            <tr>
                                <th>CRON Job</th>
                                <th>Last Ran</th>
                            </tr>
                            {states.map((state, index) => (
                                <tr  key={index}>
                                    <td>
                                    {cronNames[state.cron_name]}
                                    </td>
                                    <td>
                                        {moment(state.last_ran + "z").format("YYYY-MM-DD HH:mm:ss") }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    status: state.status
  });

  const mapDispatchToProps = (dispatch) => {
    return {
      actions: {
        getStatus: bindActionCreators(applicationStatusAction.getStatus, dispatch)
      },
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationStatusPage);