import {
  GET_LEARNER_SUCCESS,
  GET_LEARNER_CONSUMABLES_SUCCESS,
  ADD_LEARNER_CONSUMABLES_SUCCESS,
  DEL_LEARNER_CONSUMABLES_SUCCESS,
  SEARCH_LEARNER_SUCCESS,
  EMPTY_LEARNER,
  GET_LEARNER_WATERMARK,
  GET_LEARNER_TIMESPENT,
  GET_LOGGED_IN_HISTORY,
  //BEGIN_LEARNER_API_CALL
} from "../constants";
import initialState from "./initialState";

export default function learnerReducer(state = initialState.learners, action) {
  switch (action.type) {
    case GET_LEARNER_SUCCESS:
      return {
        loading: "Completed",
        consumables: { loading: "InProgress" },
        ...action.learners,
      };
    case SEARCH_LEARNER_SUCCESS:
      return action.learners;
    case GET_LEARNER_CONSUMABLES_SUCCESS:
      return {
        ...state,
        ["consumables"]: {
          ...action.consumables,
          ["loading"]: "Completed",
        },
      };
    case ADD_LEARNER_CONSUMABLES_SUCCESS:
      return state;
    case DEL_LEARNER_CONSUMABLES_SUCCESS:
      {
        console.log("STATE CONSUMABLES===>",state.consumables[1]);
        const schedules = state.consumables[1].eschool_sessions_summary.future_sessions.futures.detail;
        console.log("STATE DETAILS===>",schedules);
        const isThisArray = Array.isArray(schedules);
      return  {
        ...state,
        ["consumables"]: {
          ...state.consumables,
          [1] : {
            ...state.consumables[1],
            ["eschool_sessions_summary"] : {
              ...state.consumables[1].eschool_sessions_summary,
              ["future_sessions"] : {
                ...state.consumables[1].eschool_sessions_summary.future_sessions,
                ["futures"] : {
                  ...state.consumables[1].eschool_sessions_summary.future_sessions.futures,
                  ["detail"] : isThisArray ? schedules.filter((sess) => sess.on != action.session.start_date) : []
                }
              }
            }
          }
        }
      };
      }
    case EMPTY_LEARNER:
      return {
        state: { loading: "InProgress", consumables: { loading: "InProgress" } }
      };
    case GET_LEARNER_WATERMARK:
      return {
        ...state,
        ["waterMark"]: {
          ...action.waterMark,
          ["loading"]: "Completed",
        },
      };
    case GET_LEARNER_TIMESPENT:
      return {
        ...state,
        ["timeSpent"]: {
          ...action.timeSpent,
        },
      };
    case GET_LOGGED_IN_HISTORY:
      if(action.loggedin_history === "No History Found"){
        return {
          ...state,
          ["loggedin_history"]: action.loggedin_history,
        };
      }
      return {
        ...state,
        ["loggedin_history"]: {
          ...action.loggedin_history,
        },
      };
    default:
      return state;
  }
}
