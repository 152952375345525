import React, {useState, useEffect} from "react";
import clsx from "clsx";
import propTypes from "prop-types";
import classes from "./RecurringSessionModal.module.css";

function RecurringSessionModal({ modalOpen, setModalOpen, ...props }) {
  const [displayModal, setDisplayModal] = useState(false);
  useEffect(() => {
    setDisplayModal(modalOpen);
  }, [modalOpen]);
  return (
    <div
      style={{ display: displayModal ? "block" : "none" }}
      className={clsx(classes.modal, classes.coach_schedule_session_details_modal)}
      id={classes.coach_schedule_cancel_conformation_modal} //"coach-schedule-cancel-conformation-modal"
    >
      <div className={classes.modal_dialog}>
        <div className={classes.modal_content}>
          <a
            className={classes.close_icon}
            title="Close"
            aria-label="Close"
            role="button"
            data-dismiss="modal"
            id="recurring-close"
            onClick={(e) => {
              e.preventDefault();
              setModalOpen(false); //(data) => ({ ...data, toggle: false }));
            }}
          >
            <span>×</span>
          </a>
          <div className={classes.modal_body}>
            Do you want to apply these changes to all recurring sessions?
            {/* Do you want to cancel this session only, or cancel both this session and all future occurrences? */}
          </div>
          <div className="footer">
            <div className={classes.cancel_footer}>
              <input
                type="button"
                className={classes.cancel_button}
                value="SAVE THIS SESSION ONLY"
                onClick={
                  (e) =>{
                    // setModalOpen(
                    // (data) => ({ ...data, value: e.target.value }),
                    // () =>
                    props.onSessionCancel(e.target.value)
                  // )
                  // document.getElementById("filter-language").value="";
                  }
                }
              />
              <input
                className="footer-button"
                type="button"
                value="SAVE ALL"
                onClick={
                  (e) =>{
                    // setModalOpen(
                    // (data) => ({ ...data, value: e.target.value }),
                    // () =>
                    props.onSessionCancel(e.target.value)
                  // )
                  document.getElementById("filter-language").value="";
                  }
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

RecurringSessionModal.defaultProps = {
  modalOpen: { toggle: false },
};
RecurringSessionModal.propTypes = {
  modalOpen: propTypes.bool.isRequired,
  setModalOpen: propTypes.func,
};

export default RecurringSessionModal;
