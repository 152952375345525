import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import momenttimezone from "moment-timezone";
import { getLanguageByIdentifier, getOtherLanguagesByIdentifier } from "../../utils/languageUtils";
import * as substitutionAction from "../../redux/actions/SubstitutionAction";
import * as notificationsAction from "../../redux/actions/notificationsAction";
import CancelSubstitute from "../SubstitutionComponent/CancelSubstitute";
import ReAssignSubstitute from "../SubstitutionComponent/ReAssignSubstitute";
import loaderIcon from "../images/big_spinner.gif";

function AutoSubModal(props) {
  const { actions, autoSubDisplay, setAutoSubDisplay, substitutionFilterList, substitutionResponse, viewList, fromInactiveSub } = props;
  const [id, setId] = useState('');
  const [cancelToggle, setCancelToggle] = useState(false);
  const [reAssignToggle, setReAssignToggle] = useState(false);
  const [selectedCoach, setSelectedCoach] = useState(false);
  const [apiIDRequest, setApiIDRequest] = useState('');
  const [loader, setLoader] = useState(true);
  const [apiCoachSessionIDRequest, setApiCoachSessionIDRequest] = useState('');

  function toggleCancel(e, list) {
    e.preventDefault();
    if(list.learners_signed_up == 1) {
      setCancelToggle(!cancelToggle);
      setApiIDRequest(list.id);
      setApiCoachSessionIDRequest(list.coach_session_id);
    } else {
      let request = {
        "id":list.id, 
        "coach_session_id":list.coach_session_id,
        "reason":"substituted and cancelled"
      }
        if(confirm("Are you sure?")) {
        setLoader(true);
        actions.cancelSubstitutionFunction(request).then((response) => {
          setLoader(false); 
        });
      }
    }    
  }

  function toggleReassign(e, list) {
    e.preventDefault();    
    console.log(list)
    setApiCoachSessionIDRequest(list.coach_session_id);
    setId(list.id);
    console.log("props.substitutionFilterList",substitutionFilterList)
    for(let i=0; i<substitutionFilterList?.coachFilter.length; i++) {
      if(substitutionFilterList.coachFilter[i].name == list.full_name) {
        setSelectedCoach(substitutionFilterList.coachFilter[i].value)
      }
    }
    setReAssignToggle(!reAssignToggle);
   
    // actions.fetchCoachSubstitutionFunction(list.coach_session_id).then((data) => {
         
    // });

    actions.fetchAvailableCoachFunction({
      language: list.language_identifier,
      session_start_time: list.session_start_time,
      from_sub: true
    }).then((data) => {});
    
  }

  // useEffect(() => {
  //   if(substitutionResponse.length == 0)
  //   {
  //     setAutoSubDisplay(false);
  //   }
  // }, [substitutionResponse]);

  return (
    <>
      <div className="modal auto-sub-modal"
        style={{ display: (autoSubDisplay &&  (substitutionResponse?.length > 0 || viewList?.length > 0 )) ? "block" : "none" }}
        id="coach-schedule-edit-session"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
            <p className="bold-font">The following sessions for <span>{props.coachName}</span> were automatically sub requested:</p>
            {fromInactiveSub ? 
            viewList?.length > 0 &&
              <>
                <table className="auto-sub-table">
                  <thead>
                    <th style={{width: "35%"}}>Start Time</th>
                    <th style={{width: "25%"}}>Language</th>
                    <th style={{width: "15%"}}>Learners</th>
                    <th style={{width: "21%"}}>Action</th>
                  </thead>
                  <tbody>
                  {viewList && viewList.length > 0 && viewList.map((list, idx) => (
                    <tr  key={idx}>
                      <td style={{width: "35%"}}>{moment.utc(list.session_start_time.substring(0, 19)).tz(momenttimezone.tz.guess()).format("llll")}</td>
                      <td style={{width: "25%"}}>{ getLanguageByIdentifier(list.language_identifier) ? getLanguageByIdentifier(list.language_identifier) : getOtherLanguagesByIdentifier(list.language_identifier)}</td>
                      <td style={{width: "15%"}}>{list.no_of_learner}</td>
                      <td style={{width: "21%"}}>
                        <div className="sub_link">
                            <a href='' onClick={(e) => toggleCancel(e, list)} >Cancel</a>  <a href='' onClick={(e) => toggleReassign(e, list)}>Reassign</a>
                        </div>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </> : 
              substitutionResponse.length > 0 &&
              <>
                <table className="auto-sub-table">
                {/* <colgroup>
                  <col span="1" style={{width: "35%"}}/>
                  <col span="1" style={{width: "25%"}}/>
                  <col span="1" style={{width: "15%"}}/>
                  <col span="1" style={{width: "21%"}}/>
                </colgroup> */}
                  <thead>
                    <th style={{width: "35%"}}>Start Time</th>
                    <th style={{width: "25%"}}>Language</th>
                    <th style={{width: "15%"}}>Learners</th>
                    <th style={{width: "21%"}}>Action</th>
                  </thead>
                  <tbody>
                  {substitutionResponse && substitutionResponse.length > 0 && substitutionResponse.map((subResponse, idx) => (
                    <tr  key={idx}>
                      <td style={{width: "35%"}}>{moment.utc(subResponse.session_start_time.substring(0, 19)).tz(momenttimezone.tz.guess()).format("llll")}</td>
                      <td style={{width: "25%"}}>{ getLanguageByIdentifier(subResponse.language_identifier) ? getLanguageByIdentifier(subResponse.language_identifier) : getOtherLanguagesByIdentifier(subResponse.language_identifier)}</td>
                      <td style={{width: "15%"}}>{subResponse.learners_signed_up}</td>
                      <td style={{width: "21%"}}>
                        <div className="sub_link">
                            <a href='' onClick={(e) => toggleCancel(e, subResponse)} >Cancel</a>  <a href='' onClick={(e) => toggleReassign(e, subResponse)}>Reassign</a>
                        </div>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </>
            }
            
              <button
                type="button"
                className="auto-sub-ok"
                onClick={(e) => {
                  e.preventDefault();
                  setAutoSubDisplay(false);
                }}
              >OK</button>
            </div>
          </div>
        </div>
      </div>
      <CancelSubstitute cancelToggle={cancelToggle} setCancelToggle={setCancelToggle} apiIDRequest={apiIDRequest} apiCoachSessionIDRequest={apiCoachSessionIDRequest} cancelSubstitutionFunction={actions.cancelSubstitutionFunction} />

      <ReAssignSubstitute reAssignToggle={reAssignToggle} setReAssignToggle={setReAssignToggle} apiCoachSessionIDRequest={apiCoachSessionIDRequest} selectedCoach={selectedCoach} substitutionCoachList={props.substitutionCoachList} reassignSubstitutionFunction={actions.reassignSubstitutionFunction} id={id} fromInactiveSub={props.fromInactiveSub}  getInactiveCoachSub={props.getInactiveCoachSub}/>
    </>
  );
}

function mapStateToProps(state) {
  return { 
    substitutionResponse: state.substitution.substitutionResponse,
    substitutionFilterList: state.substitution.substitutionFilterList,
    substitutionCoachList: state.substitutionCoachList,
    // weeklyThreshold: state.substitution.weeklyThreshold,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getSubstitutionListFunction: bindActionCreators(substitutionAction.getSubstitutionList, dispatch),
      getSubstitutionFilterListFunction: bindActionCreators(substitutionAction.getSubstitutionFilterList, dispatch),
      getSubstitutionFilterApplyListFunction: bindActionCreators(substitutionAction.getSubstitutionFilterApplyList, dispatch),
      cancelSubstitutionFunction: bindActionCreators(substitutionAction.cancelSubstitution, dispatch),
      reassignSubstitutionFunction: bindActionCreators(substitutionAction.reassignSubstitution, dispatch),
      fetchCoachSubstitutionFunction: bindActionCreators(notificationsAction.fetchCoachSubstitutionHomePage, dispatch),
      fetchAvailableCoachFunction: bindActionCreators(notificationsAction.ModCoachAvailable,dispatch),
      // getWeeklyThresholdFunction: bindActionCreators(substitutionAction.getWeeklyThresholds, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoSubModal);
