import { GET_GENERATE_REPORT_SUCCESS} from "../constants";
import * as coachTimeOffApi from "../../apis/coachTimeOffApi";

export function getGenerateReportTable(data) {
  return function(dispatch) {
    return coachTimeOffApi
      .getCoachTimeReport(data)
      .then((response) => {
        console.log(response);
        dispatch({
          type: GET_GENERATE_REPORT_SUCCESS,
          responseList: response.data.list,
          responseRange: response.data.range,
        });
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function setResponseTimeOff(request, data) {
  return function(dispatch) {
    return coachTimeOffApi
      .setResponseTimeOffApi(request)
      .then((response) => {
        console.log(response);
        dispatch(getGenerateReportTable(data));
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function setResponseTimeOffHomePage(request) {
  return function(dispatch) {
    return coachTimeOffApi
      .setResponseTimeOffApi(request)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };
}