import { 
    GET_CONTACT_PREFERENCE_SUCCESS ,
    UPD_CONTACT_PREFERENCE_SUCCESS
 } from "../constants";

 import initialState from "./initialState";

export default function contactPreferenceReducer(state = initialState.contactPref, action){
    switch(action.type) {
        case GET_CONTACT_PREFERENCE_SUCCESS:
            return action.contactPref;
        case UPD_CONTACT_PREFERENCE_SUCCESS:
            return action.updateContact;
        default: 
            return state;    
    }
}