import {
  GET_COACHROSTER_SUCCESS,
  GET_MANAGEMENTTEAM_SUCCESS,
  GET_VIEW_LIST, 
} from "../constants";

import initialState from "./initialState";

export default function coachRostersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COACHROSTER_SUCCESS:
      //return action.payload;
      return {...state, coachRosters:action.payload};
    case GET_MANAGEMENTTEAM_SUCCESS:
      //return action.managementRoster;
      //return action.payload;
      return {...state, managementRosters:action.payload};
    case GET_VIEW_LIST:
      return {...state, coachRosters: {...state.coachRosters, coachlist: [action.payload.data]}}
    default:
      return state;
  }
}
