import {
  GET_MASTER_SCHEDULER_SUCCESS,
  ADD_MASTER_SCHEDULER_SUCCESS,
  GET_SHIFT_DETAILS_SUCCESS,
  GET_SHIFT_SESSION_DETAILS_LOADING,
  GET_SHIFT_SESSION_DETAILS_SUCCESS,
  GET_SHIFT_SESSION_DETAILS_FAILURE,
  CLEAR_ERRORS,
} from "../constants";
import moment from "moment";
import * as masterSchedulerApi from "../../apis/MasterSchedulerApi";
import * as appointmentsApi from "../../apis/appointmentsApi";

export function getMasterScheduler(params) {
  return function(dispatch) {
    return masterSchedulerApi
      .getMasterScheduler(params)
      .then((response) => {
        console.log("response", response.data);
        dispatch({
          type: GET_MASTER_SCHEDULER_SUCCESS,
          masterScheduler: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getShiftDetails(params) {
  console.log(params);
  return function(dispatch) {
    return masterSchedulerApi
      .getShiftDetails(params)
      .then((response) => {
        console.log("response", response.data);
        dispatch({
          type: GET_SHIFT_DETAILS_SUCCESS,
          shiftDetails: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

// export function deleteConfirmedSession(params) {
//   console.log(params);
//   return function(dispatch) {
//     return masterSchedulerApi
//       .deleteConfirmedSession(params)
//       .then((response) => {
//         return response.data;
//       })
//       .catch((err) => {
//         throw err;
//       });
//   };
// }

export function addMasterScheduler(session, params = {}) {
  const startTime = moment(session.session_start_time, "YYYY-MM-DD HH:mm:ss").toISOString();
  const endTime = moment(session.session_end_time, "YYYY-MM-DD HH:mm:ss").toISOString();
  let { language_type, ...data } = session;
  data = {
    ...data,
    session_start_time: moment(startTime)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss"),
    session_end_time: moment(endTime)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss"),
  };

  if (data.is_recurring) {
    const recurstart = startTime;
    const recurend = endTime;
    const recurring_start_date = data.session_start_time;
    //const day_index = moment(recurring_start_date).isoWeekday() - 1;
    const day_index = moment.utc(recurring_start_date).day();

    data = { ...data, recurring_start_date, recurstart, recurend, day_index };
  }

  console.log(data);

  return function(dispatch) {
    return (data.type == "ExtraSession"
      ? masterSchedulerApi.addMasterExtraSession(data)
      : masterSchedulerApi.addMasterScheduler(data)
    ).then((response) => {
      console.log("response", response.data);
      // dispatch({
      //   type: ADD_MASTER_SCHEDULER_SUCCESS,
      //   masterScheduler: response.data,
      // });
      return masterSchedulerApi
        .getMasterScheduler(params)
        .then((response) => {
          console.log("response", response.data);
          dispatch({
            type: GET_MASTER_SCHEDULER_SUCCESS,
            masterScheduler: response.data,
          });
        })
        .catch((err) => {
          throw err;
        });
    });
  };
}

//Add Bulk Extra Session

export function addBulkExtrasessions(bulkSessions, params={}) {
   return function(dispatch){
   return masterSchedulerApi.addBulkExtrasessions(bulkSessions).then((bulkResponse) => {
    return masterSchedulerApi
        .getMasterScheduler(params)
        .then((response) => {
          console.log("response", response.data);
          dispatch({
            type: GET_MASTER_SCHEDULER_SUCCESS,
            masterScheduler: response.data,
          });

          if(bulkResponse.data?.failed_sessions?.length == 0) 
          {
            return true;
          }
          else
          {
            return false;
          }
        })
        .catch((err) => {
          console.log("=============> error in master scheduler action" + err);
          throw err;
        });
    })
  }
  }








export function deleteConfirmedSession(params, requestShiftDetail, requestSchedules) {
  console.log(params);
  return function(dispatch) {
    return masterSchedulerApi
      .deleteConfirmedSession(params)
      .then((response) => {
        if (response.data.status == true) {
          //hit shift detail
          return masterSchedulerApi.getShiftDetails(requestShiftDetail).then((response) => {
            dispatch({
              type: GET_SHIFT_DETAILS_SUCCESS,
              shiftDetails: response.data,
            });
            //hit MasterSchedule
            return masterSchedulerApi.getMasterScheduler(requestSchedules).then((response) => {
              dispatch({
                type: GET_MASTER_SCHEDULER_SUCCESS,
                masterScheduler: response.data,
              });
            });
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function deleteLocalSession(params, requestShiftDetail, requestSchedules) {
  console.log(params);
  return function(dispatch) {
    return masterSchedulerApi
      .deleteLocalSession(params)
      .then((response) => {
        if (response.data.affectedRows == 1) {
          //hit shift detail
          return masterSchedulerApi.getShiftDetails(requestShiftDetail).then((response) => {
            dispatch({
              type: GET_SHIFT_DETAILS_SUCCESS,
              shiftDetails: response.data,
            });
            //hit MasterSchedule
            return masterSchedulerApi.getMasterScheduler(requestSchedules).then((response) => {
              dispatch({
                type: GET_MASTER_SCHEDULER_SUCCESS,
                masterScheduler: response.data,
              });
            });
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function pushSessionStatus(pushParams) {
  console.log(pushParams);
  return function(dispatch) {
    return masterSchedulerApi
      .pushSessionStatus(pushParams)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function pushMasterSchedulerSessions(pushParams, params) {
  console.log(pushParams);
  return function(dispatch) {
    return appointmentsApi
      .pushAppointmentSchedules(pushParams)
      .then((response) => {
        if (response.data == "success") {
          return masterSchedulerApi.getMasterScheduler(params).then((response) => {
            console.log("response", response.data);
            dispatch({
              type: GET_MASTER_SCHEDULER_SUCCESS,
              masterScheduler: response.data,
            });
          });
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function uncancelConfirmedSession(params, requestShiftDetail, requestSchedules) {
  console.log(params);
  return function(dispatch) {
    return masterSchedulerApi
      .uncancelConfirmedSession(params)
      .then((response) => {
        if (response.data.status == true) {
          //hit shift detail
          return masterSchedulerApi.getShiftDetails(requestShiftDetail).then((response) => {
            dispatch({
              type: GET_SHIFT_DETAILS_SUCCESS,
              shiftDetails: response.data,
            });
            //hit MasterSchedule
            return masterSchedulerApi.getMasterScheduler(requestSchedules).then((response) => {
              dispatch({
                type: GET_MASTER_SCHEDULER_SUCCESS,
                masterScheduler: response.data,
              });
            });
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getShiftSessionDetails(params) {
  console.log(params);
  return function(dispatch) {
    dispatch({ type: GET_SHIFT_SESSION_DETAILS_LOADING });
    return masterSchedulerApi
      .getShiftSessionDetails(params)
      .then((response) => {
        dispatch({ type: GET_SHIFT_SESSION_DETAILS_SUCCESS, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: GET_SHIFT_SESSION_DETAILS_FAILURE, payload: err.response.data.message });
      });
  };
}
// updateShiftSession
export function updateSession(session, params) {
  console.log("---------->" + JSON.stringify(session));
  const startTime = moment(session.session_start_time).format("YYYY-MM-DD HH:mm:ss");
  const endTime = moment(session.session_end_time).format("YYYY-MM-DD HH:mm:ss");

  let { language_type, ...data } = session;

  if (data.is_recurring) {
    const recurstart = startTime;
    const recurend = endTime;
    const recurring_start_date = data.session_start_time;
    //const day_index = moment(recurring_start_date).isoWeekday() - 1;
    const day_index = moment.utc(recurring_start_date).day();

    data = { ...data, recurring_start_date, recurstart, recurend, day_index };
  }

  return function(dispatch) {
    // updateConfirmedSession/updateExtraSession
    return (data.type == "ExtraSession"
      ? masterSchedulerApi.updateExtraSession(data)
      : data.type == "LocalSession" || data.type == "recurring_session"
      ? masterSchedulerApi.updateLocalSession(data)
      : masterSchedulerApi.updateConfirmedSession(data)
    )
      .then(() => {
        return masterSchedulerApi.getMasterScheduler(params).then((response) => {
          console.log("response", response.data);
          dispatch({
            type: GET_MASTER_SCHEDULER_SUCCESS,
            masterScheduler: response.data,
          });
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

export function deleteExtraSession(params, requestShiftDetail, requestSchedules) {
  console.log(params);
  return function(dispatch) {
    return masterSchedulerApi
      .deleteExtraSession(params)
      .then((response) => {
        if (response.data.status == true) {
          //hit shift detail
          return masterSchedulerApi.getShiftDetails(requestShiftDetail).then((response) => {
            dispatch({
              type: GET_SHIFT_DETAILS_SUCCESS,
              shiftDetails: response.data,
            });
            //hit MasterSchedule
            return masterSchedulerApi.getMasterScheduler(requestSchedules).then((response) => {
              dispatch({
                type: GET_MASTER_SCHEDULER_SUCCESS,
                masterScheduler: response.data,
              });
            });
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  };
}
