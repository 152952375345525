import { GET_LANGUAGE_SUCCESS } from "../constants";

import initialState from "./initialState";

export default function languageReducer(
  state = initialState.languages,
  action
) {
  switch (action.type) {
    case GET_LANGUAGE_SUCCESS:
      return action.languages;
    default:
      return state;
  }
}
