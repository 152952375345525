import { AccountAPI, EschoolAPI } from "./apiUtils";

export const getSubstitutionListApi = async () => await AccountAPI.post("/substitutions?time_range=upcoming");

export const getSubstitutionFilterListApi = async (data) => await AccountAPI.get("/filter-for-substitutions?time_range=" + data.time_range + "&language=" + data.language);

export const getSubstitutionFilterApi = async (data) => {  return await AccountAPI.post("/substitutions-with-filter", data)};

export const cancelSubstitutionApi = async (data) => await EschoolAPI.post("/cancel-substitution", data);

export const reassignSubstitutionApi = async (data) => await EschoolAPI.post("/reassign-coach-substitution", data);

export const fetchCoachSubstitutionApi = async (data) => await AccountAPI.get(`/fetch-available-coaches?sessionId=${data}`);

export const getSubstitutionReportListApi = async (data) => {  return await AccountAPI.post("/substitutions-report", data)};

export const modCoachAvailabilityApi = async (data) => await AccountAPI.post("/mod-coach-available-create-new", data);

export const getWeeklyThresholds = async() => await AccountAPI.get("/weekly-thresholds");

export const fetchCoach = async (data) => await AccountAPI.get(`/fetch-available-coaches`);

