import { AccountAPI, EschoolAPI } from "./apiUtils";

export const addAppointment = async (message) =>
  await AccountAPI.post("/appointent-type", {
    ...message,
    active: true,
  });

export const getAppointment = async () => await AccountAPI.get("/appointent-type");

export const getActiveAppointment = async () => await AccountAPI.get("/active-appointent-type");

export const updateAppointment = async(appointment, id) =>    
    await AccountAPI.patch("/appointent-type",{...appointment,["id"]:id});

export const getAppointmentSchedules = async (params) => await EschoolAPI.post("/appointment-scheduler", params);
export const getAppointmentDetails = async (params) => await EschoolAPI.post("/fetch-appointment-details", params);

// http://localhost:3000/api/fetch-available-moderator?start_time="2021-04-03 06:30:00"&lang_identifier=ENG
export const getModerators = async (params) => await AccountAPI.get("/fetch-available-moderator", { params });
/*export const deleteAppointment = async id => await AccountAPI.delete("/links",{params:{id}});*/

export const pushAppointmentSchedules = async (params) => {
  
  return await EschoolAPI.post("/push-sessions-toeschool", { ...params, ["appointments_push"]: params.appointments_push.toString()});
}

export const createAppointment = async (params) =>
  await EschoolAPI.post("/appointments", params);

export const editAppointment = async (params) =>
  await EschoolAPI.patch("/appointments", params);

export const cancelAppointment = async (params) =>
{
  console.log("check======>" + JSON.stringify(params));
  return await EschoolAPI.delete("/appointments", {params: params});
}

// http://localhost:3000/api/check-coach-available?session_start_time=2022-02-16 09:30:00&coach_id=10266 
export const checkCoachAvailable = async (params) => await AccountAPI.get("/check-coach-available", { params });