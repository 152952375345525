import { useState, useEffect } from "react";
import * as sessionApi from "../apis/SessionsApi";

export default function useModerator() {
  const [villages, setVillages] = useState([]);
  useEffect(() => {
    // Object.keys(params).length != 0 &&
    sessionApi
      .getVillages()
      .then((response) => {
        setVillages(response.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  return villages;
}
