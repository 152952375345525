export default function CreateSessionObject() {
  this.language_identifier = "all"; //eg=this. ENG
  this.language_id = "";
  this.number_of_seats = ""; // int
  this.type = "session";
  this.is_recurring = false;
  this.teacher_confirmed = true;
  this.details = ""; //optional parameter
  this.lesson = ""; //4; //optional parameter
  this.level = ""; //1; //optional parameter
  this.unit = ""; //1; //optional parameter
  this.moderator_id = "";
  this.topic_id = "";
  this.appointment_type_id = "";
  this.recurring_end_date = ""; //"2021-04-25 12=00=00"; // optional
  this.external_village_id = ""; //1; // optional
  this.recurring_frequency = 1; // 1 – weekly; 2 – biweekly; default value is 1 if not passed,
  this.topic_restrictions = "all_topics_plus_cp";
}

CreateSessionObject.prototype.setSessionSlot = function(slot) {
  this.language_identifier = slot.language_identifier;
  this.language_id = slot.language_id;
  this.number_of_seats = slot.number_of_seats; // int
  this.type = "session";
  this.is_recurring = slot.is_recurring;
  this.teacher_confirmed = slot.teacher_confirmed;
  this.details = slot.details;
  this.lesson = slot.lesson;
  this.level = slot.level;
  this.unit = slot.unit;
  this.moderator_id = slot.moderator_id;
  this.topic_id = slot.topic_id;
  this.recurring_end_date = slot.recurring_end_date;
  this.external_village_id = slot.external_village_id;
  this.recurring_frequency = slot.recurring_frequency;
  this.topic_restrictions = slot.topic_restrictions
};

CreateSessionObject.prototype.getSessionSlot = function() {
  const {
    language_identifier,
    language_id,
    number_of_seats,
    type,
    is_recurring,
    teacher_confirmed,
    lesson,
    level,
    unit,
    moderator_id,
    topic_id,
  } = this;
  return {
    language_identifier,
    language_id,
    number_of_seats,
    type,
    is_recurring,
    teacher_confirmed,
    lesson,
    level,
    unit,
    moderator_id,
    topic_id,
  };
};
