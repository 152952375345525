import React, { useState } from 'react'
import { useLocation , useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import propTypes from 'prop-types' 
import { Helmet } from "react-helmet"
import decode from "jwt-decode"

import { login } from '../redux/actions/userAction'
import "./LoginPage.css"
import { getStartPageURL } from "../utils/languageUtils";
import spinner from "../components/images/spinner.gif";

const title = "Customer Success Portal: Sign in";

function LoginPage({ login }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState("");
    const history = useHistory();    
    const location  = useLocation();
    const [signInDisabled, setSignInDisabled] = useState(false);

    function onformSubmit(e){
        e.preventDefault();  
        if(!formIsValid()) return;  
        setSignInDisabled(true);
        login({user_name:username,password})
        .then((res)=> localStorage.setItem("token",res))
        .then(() => {
            // console.log(token);
            const user = decode(localStorage.getItem("token"));
            let startPage;
            if(user.start_page){
                startPage = user.start_page;
            } else{
                if(user.type === "CoachManager"){
                    startPage = "HOME";
                } 
                else if(user.type === "Coach"){
                    startPage = "CALENDAR";
                }
                else if(user.type === "SupportLead"){
                    startPage = "LEARNERS_SEARCH";
                }
            }
            window.location.href="/"+getStartPageURL(startPage)
            setSignInDisabled(false);
        }).catch(e =>{ 
            setErrors("Invalid Username/Password");
            // console.log(e);
             let { error_message } = JSON.parse(e.message); 
             setErrors(error_message);
             setSignInDisabled(false);
        });
            
    }

    function formIsValid(){        
        const errors = {};
        
        if(!username) errors.username =  "Please enter a username";
        if(!password) errors.password =  "Please enter a password";

        setErrors(errors.username || errors.password);
        return Object.keys(errors).length === 0;
    }

    return (    
    <>
        <Helmet> 
            <title>{title}</title>    
        </Helmet>

        <div className="signin-container">
            <div className="signin-inner">
            
            { errors && 
                <div className="error" data-testid="error" role="alert">{errors}</div> 
            }
            { errors || location?.state?.loggedOut && 
                <div className="notice" data-testid="notice" >Logged out</div> 
            }            
            
            <h2 data-testid="sign_in">Sign in</h2>
            <p>Enter your username and password</p>
            <form onSubmit={onformSubmit} id="account_form" data-testid="loginform">
                <label htmlFor="coach_user_name">User name</label>
                <input type="text" className="text" id="coach_user_name" 
                    size="30" onChange={e => setUsername(e.target.value)} 
                    data-testid="username" required />
                <label htmlFor="coach_password">Password</label>
                <input type="password" className="text" id="coach_password" 
                    size="30"  onChange={e => setPassword(e.target.value)} 
                    data-testid="password" required />
                <div className="sign-in-container">
                    <input type="submit" className="btn" id="sign-in-btn" value={`Sign In`} data-testid="sigInBtn" disabled={signInDisabled}/>
                    {signInDisabled && <div className="sign-in-loader">
                        <img src={spinner} title=""/>
                    </div>}
                </div>
            </form>
            <p>
                <a href="https://qpm.trstone.com/PMSelfService/login" 
                    data-testid="frgotpwd" target="_blank" rel="noreferrer">Forgot your password?
                </a>
            </p>
            {/* <p>
                To request access to this site, contact the
                <a href="#"> list owner</a>.
            </p> */}
            </div>
        </div>
    </>
    );
}

LoginPage.propTypes = {
    login: propTypes.func.isRequired
}


function mapDispatchToProps(dispatch){
    return {        
            login: bindActionCreators(login,dispatch)        
    }
}

export default connect(null,mapDispatchToProps)(LoginPage)
