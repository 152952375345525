import React, { useState, useEffect, useRef, useReducer, createContext } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import ShiftDetail from "./ShiftDetail";
import * as masterSchedulerAction from "../../redux/actions/MasterSchedulerAction";
import CreateExtraBulkSessionModal from "./CreateExtraBulkSessionModal";
import solo from "../images/solo.png";

const LOADING = "LOADING";
const LOADED = "LOADED";
function loadingReducer(state = false, action) {
  if (action.type === LOADING) return true;
  if (action.type === LOADED) return false;
  return state;
}

function MasterSchedulerCalendar(props) {
  const { shiftDetails, setBulkSession } = props;
    const bulkSessionsObject = {
      "sessions":[],
      "language_identifier":"ENG",
      "language_id":"",
      "number_of_seats":"",
      "type":"ExtraSession",
      "lesson":"",
      "level":"",
      "unit":"",
      "topic_id":"",
      "external_village_id":"",
      "excluded_coaches":[],
   }

  const [loading, loadingDispatch] = useReducer(loadingReducer, false);
  const [double, setDouble] = useState(props.goLoader);
  const [bulkSessions, setBulkSessions] = useState(bulkSessionsObject);
  //const [openEditModal, setOpenEditModal] = useState(false);
  const decideColor = (slotType) => {
    switch (slotType) {
      case "historic_sessions":
        return "#FFFFFF";
      case "pushed_to_eschool":
        return "#BFD3DD";
      case "local_session":
        return "#D5C8E8";
      case "extra_sessions":
        return "#669966";
      case "non_eschool_sessions":
        return "#FFFFFF";
      case "recurring_sessions":
        return "#E3E2D5";
      case "on-select":
        return "#9ed9f7";
    }
  };

  const decideBorderColor = (slotType) => {
    switch (slotType) {
      case "historic_sessions":
        return "#FF9800";
      case "pushed_to_eschool":
        return "#CBCCC4";
      case "local_session":
        return "#D2CEA1";
      case "extra_sessions":
        return "#CBCCC4";
      case "non_eschool_sessions":
        return "#CECECE";
      case "recurring_sessions":
        return "#D2CEA1";
    }
  };
  // const [eventTitle, setEventTitle] = useState();
  const [schedules, setSchedules] = useState([]);
  const [updatedSchedules, setUpdatedSchedules] = useState([]);
  const [events, setEvents] = useState();
  const [session, setSession] = useState({
    displaySession: false,
    xAxis: "0px",
    yAxis: "0px",
    xDistance: "0px",
    yDistance: "0px",
    yOffset: "0px",
    sessionDate: "",
  });
  const [shiftDetailParams, setShiftDetailParams] = useState({
    languageIdentifier: props.masterFilter.languageId,
    start_date: "",
    day_index: "",
    region: props.masterFilter.region,
    slotType: props.masterFilter.classRoomType,
    village: props.masterFilter.villageId,
  });
  const [closeAllModals, setCloseAllModals] = useState(false);
  const [isSchedulesChanged, setIsSchedulesChanged] = useState(false);
  const [bulkSessionDisplay, setBulkSessionDisplay] = useState(false);

  var header_selection_array = document.querySelectorAll("th.fc-col-header-cell.fc-day, .fc-timegrid-slot-label, .fc-timegrid-axis-frame");

  useEffect(() => {
    if(props.goLoader){
      setDouble(props.goLoader);
      // setSchedules([]);
    }
  }, [props.goLoader]);

  useEffect(() => {
    if(props.bulkSession){
      setBulkSessionDisplay(true);
      header_selection_array.forEach(el => el.classList.add("bulk-enabled"));
    }
  }, [props.bulkSession]);

  useEffect(() => {
    if(bulkSessionDisplay == false){
      props.setBulkSession(false);
      header_selection_array.forEach(el => el.classList.remove("bulk-enabled"));
    }
  }, [bulkSessionDisplay]);

  function extractIdentifier(language) {
    let identifier = language.split("-");
    return identifier.length == 2
      ? identifier[0].toLowerCase()
      : identifier.length == 3
      ? "fbsolo"
      : "totale";
  }

  useEffect(()=>{
    if(props.showCalendar){
      document.querySelector("th.fc-timegrid-axis .fc-timegrid-axis-frame").innerHTML = `TIME (${props.updatedTimeZone}`
    }
  },[props.updatedTimeZone])
  useEffect(() =>{
    props.onDoubleChange(double)
  }, [double]);
  // useEffect(()=>{
  //   console.log("Entered the useeffect =====>", eventTitle)
  //   setDouble(false);
  // }, [eventTitle]);

  useEffect(() => {
    if (props.schedulerList != undefined && props.schedulerList.length != 0) {
      setSchedules(props.schedulerList);
      setIsSchedulesChanged(true);
    }
    if (props.schedulerList != undefined && props.schedulerList.length == 0) {
      setSchedules([]);
      setDouble(false);
    }
    // if(props.isFromNavigation == true){
    //   setDouble(true);
    // }
  }, [props.schedulerList]);

  function removeandAddSlot(startTimeString) {

    let removedExistingSlot = schedules.filter((slot) => { return ((slot.startTime != startTimeString && slot.startTime != moment(startTimeString).toISOString()))})

    removedExistingSlot.push({
        startTime: startTimeString,
        endTime: moment(startTimeString).add(extractIdentifier(props.masterFilter.languageId?.toLowerCase()) == "fb" ? 60 : 30, "minutes").format("YYYY-MM-DD HH:mm:ss"),
        lang: "ENG",
        eventDisplay: "block",
        slotType: "on-select",
        textEscape: true,
        label: ""
    })
    return removedExistingSlot;
  }

  function handleDateClick(e) {}

  function handleEventClick(e) {
    //setOpenEditModal(false)
    // var a=true;
    if(props.bulkSession)
    {
    console.log(e.jsEvent);
    if (moment(e.event?.startStr).isBefore(new Date())) return;
    if (moment(e.date).isBefore(new Date())) return;
    let slotTime = e.dateStr || e.event?.startStr;
    // Removing the selected slot
    if(bulkSessions.sessions.find((slot) => slot.session_start_time == slotTime
    ) && (e.event.extendedProps.type == "on-select"))
    {

      setBulkSessions({...bulkSessions, sessions: bulkSessions.sessions.filter((slot) => { 
        return slot.session_start_time != slotTime})})
      setSchedules(schedules.filter((slot) => { 
        return ((slot.startTime != slotTime && slot.startTime != moment(slotTime).toISOString()))}))
      return;
    }
    //
    
    const start_time =
          extractIdentifier(props.masterFilter.languageId?.toLowerCase()) == "fb"
            ? moment(slotTime).format("mm") == "30"
              ? moment(slotTime).subtract(30, "minutes")
              : slotTime
            : slotTime;

   setBulkSessions({...bulkSessions, sessions: [ ...bulkSessions.sessions,   {
      "session_start_time": start_time,
      "day_index": moment.utc(start_time).day(),
      "teacher_confirmed":true,
      "number_of_sessions":1
   }]})

   
  //  if(schedules.find((slot) => (slot.startTime == start_time || slot.startTime == moment(start_time).toISOString())))
  //  {
  //   setSchedules([...schedules, 
  //     schedules.map((sess) => {
  //     if ((sess.startTime == start_time || sess.startTime == moment(start_time).toISOString()))
  //     {
  //       sess.slotType = "on-select"
  //     }
  //     return sess;
  //   })])
  //  }
  //  else
  //  {
    // setSchedules([...schedules, {
    //   startTime: start_time,
    //   //endTime: moment(e.dateStr).add(30, 'minutes').toISOString(),
    //   endTime: moment(start_time).add(extractIdentifier(props.masterFilter.languageId?.toLowerCase()) == "fb" ? 60 : 30, "minutes").format("YYYY-MM-DD HH:mm:ss"),
    //   lang: "ENG",
    //   eventDisplay: "block",
    //   slotType: "on-select",
    //   textEscape: true,
    //   label: ""
    // }])
 //  }
      setSchedules(removeandAddSlot(start_time))
      return;
}

    setCloseAllModals(true);
    let xDistance = e.jsEvent.clientX;
    let yDistance = e.jsEvent.pageY;
    let xOffset = e.jsEvent.offsetX;
    let yOffset = e.jsEvent.offsetY;
    console.log("selected Date ==============>" + e.dateStr == undefined ? e.event.start : e.dateStr);
    //if (e.event.start < new Date()) return;
    setSession({
      ...session,
      ["displaySession"]: true,
      ["xAxis"]: xDistance,
      ["yAxis"]: yDistance,
      ["xDistance"]: xDistance,
      ["yDistance"]: yDistance,
      ["yOffset"]: yOffset,
      ["sessionDate"]: e.dateStr == undefined ? e.event.start : e.dateStr,
    });
    setShiftDetailParams({
      ...shiftDetailParams,
      languageIdentifier: props.masterFilter.languageId,
      start_date:
        e.dateStr == undefined
          ? moment.utc(e.event.start).format("YYYY-MM-DD HH:mm:ss")
          : moment.utc(e.dateStr).format("YYYY-MM-DD HH:mm:ss"),
      day_index: e.dateStr == undefined ? moment.utc(e.event.start).day() : moment.utc(e.dateStr).day(),
    });
    loadingDispatch({ type: LOADING });
    props.actions
      .getShiftDetails({
        ...shiftDetailParams,
        languageIdentifier: props.masterFilter.languageId,
        start_date:
          e.dateStr == undefined
            ? moment.utc(e.event.start).format("YYYY-MM-DD HH:mm:ss")
            : moment.utc(e.dateStr).format("YYYY-MM-DD HH:mm:ss"),
        day_index: e.dateStr == undefined ? moment.utc(e.event.start).day() : moment.utc(e.dateStr).day(),
      })
      .then(() => {
        loadingDispatch({ type: LOADED });
      })
      .catch(() => {
        loadingDispatch({ type: LOADED });
      });
    let element = document.getElementById("master-schedule-shift-details");
    element.scrollIntoView({ behavior: "smooth", block: "center", inline: "start" });
    setCloseAllModals(false);
  }

  function changeDate() {
    // let calendarApi = props.calRef.current.getApi();
    // calendarApi.gotoDate(moment(props.coachFilter.filterDate).format());
  }

  function renderEventContent(eventInfo) {
    var text = eventInfo.event.title;
    if(eventInfo.event.extendedProps.count === schedules.length && isSchedulesChanged){
      setDouble(false);
      setIsSchedulesChanged(false);
    }
    if (text.split("<br/>").length > 1) {
      // console.log(text.split("<br/>"));
      // setEventTitle(text);
      // setDouble(false);
      return (
        <>
          <div className="event-title">
            <p>{text.split("<br/>")[0]}</p>
            <p>
              <span style={{ display: text.split("<br/>")[1].indexOf("<img") != -1 ? "block" : "none" }}>
                <img className="solo_icon" src={solo} alt="solo"></img>{" "}
              </span>
              <span className={text.split("<br/>")[1].replace(/<[^>]+>/g, '') == "  Sub Needed " ? "sub-needed" : ""}>
                {text.split("<br/>")[1] != undefined
                  ? text.split("<br/>")[1].indexOf("<img") != -1
                    ? text.split("<br/>")[1].split("<img src='/images/solo.png'>")[0]
                    : text.split("<br/>")[1].replace(/<[^>]+>/g, '')
                  : ""}
              </span>
            </p>
            <p>
              <span
                style={{
                  display:
                    text.split("<br/>")[2] != undefined
                      ? text.split("<br/>")[2].indexOf("<img") != -1
                        ? "block"
                        : "none"
                      : "none",
                }}
              >
                <img className="solo_icon" src={solo} alt="solo"></img>{" "}
              </span>

              <span>
                {" "}
                {text.split("<br/>")[2] != undefined
                  ? text.split("<br/>")[2].split("<font color='red'>")[1] != undefined
                    ? text
                        .split("<br/>")[2]
                        .split("<font color='red'>")[1]
                        .split("</font>")[0]
                        .replace("<img src='/images/solo.png'>", "")
                    : text.split("<br/>")[2].split("<span class='substitute-info'>")[1] != undefined
                    ? text
                        .split("<br/>")[2]
                        .split("<span class='substitute-info'>")[1]
                        .split("</span>")[0]
                        .replace("<img src='/images/solo.png'>", "")
                    : text.split("<br/>")[2].split("..")[0] != undefined
                    ? text.split("<br/>")[2].split("..")[0]
                    : text.split("<br/>")[2].replace("<img src='/images/solo.png'>", "")
                  : ""}
              </span>
            </p>

            <p>
              {text.split("<br/>")[3] != undefined
                ? text.split("<br/>")[3].split("<font color='red'>")[1] != undefined
                  ? text
                      .split("<br/>")[3]
                      .split("<font color='red'>")[1]
                      .split("</font>")
                  : text.split("<br/>")[3].split("<span class='substitute-info'>")[1] != undefined
                  ? text
                      .split("<br/>")[3]
                      .split("<span class='substitute-info'>")[1]
                      .split("</span>")
                  : text.split("<br/>")[3]
                : ""}
            </p>
          </div>
        </>
      );
    } else {
      // setEventTitle(text);
      // setDouble(false);
      return (
        <>
          <div className="event-title">
            <strong>{text.split("<br/>")[0]}</strong>
          </div>
        </>
      );
    }
  }
  
  return (
    <>
      {double && (
        <>
          <div className="blockOverlay" style={{ display: "block" }}></div>
          <div className="blockMsg" style={{ display: "block" }}>
            <h1>Please wait...</h1>
          </div>
        </>
      )}
      {props.showCalendar && (
        <FullCalendar
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          dateClick={(info) => {
            handleEventClick(info);
          }}
          initialDate={new Date()}
          stickyHeaderDates={true}
          allDaySlot={false}
          displayEventTime={false}
          events={
            schedules.length > 0 &&
            schedules.map((schedule, index) => ({
              id: schedule.label,
              title: schedule.label,
              start: moment(schedule.startTime).format(),
              end: moment(schedule.endTime).format(),
              color: decideColor(schedule.slotType),
              borderColor: decideBorderColor(schedule.slotType),
              textColor: "black",
              textAlign: "center",
              fontSize: "14px",
              eventDisplay: "block",
              textEscape: true,
              type: schedule.slotType,
              extendedProps: {
                count: index + 1
              },
            }))
          }
          eventContent={renderEventContent}
          slotDuration={props.config.slotDuration} //{"00:30:00"}
          eventClick={(info) => {
        //    setOpenEditModal(false)
            console.log("INFORMATION" + info.event.extendedProps.type);
            handleEventClick(info);
          }}
          timeFormat={"hh:mm A"}
          dayHeaderFormat={{
            weekday: "long",
            month: "short",
            day: "2-digit",
            year: "numeric",
          }}
          eventTimeFormat={{
            hour: "numeric",
            minute: "2-digit",
            meridiem: "short",
          }}
          ref={props.calRef}
          height="auto"
        
        />
      )}
      {props.shiftDetails != undefined && (
        <ShiftDetail
          settings={session}
          setSettings={setSession}
          shiftDetails={props.shiftDetails}
          shiftDetailParams={shiftDetailParams}
          masterFilter={props.masterFilter}
          loading={loading}
          cancelLoading={() => {
            loadingDispatch({ type: LOADED });
          }}
          closeAllModals={closeAllModals}
        
          // openEditModal={openEditModal}
          // setOpenEditModal={setOpenEditModal}
        />
      )}
      <CreateExtraBulkSessionModal
        openModal={bulkSessionDisplay}
        setOpenModal={setBulkSessionDisplay}
        shiftDetails={props.shiftDetails}
        shiftDetailParams={shiftDetailParams}
        settings={session}
        masterFilter={props.masterFilter}
        setSettings={setSession}
        bulkSessions={bulkSessions}
        setBulkSessions={setBulkSessions}
        setBulkSession={setBulkSession}
        bulkSessionsObject={bulkSessionsObject}
        schedules={schedules}
        setSchedules={setSchedules}
      />
    </>
  );
}

MasterSchedulerCalendar.prototype = {
  actions: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shiftDetails: state.shiftDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      getShiftDetails: bindActionCreators(masterSchedulerAction.getShiftDetails, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterSchedulerCalendar);
