import { GET_STUDIO_ON_DUTY_SUCCESS, ADD_STUDIO_ON_DUTY_SUCCESS, MDY_GLOBAL_SETTINGS } from "../constants";

import * as studioTeamApi from "../../apis/StudioOnDutyApi";

export function getStudioTeamOnDuty(status) {
  return function(dispatch) {
    return studioTeamApi
      .getStudioTeam(status)
      .then((response) => {
        // console.log(response.data);
        dispatch({
          type: GET_STUDIO_ON_DUTY_SUCCESS,
          onDuty: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function updateStudioTeamOnDuty(studioTeam) {
  return function(dispatch) {
    return studioTeamApi
      .updateStudioTeam(studioTeam)
      .then(() => {
        dispatch({
          type: ADD_STUDIO_ON_DUTY_SUCCESS,
          addStudioTeam: studioTeam,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

// Update global settings:
// PATCH: http://localhost:3000/api/global-settings
// {
// "attribute_name":"on_duty_text_message",
// "attribute_value": "lister1232"
// }
// export const putGlobalSettings = async (data) => await AccountAPI.post("/global-settings", data);

export function mdyGlobalSettings(data) {
  return function(dispatch) {
    return studioTeamApi
      .globalSettings(data)
      .then(() => {
        // console.log(data);
        dispatch({
          type: MDY_GLOBAL_SETTINGS,
          mdySettings: data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}
