import {
  GET_QA_FORMS_LIST_SUCCESS,
  GET_QA_FORMS_SCORE_LIST_SUCCESS,
  GET_QA_FORMS_RATINGS_LIST_SUCCESS,
  GET_QA_FORMS_CATEGORIES_LIST_SUCCESS,
  GET_QA_FORMS_CRITERIA_LIST_SUCCESS,
  GET_QA_FORMS_UNIQUE_LIST_SUCCESS,
  GET_QA_FORMS_NONUNIQUE_LIST_SUCCESS,
  GET_COACH_SESSIONS_SUCCESS,
  GET_COACH_SCORE_SHEET_SUCCESS,
  GET_COACH_SESSION_RATING_SUCCESS,
  GET_COACH_EVALUATION_SUCCESS,
  GET_COMPARATIVE_EVALUATION_SUCCESS
} from "../constants";

import initialState from "./initialState";

export default function qaFormReducer(state = initialState, action) {
  switch (action.type) {
    case GET_QA_FORMS_LIST_SUCCESS:
      return {...state, qaFormsList:action.payload};
    case GET_QA_FORMS_SCORE_LIST_SUCCESS:
      return {...state, qaFormsScores:action.payload};
    case GET_QA_FORMS_RATINGS_LIST_SUCCESS:
      return {...state, qaFormsPercentages:action.payload};
    case GET_QA_FORMS_CATEGORIES_LIST_SUCCESS:
      return {...state, qaFormsCategories:action.payload};
    case GET_QA_FORMS_CRITERIA_LIST_SUCCESS:
      return {...state, qaFormsCriteria:action.payload};
    case GET_QA_FORMS_UNIQUE_LIST_SUCCESS:
    return {...state, qaFormsUniqueList:action.payload};
    case GET_QA_FORMS_NONUNIQUE_LIST_SUCCESS:
    return {...state, qaFormsNonUniqueList:action.payload};
    case GET_COACH_SESSIONS_SUCCESS:
      return {...state,qaCoachSessionList:action.payload}
    case GET_COACH_SCORE_SHEET_SUCCESS:
      return {...state,qaCoachScoreList:action.payload}
    case GET_COACH_EVALUATION_SUCCESS:
      return {...state,qaCoachEvalList:action.payload}
    case GET_COACH_SESSION_RATING_SUCCESS:
      return {...state,qaCoachRatingList:action.payload}  
      case GET_COMPARATIVE_EVALUATION_SUCCESS:
      return {...state,qaComparativeEvalList:action.payload} 
    // case DELETE_MANAGE_LANG_SUCCESS:
    //   return {...state, manageResponse:updateManageLanguage(state, action.param.id)};
     default:
      return state;
  }
}

// function updateManageLanguage(state, id) {
//   const update = {...state};
//   console.log(update.manageLanguageResponse);
//   const test = update.manageLanguageResponse.filter((param) => param.id !== id);
//   console.log(test);
//   return test;
// }