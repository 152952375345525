import { GET_RESOLUTIONS_SUCCESS, ADD_RESOLUTIONS_SUCCESS, UPD_RESOLUTIONS_SUCCESS } from "../constants";

import initialState from "./initialState";

export default function resolutionsReducer(state = initialState.resolutions, action) {
  switch (action.type) {
    case ADD_RESOLUTIONS_SUCCESS:
      return [...state, { ...action.addResolution }];
    case GET_RESOLUTIONS_SUCCESS:
      return action.resolution;
    case UPD_RESOLUTIONS_SUCCESS:
      return state.map((resolution) =>
      resolution.id === action.updateResolution.id ? action.updateResolution : resolution
      );
    default:
      return state;
  }
}
