import { combineReducers } from "redux";

// import learnerReducer from './learnerReducer'
import users from "./userReducer";
import links from "./linksReducer";
import regions from "./regionReducer";
import learners from "./learnerReducer";
import villages from "./villageReducer";
import villagesList from "./villageAdminReducer";
import sessions from "./sessionsReducer";
import alerts from "./coachAlertReducer";
import languages from "./languageReducer";
import recipients from "./recipientsReducer";
import studioTeam from "./studioOnDutyReducer";
import coachProfile from "./coachProfileReducer";
import studioLibrary from "./studioLibraryReducer";
import contactPref from "./contactPreferenceReducer";
import appointment from "./appointmentsReducer";
import comment from "./commentsReducer";
import resolution from "./resolutionsReducer";
import comments from "./trackCommentsReducer";
import notifications from "./notificationsReducer";
import substitutions from "./substitutionsReducer";
import trackUsers from "./trackUsersReducer";
import trackUserNames from "./trackUserNamesReducer";
import trackUserRoles from "./trackUserRoleReducer";
import rstvStore from "./rstvReducter";
import manageLanguage from "./ManageLanguageReducer";
import AdminDashboardSettings from "./adminDashboardReducer";
import auditLogs from "./auditLogsReducer";
import auditLogsTableNames from "./auditLogsTableNamesReducer";
// import coachSession from "./CoachShedulerReducer";
import additionalSubstitutions from "./additionalSubstitutionsReducer";
import coachSheduler from "./CoachShedulerReducer";
import regionsList from "./regionsReducer";
import coachManager from "./assignCoachesReducer";
import sessionDetails from "./sessionDetailsReducer";
import requestSubstituteResponse from "./requestSubstituteReducer";
import coachRoster from "./coachRostersReducer";
import appointmentSchedules from "./AppointmentSchedulesReducer";
import coachTimeList from "./CoachTimeOffReducer";
import appointmentDetails from "./AppointmentDetailsReducer";
import qualifiedCoaches from "./qualifiedCoachesReducer";
import { MasterSchedulerReducer, MasterShiftSessionReducer } from "./MasterSchedulerReducer";
import schedulingThresholds from "./schedulingThresholdReducer";
import shiftDetails from "./shiftDetailsReducer";
import substitution from "./SubstitutionReducer";
import qaforms from "./qaFormReducer";
import substitutionCoachList from "./assignSubstituteReducer";
import ApplicationtStatusReducer from "./ApplicationStatusReducer";
import ProductRightsReducer from "./ProductReportReducer";
import ConsumablesReportReducer from "./ConsumablesReportReducer";
import AutoRenewalReportReducer from "./AutoRenewalReportReducer";
import ScoreUpdateReducer from "./ScoreUpdateReducer";
import InactiveCoachSubReducer from "./InactiveCoachSubReducer";

const rootReducer = combineReducers({
  users,
  links,
  alerts,
  regions,
  learners,
  villages,
  villagesList,
  sessions,
  languages,
  studioTeam,
  recipients,
  contactPref,
  coachProfile,
  studioLibrary,
  appointment,
  comment,
  resolution,
  comments,
  notifications,
  substitutions,
  trackUsers,
  trackUserNames,
  trackUserRoles,
  rstvStore,
  manageLanguage,
  AdminDashboardSettings,
  auditLogs,
  auditLogsTableNames,
  // coachSession,
  additionalSubstitutions,
  coachSheduler,
  regionsList,
  coachManager,
  sessionDetails,
  requestSubstituteResponse,
  coachRoster,
  appointmentSchedules,
  coachTimeList,
  appointmentDetails,
  qualifiedCoaches,
  masterScheduler: MasterSchedulerReducer,
  masterSchedulerShiftDetail: MasterShiftSessionReducer,
  schedulingThresholds,
  shiftDetails,
  substitution,
  qaforms,
  substitutionCoachList,
  status: ApplicationtStatusReducer,
  productRights: ProductRightsReducer,
  consumablesReport: ConsumablesReportReducer,
  autoRenewalReport: AutoRenewalReportReducer,
  score:ScoreUpdateReducer,
  InactiveCoachSub: InactiveCoachSubReducer,

});

export default rootReducer;
