import { GET_NOTIFICATIONS_SUCCESS } from "../constants";

import initialState from "./initialState";

export default function notificationsReducer(state = initialState.links, action) {
  if (action.type == GET_NOTIFICATIONS_SUCCESS) 
    return action.notifications;
  // if(action.type == GET_SUBSTITUTION_FETCH_COACH_LIST_SUCCESS) 
  //   return {...state, substitutionCoachList:action.payload};
  return state;
}

