import { GET_CONSUMABLES_REPORT } from "../constants";
import initialState from "./initialState";

export default function ApplicationStatusReducer(state = initialState.consumablesReport, action) {
  switch(action.type){
    case "GET_CONSUMABLES_REPORT":
      return action.consumablesReport;
    default: 
      return state;
  }
}