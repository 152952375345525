import React, { useState } from "react";
import decode from "jwt-decode";
import { Link, useLocation } from "react-router-dom";

import "./Sidebar.css";

function Sidebar() {
  const user = decode(localStorage.getItem("token"));

  const { pathname: href } = useLocation();


  switch (user.type) {
    case "CoachManager":
      return (
        <div className="left-nav">
          <ul>
            <li
              className={`${href == "/homes" || href == "/manager-notifications" || href== "/links"
                  ? "current has-child main-nav"
                  : "main "
              }`}
            >
               {/* <Link to={"/homes"} className={`${href == "/homes" ? "active" : "main"}`}>
                Home
              </Link>*/}
              <a href="/homes" className={`${href == "/homes" ? "active" : "main"}`}>
              Home
              </a>
            </li>
            <li
              style={{
                display: href == "/homes" || href == "/manager-notifications" || href== "/links" ? "block" : "none",
              }}
            >
              <ul>
                <li className={`sub-nav-level-two ${href == "/manager-notifications" ? "active" : "main"}`}>
                  <Link to={"/manager-notifications"}>Notifications</Link>
                </li>
                <li className={`sub-nav-level-two ${href == "/links" ? "active" : "main"}`}>
                  <Link to={"/links"}>Rosetta Links</Link>
                </li>
              </ul>
            </li>
            <li
              className={`${
                href == "/dashboard" || href == "/schedules" || href == "/coach_scheduler" || href == "/appointments" || href == "/scheduling_thresholds" || href == "/substitutions" || href == "/substitutions-report"
                || href == "https://studio.rosettastone.com/teachers/content_review"
                  ? "current has-child main-nav"
                  : "main "
              }`}
              // onClick={(e) => {
              //   e.preventDefault();
              //   setHome(false);
              //   setSession(true);
              // }}
            >
              <Link to={"/schedules"} className={`${href == "/schedules" ? "active" : "main"}`}>
                Sessions
              </Link>
            </li>
            <li
              style={{
                display: href == "/dashboard" || href == "/schedules" || href == "/coach_scheduler" || href == "/appointments" || href == "/scheduling_thresholds" || href == "/substitutions" || href == "/substitutions-report" ? "block" : "none",
              }}
            >
              <ul>
                <li className={`sub-nav-level-two ${href == "/schedules" ? "active" : "main"}`}>
                  <Link to={"/schedules"}>Master Scheduler</Link>
                </li>
                <li className={`sub-nav-level-two ${href == "/coach_scheduler" ? "active" : "main"}`}>
                  <Link to={"/coach_scheduler"}>Coach Schedules</Link>
                </li>
                <li className={`sub-nav-level-two ${href == "/appointments" ? "active" : "main"}`}>
                  <Link to={"/appointments"}>Appointment Schedules</Link>
                </li>
                <li className={`sub-nav-level-two ${href == "/dashboard" ? "active" : "main"}`}>
                  <Link to={"/dashboard"}>Dashboard</Link>
                </li>
                <li className={`sub-nav-level-two ${href == "/substitutions" || href == "/substitutions-report" ? "current has-child main-nav active" : "main"}`} >
                    <a href="/substitutions">
                    Substitutions
                    </a>
                    {/* </Link> */}
                  </li>
                <li style={{
                  display: href == "/substitutions" || href == "/substitutions-report" ? "block" : "none",
                }}>
                  <ul>
                  <li className={`sub-nav-level-three ${href == "/substitutions" ? "active" : "main"}`}>
                    <a href="/substitutions">
                      Manage Substitutions
                    </a>
                  </li>
                  <li className={`sub-nav-level-three ${href == "/substitutions-report" ? "active" : "main"}`}>
                  <Link to={"/substitutions-report"} className={`${href == "/substitutions-report" ? "active" : "main"}`}>
                    Substitutions Report
                  </Link>
                  </li> 
                  </ul>
                </li>
                <li className="sub-nav-level-two">
                  <a href="">Staffing Model Reconciliation</a>
                </li>
                <li className="sub-nav-level-two">
                  <a href="https://studio.rosettastone.com/teachers/content_review">Session practice</a>
                </li>
                <li className={`sub-nav-level-two ${href == "/scheduling_thresholds" ? "active" : "main"}`}>
                  <Link to={"/scheduling_thresholds"} className={`${href == "/scheduling_thresholds" ? "active" : "main"}`}>Scheduling Thresholds</Link>
                </li>
              </ul>
            </li>
            <li
              className={`${href=="/create-coach" || href == "/regions" || href == "/assign-coaches" || href == "/view-coach-profiles" || href == "/coach_time_off" ? "current has-child main-nav" : "main "}`}
              // onClick={(e) => {
              //   e.preventDefault();
              //   setManage(!manage);
              // }}
            >
              {/* <a href="">Coach Management</a> */}
              {/* <Link to={"/view-coach-profiles"} className={`${href == "/view-coach-profiles" ? "active" : "main"}`}>
                Coach Management
              </Link> */}
              <a href="/view-coach-profiles">Coach Management</a>       
            </li>
            <li style={{ display: href=="/create-coach" || href == "/regions" || href == "/assign-coaches" || href == "/view-coach-profiles" || href == "/coach_time_off" ? "block" : "none" }}>
              <ul>
                <li className={`sub-nav-level-two ${href == "/create-coach" ? "active" : "main"}`}>
                  <a href="/create-coach">Create a Coach</a>
                </li>
                <li className={`sub-nav-level-two ${href == "/regions" ? "active" : "main"}`}>
                  <a href="/regions">Assign Regions</a>
                </li>
                <li className={`sub-nav-level-two ${href == "/assign-coaches" ? "active" : "main"}`}>
                  <a href="/assign-coaches">Assign Coaches</a>
                </li>
                <li className={`sub-nav-level-two ${href == "/view-coach-profiles" ? "active" : "main"}`}>
                  <a href="/view-coach-profiles">View Profiles</a>
                </li>
                <li className="sub-nav-level-two ">
                  <a href="">Activity Report</a>
                </li>
                {/* <li className="main">
                  <a href="">ReFLEX Report</a>
                </li> */}
                <li className={`sub-nav-level-two ${href == "/coach_time_off" ? "active" : "main"}`}>
                  <a href="/coach_time_off">Coach Time off</a>
                </li>
              </ul>
            </li>
            {/* <li className="main" style={{"display":(manage)?"block":"none"}}> */}
            <li className={`${href == "/comments" ? "current" : "main"}`}>
              <a href="/comments">Tracking Comments</a>
            </li>

            <li
              className={`${
                href == "/homes/admin_dashboard" || href == "/coach-roster" || href == "/edit_management_team" ? "current has-child main-nav" : "main "
              }`}
              // onClick={(e) => {
              //   e.preventDefault();
              //   setRoster(!roster);
              // }}
            >
              <a href="/coach-roster">Coach Roster</a>
            </li>
            <li
              style={{
                display: href == "/homes/admin_dashboard" || href == "/coach-roster" || href == "/edit_management_team" ? "block" : "none",
              }}
            >
              <ul>
                <li className={`sub-nav-level-two ${href == "/edit_management_team" ? "active" : "main"}`}>
                  <a href="/edit_management_team">Edit Management Team</a>
                </li>
              </ul>
            </li>
            {/* <li className="main" style={{"display":(manage)?"block":"none"}}> */}
            <li className={`${href == "/box_links" ? "current" : "main"}`}>
              <a href="/box_links">Studio Library</a>
            </li>
            {/* <li className="main" style={{"display":(roster)?"block":"none"}}> */}
            <li className={`${href == "/view-profile" ? "current" : "main"}`}>
              <a href="/view-profile">Profile</a>
            </li>
            <li className={`${href == "/learners" ? "current" : "main"}`}>
              <a href="/learners">Learners</a>
            </li>
            <li className={`${href == "/homes/admin_dashboard" ? "current" : "main"}`}>
              <a href="/homes/admin_dashboard">Admin Dashboard</a>
            </li>
            <li
              className={`${
                href == "/village-admin" ||
                href == "/appointment_types" ||
                href == "/comment_categories" ||
                href == "/resolutions" ||
                href == "/manage-languages" ||
                href == "/rstv_topics"
                  ? "current has-child main-nav"
                  : "main "
              }`}
              // onClick={(e) => {
              //   setConfig(!config);
              // }}
            >
              <a href="/manage-languages">Configurations</a>
            </li>
            <li
              style={{
                display:
                  href == "/village-admin" ||
                  href == "/appointment_types" ||
                  href == "/comment_categories" ||
                  href == "/resolutions" ||
                  href == "/manage-languages" ||
                  href == "/rstv_topics"
                    ? "block"
                    : "none",
              }}
            >
              <ul>
                <li className={`sub-nav-level-two ${href == "/manage-languages" ? "current" : "main"}`}>
                  <a href="/manage-languages">Languages Managed</a>
                </li>
                <li className={`sub-nav-level-two ${href == "/village-admin" ? "current" : "main"}`}>
                  <a href="/village-admin">Villages</a>
                </li>
                <li className={`sub-nav-level-two ${href == "/rstv_topics" ? "current" : "main"}`}>
                  <a href="/rstv_topics">RSTV Topics Report</a>
                </li>
                {/* <li className="main">
                  <a href="">AEB Topics Report</a>
                </li> */}
                {/*<li className="main">
                  <a href="">RSA Topics Report</a>
                </li> */}
                <li className={`sub-nav-level-two ${href == "/appointment_types" ? "current" : "main"}`}>
                  <a href="appointment_types">Appointment Types</a>
                </li>
                <li className={`sub-nav-level-two ${href == "/comment_categories" ? "current" : "main"}`}>
                  <a href="comment_categories">Comment Categories</a>
                </li>
                <li className={`sub-nav-level-two ${href == "/resolutions" ? "current" : "main"}`}>
                  <a href="resolutions">Resolutions</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      );
    case "SupportLead":
      return (
        <div className="left-nav">
          <ul>
            <li
              className={`${
                href == "/view-profile" || href == "/edit-profile" || href == "/contact-preference" ? "current" : "main"
              }`}
            >
              <a href="/view-profile">My Profile</a>
            </li>
            <li className={`${href == "/learners" || href == "/view_learner" ? "current" : "main"}`}>
              {/* className={`${(home || ["/dashboard","/links"].includes(href))?"current has-child main-nav":"main"}`}> */}
              <a href="/learners">Learners</a>
            </li>
            <li
              className={`${href == "/dashboard" ? "current has-child main-nav" : "main "}`}
              // onClick={(e) => {
              //   setSession(!session);
              // }}
            >
              <Link to={"dashboard"} className={`${href == "dashboard" ? "active" : "main"}`}>
                Sessions
              </Link>
            </li>
            {/* ${(home || ["/home","/links"].includes(href))?"current has-child main-nav":"main "}`} onClick={e => { e.preventDefault();setHome(!home);}}> */}
            <ul style={{ display: href == "/dashboard" ? "block" : "none" }}>
              <li className={`sub-nav-level-two ${href == "/dashboard" ? "active" : "main"}`}>
                {" "}
                {/*active*/}
                <a href="/dashboard">Dashboard</a>
              </li>
            </ul>

            <li className={`${href == "/view-coach-profiles" ? "current" : "main"}`}>
              <a href="/view-coach-profiles">View Coach Profiles</a>
            </li>

            <li
              className={`${href == "/product-rights" || href == "/consumables-report" || href == "/autorenewal-report"? "current has-child main-nav" : "main "}`}
            >
              <Link to={"/product-rights"} className={`${href == "/product-rights" ? "active" : "main"}`}>
                Reports
              </Link>
            </li>
            <ul style={{ display: href == "/product-rights" || href == "/consumables-report" || href == "/autorenewal-report"? "block" : "none" }}>
              <li className={`sub-nav-level-two ${href == "/product-rights" ? "active" : "main"}`}>
                <Link to={"/product-rights"}>Product Rights</Link>
              </li>
              <li className={`sub-nav-level-two ${href == "/consumables-report" ? "active" : "main"}`}>
                <Link to={"/consumables-report"}>Consumables</Link>
              </li>
              <li className={`sub-nav-level-two ${href == "/autorenewal-report" ? "active" : "main"}`}>
                <Link to={"/autorenewal-report"}>Autorenewal Management Report</Link>
              </li>
            </ul>
            <li className={`${href == "/links" ? "current" : "main"}`}>
              {" "}
              {/*current*/}
              <a href="/links">Rosetta Links</a>
            </li>
          </ul>
        </div>
      );

    case "Coach":
      return (
        <div className="left-nav">
          <ul>
            <li
              className={`${href == "/homes" || href== "/links"
                  ? "current has-child main-nav"
                  : "main "
              }`}
            >
             {/* <Link to={"/homes"} className={`${href == "/homes" ? "active" : "main"}`}>
                Home
              </Link> */}
              <a href="/homes" className={`${href == "/homes" ? "active" : "main"}`}>
                  Home
              </a>
            </li>
            <li
              style={{
                display: href == "/homes" || href== "/links" ? "block" : "none",
              }}
            >
              <ul>
                <li className={`sub-nav-level-two ${href == "/links" ? "active" : "main"}`}>
                  <Link to={"/links"}>Rosetta Links</Link>
                </li>
              </ul>
            </li>
            <li
              className={`${
                href == "/my_schedule" || href == "/substitutions" || href == "/http://studio.rosettastone.com/teachers/content_review"
                  ? "current has-child main-nav"
                  : "main "
              }`}
            >
              <Link to={"/my_schedule"} className={`${href == "/my_schedule" ? "active" : "main"}`}>
                Sessions
              </Link>
            </li>
            <li style={{
                display: href == "/my_schedule" || href == "/substitutions" || href == "/http://studio.rosettastone.com/teachers/content_review" ? "block" : "none",
              }}>
              <ul>
                <li className={`sub-nav-level-two ${href == "/my_schedule" ? "active" : "main"}`}>
                  {/* <Link to={"/my_schedule"}>My Schedule</Link> */}
                  <a href="/my_schedule">
                    My Schedule
                  </a>
                  
                </li>
                <li className={`sub-nav-level-two ${href == "/substitutions" ? "active" : "main"}`}>
                  <Link to={"/substitutions"}>Substitutions</Link>
                </li>
                <li className={`${href == "http://studio.rosettastone.com/teachers/content_review" ? "active" : "main"}`}>
                  <a href="http://studio.rosettastone.com/teachers/content_review">Session practice</a>
                </li>
              </ul>
            </li>
            <li className={`${href == "/box_links" ? "current" : "main"}`}>
              <a href="/box_links">Studio Library</a>
            </li>
            <li className={`${href == "/view-profile" ? "current" : "main"}`}>
              <a href="/view-profile">My Profile</a>
            </li>
            <li className={`${href == "/coach-roster" ? "current" : "main"}`}>
              <a href="/coach-roster">Coach Roster</a>
            </li>
            <li className={`${href == "/learners" ? "current" : "main"}`}>
              <a href="/learners">Learners</a>
            </li>
          </ul>
        </div>
      );
    default:
      return (
        <div className="left-nav">
          <ul>
            <li className={`${href == "/homes" ? "current" : "main"}`}>
              <a href="/homes">Home</a>
            </li>
            <li
              className={`${
                href == "/my_schedule" || href == "/substitutions" || href == "/http://studio.rosettastone.com/teachers/content_review"
                  ? "current has-child main-nav"
                  : "main "
              }`}
            >
              <Link to={"/my_schedule"} className={`${href == "/my_schedule" ? "active" : "main"}`}>
                Sessions
              </Link>
            </li>
            <li style={{
                display: href == "/my_schedule" || href == "/substitutions" || href == "/http://studio.rosettastone.com/teachers/content_review" ? "block" : "none",
              }}>
              <ul>
                <li className={`sub-nav-level-two ${href == "/my_schedule" ? "active" : "main"}`}>
                  {/* <Link to={"/my_schedule"}>My Schedule</Link> */}
                  <a href="/my_schedule">
                    My Schedule
                  </a>
                </li>
                <li className={`${href == "/substitutions" ? "active" : "main"}`}>
                  <Link to={"/substitutions"}>Substitutions</Link>
                </li>
                <li className={`${href == "http://studio.rosettastone.com/teachers/content_review" ? "active" : "main"}`}>
                  <a href="http://studio.rosettastone.com/teachers/content_review">Session practice</a>
                </li>
              </ul>
            </li>
            <li className={`${href == "/box_links" ? "current" : "main"}`}>
              <a href="/box_links">Studio Library</a>
            </li>
            <li className={`${href == "/view-profile" ? "current" : "main"}`}>
              <a href="/view-profile">My Profile</a>
            </li>
            <li className={`${href == "/coach-roster" ? "current" : "main"}`}>
              <a href="/coach-roster">Coach Roster</a>
            </li>
            <li className={`${href == "/learners" ? "current" : "main"}`}>
              <a href="/learners">Learners</a>
            </li>
          </ul>
        </div>
      );
    }
}

export default Sidebar;
