import React, { useState } from "react";

import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

function AppointmentCalendar() {
  const [events, setEvents] = useState([
    {
      title: "event 1",
      start: "2021-02-14T03:30:00",
      end: "2021-02-14T04:00:00",
      color: "yellow", // an option!
      textColor: "black", // an option!
    },
    { title: "event 2", start: "2021-02-14T04:30:00", end: "2021-02-14T06:30:00" },
    { title: "event 3", start: "2021-02-14T04:30:00", end: "2021-02-14T06:30:00" },
    { title: "event 4", start: "2021-02-14T04:30:00", end: "2021-02-14T06:30:00" },
    { title: "event 5", start: "2021-02-14T04:30:00", end: "2021-02-14T06:30:00" },
    { title: "event 6", start: "2021-02-14T04:30:00", end: "2021-02-14T06:30:00" },
    { title: "event 7", start: "2021-02-14T04:30:00", end: "2021-02-14T06:30:00" },
    { title: "event 8", start: "2021-02-14T06:30:00", end: "2021-02-14T07:00:00" },
    { title: "event 9", start: "2021-02-14T06:30:00", end: "2021-02-14T07:00:00" },
  ]);
  function handleDateClick(e) {
    console.log(e);
    const title = prompt("Please enter a title");
    if (title == null) return;
    setEvents([
      ...events,
      {
        title,
        ["date"]: e.dateStr,
      },
    ]);
  }
  return (
    <FullCalendar
      plugins={[timeGridPlugin, interactionPlugin]}
      initialView="timeGridWeek"
      dateClick={handleDateClick}
      stickyHeaderDates={true}
      events={events}
      slotDuration={"00:30:00"}
      eventClick={(info) => {
        alert("Event: " + info.event.title);
        alert("Coordinates: " + info.jsEvent.pageX + "," + info.jsEvent.pageY);
        alert("View: " + info.view.type);

        // change the border color just for fun
        info.el.style.borderColor = "red";
      }}
      // titleFormat={{
      //   month: "2-digit",
      //   day: "2-digit",
      //   year: "numeric",
      // }}
      timeFormat={"hh:mm A"}
      dayHeaderFormat={{
        weekday: "long",
        month: "short",
        day: "2-digit",
        year: "numeric",
      }}
      eventTimeFormat={{
        hour: "numeric",
        minute: "2-digit",
        meridiem: "short",
      }}
    />
  );
}

export default AppointmentCalendar;