import { GET_REGION_SUCCESS } from "../constants";

import initialState from "./initialState";

export default function regionReducer(state = initialState.regions, action) {
  switch (action.type) {
    case GET_REGION_SUCCESS:
      return action.regions;
    default:
      return state;
  }
}
