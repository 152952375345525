import { GET_APPLICATION_STATUS } from "../constants";
import { getApplicationStatus } from "../../apis/ApplicationStatusApi";

export function getStatus() {
  return function(dispatch) {
    return getApplicationStatus()
      .then((response) => {
        dispatch({
          type: GET_APPLICATION_STATUS,
          status: response.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}