import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DatePicker from 'react-datepicker';
import queryString from "query-string";
import "react-datepicker/dist/react-datepicker.css";

import CoachSchedulerCalendar from "../components/CoachSchedulerComponent/CoachSchedulerCalendar";
import CoachTimeOffPopup from "../components/CoachTimeOffComponent/CoachTimeOffPopup";
import { getCoachNames } from "../apis/CoachProfileApi";
import { sortCoachNamesBy } from "../utils/languageUtils";
import { getLanguageByIdentifier } from "../utils/languageUtils";
import * as notificationsAction from "../redux/actions/notificationsAction";
import * as languageActions from "../redux/actions/languageAction";
import * as coachProfileAction from "../redux/actions/coachProfileAction";
import * as coachShedulerActions from "../redux/actions/CoachShedulerAction";
import bigSpinner from "../components/images/big_spinner.gif";
import previous from "../components/images/previous.png";
import next from "../components/images/next.png"
import warning from "../components/images/warning_red_16_16.png";
import printIcon from "../components/images/icon_print.png";

import "bootstrap-daterangepicker/daterangepicker.css";
import "./CoachSchedulerPage.css";
import moment from "moment";
import decode from "jwt-decode";
import { getQualifiedLanguages } from "../apis/CoachProfileApi";
import { getUpcomingSessions, getSessionDetails } from "../apis/CoachSchedulerApi";
import { useVillage } from "../hooks";
import RequestSubstitute from "../components/CoachSchedulerComponent/RequestSubstitute";
import { showAlert } from "../utils/sessionUtils";

const title = (coachName) => `Customer Success Portal: Coach Schedule - ${coachName}`;
function MySchedulerPage(props) {
  let coach_id = '';
  const {
    //languages,
    actions: { getLanguages },
    coachSheduler,
    getTimeOffListResponse,
  } = props;
  const calRef = useRef(null);
  const user = decode(localStorage.getItem("token"));
  console.log("User details ==============>" + JSON.stringify(user));
  function Loading() {
    return <img src={bigSpinner} />;
    }
  const [coachs, setCoachs] = useState([]);
  const [config, setConfig] = useState({
    display: false,
    slotDuration: "00:30:00", //"00:30:00",
  });

  const villages = useVillage();
  const [settings, setSettings] = useState({
    displayDetails: false,
    xAxis: "0px",
    yAxis: "0px",
    xDistance: "0px",
    yDistance: "0px",
  });

  const [bulkSession, setBulkSession] = useState(false);

  const [filter, setFilter] = useState({
    language_category: "totale",
    language: "",
    coach_id: "",
  });

  const [coachFilter, setCoachFilter] = useState({
    language_category: "totale",
    coach_id: user.id,
    language: "all",
    filterDate: moment().startOf("day").utc().toISOString()
    //moment returns current timezone date, startOf will set time to 0
  })
  const [loaded, setLoaded] = useState(false);
  const [modifiedSubstitutions, setModifiedSubstitutions] = useState([]);
  let modified = [];
  const [showSubstitute, setShowSubstitute] = useState(false);
  const [timeOffToggle, setTimeoffToggle] = useState(false);
  const [upcomingSession, setUpcomingSession] = useState({})
  const [violationDate, setViolationDate] = useState();
  const [assignSubConfirmationDisplay, setAssignSubConfirmationDisplay] = useState({
    displayAssignSubConfirmation: false,
  });
  function toggleTimeOff(e) {
    e.preventDefault();
    setTimeoffToggle(!timeOffToggle);
    console.log(user.id)
    props.actions.getTimeOffFunction(user.id)
    .then((response) => {
      //setLoading(!loading);
        console.log(response)
    });
  }

  // Get week extremes in user timezone.
//  getWeekExtremes(filterDate, userTimezone) {
//   let curr = new Date(filterDate);
//   let filterDateInUserzone = moment.tz([curr.getFullYear(), curr.getMonth(), curr.getDate()], userTimezone);

//   let firstDay = filterDateInUserzone.startOf('week');
//   let firstDayUTC = moment.utc(firstDay).toDate();

//   let lastDay = filterDateInUserzone.endOf('week');
//   let lastDayUTC = moment.utc(lastDay).toDate();

//   return [firstDayUTC, lastDayUTC];
//   }

  const [schedulerList, setSchedulerList] = useState();
  const [showCalendar, setShowCalendar] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayUpcoming, setDisplayUpcoming] = useState(false);
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [maxUnitInfo, setMaxUnitInfo] = useState(false);
  const [goLoader, setGoLoader] = useState(true);
  const [updatedTimeZone, setUpdatedTimeZone] = useState();
  // const [isFromNavigation, setIsFromNavigation] = useState(false);
  //const [loading, loadingDispatch] = useReducer(loadingReducer, false);
  useEffect(() => {
    console.log("======================>" + JSON.stringify(coachFilter));
    if (JSON.stringify(coachSheduler) != "{}") {
      if (coachFilter.language == "all")
      {
        if (coachSheduler.schedules.length == 0) {
          setGoLoader(false);
        }
        setSchedulerList(coachSheduler.schedules);
        setMaxUnitInfo(coachSheduler.maxUnitInfo);
      }
      else
      {
        let filteredSessions = coachSheduler.schedules.filter((li) => li.lang == coachFilter.language);
        // if (filteredSessions.length == 0) {
        //   alert("No sessions found for the selected language");
        //   return;
        // }
         if (filteredSessions.length == 0) {
          setGoLoader(false);
        }
        setSchedulerList(filteredSessions);
        setMaxUnitInfo(coachSheduler.maxUnitInfo);
      }
      //Next Session
      props.actions.getClosestSessions();
      //Next Session
    }
    // getUpcomingSessions().then((response) => {
    //   setUpcomingSessions(response.data)
    // })

  }, [coachSheduler]);

  useEffect(() => {
    getLanguages();
    // props.coachProfile.length > 0 && setLanguages(props.coachProfile[1]);
    getCoachNames("Coach").then((res) => setCoachs(res.data));
    props.actions.getCoach({ id: user.id });
    coach_id = user.id

    //Default Load of Calendar
    props.actions.getCoachScheduler(coachFilter);
    //props.actions.getCoach({ id: filter.coach_id });
    setGoLoader(true);
    setShowCalendar(true);
    let currentDate = new Date().toString();
    let CurrentTimeZone = currentDate.split("(")[1];
    setUpdatedTimeZone(CurrentTimeZone);
  }, []);

  useEffect(() => {
    //props.coachProfile.length > 0 && setLanguages(props.coachProfile[1]);
    props.coachProfile.length > 0 &&
      getQualifiedLanguages(props.coachProfile[0][0].id).then((response) => {
        return setLanguages(response.data);
      });
      const CPLanguages = props.coachProfile[1]?.filter(e => e.conv_pract).map(e => e.identifier = e.identifier + '-Solo')    
      setCoachFilter({ ...coachFilter, CPLanguages });
  }, [props.coachProfile]);

  useEffect(() => {
    if (props.additionalSubstitutions.length == 0 && !loaded) {
      props.actions.getSubstitutions({ time_range: "upcoming" });
      setLoaded(true);
    }
    if(props.additionalSubstitutions.length == 0){
      setShowSubstitute(false)
    } else {
    props.additionalSubstitutions.map((substitution) => {
      if (showAlert(substitution.data[0].session_start_time))
      {
        modified.push({
            lang: substitution.lang[0],
            session_start_time: substitution.data[0].session_start_time,
            level_unit_lesson:substitution.data[0].level_unit_lesson,
            row_id:substitution.data[0].id ,
            coach_session_id:substitution.data[0].coach_session_id,
            id:substitution.coach_id,
            sub_needed_for: substitution.data[0].sub_needed_for,
            can_grab: substitution.data[0].can_grab,
            scheduled_session_id: substitution.data[0].scheduled_session_id,
            scheduled_eschool_session_id: substitution.data[0].scheduled_eschool_session_id,
            grab_disable: substitution.data[0].grab_disable
          });
      }
    });
    console.log("modified value" + modified);
    if(modified.length != 0) { setShowSubstitute(true)}
    setModifiedSubstitutions(modified);
  }
  },[props.additionalSubstitutions]);

  function onFilterChange(e) {
    const { name, value } = e.target;
    // setIsFromNavigation(false);
    setCoachFilter({ ...coachFilter, [name]: value });
    setFilter({ ...filter, [name]: value });
    let slotDuration = {};
    if (name == "language_category") slotDuration = { slotDuration: value == "totale" ? "01:00:00" : "00:30:00" };
    setConfig({ ...config, ...slotDuration, display: true });
    setGoLoader(false);
  }
  console.log(coachFilter);

  const loaderCallBack = (data) => {
    setGoLoader(data)
  }

  function onSubmitHandler(selectedDate = null , action = null) {
    // if (filter.language == "") {
    //   alert("Please Select a Language.");
    //   return;
    // }
    // if (filter.coach_id == "") {
    //   alert("Please Select a Coach");
    //   return;
    // }
    // setSchedulerList([]);
    setGoLoader(true);
    let modals= document.querySelectorAll(".modal .close-icon, .rcmodal .cancel, #coach-modal-close, #recurring-close, #cancel-modal-close");
    for (let modal of modals) {
      modal.click();
    }
    if (action == "custom")
    {
      // setIsFromNavigation(true);
      if (calRef.current != null) {
        let calendarApi = calRef.current.getApi();
        calendarApi.gotoDate(selectedDate);
      }

      setCoachFilter({ ...coachFilter, filterDate: selectedDate});
      props.actions.getCoachScheduler({ ...coachFilter, filterDate: selectedDate});
      setShowCalendar(true);
      return;
    }
    console.log("This is the value in coachFilter" + JSON.stringify(coachFilter));
    props.actions.getCoachScheduler(coachFilter);
    //props.actions.getCoach({ id: filter.coach_id });

    setShowCalendar(true);
    let currentDate = new Date().toString();
    let CurrentTimeZone = currentDate.split("(")[1];
    setUpdatedTimeZone(CurrentTimeZone);
  }

  function showSessionDetails(e, session){
    e.preventDefault();
    let xDistance = e.clientX;
    let yDistance = e.pageY;
    let xOffset = e.offsetX;
    let yOffset = e.offsetY;

      setSettings({
        ...settings,
        ["xAxis"]: xDistance,
        ["yAxis"]: yDistance,
        ["xDistance"]: xDistance,
        ["yDistance"]: yDistance,
        ["displayDetails"]: true
      });

      getSessionDetails({
        coach_id: session.coach_id,
        session_id: session.id,
        session_start_time: moment.utc(session.session_start_time).format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((response) => {
        //loadingDispatch({ type: LOADED });
        setUpcomingSession(response.data[0])
      })
      .catch((error) => {
       // loadingDispatch({ type: LOADED });
       console.log("Error in Upcoming Session Fetch" + error);
      });
  }

  const launchOnline = function(launch_string) {
    const config =
      "menubar=0,resizable=1,scrollbars=0,toolbar=0,location=0,directories=0,status=0,top=0,left=0";
    let child_window;
    child_window = window.open(launch_string, "launch_window", config);
  };

  function extractIdentifier(language) {
    let identifier = language.split("-");
    return identifier.length == 2
      ? identifier[0].toLowerCase()
      : identifier.length == 3
      ? "fbsolo"
      : "totale";
  }

  // const cancelLoading=() => {
  //   loadingDispatch({ type: LOADED });
  // }

  const isActiveSession = (start_time) => {
    return (moment() < moment(start_time))
  }

  const handleRequestSuccess = (value) => {
    setSettings((settings) => ({ ...settings, ["displayDetails"]: !settings.displayDetails }));
  };

  const [requestSubstituteDisplay, setRequestSubstituteDisplay] = useState({
    displayRequestSubstitute: false,
    xAxis: "0px",
    yAxis: "0px",
  });

  const [upcomingLoader, setUpcomingLoader] = useState(false);

  function handleSubstituteclicked(e, session) {
    props.actions
      .checkViolationPolicy({
        start_time: moment.utc(session.session_start_time).format("yyyy-MM-DD HH:mm:ss"),
        session_id: session.id,
        //coach_id: session.coach_id || props?.coachFilter?.coach_id,
        coach_id: (session.coach_id == coachFilter.coach_id) ? (session.coach_id || coachFilter?.coach_id) : session.moderator_id
      })
      .then((response) => {
        setViolationDate(response.at_session);
        // setTimeout(function(){

        if (response.violation == true) {
          setAssignSubConfirmationDisplay({ ...assignSubConfirmationDisplay, ["displayAssignSubConfirmation"]: true });
        } else {

     //       setAssignSubstituteDisplay({ ...assignSubstituteDisplay, ["displayAssignSubstitute"]: false });
            // , () => {
              setRequestSubstituteDisplay({
                ...requestSubstituteDisplay,
                ["displayRequestSubstitute"]: true,
                xAxis: settings.xAxis - 350,
                yAxis: settings.yAxis - 200,
              });
            // });
          }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleConfirmAssignSubstituteClicked(e, session) {
    setAssignSubConfirmationDisplay({ ...assignSubConfirmationDisplay, ["displayAssignSubConfirmation"]: false });
      if (session.type === "ConfirmedSession") {
        setRequestSubstituteDisplay({
          ...requestSubstituteDisplay,
          ["displayRequestSubstitute"]: true,
          xAxis: settings.xDistance > 610 ? settings.xAxis - 120 : settings.xAxis + 380 - 120,
          yAxis: settings.yAxis - 164 + 75,
        });
      } else {
        setRequestSubstituteDisplay({
          ...requestSubstituteDisplay,
          ["displayRequestSubstitute"]: true,
          xAxis: settings.xDistance > 610 ? settings.xAxis - 120 : settings.xAxis + 380 - 120,
          yAxis: settings.yAxis - 108 + 75,
        });
      }
  }

  const getBackgroundColor = (session) => {
    let startTime = moment(session.session_start_time).subtract('minutes',15);
    let currentTime = moment(new Date());
    let EndTime = moment(session.session_end_time);
    if(startTime <= currentTime && currentTime <= EndTime){
      return "#FFFF00";
    }
    else{
      return "#FFFFFF";
    }
  }

  function printData() {
    var divContents = document.getElementById("sessions-print-section").innerHTML;
    var a = window.open('');
    a.document.write('<html>');
    a.document.write('<body > ');
    a.document.write(divContents);
    a.document.write('</body></html>');
    a.print();
    a.close();
    return false;
  }

  // useEffect(()=>{
  //   if(modalOpen["open"]){
  //     let session_modal = document.getElementById("upcoming-coach-schedule-session-details");
  //     session_modal.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  //   }
  // },[modalOpen]);


  return (
    <>
      <Helmet>
        <title>{title("")}</title>
      </Helmet>
      <div className="right-content">
      <CoachTimeOffPopup timeOffToggle={timeOffToggle} setTimeoffToggle={setTimeoffToggle}  getTimeOffListResponse={props.getTimeOffListResponse} createTimeOffFunction={props.actions.createTimeOffFunction} cancelTimeOffFunction={props.actions.cancelTimeOffFunction} coachId ={user.id} loading={loading} setLoading={setLoading} />

        {/* style={{ ...(config.display && { height: "1080px" }) }}> */}
        <div className="coach-schedule">
        {queryString.parse(window.location.search).switched && (
        <div className="login-notice"><p>Signed in as {JSON.stringify(user) != "{}" && user.full_name}</p></div>
        )}
        <div className="mySchedule-banner">
          <div className="left-section">
            <div className="title">
              <h2>My Schedule {JSON.stringify(user) != "{}" && " - " + user.full_name}</h2>
              {/* <a href="#" className="first-link">
                Edit this Coach&apos;s Availability
              </a> */}
              <a href="#" className="first-link" onClick={(e) => toggleTimeOff(e)}>
                Time Off
              </a>
            </div>
            <div className="today-date-section">
              <span className="today-date">Today&apos;s Date:</span>
              <span className="today-date-in-dashboard"> {moment().format(
                "dddd, MMMM DD,YYYY"
              )}</span>
            </div>
            <div className="coach-session-info-div">
              {JSON.stringify(coachSheduler) != "{}" &&
              <>
                <div>
                  <span className="bold-font">Scheduled: </span>
                  <span>{coachSheduler.coachSessionCount.scheduled}</span>(<span>{coachSheduler.coachSessionCount.scheduledHours}</span> hrs),
                </div>
                <div>
                  <span className="bold-font">Cancelled: </span>
                  <span>{coachSheduler.coachSessionCount.cancelled}</span>,
                </div>
                <div>
                  <span className="bold-font">Sub-requested: </span>
                  <span>{coachSheduler.coachSessionCount.subRequested}</span>
                </div>
                </>
              }
            </div>
          </div>
          <div className="right-section">
            <div style={{position: "relative"}}>
              <button type="button" onClick={(e) => {
                e.preventDefault();
                setUpcomingLoader(true);
                 getUpcomingSessions().then((response) => {
                  setUpcomingSessions(response.data)
                  setUpcomingLoader(false);
                  }).catch((err) => {
                    console.log("Error while loading upcoming schedules" + err);
                    setUpcomingLoader(false);
                  })
                setDisplayUpcoming(true);
              }}>View My Upcoming Classes</button>
              <div
                className="modal coach-schedule-session-details-modal"
                id="upcoming-classes-modal"
                style={{
                  display: displayUpcoming ? "block" : "none"
                }}
              >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal_header">
                    <h4 className="modal-title">MY UPCOMING CLASS SCHEDULE</h4>
                    <a
                      className="close-icon"
                      title="Close"
                      aria-label="Close"
                      role="button"
                      data-dismiss="modal"
                      onClick={(e) => {
                        e.preventDefault();
                        setDisplayUpcoming(false);
                      }}
                    >
                      <span>×</span>
                    </a>
                  </div>
                  <div className="modal-body">
                    <div className="classes-section" id="sessions-print-section">
                    {(upcomingSessions.length != 0 ? Object.keys(upcomingSessions[0]).length : 0) == 0 && upcomingLoader == false &&
                    <div>No Sessions found</div>
                    }

                    {upcomingLoader == true &&
                    <div>Loading...</div>
                    }

                    {Object.keys(upcomingSessions[0] || {}).map((key, index) => {
                      return(<div key={key}>
                      <div className="day">{key}</div>
                      {upcomingSessions[0][key].map((session, index) => {
                      return(<div key={session.id} style={{ backgroundColor: getBackgroundColor(session) }}>
                      <label>- {getLanguageByIdentifier(session.language_identifier)}</label><a href="" onClick={(e) => showSessionDetails(e, session)}> ( {moment(session.session_start_time).format('hh:mm a')} - {moment(session.session_end_time).format('hh:mm a')} )</a>
                      </div>)
                      })}
                    </div>)
                    })}
                    </div>
                    <div className="print-section">
                      <a href="#" onClick={printData}><img alt="Print" className="print-button" src={printIcon} title="Print Schedule"/></a>
                    </div>
                  </div>
                </div>
              </div>
          </div>
            </div>
            <div style={{display: "none"}}>
              <button type="submit">Export This Schedule</button>
            </div>
          </div>
        </div>


        {/* Session Detail for Upcoming Session */}
        {Object.keys(upcomingSession).length != 0 && user.type == "Coach" && (
      <div>
        {/* <div style={{marginTop: "14px", marginLeft: "14px", color: "white", float: "left"}}>
          {"In " + (moment(moment(upcomingSession.session_start_time) - moment()).format('mm')) + " minutes"}
           <img src={timer} alt="timer" onClick={(e) => onTimerClick(e)}/>
        </div> */}

          {/* {upcomingSession != undefined && upcomingSession != {} && ( */}
          <div
            className="modal coach-schedule-session-details-modal"
            id="upcoming-coach-schedule-session-details"
            style={{
              display: settings.displayDetails ? "block" : "none",
              left: settings.xAxis - 380 - 100 + "px",
              top: settings.yAxis - 185 - 100 + "px"
            }}
          >
            <div className="modal-dialog">
              {/* {loading ? (
                <div>LOADING....</div>
              ) :
               (*/}
                <div className="modal-content">
                  <div className="modal_header">
                    <h4 className="modal-title">
                      {"SESSION DETAILS"}
                    </h4>
                    <a
                      className="close-icon"
                      title="Close"
                      aria-label="Close"
                      role="button"
                      data-dismiss="modal"
                      onClick={(e) => {
                        e.preventDefault();
                      //  cancelLoading();
                        setSettings((settings) => ({
                          ...settings,
                          ["displayDetails"]: !settings.displayDetails,
                        }));
                      }}
                    >
                      <span>×</span>
                    </a>
                  </div>
                  <div className="modal-body">
                    <div className="row1">
                      <div className="left-section">
                        <div>
                          <span>
                            {moment(
                              moment(upcomingSession.session_start_time).format()
                            ).format("ddd MM/DD/YY hh:mm A")}
                          </span>

                          {/* <input className="cancel-button" type="button" value="CANCEL" /> */}
                        </div>
                        {upcomingSession.type != "Appointment" &&
                          upcomingSession.rstv_guid == null && (
                            <div>
                              <div>
                                {getLanguageByIdentifier(
                                  upcomingSession.language_identifier
                                )}
                              </div>
                              {upcomingSession.teacher_confirmed == "" &&
                                upcomingSession.sub_requested == 0 && (
                                  <div className="red">Unconfirmed</div>
                                )}
                              {villages.length > 0 &&
                                villages.map(({ id, name }) =>
                                  upcomingSession.external_village_id == id ? (
                                    <div key={id}>{name}</div>
                                  ) : null
                                )}
                            </div>
                          )}
                      </div>

                      {upcomingSession.cancelled == "false" && isActiveSession(upcomingSession.session_end_time) && (
                        <div className="right-section">
                          {upcomingSession.sub_requested == 0 && (
                            <div>
                              <a
                                className="underline"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleSubstituteclicked(e, upcomingSession);
                                }}
                              >
                                Request a Sub
                              </a>
                            </div>
                          )}
                        </div>
                      )}

                    </div>

                    {upcomingSession.type == "ConfirmedSession" && (
                      <div>
                        <div className="row2">
                          <div className="left-section">
                            {upcomingSession.learners_signed_up != null &&
                              upcomingSession.rstv_guid == null && (
                                <div>
                                  {upcomingSession.learners_signed_up == 0 &&
                                    extractIdentifier(
                                      upcomingSession.language_identifier
                                    ) != "cp" && (
                                      <div>No Learners have signed up.</div>
                                    )}
                                  {upcomingSession.learners_signed_up > 0 &&
                                    upcomingSession.learner_details.attendance !=
                                      [] && (
                                      // <div>No of Learners have signed up: {dummySession[0].learners_signed_up}</div>
                                      <div>

                                      {upcomingSession.topic &&
                                        <div>
                                          { upcomingSession.topic.title &&
                                            <>
                                              <span style={{float: "left"}}>Topic:</span>

                                              <div style={{float: "left"}}>

                                                {upcomingSession.topic.title}
                                              </div>
                                              <br />
                                            </>
                                          }
                                        </div>
                                      }

                                        Learners:
                                        {upcomingSession.learners_signed_up == 1 ? (
                                          <div>
                                            <a href="#" target="_blank">
                                              {
                                                upcomingSession.learner_details
                                                  .attendance.first_name
                                              }{" "}
                                              {
                                                upcomingSession.learner_details
                                                  .attendance.last_name
                                              }
                                            </a>
                                            <br />
                                          </div>
                                        ) : (
                                          <div>
                                            {" "}
                                            {upcomingSession.learner_details.attendance.map(
                                              (learner) => (
                                                <div key={learner.student_guid}>
                                                  <a href="#" target="_blank">
                                                    {learner.first_name}{" "}
                                                    {learner.last_name}
                                                  </a>
                                                  <br />
                                                </div>
                                              )
                                            )}{" "}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                </div>
                              )}
                            {upcomingSession.rstv_guid == null &&
                              extractIdentifier(
                                upcomingSession.language_identifier
                              ) != "cp" && (
                                <div>
                                  {" "}
                                  Avg attendance/session :{" "}
                                  {upcomingSession.average_attendance != null
                                    ? upcomingSession.average_attendance
                                    : 0.0}
                                </div>
                              )}
                            {upcomingSession.rstv_guid != null && (
                              <div>
                                <div>
                                  Language:{" "}
                                  {getLanguageByIdentifier(
                                    upcomingSession.language_identifier
                                  )}
                                </div>
                                <div>Coach: {upcomingSession.coach_name}</div>
                                <div>Topic: {upcomingSession.rstv_topic_title}</div>
                                <div>
                                  Moderator: {upcomingSession.moderator_name}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row3">
                          <div className="left-section">
                            {upcomingSession.rstv_guid == null && (
                              <div>
                                Eschool Session Id :
                                {upcomingSession.eschool_session_id != null
                                  ? upcomingSession.eschool_session_id
                                  : 0}
                              </div>
                            )}
                          </div>
                          {upcomingSession.cancelled == "false" &&
                            extractIdentifier(
                              upcomingSession.language_identifier
                            ) != "cp" && (
                              <div className="right-section">
                                {user.type == "Coach" &&
                                  (extractIdentifier(
                                    upcomingSession.language_identifier
                                  ) == "totale" ? (
                                    moment() >
                                    moment(
                                      upcomingSession.session_start_time
                                    ).subtract(10, "minutes") ? (
                                      <>
                                        <div className="session-feedback-button">
                                          <a
                                            onClick={(e) => {
                                              e.preventDefault();
                                              let arr = upcomingSession.launch_session_url
                                                ? upcomingSession.launch_session_url.split(
                                                    "'"
                                                  )[1]
                                                : "";
                                              //   .split(",");
                                              launchOnline(arr); //[0], arr[1]);
                                            }}
                                            rel="noreferrer"
                                            target="_blank"
                                          >
                                            Launch Session
                                          </a>
                                        </div>
                                        <div className="session-feedback-button feedback-disabled">
                                          Session Feedback
                                        </div>
                                      </>
                                    ) : (
                                      <div className="session-feedback-button feedback-disabled">
                                        Session Feedback
                                      </div>
                                    )
                                  ) : (
                                    <div className="session-feedback-button">
                                      <a
                                        onClick={(e) => {
                                          e.preventDefault();
                                          let arr = upcomingSession.launch_session_url
                                            ? upcomingSession.launch_session_url.split(
                                                "'"
                                              )[1]
                                            : "";
                                          //   .split(",");
                                          launchOnline(arr); //[0], arr[1]);
                                        }}
                                        rel="noreferrer"
                                        target="_blank"
                                      >
                                        Launch Session
                                      </a>
                                    </div>
                                  ))

                                }

                              </div>
                            )
                            }
                        </div>
                      </div>
                    )
                    }
                    {upcomingSession.type == "Appointment" && (
                      <div>
                        <div className="row2">
                          <div className="left-section">
                            <div>
                              Appointment: {upcomingSession?.appointment_title}
                            </div>
                            <br />
                            <div>
                              <strong>Appointment Details:</strong>
                              <br />
                              {upcomingSession?.details}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              {/* ) */}
              {/* } */}
            </div>
          </div>
        {/* )} */}
        </div>)}

        {/* Violation Popup */}

        {assignSubConfirmationDisplay.displayAssignSubConfirmation && (
          <div className="rcmodal" id="coach-schedule-request-confirmation">
            <div className="rcmodal-dialog">
              {/* {loading ? (
                <div> LOADING....</div>
              ) : ( */}
                <div className="rcmodal-content">
                  <div className="rcmodal-body">
                    <div>
                      This substitution request is in violation of the Studio team&apos;s Substitution policy.
                      Substitution(s) were already requested for session at : {violationDate}. Would you like to
                      proceed?
                    </div>
                  </div>
                </div>
              {/* )} */}
              <div className="rcfooter">
                <button
                  className="cancel"
                  data-dismiss="modal"
                  onClick={(e) => {
                    console.log("assignSubConfirmationDisplay", assignSubConfirmationDisplay);
                    setAssignSubConfirmationDisplay((assignSubConfirmationDisplay) => ({
                      ...assignSubConfirmationDisplay,
                      ["displayAssignSubConfirmation"]: false,
                    }));
                  //  cancelLoading();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="ok-btn"
                  onClick={(e) => {
                    handleConfirmAssignSubstituteClicked(e, upcomingSession);
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Session Detail for Upcoming Session Ends */}


          <div className="substitution-alert-div" style={{
              display: showSubstitute ? "block" : "none",
            }}>
            <div className="substitution-alert">
              <table className="sub-alert-table">
                <tbody>
                  <tr>
                    <td className="substitution-needed">SUBSTITUTION NEEDED!</td>
                    <td className="sub-data-div">
                      <table className="sub-data">
                        <tbody>
                        {modifiedSubstitutions.map((substitution, i) => (
                          <tr  key={i}>
                             <td className="width3">
                                <img className="warning_icon" src={warning} />
                            </td>
                            <td className="width25 substitution_needed">{substitution.sub_needed_for ? (substitution.sub_needed_for == "Coach" ? "Coach Required!" : "Moderator Required!") : ""}</td>
                            <td className="width25">{substitution.lang}</td>
                            <td className="width25">{moment(substitution.session_start_time).format("ddd, MMMM DD,YYYY hh:mm A")}</td>
                            <td className="width15">{substitution.level_unit_lesson}</td>
                            {substitution.can_grab && substitution.grab_disable == false &&
                              <td className="width10">
                                <input type="button" value="Grab" onClick= {() => {
                                  props.actions.reassignSubstitutionFunction({
                                    filter_selected_coach_value : substitution.id,
                                    from_cs: false,
                                    assigned_coach: user.id,
                                    reason:"Auto Subrequest",
                                    coach_session_id:substitution.coach_session_id,
                                    scheduled_session_id: substitution.scheduled_session_id,
                                    scheduled_eschool_session_id: substitution.scheduled_eschool_session_id,
                                    is_grabing: true
                                }).then((response) => {
                                  props.actions.getSubstitutions({ time_range: "upcoming", alert: "true" });
                                })
                                }
                                } />
                              </td>
                            }
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </td>
                    <td className="see-all-substitutions">
                      <font className="substitution-font">
                        <a href="/substitutions"> see all sub requests</a>
                      </font>
                    </td>
                    <td>
                      <div className="substitution-close-button" onClick={() => {setShowSubstitute(false)}}>X</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="scheduler-form-data">
          <input className="today-button" type="button" value="Today" onClick={(e) => {onSubmitHandler(moment().endOf("day").toISOString(), "custom")}}/>
            <div className="calendar-data">
              {/* <DateRangePicker
                onEvent={(e, picker) => {
                  e.preventDefault();
                  setCoachFilter({
                    ...coachFilter,
                    filterDate: picker.startDate,
                    // end_date: picker.endDate
                  });
                  //   getNotifications({
                  //     time_range: "custom",
                  //     start_date: picker.startDate.format("YYYY-MM-DD"),
                  //     end_date: picker.endDate.format("YYYY-MM-DD"),
                  //   });
                }}
              >
                <input type="text" className="form-control" />
              </DateRangePicker> */}
              <button className="arrow">
                  {/* <img alt="Previous Week" onClick={(e) => {onSubmitHandler(moment(coachFilter.filterDate).subtract(8,'days').startOf('week').endOf("day").toISOString(), "custom");}} className="previous-week" src={previous} title="Previous Week"/> */}
                  <img alt="Previous Week" onClick={(e) => {onSubmitHandler( moment(coachFilter.filterDate)
                          .subtract(7, "days")
                          .endOf("day")
                          .toISOString(), "custom");}} className="previous-week" src={previous} title="Previous Week"/>
              </button>
              <DatePicker
                selected={new Date(coachFilter.filterDate)}
                onChange={(date, e) => {
                  e.preventDefault;
                  setCoachFilter({
                    ...coachFilter,
                    filterDate: date,
                  });
                  e.target.value = date;
                  props.actions.getCoachScheduler({ ...coachFilter, filterDate: date});
                  setShowCalendar(true);
                  setGoLoader(true);
                }}
                value={moment(coachFilter.filterDate).startOf('week').format('MMMM DD, YYYY') + "    -    " + moment(coachFilter.filterDate).endOf('week').format('MMMM DD, YYYY')}
                className="date-picker"
              />
              <button href="#" className="arrow">
                  {/* <img alt="Next Week" onClick={(e) => {onSubmitHandler(moment(coachFilter.filterDate).add(8,'days').startOf('week').endOf("day").toISOString(), "custom");}}   className="next-week" src={next} title="Next Week"/> */}
                  <img alt="Next Week" onClick={(e) => {onSubmitHandler(moment(coachFilter.filterDate).add(7,'days').startOf('week').endOf("day").toISOString(), "custom");}}   className="next-week" src={next} title="Next Week"/>
              </button>
            </div>
            {/* <div className="scheduler-language-category">
              <select className="dropdown" name="language_category" onChange={onFilterChange}>
                <option value="totale">TOTALe/ReFLEX</option>
                <option value="www">World Wide English</option>
                <option value="fb">Fluency Builder</option>
                <option value="cp">Cross Product</option>
                <option value="yp">Your Plan</option>
                <option value="rstv">RSTV</option>
              </select>
            </div> */}
            <div className="scheduler-language">
              <select name="language" onChange={onFilterChange} className="dropdown longer-dropdown language">
                <option value="all">All Languages</option>

                {languages.map((language) => {
                  return (
                    <option key={language.id} value={language.identifier}>
                      {getLanguageByIdentifier(language.identifier)}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* <div className="cs-coach">
              <select
                name="coach_id"
                className="coach dropdown"
                onChange={onFilterChange}
                disabled={filter.language == ""}
              >
                <option value="">Select a Coach</option>
                {sortCoachNamesBy(coachs, "full_name").map((coach) => (
                  <option key={coach.id} value={coach.id}>
                    {coach.full_name}
                  </option>
                ))}
              </select>
            </div> */}
            <input className="go-button" type="button" value="GO" onClick={onSubmitHandler} />
          </div>
          {/* <div className="filter-language-div">
            <span className="bold-font">Filter Schedule by :</span>
            <select className="dropdown longer-dropdown" name="filter-language" onChange={onFilterChange}>
              <option value="">Select a Language</option>
              {languages.map((language) => {
                return (
                  <option key={language.id} value={language.id}>
                    {getLanguageByIdentifier(language.identifier)}
                  </option>
                );
              })}
            </select>
          </div> */}
        </div>
        <div className="week-calendar">
          {/* style={{ ...(!config.display && { display: "none" }) }}> */}
          {/* {schedulerList != [] && JSON.stringify(schedulerList)} */}
          {/* {schedulerList != [] && */}
          <CoachSchedulerCalendar config={config} schedulerList={schedulerList} coachFilter={coachFilter} showCalendar={showCalendar} isFromAppointment={false} calRef={calRef} maxUnitInfo={maxUnitInfo} isFromMyScheduler={true} double={goLoader} onDoubleChange={(data) => loaderCallBack(data)} updatedTimeZone={updatedTimeZone}
          bulkSession={bulkSession}
          setBulkSession={setBulkSession}
          />
          {/* } */}
          {/* style={{ ...(!config.display && { display: "none" }) }} /> */}
        </div>
        <div style={{ overFlow: "auto" }} />
      </div>
      <RequestSubstitute
        settings={requestSubstituteDisplay}
        setSettings={setRequestSubstituteDisplay}
        sessionInfo={upcomingSession}
        coachFilter={coachFilter}
        onRequestSuccess={handleRequestSuccess}
        isFromAppoinment={false}
      />
    </>
  );
}

MySchedulerPage.propTypes = {
  //languages: propTypes.array.isRequired,
  // notifications: propTypes.array.isRequired,
  actions: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  coachProfile: state.coachProfile,
  //languages: state.languages,
  coachSheduler: state.coachSheduler.coachSheduler,
  getTimeOffListResponse: state.coachSheduler.getTimeOffListResponse,
  additionalSubstitutions: state.additionalSubstitutions,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      // getNotifications: bindActionCreators(notificationsAction.getNotifications, dispatch),
      getLanguages: bindActionCreators(languageActions.getLanguages, dispatch),
      getCoach: bindActionCreators(coachProfileAction.getCoach, dispatch),
      getCoachScheduler: bindActionCreators(coachShedulerActions.getCoachScheduler, dispatch),
      getTimeOffFunction:bindActionCreators(coachShedulerActions.getTimeOff, dispatch),
      createTimeOffFunction:bindActionCreators(coachShedulerActions.createTimeOff, dispatch),
      cancelTimeOffFunction:bindActionCreators(coachShedulerActions.cancelTimeOff, dispatch),
      getClosestSessions: bindActionCreators(coachShedulerActions.getClosestSessions, dispatch),
      getSubstitutions: bindActionCreators(notificationsAction.getAdditionalSubstitutions,dispatch),
      reassignSubstitutionFunction: bindActionCreators(notificationsAction.reassignSubstitution, dispatch),
      getSessionDetails: bindActionCreators(coachShedulerActions.getSessionDetails, dispatch),
      checkViolationPolicy: bindActionCreators(coachShedulerActions.checkViolationPolicy, dispatch)
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MySchedulerPage);
