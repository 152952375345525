import { useState, useEffect } from "react";
import * as masterSchedulerApi from "../apis/MasterSchedulerApi";

export default function useAvailableCoach(all = false) {
  const [coachs, setCoach] = useState([]);
  const [coachParams, setCoachParams] = useState({});

  useEffect(() => {
    Object.keys(coachParams).length != 0 &&
      (all ? masterSchedulerApi.getModerators(coachParams) : masterSchedulerApi.getAvailableCoaches(coachParams)) // sessionId=914409///.getSessionDetails(sessionParams)
        .then((response) => {
          setCoach(response.data);
        })
        .catch((err) => {
          throw err;
        });
  }, [coachParams]);

  return { coachs, setCoachParams };
}
