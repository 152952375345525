import {
  GET_COMMENT_SUCCESS, 
  ADD_COMMENT_SUCCESS, 
  UPD_COMMENT_SUCCESS,
} from "../constants";

import initialState from "./initialState";

export default function commentsReducer(state = initialState.comments, action) {
  switch (action.type) {
    case ADD_COMMENT_SUCCESS:
      return [...state, { ...action.addComment }];
    case GET_COMMENT_SUCCESS:
      return action.comment;
    case UPD_COMMENT_SUCCESS:
      return state.map((comment) =>
      comment.id === action.updateComment.id ? action.updateComment : comment
      );
    default:
      return state;
  }
}
