import React, { useState, useEffect, useMemo, useReducer } from "react";
import { useLocation } from "react-router-dom";
import propTypes from "prop-types";
import decode from "jwt-decode";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "underscore";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import useStateWithCallback, { useStateWithCallbackInstant, useStateWithCallbackLazy } from "use-state-with-callback";

import * as coachShedulerAction from "../../redux/actions/CoachShedulerAction";
import { getLanguageByIdentifier } from "../../utils/languageUtils";
import { useVillage, useAppointmentTypes, useModerator, useAvailableCoach } from "../../hooks";
import * as RstvAction from "../../redux/actions/RSTVAction";
import * as substitutionAction from "../../redux/actions/SubstitutionAction";
import { getQualifiedLanguages } from "../../apis/CoachProfileApi";

import "./EditSessionModal.css";
import CancelSessionModal from "./CancelSessionModal";
import RecurringSessionModal from "../MasterSchedulerComponent/RecurringSessionModal";
import AutoSubModal from "./AutoSubModal";
import { getMaxLevelUnit } from "../../apis/CoachSchedulerApi"
import { getCoachRstvTopic } from "../../apis/CoachSchedulerApi";
import { topicTypes } from "../../utils/sessionUtils"

// function useForceUpdate() {
//   const [value, setValue] = useState(0); // integer state
//   return () => setValue((value) => value + 1); // update the state to force render
// }
function extractIdentifier(language) {
  let identifier = language.split("-");
 // return identifier.length == 2 ? identifier[0].toLowerCase() : "totale";
  return identifier.length == 2 ? identifier[0].toLowerCase() : (identifier.length == 3 ? "fbsolo" : "totale");
}

function languageReducer(state = "", action) {
  console.log("Action Language==================>" + action.language);
  for (let language of ["totale", "solo", "fb", "yp", "rstv", "cp"]) {
    if (extractIdentifier(action.language).includes(language.toLowerCase())) return language;
  }

  return "totale";
}

function getRecurring(data) {
  const { is_recurring, recurring_end_date, recurring_frequency, ...rest } = data;
  if (is_recurring == false) return rest;
  else {
    const endDate = moment(recurring_end_date, "YYYY-MM-DD").isValid() ? { recurring_end_date } : {};
    return { ...data, ...endDate, recurring_frequency, recurring_start_date: data.session_start_time };
  }
}

function sessionPayload(action, appointmentTypes = []) {
  const recurring = getRecurring(action.payload);

  if (action.payload.type === "session" || action.payload.type == "ConfirmedSession") {
    const { teacher_confirmed, appointment_type_id, details, ...restSession } = recurring;
    if (["totale", "solo", "fb", "yp", "cp"].includes(action.type)) {
      console.log(action);
      const { moderator_id, ...remain } = restSession;
      return remain;
    }
    if (action.type === "rstv") {
      const { number_of_seats, lesson, level, unit, ...remain } = restSession;
      return remain;
    }
  }

  if (action.payload.type.toLowerCase() === "appointment".toLowerCase()) {
    const {
      teacher_confirmed,
      number_of_seats,
      lesson,
      level,
      unit,
      language_identifier,
      moderator_id,
      topic_id,
      appointment_type_id,
      ...restAppointments
    } = recurring;

    const label = appointmentTypes.find((type) => type.id == appointment_type_id)?.title;
    return { ...restAppointments, type: "appointment", appointment_type_id, label };
  }
}

function EditSessionModal({ ...props }) {
  const [session, setSession] = useState({
    language_identifier: "all", //eg:. ENG
    language_id: "",
    number_of_seats: null, // int
    type: "session",
    is_recurring: false,
    teacher_confirmed: false,
    details: "", //optional parameter
    lesson: "", //4, //optional parameter
    level: "", //1, //optional parameter
    unit: "", //1, //optional parameter
    moderator_id: "",
    topic_id: "",
    coach_id: "",
    appointment_type_id: null,
    session_start_time: null,
    session_end_time: null,
    recurring_end_date: "", //"2021-04-25 12:00:00", // optional
    external_village_id: "", //1, // optional
    recurring_frequency: 1, // 1 – weekly, 2 – biweekly, default value is 1 if not passed
    wildcard: false,
  });

  const [languages, setLanguages] = useState([]);
  const appointmentTypes = useAppointmentTypes();
  // const { moderators, setModeratorParams } = useModerator();
  // const { session: getSingleSession, setSessionParams } = useSession();
  const villages = useVillage();
  const { coachs: moderators, setCoachParams: setModeratorParams } = useAvailableCoach(false);

  const [store, dispatch] = useReducer(languageReducer, "");
  const { settings, setSettings, displayEdit, setDisplayEdit, sessionDetails, positionAttributes, maxUnitInfo } = props;
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const [cancelModalOpen, setCancelModalOpen] = useStateWithCallbackLazy({ toggle: false, value: null });
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [intimation, setIntimation] = useState(null);
  const [rstvTopics, setRstvTopics] = useState([]);
  const [openCancelReason, setOpenCancelReason] = useState(false);
  const [reasonFilter, setReasonFilter] = useState("");
  const [cancelConfirmationModal, setCancelConfirmationModal] = useState(false);
  const [textboxDisplay, setTextboxDisplay] = useState(false);
  const [text, setText] = useState("");
  const user = decode(localStorage.getItem("token"));
  const [autoSubDisplay, setAutoSubDisplay]  = useState(false);
  const [profile, setProfile] = useState({
    "coachId": "all",
    "langCode": "all",
    "learnersCount": "all", 
    "timeRange": "Upcoming",
    "auto_sub": true
  });


  // , () =>
  //   onSessionCancel()
  // );
  // const [checkRecurring, setCheckRecurring] = useState({ disable_recurring: false });

  useEffect(() => {
    if (Object.keys(sessionDetails).length > 0) {
      let modifiedSession = {};
      //      const { recurring_end_date, freq_in_weeks, ...sessionDetail } = sessionDetails;
      let { freq_in_weeks, ...sessionDetail } = sessionDetails;
      //  let recurr_end_date;
      if (sessionDetail.disable_recurring && sessionDetail.is_recurring == false)
        sessionDetail = { ...sessionDetail, is_recurring: false };

      if (sessionDetail.is_recurring) {
        //Hidden to solve the issue of recurring end date field get wrong date
        // if (recurring_end_date) {
        //   let curr = new Date(recurring_end_date);
        //   curr.setDate(curr.getDate() + 3);
        //   recurr_end_date = curr.toISOString().substr(0, 10);
        // } else {
        //   recurr_end_date = "";
        // }

        modifiedSession = {
          ...sessionDetail,
          recurring_frequency: freq_in_weeks ? freq_in_weeks : 1,
          //   recurring_end_date: recurr_end_date, //moment(recurring_end_date).format("YYYY-MM-DD"),
        };
      } else modifiedSession = { ...sessionDetail, recurring_frequency: 1 };
        if( sessionDetail.type != "Appointment"){
          setModeratorParams({ sessionId: sessionDetail.id });
        }
      setSession(modifiedSession);
      dispatch({
        language:
          modifiedSession?.language_identifier ||
          modifiedSession?.language ||
          modifiedSession?.language_type ||
          modifiedSession[0]?.language_identifier ||
          modifiedSession[0]?.language ||
          modifiedSession[0]?.language_type ||
          "totale",
      });
      // To display MaxUnitLevel details
    //  if (!["cp", "yp", "fb", "rstv"].includes(sessionDetail.language_identifier)) {
        
        getMaxLevelUnit({coach_id: sessionDetail.coach_id, language_id: sessionDetail.language_identifier}).then((response) => {
          //let int = `Max Level:5 Unit:${field?.max_unit || 0} Lesson:4`;
          let int = `Max Level:${response.data.maxLevel || 0} Unit:${response.data.maxUnit || 0} Lesson:4`;
          setIntimation(int);
        }).catch((err) => {
          console.log("Error occured during MaxUnitLevel Fetch :" + err);
          setIntimation(null)
        })


      {sessionDetail.type != "Appointment" && (
        (sessionDetail.language_identifier.toLowerCase().includes("rstv")) &&
          getCoachRstvTopic(sessionDetail.language_identifier).then((response) => {
            setRstvTopics(response.data)
          })
        )
      }
      // }
      // else
      // {
      //   setIntimation(null)
      // }
    //

    }
  }, [sessionDetails]);

  useEffect(() => {
    let additional = {};
    // if (!["cp", "yp", "fb", "rstv"].includes(store)) {
    // }//} else setIntimation(null);
    if (store === "cp") {
      additional.unit = "";
      additional.lesson = "";
      additional.number_of_seats = "";
    }
    if (store === "yp") additional.lesson = "";

  
    // if (store === "fb") additional.number_of_seats = 6;
    setSession({ ...session, ...additional });
  }, [store]);

  // useEffect(() => {
  //   if (typeof settings?.dateStr != "undefined" && session.language_identifier != "all" && store == "rstv")

  // }, [session.moderator_id]); // , props.settings.dateStr]);

  useEffect(() => {
    //   props.coachProfile.length > 0 && setProfile(props.coachProfile[0]);
    //props.coachProfile.length > 0 && setLanguages(props.coachProfile[1]);
    props.coachProfile.length > 0 &&
      getQualifiedLanguages(props.coachProfile[0][0].id).then((response) => {
        return setLanguages(response.data);
      });
    //   props.rstvResponse.length == 0 && props.actions.getRstvfunction();

    // if (props.coachProfile.length > 0 && typeof settings?.dateStr != "undefined") {
    // }
  }, [props.coachProfile, settings.dateStr]);

  // useEffect(() => {
  // props.coachProfile.length > 0 && console.log(moment.utc(settings.dateStr).format("yyyy-MM-DD HH:mm:ss"));

  //   props.coachProfile.length > 0 &&
  //     coachShedulerAction
  //       .checkRecurrings({
  //         coach_id: props.coachProfile[0][0].id,
  //         session_start_time: moment.utc(sessionDetails.session_start_time).format("yyyy-MM-DD HH:mm:ss"),
  //         day_index: moment.utc(settings.dateStr).day(),
  //       })
  //       .then((result) => {
  //         setCheckRecurring(result);
  //       });
  // }, [displayEdit, sessionDetails.session_start_time]);

  // const getNotes = useMemo(() => {
  // const notes = [];

  // if (checkRecurring.disable_recurring) {
  //   notes.push(
  //     "This coach is already scheduled to have a recurring Session/Appointment starting on " +
  //       "the following week. You can only schedule a one-off Session/Appointment dialog at this time"
  //   );
  // }
  // return notes.length <= 0 ? false : notes;
  // }, [checkRecurring.disable_recurring]);

  const getInputDisableConfig = useMemo(() => {
    const disable = {
      village: false,
      seat: false,
      wildcard: false,
      unit: false,
      lesson: false,
      topic: false,
      moderator: false,
    };
    if (session.learners_signed_up == "0") {
      return {
        cp: disable,
        yp: disable,
        www: disable,
        fb: disable,
        solo: disable,
        totale: { ...disable, village: true, seat: true, unit: true, lesson: true, wildcard: true },
        // fb: { ...disable, seat: true },
        rstv: { ...disable, topic: true, moderator: true, wildcard: true },
      };
    } else {
      return {
        cp: disable,
        yp: disable,
        www: disable,
        fb: disable,
        solo: disable,
        totale: { ...disable, village: false, seat: false, unit: true, lesson: true },
        //  fb: { ...disable, seat: true },
        rstv: { ...disable, topic: true, moderator: true },
      };
    }
  });

  function onSessionChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (target.name === "language_identifier") 
    {
      dispatch({ language: value });
      {rstvTopics.length == 0 && (value.toLowerCase().includes("rstv")) &&
        getCoachRstvTopic(value).then((response) => {
          setRstvTopics(response.data)
        })
      }
    }
    setSession({ ...session, [target.name]: value });
  }

  useEffect(()=>{
    if(session.unit == null){
      session.unit = "null"
    }
    if(session.lesson == null){
      session.lesson = "null"
    }
  },[session])

  const promt = (msg) => {
    setLoading(false);
    alert(msg);
    return false;
  };

  function validate(payload) {
    if (store == "rstv") {
      for (const required of ["moderator_id", "topic_id"]) {
        if (payload[required] == "" || payload[required] == null)
          return promt(`Please select a ${required.split("_")[0]}`);
      }
      if (payload.coach_id == payload.moderator_id) return promt("Coach and Moderator should be different");
    }

    return true;
  }

  function search(nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
        if (myArray[i].identifier === nameKey) {
            return myArray[i];
        }
    }
  }


  function onSessionSubmit(recurrall = "") {
    //let saveAll = false;
    setLoading(true);
    let action = "this";
    if (
      (session.is_recurring && sessionDetails.is_recurring) ||
      (!session.is_recurring && sessionDetails.is_recurring)) {
      // if (
      //   (session.is_recurring && sessionDetails.is_recurring) ||
      //   (!session.is_recurring && sessionDetails.is_recurring)|| (!props.sessionDetails.is_recurring && session.is_recurring)) {
      if (recurrall == "SAVE ALL") action = "all";
      setSaveModalOpen(false);
     // saveAll = confirm("Do you want this session to persist in all recurrk ing sessions?");
    }
    const payload = sessionPayload({ type: store, payload: session }, appointmentTypes);
    if (!validate(payload)) return;

    if(store == "totale" && payload.unit != '' && (payload.type == "session" || payload.type == "ConfirmedSession"))
    {
      if (maxUnitInfo.length != 0) {
        const unitInfo = search(payload.language_identifier, maxUnitInfo)
            if (payload.unit > unitInfo.max_unit) {
              alert("Coach is not qualified to teach selected unit");
              setLoading(false);
              return;
            }
      } 
    }


    props.actions
      .updateSession(
        {
          ...payload,
          ...{ save: action },
          language_type: store,
          is_recurring: session.is_recurring,
          unit: session.unit == "null" ? null : session.unit,
          lesson: session.lesson == "null" ? null : session.lesson
        },
        props.coachFilter
      )
      .then(() => {
        setLoading(false);
        setSession({
          language_identifier: "all", //eg:. ENG
          language_id: "",
          number_of_seats: null, // int
          type: session.type,
          is_recurring: false,
          teacher_confirmed: false,
          details: "", //optional parameter
          lesson: "", //4, //optional parameter
          level: "", //1, //optional parameter
          unit: "", //1, //optional parameter
          moderator_id: "",
          topic_id: "",
          coach_id: "",
          appointment_type_id: "",
          session_start_time: "",
          session_end_time: "",
          recurring_end_date: "", //"2021-04-25 12:00:00", // optional
          external_village_id: "", //1, // optional
          recurring_frequency: 1, // 1 – weekly, 2 – biweekly, default value is 1 if not passed
          wildcard: false
        });
        setSaveModalOpen(false);
        setDisplayEdit(false);
        toast.success("Session Edited Successfully");
        if(document.getElementById("filter-language")){
          document.getElementById("filter-language").value=""; 
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error("Failed to process the data...");
        setDisplayEdit(false);
      });
  }

  function onFilterChange(e) {
    const { name, value } = e.target;
    if(e.target.value !== "Other"){
      setTextboxDisplay(false);
    } else{
      setTextboxDisplay(true);
    }
    setReasonFilter(value);
  }

  function textChange(e){
    const { name, value } = e.target;
    setText(value);
  }

  function formStyle(prop = "") {
    const sessionInput = { display: session.type == "ConfirmedSession" ? prop : "none" };
    const sessionLanguageInput = { ...(session.language_identifier == "all" ? { display: "none" } : sessionInput) };
    const recurringInput = { display: session.is_recurring ? prop : "none" };
    const appointmentInput = { display: session.type == "appointment" ? prop : "none" };
    console.log("Recurring Input" + JSON.stringify(recurringInput));
    return {
      language: sessionInput,
      village: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      seats: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      unit: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      level: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      appointment_type: appointmentInput,
      appointment_details: appointmentInput,
      is_recurring: recurringInput,
    };
  }

  function fieldStyle() {
    console.log("Store details==========>" + JSON.stringify(store));
    return getInputDisableConfig[store];
  }

  function onSessionCancel(cancelModalResult = null) {
    setLoading(true);
    let cancelAll = false;
    console.log(338, cancelAll);
    if (sessionDetails.is_recurring) {
      // cancelAll = confirm(
      //   "Do you want to cancel this session only, or cancel both this session and all future occurrenes?"
      // );
      //cancelAll = cancelModalOpen.value == "CANCEL WITH RECURRENCE" ? true : false;
      cancelAll = cancelModalResult == "CANCEL WITH RECURRENCE" ? true : false;
    }

    props.actions
      .cancelSession(
        {
          id: sessionDetails.id,
          recurring: cancelAll,
          reason: reasonFilter == "Other" ? text : reasonFilter,
        },
        props.coachFilter
      )
      .then(() => {
        autoSub();
        setSettings((setting) => ({ ...setting, displayDetails: false }));
        setDisplayEdit(false);
        setCancelModalOpen({ ...cancelModalOpen, toggle: false });
        setLoading(false);
        toast.success("Session Cancelled Successfully");
        if(document.getElementById("filter-language")){
          document.getElementById("filter-language").value=""; 
        }
      })
      .catch((err) => {
        console.log(err);
        setCancelModalOpen({ ...cancelModalOpen, toggle: false });
        setLoading(false);
        toast.error("Failed to request Substitute");
      });
    // .finally(function() {
    //   setCancelModalOpen({ ...cancelModalOpen, toggle: false });
    // });
  }

  function autoSub(){
    props.actions.getSubstitutionFilterApplyListFunction({...profile, coachId: props.coachFilter.coach_id})
    .then(() => {
      props.actions.getSubstitutionFilterListFunction({time_range: profile.timeRange.toLowerCase(), language: profile.langCode}).then(console.log());
      setAutoSubDisplay(true);
    });
  }

  return (
    <>
      <div className="blockOverlay" style={{ display: loading ? "block" : "none" }}></div>
      <div className="blockMsg" style={{ display: loading ? "block" : "none" }}>
        <h1>Please wait...</h1>
      </div>
      <div
        className="modal"
        style={{
          display: displayEdit ? "block" : "none",
          left: settings.xDistance > 610 ? positionAttributes.xAxis - 250 + "px" : positionAttributes.xAxis + 250 + "px",
          top: positionAttributes.yAxis + "px",
        }}
        id="coach-schedule-edit-session"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal_header">
              <h4 className="modal-title">
                EDIT {session.type?.toUpperCase() == "APPOINTMENT" ? session.type?.toUpperCase() : "SESSION"}{" "}
                <span style={{ color: "#1090CF" }}>
                  {moment(moment(props.sessionDetails.session_start_time).format()).format("ddd MM/DD/YY hh:mm A")}
                </span>
              </h4>
              <a
                className="close-icon"
                title="Close"
                aria-label="Close"
                role="button"
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  setDisplayEdit(false);
                }}
              >
                <span>×</span>
              </a>
            </div>
            <div className="modal-body">
              {session.type == "session" ||
                (session.type == "ConfirmedSession" && (
                  <>
                    <div className="row1">
                      <div className="left-section">
                        <div>
                          <span>Session Language</span>
                          <select
                            name="language_identifier"
                            style={{ display: "inline-block" }}
                            value={session.language_identifier}
                            disabled="disabled"
                          >
                            {languages.length > 0 &&
                              languages.map(
                                (language) =>
                                  getLanguageByIdentifier(language.identifier) != "--none--" && (
                                    <option key={language.identifier} value={language.identifier}>
                                      {getLanguageByIdentifier(language.identifier)}
                                    </option>
                                  )
                              )}
                          </select>
                        </div>
                      </div>
                      <div className="right-section">
                        <div>
                          <span>Recurring</span>
                          <input
                            name="is_recurring"
                            type="checkbox"
                            checked={session.is_recurring ? "checked" : ""}
                            onChange={onSessionChange}
                            disabled={pathname.includes("my_schedule") || session.disable_recurring} // checkRecurring.disable_recurring}
                          />
                        </div>
                        <div style={formStyle().is_recurring}>
                          <span>Frequency</span>
                          <select
                            value={session.recurring_frequency}
                            name="recurring_frequency"
                            onChange={onSessionChange}
                            disabled={true}
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                          </select>
                        </div>
                        <div style={formStyle().is_recurring}>
                          <div className="calendar-section">
                            <span className="end-at">End at </span>
                            <span>
                              {/* <input
                                className="jquery_end_datepicker hasDatepicker"
                                id="end_recurring_at"
                                name="recurring_end_date"
                                min={moment(new Date())
                                  .subtract("years", 10)
                                  .format("YYYY-01-01")}
                                max={moment(new Date())
                                  .add("years", 10)
                                  .format("YYYY-01-01")}
                                type="date"
                                onChange={onSessionChange}
                                value={moment(session.recurring_end_date).format("YYYY-MM-DD")}
                              /> */}

                              <DatePicker
                                selected={
                                  moment(session.recurring_end_date, "yyyy-MM-DD").isValid()
                                    ? moment(session.recurring_end_date).toDate() //.format(""yyyy-MM-DD"")
                                    : null
                                }
                                minDate={moment(props.sessionDetails.session_start_time).toDate()}
                                id="end_recurring_at"
                                className={{ border: "1px solid #444", width: "160px", height: "80%" }}
                                name="recurring_end_date"
                                onChange={(date) => {
                                  setSession({
                                    ...session,
                                    recurring_end_date: moment(date, "MM/DD/yyyy").format("yyyy-MM-DD"), //date, //moment(date, "MM/DD/YYYY").format("yyyy-MM-DD "),
                                  });
                                }}
                              // onChange={date => setStartDate(date)}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {store == "rstv" ? (
                      <>
                        <div className="row2">
                          <div className="left-section">
                            <select
                              className="dropdown"
                              id="moderator"
                              name="moderator_id"
                              value={session.moderator_id}
                              onChange={onSessionChange}
                            >
                              <option value="">Select Moderator</option>
                              {moderators.map(({ id, full_name }) => (
                                <option key={id} value={id}>
                                  {full_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="row3">
                          <div className="left-section">
                            <select
                              className="dropdown appointment-dropdown"
                              name="topic_id"
                              value={session.topic_id}
                              onChange={onSessionChange}
                            >
                              <option value="">-Topic-</option>
                              {/* {props.rstvResponse.length > 0 &&
                                props.rstvResponse.map((rstv) => (
                                  <option key={rstv.id} value={rstv.id}>
                                    {rstv.title}
                                  </option>
                                ))} */}

                              {rstvTopics.length > 0 &&
                                rstvTopics.map((rstv) => (
                                  <option key={rstv.id} value={rstv.id}>
                                    {rstv.title.length > 50 ? rstv.title.substring(0,50) : rstv.title}
                                  </option>
                              ))}
                             
                            </select>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row2">
                          <div className="left-section">
                            <span>Village (optional)</span>
                            <select
                              name="external_village_id"
                              value={session.external_village_id}
                              onChange={onSessionChange}
                              disabled={!fieldStyle().village}
                            >
                              {/* <option value="">Select a Village</option> */}
                              <option value="">No Village</option>
                              {villages.length > 0 &&
                                villages.map(({ id, name }) => (
                                  <option key={id} value={id}>
                                    {name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="right-section">
                            <div>
                              <span>Wildcard</span>
                              <input
                                className="wildcard-input"
                                name="wildcard"
                                type="checkbox"
                                checked={JSON.parse(session.wildcard) ? "checked" : ""}
                                onChange={onSessionChange}
                                disabled={(session.learners_signed_up > 0 && session.learner_details.attendance != []) ? true : !fieldStyle().wildcard}
                              />
                              <span>Confirmed</span>
                              <input
                                type="checkbox"
                                value="true"
                                checked={session.teacher_confirmed ? "checked" : ""}
                                onChange={onSessionChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row3">
                          <div className="left-section">
                            <span>Number of Seats</span>
                            <select
                              name="number_of_seats"
                              onChange={onSessionChange}
                              value={session.number_of_seats}
                              disabled={!fieldStyle().seat}
                            >
                              {_.range(1, 11).map((num) => (
                                <option key={num} value={num}>
                                  {num}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="right-section">
                            <div className="unit-lesson-section">
                              <span>Unit</span>
                              <select
                                name="unit"
                                onChange={onSessionChange}
                                value={session.unit}
                                disabled={JSON.parse(session.wildcard) ? true : !fieldStyle().unit}
                              >
                                <option value="null">-Unit-</option>
                                {_.range(1, 21).map((num) => (
                                  <option key={num} value={num}>
                                    {num}
                                  </option>
                                ))}
                              </select>
                              <span>Lesson</span>
                              <select
                                name="lesson"
                                onChange={onSessionChange}
                                value={session.lesson}
                                disabled={JSON.parse(session.wildcard) ? true : !fieldStyle().lesson}
                              >
                                <option value="null">-Lesson-</option>
                                <option value="2">2</option>
                                <option value="4">4</option>
                              </select>
                            </div>
                            {store == "totale" &&
                                <div className="max-text bold-font">{intimation ? intimation : "Max Level:1 Unit:1 Lesson:4"}</div>
                            }
                          </div>
                        </div>
                        <div className="topic-restriction">
                        <div>Allowed Topics</div>    
                        <select name='topic_restrictions' 
                          onChange={onSessionChange} 
                          disabled={!props.coachFilter.CPLanguages?.includes(session.language_identifier)}
                        >
                          {topicTypes.map(({ name, value }) => (
                            <option 
                              key={value} 
                              value={value}                                
                              selected={session.topic_restrictions === value}
                              >
                                { name }
                              </option>
                          ))}
                        </select>                    
                      </div>
                      </>
                    )}
                  </>
                ))}
              {session.type?.toLowerCase() == "appointment" && (
                <>
                  <div className="row1">
                    <div className="left-section">
                      <strong>Appointment:</strong>
                      <p>{session.appointment_title}</p>
                    </div>
                  </div>
                  <div className="appointment-content">
                    <div className="title bold-font">Appointment details:</div>
                    <textarea
                      cols="34"
                      name="details"
                      maxLength="500"
                      value={ session.details  == null ? "" : session.details }
                      onChange={onSessionChange}
                    ></textarea>
                    <div className="appointment-char">
                      <span>Characters remaining:</span>
                      <span className="char-count">
                        {session.details != null && session.details.length ? 500 - session.details.length : "500"}
                      </span>
                    </div>
                  </div>
                  <div className="left-section">
                    <div>
                      <span>Recurring</span>
                      <input
                        name="is_recurring"
                        type="checkbox"
                        checked={session.is_recurring ? "checked" : ""}
                        onChange={onSessionChange}
                        disabled={pathname.includes("my_schedule") || session.disable_recurring}
                      />
                    </div>
                    <div style={formStyle().is_recurring}>
                      <span>Frequency</span>
                      <select
                        value={session.recurring_frequency}
                        name="recurring_frequency"
                        onChange={onSessionChange}
                        disabled={true}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                      </select>
                    </div>
                    <div style={formStyle().is_recurring}>
                      <div className="calendar-section">
                        <span className="end-at">End at </span>
                        <span>
                          {/* <input
                            // className="jquery_end_datepicker hasDatepicker"
                            id="end_recurring_at"
                            name="recurring_end_date"
                            min={moment(new Date())
                              .subtract("years", 10)
                              .format("YYYY-01-01")}
                            max={moment(new Date())
                              .add("years", 10)
                              .format("YYYY-01-01")}
                            type="date"
                            onChange={onSessionChange}
                            value={
                              session?.recurring_end_date?.substring(0, 4) == "1970" ? "" : session.recurring_end_date
                            }
                          /> */}
                          <DatePicker
                            selected={
                              moment(session.recurring_end_date, "yyyy-MM-DD").isValid()
                                ? moment(session.recurring_end_date).toDate() //.format("MM/DD/yyyy")
                                : null
                            }
                            minDate={moment(props.sessionDetails.session_start_time).toDate()}
                            name="recurring_end_date"
                            id="end_recurring_at"
                            className={{ border: "1px solid #444", width: "160px", height: "80%" }}
                            onChange={(date) => {
                              setSession({
                                ...session,
                                recurring_end_date: moment(date, "MM/DD/yyyy").format("yyyy-MM-DD"), //date, //moment(date, "MM/DD/YYYY").format("yyyy-MM-DD "),
                              });
                            }}
                          // onChange={date => setStartDate(date)}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="footer">
                {user.type != "Coach" && <input
                  className="footer-button"
                  type="button"
                  value="CANCEL"
                  onClick={() => {
                    if(session.learners_signed_up > 0)
                    {
                      setOpenCancelReason(true);
                    }
                    else
                    {
                      if (session.is_recurring) {
                        setCancelModalOpen({ ...cancelModalOpen, toggle: true });
                      } else {
                        setCancelConfirmationModal(true);
                        // onSessionCancel();
                      }
                    }
                  }}
                />}
                <input
                  className="create-button"
                  type="button"
                  value="SAVE"
                  onClick={() => {
                  if (
                      (!props.sessionDetails.is_recurring && session.is_recurring) ||
                      props.sessionDetails.is_recurring
                    )
                   {
                      setSaveModalOpen(true);
                    } else {
                      onSessionSubmit();
                    }
                  }}/>
              </div>
            </div>
          </div>
          {/* {getNotes && <div className="footer bold-font">Note: {getNotes.join(". ")}.</div>} */}
        </div>
{/*  */}
        {openCancelReason &&
          <div
          style={{ display: openCancelReason ? "block" : "none" }}
          className="modal, coach-schedule-session-details-modal"
          id="master-schedule-cancel-reason"
          >
            <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal_header">
              <h4 className="modal-title">CANCEL SESSION</h4>
              <a
                className="close-icon"
                title="Close"
                aria-label="Close"
                role="button"
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  setReasonFilter(false);
                  setOpenCancelReason(false);
                }}
              >
                <span>×</span>
              </a>
            </div>
            <div className="modal-body">
              <div>
                Please select the reason that best explains why this session is being cancelled.
              </div>
              <div>
                <select name="reason" onChange={onFilterChange}>
                  <option value="Select">--REASON--</option>
                  <option value="Sickness">Sickness</option>
                  <option value="System Issue">System Issue</option>
                  <option value="Personal Emergency">Personal Emergency</option>
                  <option value="Internet outage">Internet outage</option>
                  <option value="Electricity outage">Electricity outage</option>
                  <option value="No-show">No-show</option>
                  <option value="Schedule glitch">Schedule glitch</option>
                  <option value="Personnel change">Personnel change</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div style={{ display: textboxDisplay ? "block" : "none" }}>
                <textarea cols="55" className="cancel-other-reasons" rows="3" title="Other reasons" onChange={textChange}></textarea>
              </div>
            </div>
            <div className="footer">
              <input
                type="button"
                value="Cancel Session"
                onClick={() => {
                  if(reasonFilter == "" || reasonFilter == "Select"){
                    alert("Please select a reason");
                    return false;
                  } else if(reasonFilter == "Other"){
                    if(text == ""){
                      alert("Please provide a reason");
                      return;
                    }
                  }
                  setOpenCancelReason(false);
                  if (session.is_recurring) {
                    setCancelModalOpen({ ...cancelModalOpen, toggle: true });
                  } else {
                    setCancelConfirmationModal(true);
                    // onSessionCancel();
                  }
                  //To fix non recurring learner added session
                  // (shiftInfo[userClicked.index].recurring_schedule_id != null) ?
                  //                           showCancelConfirmation(userClicked.index) :
                  //                           showConfirmedCancel(userClicked.index)
                  //
             //     showCancelConfirmation(userClicked.index);
                }}
              />
            </div>
          </div>
        </div>
          </div>
        }
        {/*  */}
      </div>
      {cancelConfirmationModal &&
        <div
        style={{ display: cancelConfirmationModal ? "block" : "none" }}
        className="modal coach-schedule-session-details-modal"
        id="coach-cancel-confirmation-modal"
        >
          <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal_header">
            <h4 className="modal-title"></h4>
            <a
              className="close-icon"
              title="Close"
              aria-label="Close"
              role="button"
              data-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                setCancelConfirmationModal(false);
              }}
            >
              <span>×</span>
            </a>
          </div>
          <div className="modal-body">
            <div>
            Are you sure you want to cancel the session?
            </div>
          </div>
          <div className="footer">
            <input
              type="button"
              value="Yes"
              className="cancel-ok"
              onClick={() => {
                onSessionCancel();
                setCancelConfirmationModal(false);
              }}
            />
            <input
              type="button"
              value="No"
              onClick={(e) => {
                e.preventDefault();
                setCancelConfirmationModal(false);
              }}
            />
          </div>
        </div>
      </div>
        </div>
      }
      <CancelSessionModal
        modalOpen={cancelModalOpen}
        setModalOpen={setCancelModalOpen}
        onSessionCancel={onSessionCancel}
      />
      <RecurringSessionModal
        modalOpen={saveModalOpen}
        setModalOpen={setSaveModalOpen}
        onSessionCancel={onSessionSubmit}
      />
      <AutoSubModal setAutoSubDisplay={setAutoSubDisplay} autoSubDisplay={autoSubDisplay} substitutionResponse={props.substitutionResponse} substitutionFilterList={props.substitutionFilterList}
      coachName={props.coachName} fromInactiveSub={null} getInactiveCoachSub={null}/>
    </>
  );
}

EditSessionModal.propTypes = {
  actions: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({ coachProfile: state.coachProfile, rstvResponse: state.rstvStore.rstvResponse,substitutionResponse: state.substitution.substitutionResponse, substitutionFilterList: state.substitution.substitutionFilterList  });

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      updateSession: bindActionCreators(coachShedulerAction.updateSessionDetail, dispatch),
      getRstvfunction: bindActionCreators(RstvAction.getRstv, dispatch),
      cancelSession: bindActionCreators(coachShedulerAction.cancelCoachSession, dispatch),
      getSubstitutionFilterApplyListFunction: bindActionCreators(substitutionAction.getSubstitutionFilterApplyList, dispatch),
      getSubstitutionFilterListFunction: bindActionCreators(substitutionAction.getSubstitutionFilterList, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSessionModal);
