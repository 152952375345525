/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useMemo, useReducer, useCallback } from "react";
import propTypes from "prop-types";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "underscore";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import * as coachShedulerAction from "../../redux/actions/CoachShedulerAction";

import * as masterShedulerAction from "../../redux/actions/MasterSchedulerAction";
import * as RstvAction from "../../redux/actions/RSTVAction";
import { useVillage, useModerator, useAvailableCoach, useConfig } from "../../hooks";
import CreateSlotObject from "../../business_layer/model/CreateSlotObject";
import { getCoachMaxUnits } from "../../apis/CoachProfileApi";
import { topicTypes } from "../../utils/sessionUtils"

import "react-datepicker/dist/react-datepicker.css";
import "./CreateShiftModal.css";
import classes from "../CoachSchedulerComponent/CreateSessionModal.module.css";
import clsx from "clsx";
import { getCoachRstvTopic } from "../../apis/CoachSchedulerApi";


function languageReducer(state = "", action) {
  for (let language of ["totale", "solo", "fb", "yp", "rstv", "cp"]) {
    if (action.language.toLowerCase().includes(language.toLowerCase())) return language;
  }
  return "totale";
}

function getRecurring(data) {
  const { is_recurring, recurring_end_date, recurring_frequency, ...rest } = data;
  if (is_recurring == false || rest.type == "ExtraSession") return rest;
  else {
    const endDate = moment(recurring_end_date, "YYYY-MM-DD").isValid() ? { recurring_end_date } : {};
    return { ...data, ...endDate, recurring_frequency, recurring_start_date: data.session_start_time };
  }
}

function sessionPayload(action) {
  const recurring = getRecurring(action.payload);

  if (
    action.payload.type === "session" ||
    action.payload.type == "ConfirmedSession" ||
    action.payload.type == "ExtraSession" ||
    action.payload.type == "LocalSession"
  ) {
    const { teacher_confirmed, appointment_type_id, details, ...restSession } = recurring;
    if (["totale", "solo", "fb", "yp", "cp"].includes(action.type)) {
      const { moderator_id, ...remain } = restSession;
      return remain;
    }
    if (action.type === "rstv") {
      const { number_of_seats, lesson, level, unit, ...remain } = restSession;
      return remain;
    }
  }
}

function CreateShiftModal({ ...props }) {
  const createObject = new CreateSlotObject();
  const { settings, setSettings, SessionDetails, masterFilter } = props;
  
  const [session, setSession] = useState({
    ...createObject,
    language_identifier: masterFilter.languageId,
    is_recurring: true,
    type: ["rstv", "cp"].some((e) => masterFilter.languageId.toLowerCase().includes(e)) ? "session" : "ExtraSession",
    max_unit: 20,
    wildcard: false
  });
  const { moderators, setModeratorParams } = useModerator(true);
  const [checkRecurring, setCheckRecurring] = useState({
    disable_recurring: false,
  });

  const [modCheckRecurring, setModCheckRecurring] = useState({
    disable_recurring: false,
  });
  //const { sessions, setSessionParams } = useConfig();
  const villages = useVillage();
  const [store, dispatch] = useReducer(languageReducer, "totale");
  const [double, setDouble] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { coachs, setCoachParams } = useAvailableCoach(true);  
  const calMultiRef = useRef();
  const coachTime = useRef("");
  const [maxUnit, setMaxUnit] = useState({});
  const [notes, setNotes] = useState(null);
  const [rstvTopics, setRstvTopics] = useState([]);
  const [intimation, setIntimation] = useState(null);  
  const [isCoachQualifiedForCP, setIsCoachQualifiedForCP] = useState(false);
  
  const coaches = useMemo(() => session.topic_restrictions === "none" ? coachs : coachs.filter(c => c.conv_pract), [session.topic_restrictions, coachs]);
  
  useEffect(() => {
    setIsCoachQualifiedForCP(coachs.find(c => c.id == session.coach_id)?.conv_pract ?? 0);    
  },[session.coach_id, coachs]);

  // This will update the allowed topics dropdown based on coach CP qualification
  useEffect(() => {  
    const topic_restrictions = isCoachQualifiedForCP ? "all_topics_plus_cp" : "none"; 
    //setSession({...session, topic_restrictions})      
  }, [isCoachQualifiedForCP])
  
  // useEffect(() => {
  //   if (session.type == "ExtraSession") {
  //     setOptions([
  //       { label: "All", value: "*" },
  //       ...coachs.map(({ id, full_name }) => ({ label: full_name, value: id })),
  //     ]);
  //     setSelectedOptions([
  //       { label: "All", value: "*" },
  //       ...coachs.map(({ id, full_name }) => ({ label: full_name, value: id })),
  //     ]);
  //   }
  // }, [sessions]);

  useEffect(() => {
    if (session.type == "ExtraSession") {
      setOptions([
        { label: "All", value: "*" },
        ...coaches.map(({ id, full_name, conv_pract }) => ({ label: full_name, value: id, conv_pract })),
      ]);
      setSelectedOptions([
        { label: "All", value: "*" },
        ...coaches.map(({ id, full_name, conv_pract }) => ({ label: full_name, value: id, conv_pract })),
      ]);
    }
  }, [coaches]);

  /*
   * This will keep the coaches selection if the topic changes from restricted to unrestricted.
   * But if topic goes from unrestricted to restricted, we de-select non qualified coaches that were selected
   * leaving this commented out because i am 90% sure they'll want this in the future. Dec 12, 2023
  useEffect(() => {
    if (session.type == "ExtraSession" && selectedOptions.length) {     
      if (session.topic_restriction == "none") {
        setSelectedOptions([...coaches.map(({ id, full_name, conv_pract }) => ({ label: full_name, value: id, conv_pract }))]);
      } else {
        console.log(selectedOptions)
        setSelectedOptions(selected);
      }     
    }
  }, [session.topic_restrictions]);
  */

  useEffect(() => {
    if (masterFilter.languageId != "" && props.openModal == true) {
      let additional = {};
      const value = masterFilter.languageId;
      dispatch({ language: value });
      if (!["cp", "yp", "fb", "rstv"].includes(value.toLowerCase())) additional.number_of_seats = 5;
      if (value.toLowerCase().includes("cp")) additional.number_of_seats = 0;
      if (value.toLowerCase().includes("yp")) additional.number_of_seats = 4;
      if (value.toLowerCase().includes("fb")) additional.number_of_seats = 6;
      if (value.toLowerCase().includes("solo")) additional.number_of_seats = 1;
      setSession({
        ...session,
        language_identifier: masterFilter.languageId,
        type: ["rstv", "cp"].some((e) => masterFilter.languageId.toLowerCase().includes(e))
          ? "session"
          : "ExtraSession",
        is_recurring: true,
        wildcard: false,
        topic_restrictions: /FB-.+-Solo/i.test(masterFilter.languageId) ? "all_topics_plus_cp" : "none",
        ...additional,
      });
      {masterFilter.languageId.toLowerCase().includes("rstv") &&
        getCoachRstvTopic(masterFilter.languageId).then((response) => {
          setRstvTopics(response.data)
        })
      }
    }
  }, [masterFilter.languageId, props.openModal]);

  useEffect(() => {
    console.log(session, coachs, props.shiftDetailParams.start_date, props.openModal);
    if (props.openModal == true) {
      if (coachTime.current != props.shiftDetailParams.start_date) {
        setCoachParams({
          language: masterFilter.languageId,
          session_start_time: moment(props.shiftDetailParams.start_date).format("YYYY-MM-DD HH:mm:ss"),
        });
        coachTime.current = props.shiftDetailParams.start_date;
      }
      store == "rstv" &&
        setModeratorParams({
          language: masterFilter.languageId,
          session_start_time: moment(props.shiftDetailParams.start_date).format("YYYY-MM-DD HH:mm:ss"),
        });
    }
  }, [session, coaches, props.shiftDetailParams.start_date, props.openModal]);

  //start from above
  // useEffect(() => {
  //   if (props.openModal == true && props.shiftDetailParams.start_date != "") {
  //     setSessionParams({
  //       start_time: moment(props.shiftDetailParams.start_date).unix() || "",
  //       language_identifier: masterFilter.languageId || "",
  //       region_id: "all",
  //       external_village_id: "all",
  //     });
  //   }
  // }, [masterFilter.languageId, props.shiftDetailParams.start_date, props.openModal]);

  const getInputDisableConfig = useMemo(() => {
    const disable = { village: false, seat: false, unit: false, lesson: false, topic: false, moderator: false };
    return {
      cp: disable,
      yp: disable,
      www: disable,
      solo: disable,
      fb: disable,
      totale: { ...disable, village: true, seat: true, unit: true, lesson: true },
      rstv: { ...disable, topic: true, moderator: true },
    };
  });

  function onSessionChange(e) {
    const target = e.target;
    if(e.target.name == "coach_id" && store == "totale")
    {
      let requestData = {
        'coach_id' : e.target.value,
        'language_id': masterFilter.languageId
      };
      getCoachMaxUnits(requestData).then((response) => {
        // let maxUnitValue = search(masterFilter.languageId, response.data.maxUnit)
        // console.log("Inside session change===========>" + JSON.stringify(maxUnitValue));
        setMaxUnit(response.data?.maxUnit)
        let int = `Max Level:${response.data.maxLevel || 0} Unit:${response.data.maxUnit || 0} Lesson:4`;
            setIntimation(int);
        }).catch((err) => {
          console.log("Error occured during MaxUnitLevel Fetch :" + err);
          setIntimation(null)
        })
    }
    if(e.target.name == "coach_id")
    {
      if(e.target.value != "")
      {
        
        coachShedulerAction
        .checkRecurrings({
          coach_id: e.target.value,
          session_start_time: moment
            .utc(settings.sessionDate)
            .format("yyyy-MM-DD HH:mm:ss"),
          day_index: moment.utc(settings.sessionDate).day(),
        })
        .then((result) => {
          if(result.disable_recurring) //CSP-2584
          {
          //  setSession({...session, ["is_recurring"]: false})
            setNotes(
              "This coach is already scheduled to have a recurring Session/Appointment starting on " +
                "the following week. You can only schedule a one-off Session/Appointment dialog at this time"
            )
            setCheckRecurring({...result, disable_recurring: true});
          }
          else
          {
           // setSession({...session, ["is_recurring"]: true})
           setNotes(null)
           setCheckRecurring({...result, disable_recurring: false});
          }
        });
      }
    }

    if(e.target.name == "moderator_id")
    {
      if(e.target.value != "")
      {
        coachShedulerAction
        .checkRecurrings({
          coach_id: e.target.value,
          session_start_time: moment
            .utc(settings.sessionDate)
            .format("yyyy-MM-DD HH:mm:ss"),
          day_index: moment.utc(settings.sessionDate).day(),
        })
        .then((result) => {
          if(result.disable_recurring) //CSP-2584
          {
          //  setSession({...session, ["is_recurring"]: false})
            setNotes(
              "This coach is already scheduled to have a recurring Session/Appointment starting on " +
                "the following week. You can only schedule a one-off Session/Appointment dialog at this time"
            )
            setModCheckRecurring({...result, disable_recurring: true});
          }
          else
          {
           // setSession({...session, ["is_recurring"]: true})
           setNotes(null)
           setModCheckRecurring({...result, disable_recurring: false});
          }
        });
      }
    }

    const value = target.type === "checkbox" ? target.checked : target.value;
    setSession({ ...session, [target.name]: value });
  }

  function onChange(selectedItems, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (event.action === "deselect-option" && event.option.value === "*") {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(selectedItems.filter((o) => o.value !== "*"));
    } else if (selectedItems.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(selectedItems);
    }
  }
  // eslint-disable-next-line no-unused-vars
  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `All`;
    } else if (value.length === 0) {
      return `Select`;
    } else {
      return `${value.length} selected`;
    }
  }

  const promt = (msg) => {
    setDouble(false);
    alert(msg);
    return false;
  };

  function search(nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
        if (myArray[i].identifier === nameKey) {
            return myArray[i];
        }
    }
}

  function validate(payload) {
    if (store == "rstv") {
      for (const required of ["coach_id", "moderator_id", "topic_id"]) {
        if (payload[required] == "" || payload[required] == null)
          return promt(`Please select a ${required.split("_")[0]}`);
      }
      if (payload.coach_id == payload.moderator_id) return promt("Coach and Moderator should be different");
    }

    if (store == "totale") {
      if (session.type == "session") {
        for (const required of ["coach_id"]) {
          if (payload[required] == "" || payload[required] == null)
            return promt(`Please select a ${required.split("_")[0]}`);
        }
      } else {
        const Soptions = [...selectedOptions.map((opt) => opt.value)];
        if (Soptions.length == 0 || selectedOptions == []) {
          return promt(`Please select a Coach`);
        }
      }
    }
    return true;
  }

  function onSessionSubmit() {
    setDouble(true);
    const payload = sessionPayload({ type: store, payload: session });
    const start_time =
      store == "fb"
        ? moment(settings.sessionDate).format("mm") == "30"
          ? moment(settings.sessionDate).subtract(30, "minutes")
          : settings.sessionDate
        : settings.sessionDate;
    if (!validate(payload)) return;

    const Soptions = ["*", ...selectedOptions.map((opt) => opt.value)];

    
    // if (maxUnitInfo.length != 0) {
    //   const unitInfo = search(payload.language_identifier, maxUnitInfo)
      if(store == "totale" && payload.unit != '' && payload.type == "session")
      {
          if (payload.unit > maxUnit) {
            alert("Coach is not qualified to teach selected unit");
            setDouble(false);
            return;
          }
      }
    //  }
    props.actions
      .addSessions(
        {
          ...payload,
          language_type: store,
          is_recurring: (checkRecurring.disable_recurring || modCheckRecurring.disable_recurring) ? false : payload.is_recurring,
          ...(["solo", "fb", "yp"].includes(store)
            ? { max_unit: 1, unit: payload.unit || null }
            : store == "cp"
            ? { max_unit: 20, unit: false }
            : { unit: payload.unit || null }), //max_unit
          ...(session.type == "ExtraSession"
            ? { excluded_coaches: options.filter((option) => !Soptions.includes(option.value)).map((opt) => opt.value) }
            : { coach_id: payload.coach_id }),
          session_start_time: moment(moment(start_time).format()).format("YYYY-MM-DD HH:mm:ss"),
          session_end_time: moment(moment(start_time).format())
            .add(store == "fb" ? 60 : 30, "minutes")
            .format("YYYY-MM-DD HH:mm:ss"),
          teacher_confirmed: true,
          ...(session.type == "ExtraSession"
            ? { name: document.getElementById("session-name-txt").value }
            : { name: "" }),
          number_of_sessions: parseInt(session.number_of_sessions),
        },
        masterFilter
      )
      .then(() => {
        setDouble(false);
        setSession({
          ...createObject,
          coach_id: "",
          is_recurring: true,
          language_identifier: masterFilter.languageId,
          type: ["rstv", "cp"].some((e) => masterFilter.languageId.toLowerCase().includes(e))
            ? "session"
            : "ExtraSession",
          max_unit: 20,
          wildcard: false,
          recurring_frequency: 1,
          recurring_end_date: "",
          number_of_sessions: "1",
          foo: "bar",
          topic_restrictions: isCoachQualifiedForCP ? "all_topics_plus_cp" : "none"
        });
        setSettings((settings) => ({ ...settings, ["displaySession"]: false }));
        coachTime.current = "";
        props.setOpenModal(false);
      })
      .catch(() => {
        setDouble(false);
        // console.log(err);
        // if (err?.message?.error_message) toast.error(err.message.error_message);
        // else if (err?.message?.error) toast.error(err.message.error);
        // else if (err?.message) toast.error(err.message);
        // else if (err) toast.error(err);
        // else toast.error("Failed to create...");
        toast.error("Failed to create...");
        setSession(createObject);
        setSettings((settings) => ({ ...settings, ["displayCreate"]: false }));
      });
  }

  function formStyle(prop = "") {
    const sessionType = session.type == "session" || session.type == "ExtraSession";
    const sessionInput = { display: sessionType ? (prop == "" ? "block" : prop) : "none" };
    const sessionLanguageInput = { ...(session.language_identifier == "all" ? { display: "none" } : sessionInput) };
    // const recurringInput = {
    //   display: session.is_recurring && session.type === "session" ? (prop == "" ? "block" : prop) : "none",
    // };

    const recurringInput = {
      display: session.is_recurring && !checkRecurring.disable_recurring && !modCheckRecurring.disable_recurring && session.type === "session" ? (prop == "" ? "block" : prop) : "none",
    };

    return {
      language: sessionInput,
      village: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      seats: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      unit: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      level: store == "rstv" ? { display: "none" } : sessionLanguageInput,
      is_recurring: recurringInput,
    };
  }

  const fieldStyle = useCallback(() => {
    return getInputDisableConfig[store];
  }, [store]);

  return (
    <>
      <div className="blockOverlay" style={{ display: double ? "block" : "none" }}></div>
      <div className="blockMsg" style={{ display: double ? "block" : "none" }}>
        <h1>Please wait...</h1>
      </div>
      <div
        className="modal coach-schedule-session-details-modal master-schedule-create-edit-modal"
        id="master-schedule-create-session"
        style={{
          display: props.openModal ? "block" : "none",
          left: settings.xAxis > 610 ? settings.xAxis - 47 - 475 + "px" : settings.xAxis - 47 - 475 + 440 + "px",
          top: settings.yAxis - 370 + "px",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal_header">
              <h4 className="modal-title">
                CREATE NEW SESSION at{" "}
                <span style={{ color: "#1090CF" }}>
                  {moment(moment(settings.sessionDate).format()).format("ddd MM/DD/YY hh:mm A")}
                </span>
              </h4>
              <a
                className="close-icon"
                title="Close"
                aria-label="Close"
                role="button"
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  setSession({
                    ...createObject,
                    language_identifier: masterFilter.languageId,
                    is_recurring: true,
                    type: "session",
                    max_unit: 20,
                    wildcard: false,
                    number_of_sessions: "1"
                  });
                  // setSettings((settings) => ({ ...settings, ["displaySession"]: true }));
                  props.setOpenModal(false);
                  if(document.getElementById("session-coach-dropdown"))
                  document.getElementById("session-coach-dropdown").value = "";
                }}
              >
                <span>×</span>
              </a>
            </div>
            <div className="modal-body">
              <div className="left-section">
                {/* only show conv topics for fb solo. IDs are in the format FB-ITA-Solo */}
                {/FB-.+-Solo/i.test(masterFilter.languageId) && 
                  <div className="topic-restriction">
                    <div>Allowed Topics</div>    
                    <select name='topic_restrictions' onChange={onSessionChange}>
                        {topicTypes.map(({ name, value }) => (
                          <option 
                            key={value} 
                            value={value}
                            selected={session.topic_restrictions === value}
                            >                        
                              { name }
                            </option>
                        ))}
                      </select>                    
                  </div>
                }

                <div className="coach-availabe">
                  {session.type == "session" ? <span>Coach:</span> : <span>Included Coaches:</span>}
                  {session.type == "session" ? (
                    <select
                      className="session-dropdown"
                      id="session-coach-dropdown"
                      name="coach_id"
                      value={session.coach_id}
                      onChange={onSessionChange}
                    >
                      <option value="">--AVAILABLE--</option>
                      {coaches.map(({ id, full_name }) => (
                        <option key={id} value={id}>
                          {full_name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <ReactMultiSelectCheckboxes
                      options={options}
                      value={selectedOptions}
                      onChange={onChange}
                      ref={calMultiRef}
                      setState={setSelectedOptions}
                      getDropdownButtonLabel={getDropdownButtonLabel}
                    />
                  )}
                </div>
                <div className="village-dropdown">
                  <div>(Optional)</div>
                  <select
                    name="external_village_id"
                    value={session.external_village_id}
                    onChange={onSessionChange}
                    disabled={!fieldStyle().village}
                  >
                    <option value="">Select a Village</option>
                    {villages.length > 0 &&
                      villages.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                  </select>
                </div>
                <div style={formStyle().seats}>
                  <span>Number of Seats : </span>
                  <span>
                    <select
                      name="number_of_seats"
                      value={session.number_of_seats}
                      onChange={onSessionChange}
                      disabled={!fieldStyle().seat}
                    >
                      {_.range(1, 11).map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>                
                {(session.type === "ExtraSession" && <div style={formStyle().seats}>
                  <span className="number_of_sessions">No. of Sessions : </span>
                  <span>
                    <select
                      name="number_of_sessions"
                      value={session.number_of_sessions}
                      onChange={onSessionChange}
                      data-start-time={session.session_start_time}
                    >
                      {_.range(1, 11).map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </span>
                </div> )}
                <div className="moderator-div">
                  <select
                    id="moderator"
                    name="moderator_id"
                    value={session.moderator_id}
                    onChange={onSessionChange}
                    style={
                      store != "rstv"
                        ? { display: "none" }
                        : session.type === "appointment"
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <option value="">Select Moderator</option>
                    {moderators.map(({ id, full_name }) => (
                      <option key={id} value={id}>
                        {full_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="topic-div">
                  <select
                    className="appointment-dropdown"
                    style={
                      store != "rstv"
                        ? { display: "none" }
                        : session.type === "appointment"
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    name="topic_id"
                    value={session.topic_id}
                    onChange={onSessionChange}
                  >
                    <option value="">-Topic-</option>

                    {/* {props.rstvResponse.length > 0 &&
                      props.rstvResponse.map((rstv) => (
                        <option key={rstv.id} value={rstv.id}>
                          {rstv.title.length > 50 ? rstv.title.substring(0,50) : rstv.title}
                        </option>
                      ))} */}

                  {rstvTopics.length > 0 &&
                      rstvTopics.map((rstv) => (
                        <option key={rstv.id} value={rstv.id}>
                          {rstv.title.length > 50 ? rstv.title.substring(0,50) : rstv.title}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="right-section">
                <div>
                  <span className="margin-right-label">Session Type</span>
                  <select
                    className="session-dropdown"
                    id="session-dropdown"
                    name="type"
                    value={session.type}
                    onChange={onSessionChange}
                    disabled={["rstv", "cp"].includes(store) ? true : false}
                  >
                    <option value="session">Standard Session</option>
                    <option value="ExtraSession">Extra Session</option>
                  </select>
                </div>
                {session.type === "session" && (
                  <div className="recurring-checkbox">
                    <span className="margin-right-label">Recurring</span>
                    <span>
                      <input
                        name="is_recurring"
                        type="checkbox"
                        checked={session.is_recurring && !checkRecurring.disable_recurring && !modCheckRecurring.disable_recurring ? "checked" : ""}
                        onChange={onSessionChange}
                        disabled={(checkRecurring.disable_recurring || modCheckRecurring.disable_recurring) ? true : false}
                         //disabled={checkRecurring.disable_recurring || settings.fromDetails == true}
                      />
                    </span>
                  </div>
                )}
                <div className="recurring-config">
                  <div style={formStyle().is_recurring}>
                    <span className="margin-right-label">Recurring Frequency</span>
                    <span>
                      <select
                        id="recurring_freq"
                        name="recurring_frequency"
                        value={session.recurring_frequency}
                        onChange={onSessionChange}
                        style={{ verticalAlign: "bottom" }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                      </select>
                    </span>
                  </div>
                  <div style={formStyle().is_recurring}>
                    <span className="margin-right-label" style={{ float: "left" }}>
                      End at
                    </span>
                    <span className="end-recurring-at">
                      <div className="customDatePickerWidth">
                        <DatePicker
                          selected={
                            moment(session.recurring_end_date, "yyyy-MM-DD").isValid()
                              ? moment(session.recurring_end_date, "yyyy-MM-DD").toDate() //.format("MM/DD/yyyy")
                              : null
                          }
                          minDate={moment(settings.sessionDate).toDate()}
                          id="end-recurring-at"
                          name="recurring_end_date"
                          onChange={(date) => {
                            setSession({
                              ...session,
                              recurring_end_date: moment(date, "MM/DD/yyyy").format("yyyy-MM-DD"), //date, //moment(date, "MM/DD/YYYY").format("yyyy-MM-DD "),
                            });
                          }}
                        />
                      </div>
                    </span>
                  </div>
                  <div className="confirmed-div" style={{ float: "left" }}>
                    <span className="margin-right-label">Confirmed</span>
                    <span>
                      <input
                        // checked={session.teacher_confirmed ? "checked" : ""}
                        checked="checked"
                        id="teacher_confirmed"
                        name="teacher_confirmed"
                        type="checkbox"
                        onChange={onSessionChange}
                      />
                    </span>
                  </div>
                  {session.type !== "session" && (
                    <div className="session-name clear-fix">
                      <span>Session Name</span>
                      <input id="session-name-txt" name="session-name-txt" type="text" />
                    </div>
                  )}
                  <div className="unit-lesson-section clear-fix" style={(formStyle("block").unit, formStyle().seats)}>
                    <div>
                      <span>Unit</span>
                      <select
                        className="level-unit-dropdown"
                        name="unit"
                        value={session.unit}
                        onChange={onSessionChange}
                        disabled={!fieldStyle().unit}
                      >
                        <option value="">-Unit-</option>
                        {_.range(1, 21).map((num) => (
                          <option key={num} value={num}>
                            {num}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <span>Lesson</span>
                      <select
                        className="level-unit-dropdown"
                        name="lesson"
                        value={session.lesson}
                        onChange={onSessionChange}
                        disabled={!fieldStyle().lesson}
                      >
                        <option value="">-Lesson-</option>
                        <option value="2">2</option>
                        <option value="4">4</option>
                      </select>
                    </div>
                  </div>
                  {store == "totale" && session.type != "ExtraSession" &&
                    <div className="max-unit-lesson">{intimation}</div>
                  }
                </div>
              </div>
            </div>
            <div className="footer">
              <div>
                <input
                  type="button"
                  disabled={double}
                  value="CREATE"
                  className="footer-button"
                  onClick={onSessionSubmit}
                />
              </div>
            </div>
          </div>
          {notes && (
              <div
                className={clsx(classes.footer, classes.bold_font)}
                style={{ maxWidth: "475px" }}
              >
                Note: {notes}
              </div>
          )}
        </div>
      </div>
    </>
  );
}

CreateShiftModal.defaultProps = {
  masterFilter: { languageId: "all" },
};

CreateShiftModal.propTypes = {
  settings: propTypes.object,
  setSettings: propTypes.func,
  SessionDetails: propTypes.object,
  masterFilter: propTypes.object,
  shiftDetailParams: propTypes.object,
  openModal: propTypes.bool.isRequired,
  setOpenModal: propTypes.func,
  coachProfile: propTypes.array.isRequired,
  rstvResponse: propTypes.array.isRequired,
  actions: propTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return { 
    coachProfile: state.coachProfile, 
    rstvResponse: state.rstvStore.rstvResponse
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      addSessions: bindActionCreators(masterShedulerAction.addMasterScheduler, dispatch),
      getRstvfunction: bindActionCreators(RstvAction.getRstv, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateShiftModal);
