import { GET_PRODUCT_REPORT } from "../constants";
import initialState from "./initialState";

export default function ProductReportReducer(state = initialState.productRights, action) {
  switch(action.type){
    case "GET_PRODUCT_REPORT":
      return action.productRights;
    default: 
      return state;
  }
}