export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";

export const GET_RSTV_SUCCESS = "GET_RSTV_SUCCESS";
export const UPD_RSTV_SUCCESS = "UPD_RSTV_SUCCESS";
export const ADD_RSTV_SUCCESS = "ADD_RSTV_SUCCESS";

export const GET_NON_MANAGE_LANG_SUCCESS = "GET_NON_MANAGE_LANG_SUCCESS";
export const GET_MANAGE_LANG_SUCCESS = "GET_MANAGE_LANG_SUCCESS";
export const DELETE_MANAGE_LANG_SUCCESS = "DELETE_MANAGE_LANG_SUCCESS";

export const ADD_COACH_ALERT_SUCCESS = "ADD_COACH_ALERT_SUCCESS";
export const GET_COACH_ALERT_SUCCESS = "GET_COACH_ALERT_SUCCESS";
export const DEL_COACH_ALERT_SUCCESS = "DEL_COACH_ALERT_SUCCESS";

export const GET_LINKS_SUCCESS = "GET_LINKS_SUCCESS";
export const ADD_LINKS_SUCCESS = "ADD_LINKS_SUCCESS";
export const UPD_LINKS_SUCCESS = "UPD_LINKS_SUCCESS";
export const DEL_LINKS_SUCCESS = "DEL_LINKS_SUCCESS";

export const GET_SESSIONS_SUCCESS = "GET_SESSIONS_SUCCESS";
export const GET_RECIPIENTS_SUCCESS = "GET_RECIPIENTS_SUCCESS";

export const GET_COACH_PROFILE_SUCCESS = "GET_COACH_PROFILE_SUCCESS";

export const GET_CONTACT_PREFERENCE_SUCCESS = "GET_CONTACT_PREFERENCE_SUCCESS";

export const UPD_CONTACT_PREFERENCE_SUCCESS = "UPD_CONTACT_PREFERENCE_SUCCESS";
// export const GET_COACH_PROFILE_SUCCESS = "GET_COACH_PROFILE_SUCCESS";
export const UPD_COACH_PROFILE_SUCCESS = "UPD_COACH_PROFILE_SUCCESS";

export const GET_STUDIO_LIBRARY_SUCCESS = "GET_STUDIO_LIBRARY_SUCCESS";
export const ADD_STUDIO_LIBRARY_SUCCESS = "ADD_STUDIO_LIBRARY_SUCCESS";
export const UPD_STUDIO_LIBRARY_SUCCESS = "UPD_STUDIO_LIBRARY_SUCCESS";
export const DEL_STUDIO_LIBRARY_SUCCESS = "DEL_STUDIO_LIBRARY_SUCCESS";

export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS";
export const GET_VILLAGE_SUCCESS = "GET_VILLAGE_SUCCESS";
export const GET_REGION_SUCCESS = "GET_REGION_SUCCESS";

export const BEGIN_LEARNER_API_CALL = "BEGIN_LEARNER_API_CALL";
export const GET_LEARNER_SUCCESS = "GET_LEARNER_SUCCESS";
export const UPD_LEARNER_SUCCESS = "UPD_LEARNER_SUCCESS";
export const EMPTY_LEARNER = "EMPTY_LEARNER";
export const SEARCH_LEARNER_SUCCESS = "SEARCH_LEARNER_SUCCESS";

export const GET_COMMENTS = "GET_COMMENTS";
export const ADD_COMMENT = "ADD_COMMENT";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const GET_STUDIO_ON_DUTY_SUCCESS = "GET_STUDIO_ON_DUTY_SUCCESS";
export const ADD_STUDIO_ON_DUTY_SUCCESS = "ADD_STUDIO_ON_DUTY_SUCCESS";
export const MDY_GLOBAL_SETTINGS = "MDY_GLOBAL_SETTINGS";

export const GET_LEARNER_CONSUMABLES_SUCCESS = "GET_LEARNER_CONSUMABLES_SUCCESS";
export const ADD_LEARNER_CONSUMABLES_SUCCESS = "ADD_LEARNER_CONSUMABLES_SUCCESS";
export const DEL_LEARNER_CONSUMABLES_SUCCESS = "DEL_LEARNER_CONSUMABLES_SUCCESS";

export const GET_VILLAGE_LIST_SUCCESS = "GET_VILLAGE_LIST_SUCCESS";
export const UPD_VILLAGE_LIST_SUCCESS = "UPD_VILLAGE_LIST_SUCCESS";
export const ADD_VILLAGE_LIST_SUCCESS = "ADD_VILLAGE_LIST_SUCCESS";

export const GET_APPOINTMENT_SUCCESS = "GET_APPOINTMENT_SUCCESS";
export const ADD_APPOINTMENT_SUCCESS = "ADD_APPOINTMENT_SUCCESS";
export const UPD_APPOINTMENT_SUCCESS = "UPD_APPOINTMENT_SUCCESS";

export const GET_COMMENT_SUCCESS = "GET_COMMENT_SUCCESS";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const UPD_COMMENT_SUCCESS = "UPD_COMMENT_SUCCESS";

export const GET_RESOLUTIONS_SUCCESS = "GET_RESOLUTIONS_SUCCESS";
export const ADD_RESOLUTIONS_SUCCESS = "ADD_RESOLUTIONS_SUCCESS";
export const UPD_RESOLUTIONS_SUCCESS = "UPD_RESOLUTIONS_SUCCESS";

export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_SUBSTITUTIONS_SUCCESS = "GET_SUBSTITUTIONS_SUCCESS";
export const GET_ADDITIONAL_SUBSTITUTIONS_SUCCESS = "GET_ADDITIONAL_SUBSTITUTIONS_SUCCESS";

// Substitution

export const GET_TRACK_USERS_SUCCESS = "GET_TRACK_USERS_SUCCESS";
export const GET_TRACK_USERS_NAMES_SUCCESS = "GET_TRACK_USERS_NAMES_SUCCESS";
export const GET_TRACK_USERS_ROLE_SUCCESS = "GET_TRACK_USERS_ROLE_SUCCESS";

export const GET_ADMIN_DASHBOARD_SETTINGS = "GET_ADMIN_DASHBOARD_SETTINGS";
export const UPD_ADMIN_DASHBOARD_SETTINGS = "UPD_ADMIN_DASHBOARD_SETTINGS";

export const GET_COACH_SCHEDULER_SUCCESS = "GET_COACH_SCHEDULER_SUCCESS";
export const ADD_COACH_SCHEDULER_SUCCESS = "ADD_COACH_SCHEDULER_SUCCESS";
export const UPD_COACH_SCHEDULER_SUCCESS = "UPD_COACH_SCHEDULER_SUCCESS";

export const GET_AUDIT_LOGS_TABLE_NAME_SUCCESS = "GET_AUDIT_LOGS_TABLE_NAME_SUCCESS";
export const GET_AUDIT_LOGS_SUCCESS = "GET_AUDIT_LOGS_SUCCESS";

export const GET_REGIONS_SUCCESS = "GET_REGIONS_SUCCESS";
export const ADD_REGIONS_SUCCESS = "ADD_REGIONS_SUCCESS";
export const UPD_REGIONS_SUCCESS = "UPD_REGIONS_SUCCESS";
export const DEL_REGIONS_SUCCESS = "DEL_REGIONS_SUCCESS";

export const GET_SESSION_DETAILS_SUCCESS = "GET_SESSION_DETAILS_SUCCESS";
export const ADD_SESSION_DETAILS_SUCCESS = "ADD_SESSION_DETAILS_SUCCESS";

export const GET_UNASSIGNED_COACH_SUCCESS = "GET_UNASSIGNED_COACH_SUCCESS";
export const GET_COACH_MANAGER_SUCCESS = "GET_COACH_MANAGER_SUCCESS";
export const GET_COACH_MANAGER_ASSIGNED_SUCCESS = "GET_COACH_MANAGER_ASSIGNED_SUCCESS";


export const GET_COACHROSTER_SUCCESS = "GET_COACHROSTER_SUCCESS";

export const GET_MANAGEMENTTEAM_SUCCESS = "GET_MANAGEMENTTEAM_SUCCESS";
export const UPD_MANAGEMENTTEAM_SUCCESS = "UPD_MANAGEMENTTEAM_SUCCESS";
export const ADD_MANAGEMENTTEAM_SUCCESS = "ADD_MANAGEMENTTEAM_SUCCESS";

export const GET_APPOINTMENT_SCHEDULER_SUCCESS = "GET_APPOINTMENT_SCHEDULER_SUCCESS";
export const REQUEST_SUBSTITUTE_COACH = "REQUEST_SUBSTITUTE_COACH";

export const GET_GENERATE_REPORT_SUCCESS = "GET_GENERATE_REPORT_SUCCESS";
export const GET_APPOINTMENT_DETAILS_SUCCESS = "GET_APPOINTMENT_DETAILS_SUCCESS";
export const GET_QUALIFIED_COACHES = "GET_QUALIFIED_COACHES";

export const GET_SCHEDULINGTHRESHOLD_SUCCESS = "GET_SCHEDULINGTHRESHOLD_SUCCESS";
export const UPD_SCHEDULINGTHRESHOLD_SUCCESS = "UPD_SCHEDULINGTHRESHOLD_SUCCESS";
export const GET_AVAILABLE_COACHES = "GET_AVAILABLE_COACHES";

export const GET_TIME_OFF_LIST_SUCCESS = "GET_TIME_OFF_LIST_SUCCESS";
export const CREATE_TIME_OFF_LIST_SUCCESS = "CREATE_TIME_OFF_LIST_SUCCESS";

export const GET_MASTER_SCHEDULER_SUCCESS = "GET_MASTER_SCHEDULER_SUCCESS";
export const ADD_MASTER_SCHEDULER_SUCCESS = "ADD_MASTER_SCHEDULER_SUCCESS";
export const GET_SHIFT_DETAILS_SUCCESS = "GET_SHIFT_DETAILS_SUCCESS";
export const GET_SHIFT_SESSION_DETAILS_LOADING = "GET_SHIFT_SESSION_DETAILS_LOADING";
export const GET_SHIFT_SESSION_DETAILS_SUCCESS = "GET_SHIFT_SESSION_DETAILS_SUCCESS";
export const GET_SHIFT_SESSION_DETAILS_FAILURE = "GET_SHIFT_SESSION_DETAILS_FAILURE";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const GET_SUBSTITUTION_LIST_SUCCESS = "GET_SUBSTITUTION_LIST_SUCCESS";
export const GET_SUBSTITUTION_FILTER_LIST_SUCCESS = "GET_SUBSTITUTION_FILTER_LIST_SUCCESS";
export const GET_SUBSTITUTION_FETCH_COACH_LIST_SUCCESS = "GET_SUBSTITUTION_FETCH_COACH_LIST_SUCCESS";
export const GET_SUBSTITUTION_REPORT_LIST_SUCCESS = "GET_SUBSTITUTION_REPORT_LIST_SUCCESS";
export const GET_SUBSTITUTION_FULL_REPORT_LIST_SUCCESS = "GET_SUBSTITUTION_FULL_REPORT_LIST_SUCCESS";
export const GET_FUTURE_SESSION = "GET_FUTURE_SESSION";
export const GET_WEEKLY_THRESHOLD = "GET_WEEKLY_THRESHOLD";
export const CANCEL_SUBSTITUTIONS = "CANCEL_SUBSTITUTIONS";
export const REASSIGN_SUBSTITUTIONS = "REASSIGN_SUBSTITUTIONS";
export const REASSIGN_SUBSTITUTE = "REASSIGN_SUBSTITUTE";
export const CANCEL_SUBSTITUTE = "CANCEL_SUBSTITUTE";

export const GET_QA_FORMS_LIST_SUCCESS = "GET_QA_FORMS_LIST_SUCCESS";
export const GET_QA_FORMS_SCORE_LIST_SUCCESS = "GET_QA_FORMS_SCORE_LIST_SUCCESS";
export const GET_QA_FORMS_RATINGS_LIST_SUCCESS = "GET_QA_FORMS_RATINGS_LIST_SUCCESS";
export const GET_QA_FORMS_CATEGORIES_LIST_SUCCESS = "GET_QA_FORMS_CATEGORIES_LIST_SUCCESS";
export const GET_QA_FORMS_CRITERIA_LIST_SUCCESS = "GET_QA_FORMS_CRITERIA_LIST_SUCCESS";
export const GET_QA_FORMS_UNIQUE_LIST_SUCCESS = "GET_QA_FORMS_UNIQUE_LIST_SUCCESS";
export const GET_QA_FORMS_NONUNIQUE_LIST_SUCCESS = "GET_QA_FORMS_NONUNIQUE_LIST_SUCCESS";
export const DEL_DRAFT_QA_FORM_SUCCESS ="DEL_DRAFT_QA_FORM_SUCCESS";
export const GET_QA_FORMS_ENTRIES_SUCCESS = "GET_QA_FORMS_ENTRIES_SUCCESS";
export const UPDATE_QA_FORMS_ENTRIES_SUCCESS = "UPDATE_QA_FORMS_ENTRIES_SUCCESS";
export const GET_COACH_SESSIONS_SUCCESS = "GET_COACH_SESSIONS_SUCCESS";
export const GET_COACH_SCORE_SHEET_SUCCESS = "GET_COACH_SCORE_SHEET_SUCCESS";
export const GET_COACH_SESSION_RATING_SUCCESS = "GET_COACH_SESSION_RATING_SUCCESS";
export const GET_COACH_EVALUATION_SUCCESS = "GET_COACH_EVALUATION_SUCCESS";
export const GET_COMPARATIVE_EVALUATION_SUCCESS = "GET_COMPARATIVE_EVALUATION_SUCCESS";

export const GET_APPLICATION_STATUS = "GET_APPLICATION_STATUS";
export const GET_PRODUCT_REPORT = "GET_PRODUCT_REPORT";
export const GET_CONSUMABLES_REPORT = "GET_CONSUMABLES_REPORT";
export const GET_AUTORENEWAL_REPORT = "GET_AUTORENEWAL_REPORT";
export const GET_LEARNER_WATERMARK = "GET_LEARNER_WATERMARK";
export const GET_LEARNER_TIMESPENT = "GET_LEARNER_TIMESPENT";
export const GET_LOGGED_IN_HISTORY = "GET_LOGGED_IN_HISTORY";
export const GET_SCORE_UPDATE = "GET_SCORE_UPDATE";
export const GET_INACTIVE_COACH_SUB="GET_INACTIVE_COACH_SUB";
export const GET_VIEW_LIST="GET_VIEW_LIST";