import React from 'react'
import propTypes from 'prop-types' 

import close from "../images/closelabel.gif"
import "./Modal.css"


class Modal extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            toggle:false,
            input:true,
            text:"",
            // count:1000
        }

        this.inputRef = React.createRef();

        this.typeAlert = this.typeAlert.bind(this);
        // this.removeAlert = this.removeAlert.bind(this);
        this.addAlert = this.addAlert.bind(this);
    }
  
    setToggle(e){
        e.preventDefault();
        this.props.getAlert();
        this.setState(state => state.toggle = !state.toggle);
    } 

    typeAlert(e){
        let length = [...e.target.value].length;
        this.inputRef.current.innerText =  1000 - length;
        if([...e.target.value].length > 0) this.setState({input:false,text:e.target.value})
        else this.setState({input:true,text:e.target.value})
    }

    addAlert(e){
        e.preventDefault();
        this.props.addAlert(this.state.text);
        this.props.setToggle(!this.props.toggle);
    }
    removeAlert(alert){      
      
        this.props.removeAlert(alert);        
        this.setState({text:""});
        this.inputRef.current.innerText = 1000;
        this.props.setToggle(!this.props.toggle);            
    }

    componentDidMount(){        
        const { alert } = this.props;
        this.inputRef.current.innerText = 1000 - (alert? alert.description.length : 0);
        this.setState({text:alert?.description})
    }

   render(){
       const { input } = this.state;
       const { coachAlert ,toggle, alert, userType } = this.props;
      
    return (
        <div>
      <div className="modal" style={{"display":(toggle)?"block":"none"}} id="coach_alert_modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal_header">
              <h4 className="modal-title" style={{color: userType !== "Coach" ? "#000" : "#FF0000"}}>{userType !== "Coach" ? "Manage Coach Alert" : "Coach Alert"}</h4>
              <button type="button" className="close" onClick={coachAlert} > 
                <img src={close} title="close" className="close_image"/> 
              </button>
            </div>
            <div className="modal-body">
              <div style={{display: userType !== "Coach" ? "block" : "none"}}>
                <textarea id="coach_alert_message" name="coach_alert_message" rows="5"  
                onInput={this.typeAlert} maxLength="1000" defaultValue={this.state.text}/>
                <p>Characters remaining: <span id="char_count" ref={this.inputRef}>{/*this.count.current||1000*/}</span></p>
              </div>
              <p style={{display: userType === "Coach" ? "block" : "none"}}>{this.state.text}</p>
            </div>
            <div className="footer">
              {userType !== "Coach" ? 
              <>
                <input disabled={alert != undefined && "description" in alert ? false : true} 
                  id="remove_alert" type="submit" value="Remove" onClick={() => this.removeAlert(alert)}/>
                <input disabled={input} onClick={this.addAlert}               
                  id="publish_alert" type="submit" value="Publish"/>
              </> :
              <>
                <input type="submit" value="I'VE READ IT" onClick={coachAlert}/>
              </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    )
    }
}

Modal.propTypes = {
  addAlert: propTypes.func.isRequired,
  setToggle: propTypes.func.isRequired,
  toggle: propTypes.bool.isRequired,
  removeAlert: propTypes.func.isRequired,
  coachAlert: propTypes.func.isRequired,
  alert: propTypes.object
}

export default Modal

